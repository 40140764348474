<template>
<!-- <v-dialog
    v-model="selectedOpen"
    persistent
    max-width="98%"
    width="100%"
    min-width="25rem"
    dense
> -->
    <v-dialog v-model="displayTaskSummary" overlay-opacity="1" overlay-color="rgba(13, 13, 13, .75)" width="90%" class="daily-summary" persistent max-width="75%"> 
        <v-card  style="border: var(--task2Do-dialog-dropdown-border);">
        
            <!-- <v-toolbar :color="editEventObj.color ? editEventObj.color : tempEventObj.color" dark> -->
            <v-toolbar dense :color="getSelectedTask.color" dark>
                <!-- <v-btn @click="deleteFirebaseEvent(getSelectedTask.id)" icon>
                <v-icon>mdi-delete</v-icon>
                </v-btn> -->
                <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                <v-toolbar-title
                    v-html="taskTitlebarText"
                ></v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn icon>
                    <!-- <v-icon>mdi-pencil</v-icon> -->
                    <v-icon  @click="closeTaskSummary()">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <!-- <v-card-text>{{ getSelectedTask.body }}</v-card-text> -->
            <v-card-text style="font-family: Prompt; font-weight: 400; color: rgb(200, 200, 225);">
                <div style="position: relative; display: inline-block; margin: 0.5rem 0.25rem; text-align: left; width: 92.5%; height: auto; border: 0.0875rem solid rgba(150, 175, 200, 0.95);padding: .5rem .25rem;">
                    <!-- <form v-if="tempEventObj.id !== getSelectedTask.id"> -->
                    
                        <!-- <span> Task #: {{ getSelectedTask.index }} </span> <br class=""> -->
                        <span><h2 style="font-size: 125%; line-height: 1.25;"> {{ getTaskTypes[getSelectedTask.type_id].typeName }}: <span style="color: rgb(225, 225, 250);"> {{ getSelectedTask.name }}</span></h2></span> <br class="">
                     
                        <h3><span> From: <strong style="font-size: 115%; color: rgb(25, 175, 25);"> {{ $datedJS.dated.getTwelveHourTime(getSelectedTask.start, false) }}</strong></span> 
                        <span> To: <strong style="font-size: 115%; color: rgb(25, 175, 25);"> {{ $datedJS.dated.getTwelveHourTime(getSelectedTask.end, false) }} </strong></span> (Duration: {{ $datedJS.dated.getFormattedMinDuration(getSelectedTask.duration) }})</h3>
                </div>
                <div v-if="getSelectedTask.body" style="position: relative; display: inline-block; margin: 0.5rem 0.25rem; text-align: left; width: 92.5%; max-height: 50vh; height: auto; overflow-y: auto;  border: 0.0875rem solid rgba(150, 175, 200, 0.95);padding: .5rem .25rem;">
                        <span style="text-align: left;"> <h3>Details: </h3><p style="font-size: 115%; font-weight: 500; line-height: 1.25;"><br class=""> <span style="color: rgb(225, 225, 250);" v-html="taskBodyHTML"> </span></p></span> <br class="">
                 
                </div>
                <!-- </v-container> -->
            </v-card-text>
            <v-card-actions>
                <v-btn style="left: 75%; background-color: rgba(13, 13, 128, .666); color: rgb(245, 250, 255); font-weight: 700; font-size: 1.125rem; margin: .125rem .25rem; padding: .25rem 1.125rem;"  outlined @click="closeTaskSummary()">
                    Close
                </v-btn>

                <v-btn text color="Primary"
                    v-if="((editingNewEvent === false) && (editingExistingEvent === false) && (selectedEventIndex !== null))"  
                    @click.prevent="editEvent(allTasks2Do[selectedTaskIndex])">
                    Edit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    name: "TaskSummary",
    // props: [
    //     "daySummaryDate"
    // ],
    data: () => ({
        componentName: "TaskSummary",
        shaped: false,
        outlined: false,
        rounded: false,
        // dense: false,
        autofocus: true
    }),
    computed: {
        ...mapGetters('tasks', {
            allTasks2Do: 'allTasks2Do',
            getTaskPriorityLevels: 'getTaskPriorityLevels',
            getTaskTypes: 'getTaskTypes',
            getEventsFromDate: 'getEventsFromDate',
            getTodaysTasks: 'getTodaysTasks',
            getUnFinishedTodos: 'getUnFinishedTodos',
            getSelectedTaskId: 'getSelectedTaskId',
            getSelectedTask: 'getSelectedTask'
        }),
        ...mapGetters('uiState', {
            getTaskSummaryState: 'getTaskSummaryState'
        }),
        displayTaskSummary() {
            return this.getTaskSummaryState.show && this.getSelectedTaskId;
        },
        selectedTaskIndex() {
            return this.getSelectedTask.index;
        },
        taskTitlebarText() {
            return  '<strong style="font-size: 115%;">' + this.$datedJS.dated.getMmDdYyyyDate(this.getSelectedTask.start) + '</strong>: <strong style="font-size: 115%;">' + this.getSelectedTask.name + '</strong> - #' + this.getSelectedTask.index
        },
        taskBodyHTML() {
            return this.getSelectedTask.body.replaceAll('\n', '<br class=\"\">');
        }
        // daySummaryDate() {
        //     console.log('this.getTaskSummaryState.date: ' + this.getTaskSummaryState.date);
        //     return this.getTaskSummaryState.date;
        // },
       
      
    },
    created() {
    
        console.log("Display TaskSummary created function called");
    },
    mounted() {
        console.log('daySummaryDate: ' + this.daySummaryDate);
    },
    methods: {
        ...mapActions( 'uiState', {
            toggleDaySummary: 'toggleDaySummary',
            openTaskSummary: 'openTaskSummary',
            closeTaskSummary: 'closeTaskSummary'
        }),
        onCloseButton() {
            console.log(`calling: toggleDaySummary`);
            // let today = new Date;
            this.closeTaskSummary();
           
        }
    }
};
</script>

<style>


</style>