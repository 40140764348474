import Vue from "vue";
import axios from "axios";

const state = {
    notes: [
        {
            id: 1,
            name: "My First Note"
        },
        {
            id: 2,
            name: "My second note"
        }
    ]
};

const getters = {
    allNotes: state => state.notes
    // getNoteUrl: (state => state.fetchUrl)
};

const actions = {
    async fetchNotes({ dispatch, commit , getters, rootGetters }) {
        return await new Promise((resolve, reject) => {
            // let notesUrl = "https://ts-api.t2do.io/fileorg/note/search/note_creator/" + store.getters.currentUser;
            // url: "https://ts-api.t2do.io/fileorg/note/search/note_creator/DevTeam",
            // console.log("notes requires url: " + 'https://ts-api.t2do.io/fileorg/note/search/note_creator/' + rootGetters.currentUser);
            Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            // axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            Vue.prototype.$http({
                url: 'https://ts-api.t2do.io/fileorg/note/search/note_creator/' + rootGetters['auth/getCurrentUserName'],
                method: "GET"
            }).then(response => {
                // console.log("notes query response: " + response.data.note_data + "\n");
                commit("SET_NOTES", response.data.note_data);
                resolve(response);
            }).catch(err => {
                console.log("Error: " + err);
                let statusObj =  { statusCode: err, status: 'failed-to-fetch-note-data-from-api', name: err.name, message: err.message};
                dispatch("authError", statusObj);
                // this.commit("auth_error", err);
                reject(err);
                // this.$store.dispatch("logoutUser").then(() => {
                // this.$router.push("/login");
                // });
                //localStorage.removeItem("jwt");         
            });
        });
    },
    async addNote( { dispatch, commit }, noteObj) {
        // url: 'https://ts-api.t2do.io/fileorg/note/add/' + store.getters.currentUserId,
        axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
        const response = await axios({
            url: 'https://ts-api.t2do.io/fileorg/note/add',
            method: 'POST',
            data: noteObj
        });
        commit('NEW_NOTE', noteObj);

    },
    async updateNote( { dispatch, commit, rootGetters }, noteObj) {
        return await new Promise((resolve, reject) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            axios({
                url: `https://ts-api.t2do.io/fileorg/note/edit/${noteObj.note_id}`,
                method: "PUT",
                data: noteObj
            }).then(response => {
                console.log("notes query response: " + response.data.note_data + "\n");
            
                commit("SET_NOTES",  response.data.note_data);
                resolve(response);
            }).catch(err => {
                console.log("Error: " + err);
                let statusObj =  { statusCode: err, status: 'failed-to-update-note-data-to-api', name: err.name, message: err.message};
                dispatch("authError", statusObj);
                // commit("auth_error", err);
                reject(err);
            });
        });
    },
    async deleteNote({ dispatch, commit, rootGetters }, id) {
        return await new Promise((resolve, reject) => {
            
            axios({
                url: `https://ts-api.t2do.io/fileorg/note/delete/${id}`,
                method: "DELETE"
            }).then(response => {
                console.log("notes query response: " + response.data.note_data + "\n");
                commit("REMOVE_NOTE", id);
                resolve(response);
            }).catch(err => {
                console.log("Error in notes.js: " + err);
                let statusObj =  { statusCode: err, status: 'failed-to-delete-note-data-from-api', name: err.name, message: err.message};
                dispatch("authError", statusObj);
            });
        });
    },

};

const mutations = {
    SET_NOTES: (state, notes) => (state.notes = notes),
    NEW_NOTE: (state, noteObj) => state.notes.unshift(noteObj),
    REMOVE_NOTE: (state, id) => (state.notes = state.notes.filter(note => note.id !== id))
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};