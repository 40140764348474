/**************************************************************************************************
 *                      Trinix Computer Systems - JS-Utils-n-Helpers
 *                             2020 (c) Trinix Computer Systems
 *                            J S - U T I L S - n - H E L P E R S
 *                                    Development Version
 *
 * Created by: Brandon Reilly on 01/29/20
 * Updated on: 02/13/20 @ 7:10PM
 * Updated on: 2020-03-31 @ 3:00PM
 *
 * This JS library contains a bunch of small helper functions that have come in handy at some point
 *
 **************************************************************************************************/

/**************************************************************************************************
 *                                  DOM Manipulation Functions
 **************************************************************************************************/

// function showForm(formId) {
  
//   let formContainerElement = document.getElementById(formId);
//   formContainerElement.classList.add("show");
//   let formFieldHeight = formContainerElement.getElementsByTagName("fieldset")[0]
//     .clientHeight;
//   formContainerElement
//     .getElementsByTagName("form")[0]
//     .style.setProperty("--form-fieldset-height", formFieldHeight + "px");
// }

// function hideForm(formId) {
//   let formContainerElement = document.getElementById(formId);
//   formContainerElement.classList.remove("show");
// }

/**************************************************************************************************
 *                                  Math Utility Functions
 **************************************************************************************************/

export function unsignInt(number) {
  if (number < 0) {
    number = number * -1;
  }
  return number;
}
// //shorter version
// const unsignInt = number => {
//     if (number < 0) number *= -1;
//     return number;
// }

// //the shortest version (unless someone has a idea how to make it shorter than 1 line)
//  const unsignInt = x => (x < 0) ? x*=-1 : x;

export const isEven = x => {
  //if remainder is 0 after deviding by 2 then x is even...return true, otherwiwse return false
  if (x % 2 === 0) {
    return true;
  }
  return false;
};

// const isEven = x => {
//     if(x % 2 === 0) return true;
//     return false;
// }

// const isEven = x => (x % 2 === 0) ? true : false;

export const isOdd = x => (x % 2 === 0 ? false : true);


// random number helper:
export function randomNum(x, y) {
  return Math.floor((y - x + 1) * Math.random()) + x;
}
/*****************************************************************************************
 *                                  Unique ID Generators
 ****************************************************************************************/
export function genUniqueId(prefix = "id") {
  let sanitizedPrefix = prefix.replace(/\s./g, "");
  let uniqueId = (
    sanitizedPrefix +
    "-" +
    Math.random()
      .toString(32)
      .substr(2, 16)
  ).toString();
  return uniqueId;
}
// Usage:
// document.getElementById("fileFolder-id").value = genUniqueId('dirId-fileFolder');

export const getCharUID = (prefix = "id-", idLength = 16) =>
  "" +
  (prefix.replace(/\s./g, "") +
    "-" +
    Math.random()
      .toString(idLength)
      .substring(2, idLength / 2)
      .toString());

// usage:
// let liTempId = getCharUID('temp-id', 32);

function genPosRandInt(maximumValue) {
  return Math.ceil(Math.random() * Math.floor(maximumValue));
}

// UUID v4 generator that is apparently RFC4122 compliant
// Basis of function from: https://gist.github.com/jcxplorer/823878
export function genUUIDv4() {
  let uuidV4 = "";
  let random;

  for (let i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0;

    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuidV4 += "-";
    }

    uuidV4 += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(
      16
    );
  }

  return uuidV4;
}

/******************************************************************************
 *                                  JSON Functions
 *****************************************************************************/

// This function is to be used as the replacer function in JSON.strinify when 
// a TypeError: cyclic object value is reported.
// TODO: understand the usage of WeakSet to a better degree. 
// Source found @: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
export function jsonCircularReplacer() {
  const objectSeen = new WeakSet();
  return(key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (objectSeen.has(value)) {
        return;
      }
      objectSeen.add(value);
    }
    return value;
  };
}


/******************************************************************************
 *                                  String Functions
 *****************************************************************************/

export function capitalizeString(stringToCapitalize = "") {
  if (stringToCapitalize !== "") {
    return (stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1));
  } else {
    return "";
  }
  
}


/******************************************************************************
 * Create copyright notice string with optional Entity name and year extablished arguments
 * @param {string} entityName The name of the entity that the copyright notice is for.
 * @param {string} yearEst  [yearEst=null] - The year in which the business or other entity was established. If not provided the copyright notice will just contain the current year.
 */
export function printCopyright(entityName = "", yearEst = null) {
  return `Copyright \u00A9 ${
    yearEst !== null ? yearEst + "-" : ""
  }${new Date().toISOString().substr(0, 4)} ${entityName ? entityName : ""}`;
}
