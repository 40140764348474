<template>
    <v-container class="widget" :class="[ minimizeState ? minimizeClassName : ' ',
        maximizeState ? maximizeClassName : ' ', focusedWidget === 'CalendarWidget' ? ' focusedWidget ' : ' backgroundWidget ', fixedWidget ? ' fixedWidget ' : '' ]" >

        <div style="position: absolute; display: block; background: unset; opacity: 0; left: 0; top: 0; width: 100%; height: 100%; z-index: 1500;"
            v-if="focusedWidget !== 'CalendarWidget'"
            @click.stop="$emit('bring-to-front', 'CalendarWidget')" >
        </div>
        
        <!-- <v-row class="task2Do-calendar" style="min-width: 40rem; max-width: 98vw; width: 95vw; max-height: 75vh; height: 66vh;" > -->
        
        <v-card class="widget-window-outer-container" :class="[ fixedWidget ? ' fixed-widget ' : '']" >
            <!-- <WindowBar :widgetLabel="widgetLabel"
                        :minimizeState="minimizeState"
                        v-on:minimize="minimize"
                        :maximizeState="maximizeState"
                        v-on:maximize="maximize"
                        :notifications="notificationsList"
                        :notificationType="notificationString"
                        :fixedWindow="fixedWidget" /> -->

            <SystemBar v-if="!fixedWidget" :widgetLabel="widgetLabel"
                        :minimizeState="minimizeState"
                        v-on:minimize="minimize"
                        :maximizeState="maximizeState"
                        v-on:maximize="maximize"
                        v-on:closeWidget="closeWidget"
                        :notifications="notificationsList"
                        :notificationType="notificationString"
                        :fixedWindow="fixedWidget" />

            <EditToolbar :editModeProp="editMode"
                :editToolbarItems="calendarEditToolbarItems"
                @createAppointment="createNewAppointment"
                @editSelectedAppointment="editSelectedAppointment"
                @displayTasks2DoToo="displayTasks2DoToo"
                @toPrevTimePeriod="toPrevTimePeriod"
                @toNextTimePeriod="toNextTimePeriod"
                @toggleCalendarScrollLock="toggleCalendarScrollLock"
                @setToday="setToday"
                @setTypeDay="setTypeDay"
                @setType4Day="setType4Day"
                @setTypeWeek="setTypeWeek"
                @setTypeMonth="setTypeMonth"
                @setCalendarType="setCalendarType"
                @setCalendarCategories="setCalendarCategories"
                @timeZoomIn="zoomInToCurrentTime"
                @timeZoomOut="zoomOutOfCurrentTime"
                :dropDownSelection="calendarDisplayTypeLabel"
                @deleteSelectedAppointment="deleteSelectedAppointment"
                />
                <!-- :infoDisplayBarData="computedInfoDisplayBarData" -->
			 <!-- > -->

              <!-- <v-btn outlined class="mr-4" @click="setToday">
                            Today
                            </v-btn>
                            <v-btn fab text small @click="prev">
                            <v-icon small>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn fab text small @click="next">
                            <v-icon small>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ title }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu bottom right>
                                <template v-slot:activator="{ on }">
                                    <v-btn outlined v-on="on">
                                        <span>{{ typeToLabel[type] }}</span>
                                        <v-icon right>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="type = 'day'">
                                        <v-list-item-title>Day</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = 'week'">
                                        <v-list-item-title>Week</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = 'month'">
                                        <v-list-item-title>Month</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = 'custom-daily'">
                                        <v-list-item-title>5 days</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu> -->
  
            <v-container  class="widget-inner-container" style="max-height: calc(90vh - 1rem); height: 100%; margin: -0.125rem auto .5rem; padding: .125rem;">
                <!-- <v-row dense>
                    <v-col cols="12"> -->
 <!-- @change="updateRange" -->
                <v-sheet @mouseup.right="logEvent" class="calendar-monthly-background">
                    <!-- :events="[...events, ...dailyEvents, tempEventObj]" -->
                    <v-calendar v-if="getComponentDisplayState.openWidgets.includes('CalendarWidget') && isDataLoadedFromAPI"
                        ref="calendar"
                        v-model="value"
                        color="primary"
                        :interval-height="calendarIntervalHeight"
                        :interval-minutes="calendarIntervalMinutes"
                        :interval-count="calendarIntervalCount"
                        interval-width="54px"
                        :first-time="calendarIntervalStartTime"
                        :max-days="maxDays"
                        :events="[...getCalendarEvents2Do, ...getDailyEvents, getSelectedTask]"
                        event-start="start_unix_timestamp"
                        event-end="end_unix_timestamp"
                        :event-color="getEventColor"
                        :event-ripple="true"
                        show-month-on-first="true"
                        :event-margin-bottom="4"
                        :event-category="calendarPropertyCategoryFilter"
                        event-overlap-mode="column"
                        event-overlap-threshold="30"
                        :now="today"
                        :type="calendarDisplayType"
                        :categories="calendarCategories"
                        :category-days="calendarCategoryDays"
                        :start="calendarStartDate"
                        :end="calendarEndDate"
                        category-show-all
                        @change="updateRange" 
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @mousedown:event="mouseDownTask"
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseup:event="mouseUpTask"
                        @mouseleave.native="cancelDrag"
                        @dblclick.native="dblClickTask"
                        @touchstart:event="touchStartDrag"
                        @touchstart:time="touchStart"
                        @touchmove:time="touchMove"
                        @touchend:event="mouseUpTask"
                        @touchend:time="endDrag"
                        @touchcancel.native="cancelDrag" 
                        >

                        <!-- @click:event="showEvent" -->
                        >
                      
                        <!-- event-overlap-mode="column" -->
                    
                        <template v-slot:event="{ event, timed, eventSummary }" >

            
                            <div  v-if="event.status_id === 10" class="v-event-draggable">
                                <v-icon large style="font-size: 1.75rem; color: rgb(0, 150, 0); text-shadow: 0rem 0.125rem 0.015rem rgba(13, 13, 13, 0.75); position: absolute; margin-top: -0.35rem;">mdi-check</v-icon><br class="">
                                <span  style="color: rgba(225, 225, 225, .666);" v-html="eventSummary()"></span>
                            </div>
                            <div  v-else-if="event.status_id === 6" class="v-event-draggable">
                                <v-icon large style="font-size: 1.75rem; color: rgb(200, 0,  0); text-shadow: 0rem 0.125rem 0.015rem rgba(13, 13, 13, 0.75); position: absolute; margin-top: -0.35rem;">mdi-close</v-icon><br class="">
                                <span  style="color: rgba(225, 225, 225, .666);" v-html="eventSummary()"></span>
                            </div>
                            <div v-else-if="event.type_id >= 2 && event.type_id <= 9" class="v-event-draggable" :class="[ getTaskStatusTypes[event.status_id].statusName === 'In Progress' ? 'task-in-progress ' : 'task-not-in-progress', event.id === selectedEventId ? 'selected' : '', event.priority_lvl_id ? getTaskPriorityLevels[event.priority_lvl_id - 1].levelId === 10 ? 'needs-immediate-attention' : '' : ''] " >
                                <span class="task-progress-percentage">{{ event.progress_percent }}%</span><br class="">
                                <span style="margin-left: 0.15rem;  margin-right: 2rem; color: rgb(225, 225, 225);" v-html="eventSummary()"></span>
                            </div>
                            <div v-else class="v-event-draggable" >
                                <span style="margin-left: 0.15rem; color: rgb(225, 225, 225);" v-html="eventSummary()"></span>
                            </div>

                            <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)" @touchstart.stop="extendBottom(event)">
                            </div>
                        
                        </template>
                    
                        <template v-slot:day-body="{ date, week }">
                            <div
                                id="current-time-indicator-id"
                                class="v-current-time first"
                                :class="{ first: date === week[0].date }"
                                :style="{ top: nowY }"
                                :data-current-time="current12HourTime"
                            ></div>
                        </template>
                    </v-calendar>
                    <!-- 
                        :class="{ first: date === week[0].date }"
                    -->
                    <!-- <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :auto=true
                    :origin="center"
                    :activator="selectedElement"
                    class="mx-auto"
                    style="min-width: 20rem !important; min-height: 50%;"
                    > -->

                    <!-- <v-row justify="center"> -->
                   
                    <!-- </v-row> -->

                    <!-- </v-menu> -->
                </v-sheet>
               
            </v-container>
        </v-card>
          <v-dialog
                    v-model="selectedOpen"
                    persistent
                    max-width="98%"
                    width="100%"
                    min-width="25rem"
                    dense
                >
                    <!-- <v-card color="grey lighten-4" min-width="350px"> -->
                        <v-card  style="border: var(--task2Do-dialog-dropdown-border);">
                        
                            <!-- <v-toolbar :color="editEventObj.color ? editEventObj.color : tempEventObj.color" dark> -->
                            <v-toolbar dense :color="selectedEvent.color" dark>
                                <!-- <v-btn @click="deleteFirebaseEvent(selectedEvent.id)" icon>
                                <v-icon>mdi-delete</v-icon>
                                </v-btn> -->
                                <v-btn icon>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                                <v-toolbar-title
                                    v-html="selectedEvent.name"
                                ></v-toolbar-title>
                                <v-spacer></v-spacer>

                                <v-btn icon>
                                    <!-- <v-icon>mdi-pencil</v-icon> -->
                                    <v-icon  @click="closeEventSummary()">mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>

                            <!-- <v-card-text>{{ selectedEvent.body }}</v-card-text> -->
                            <v-card-text>
                                <v-container>
                                    <!-- <form v-if="tempEventObj.id !== selectedEvent.id"> -->
                                    <form class="ma-0">
                                        <span> Task #: {{ selectedEvent.index }} </span> <br class="">
                                        <span> Task: <strong style="font-size: 125%;"> {{ selectedEvent.name }}</strong></span> <br class="">
                                        <!-- <span> Task Type: {{ getTaskTypes[selectedEvent.type_id].typeName }} </span> <br class=""> -->
                                        <span> From: <strong style="font-size: 105%;"> {{ selectedEvent.start }} </strong></span> <br class="">
                                        <span> To: <strong style="font-size: 105%;"> {{ selectedEvent.end }} </strong></span> <br class="">
                                        <span> Details: {{ selectedEvent.body }}</span> <br class="">
                                    </form>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text color="Primary" @click="closeEventSummary()">
                                    Close
                                </v-btn>

                                <v-btn text color="Primary"
                                    v-if="((editingNewEvent === false) && (editingExistingEvent === false) && (selectedEventIndex !== null))"  
                                    @click.prevent="editEvent(allTasks2Do[selectedEventIndex])">
                                    Edit
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                            <!-- </v-col>
                        </v-row> -->
            
                <TimedEventForm
                    v-if="editFormOpen"
                    :showTimedEventForm="editFormOpen"
                    :task2DoObjProp="editEventObj"
                    :editingExistingEventProp="editingExistingEvent"
                    :editingNewEventProp="editingNewEvent"
                    @closeTimedEventForm="closeEventEdit"
                    @cancelTimedEventForm="cancelEventEdit"
                    @refreshData="getAllEvents"

                />   
    </v-container>
  <!-- </v-row> -->
</template>

<script>
// import { db } from "@/main";
import { mapGetters, mapActions, mapMutations } from "vuex";
import WindowBar from "@/components/widgets/components/WindowBar.vue";
import SystemBar from "@/components/widgets/components/SystemBar.vue";
import EditToolbar from "@/components/widgets/components/EditToolbar.vue";
import DatetimePicker from 'vuetify-datetime-picker';
import TimedEventForm from "@/components/forms/TimedEventForm.vue";

export default {
    name: "CalendarWidget",
    components: {
            WindowBar,
            SystemBar,
            EditToolbar,
            DatetimePicker,
            TimedEventForm
    },
    props: ["focusedWidget", "fixedWidget", "priorityLevelLookup" ],
    data: () => ({
        componentName: "CalendarWidget",
        calendarReady: false,
        updateIntervalId: null,
        calendarScrollLocked: false,
        calendarInProgressEvents: [],
        calendarTimeZoomed: false,
        calendarZoomedOutScrollLeft: 0,
        calendarNumDaysInView: 7,
        calendarIntervalCount: 24,
        calendarIntervalMinutes: 60,
        calendarNumIntervalsOnScreen: 16,
        calendarIntervalStartTime: '00:00',
        calendarFirstOfFocusedMonthTimestamp: null,
        calendarLastDayOfFocusedMonthTimestamp: null,
        calendarCurMonth: null,
        calendarNumDaysInCurMonth: null,
        calendarIntervalHeight: 32,
        calendarDayBodyHeight: 0,
        calendarIntervalWidth: 24,
        calendarBodyBoundingBox: {
            x: 0,
            y: 0,
            width: 300,
            height: 1,
            top: 1,
            right: 0,
            bottom: 1,
            left: 0
        },
        calendarCurrentDayButtonBoundingBox: {
            x: 0,
            y: 0,
            width: 0,
            height: 1,
            top: 1,
            right: 0,
            bottom: 1,
            left: 0
        },
        appFooterHeight: 0,
        // startDatetime: null,
        // endDatetime: null,
        today: '', //this.$datedJS.dated.getLocalISODateTime(new Date()).substr(0, 10),
        // focus: '', // new Date().toISOString().substr(0, 10), //this.$datedJS.dated.getLocalISODateTime(new Date()).substr(0, 10), //
        calendarDisplayType: "4day",
        typeToLabel: {
            month: "Month",
            week: "Week",
            day: "Day",
            "4day": "4 Days"
        },
        calendarDisplayTypeLabel: '',
        calendarInitialStartOffset: 10,
        calendarStartDate: '2021-12-21 12:00',
        calendarEndDate: '2022-02-10 12:00',
        calendarStartOffset: 0,   // This is used to set the number of days offset from today that will be the first date shown in the category view
        calendarCategoryDays: 50,
        calendarDayColumnWidth: 80,
        calendarBodyDaysWidth: 4080,
        calendarDailyPaneElement: null,
        calendarDailyHeadElement: null,
        calendarIntervalBodyElement: null,
        currentTimeIndicatorElement: null,
        currentTimeWidthScale: 1,
        calendarCurrentDayButton: null,
        syntheticScrollEvent: null,
        syntheticScrollEventDetails: {},
        calendarHorizontalScrollAmount: 0,
        calendarVerticalScrollAmount: 0,
        allCategories: ['NEED', 'want', 'unset'],
        justNeedCategory: ['NEED', 'MUST'],
        calendarCategories: ['NEED', 'want', 'unset'],
        calendarPropertyCategoryFilter: "want_or_need",
        colors: [
            "blue",
            "indigo",
            "deep-purple",
            "cyan",
            "green",
            "orange",
            "grey darken-1"
        ],
        names: [
            "Meeting",
            "Holiday",
            "PTO",
            "Travel",
            "Event",
            "Birthday",
            "Conference",
            "Party"
        ],
        ignoredTaskTypes: [
            'Journal Log',
            'Random Thought',
            // 'General Purpose Note',
            'Quote',
            'Quip'
        ],
        editMode: '',
        priorityLevelList: 'priority-level-list',
        selectedPriorityLevel: {
            levelNum: 1,
            levelColor: "rgba(13, 13, 13, 1)"
        },
        task_owner_screen_name: null,
        name: null,
        details: null,
        body: null,
        start: null,
        end: null,
        categoryId: 1,
        maxDays: 56,
        defaultPriorityLevel: 1,
        defaultTaskType: 9,
        currentEventPriorityLevelID: 1,
        currentEventTaskTypeID: 9,
        // currentEventPriorityLevelNum: 0,
        color: "#FFFFF",
        events: [],
        dailyEvents: [],
        tempEventObj: {},
        editEventObj: {},
        creatingEvent: false,
        editingNewEvent: false,
        editingExistingEvent: false,
        selectedEventIndex: null,
        mouseDownEventId: null,
        mouseUpEventId: null,
        selectedEventId: null,
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        editFormOpen: false,
        alwaysFalse: false,
        // firebaseEvents: [],
        apiEvents: [],
        value: '',
        // backendSelection: "Task2DoAPI",
        // apiAddTaskDialog: false,
        // addEventDialog: false,
        widgetLabel: "Calendar",
        minimizeInBackground: true,
        minimizeInForeground: false,
        minimizeClassName: "minimize-widget",
        maximizeClassName: "maximize-widget",
        maximizeState: true,
        notificationsList: [],
        notificationString: '',
        calendarEditToolbarItems: {
            // createAppointmentButton: {
            //     id: 1,
            //     name: 'createAppointmentButton',
            //     type: 'button',
            //     label: '+Appointment',
            //     icon: '\u{1F5D3}',
            //     tooltip: "Add a new appointment to your schedual",
            //     functionToCall: "createNewAppointment",
			// 	disabled: false,
			// 	enabled: true,
            //     visible: true
            // },
            // editSelectedAppointmentButton: {
            //     id: 3,
            //     name: 'editSelectedAppointmentButton',
            //     type: 'button',
            //     label: 'Edit Appointment',
            //     icon: 'mdi-pen', 
            //     tooltip: "Edit Selected Appointment",
            //     functionToCall: "editSelectedAppointment",
			// 	disabled: true,
			// 	enabled: false,
            //     visible: true
			// }, 
            // showNotesListButton: {
            //     id: 5,
            //     name: 'showTodosButton',
            //     type: 'button',
            //     label: 'Show Current Task\'s 2 DO!',
            //     icon: '\u{1F5C2}', 
            //     tooltip: "Show your pending 2 DO(s) with Appointments.",
            //     functionToCall: "displayTasks2DoToo",
			// 	disabled: false,
			// 	enabled: true,
            //     visible: true
            // },
			// deleteSelectedAppointmentButton: {
            //     id: 2,
            //     name: 'deleteSelectedAppointmentButton',
            //     type: 'button',
            //     label: 'Delete Appointment',
            //     icon: 'mdi-delete-forever', 
            //     tooltip: "Delete Selected Appointment",
            //     functionToCall: "deleteSelectedAppointment",
			// 	disabled: false,
			// 	enabled: false,
            //     visible: true
            // },
			// empty: {
            //     id: 100,
            //     name: 'emptySpacer',
            //     type: 'spacer',
            //     label: ' ',
			// 	icon: ' ',
            //     tooltip: '',
            //     functionToCall: '',
			// 	// disabled: true,
			// 	enabled: true,
            //     visible: true
			// },
            calendarDisplayType: {
                id: 5,
                name: 'calendarDisplayType',
                type: 'dropDownSelect',
                label: 'this.calendarDisplayTypeLabel',
                icon: 'mdi-menu-down',
                tooltip: 'Select a calendar display format',
                disabled: false,
				enabled: true,
                visible: true,
                selectionOptions: [
                    {
                        id: 1,
                        name: 'Day',
                        label: 'Day',
                        functionToCall: "setCalendarType",
                        functionArgument: 'day',
                        clickAction: "type = 'day'",
                    },
                    {
                        id: 2,
                        name: '4Day',
                        label: '4 Day',
                        functionToCall: "setCalendarType",
                        functionArgument: '4day',
                        clickAction: "type = '4day'",
                    },
                    {
                        id: 3,
                        name: 'Week',
                        label: 'Week',
                        functionToCall: "setCalendarType",
                        functionArgument: 'week',
                        clickAction: "type = 'week'",
                    },
                    {
                        id: 4,
                        name: 'Month',
                        label: 'Month',
                        functionToCall: "setCalendarType",
                        functionArgument: 'month',
                        clickAction: "type = 'month'",
                    },
                    {
                        id: 5,
                        name: 'TimedEventsCategory',
                        label: 'Timed',
                        functionToCall: "setCalendarCategories",
                        functionArgument: [ 'owner_screen_name', [ 'getCurrentUserName' ] ],
                        clickAction: "type = 'category'",
                    },
                    {
                        id: 6,
                        name: 'NeedWantCategory',
                        label: 'NEED vs. want',
                        functionToCall: "setCalendarCategories",
                        functionArgument: [ 'want_or_need', [ 'NEED', 'want' ] ],
                        clickAction: "type = 'category'",
                    },
                    {
                        id: 7,
                        name: 'Unfinished',
                        label: 'Unfinished',
                        functionToCall: "setCalendarCategories",
                        functionArgument: [ 'status_id', [ 1, 9 ]],
                        clickAction: "type = 'category'",
                    }
                ]
            },
            focus2Today: {
                id: 7,
                name: 'focus2Today',
                type: 'button',
                label: 'Focus Calendar 2 2Day',
                icon: '\u{1F324}',
                tooltip: "Focus calendar 2 2Day!",
                functionToCall: "setToday",
				disabled: false,
				enabled: true,
                visible: true
            },
             prevTimePeriod: {
                id: 6,
                name: 'prevTimePeriod',
                type: 'button',
                label: 'Move calendar to previous time period',
                icon: 'mdi-chevron-left',
                tooltip: 'Move to previous time period',
                functionToCall: 'toPrevTimePeriod',
				disabled: false,
				enabled: true,
                visible: true
            },
            nextTimePeriod: {
                id: 8,
                name: 'nextTimePeriod',
                type: 'button',
                label: 'Move calendar to next time period',
                icon: 'mdi-chevron-right',
                tooltip: 'Move to next time period',
                functionToCall: 'toNextTimePeriod',
				disabled: false,
				enabled: true,
                visible: true
            },
            calendarScrollLock: {
                id: 11,
                name: 'calendarScrollLock',
                type: 'buttonToggle',
                label: 'Disable scrolling in the calendar to allow for easier event creation',
                curStateIndex: 0,
                states: [
                     {
                        value: 'off',
                        icon: 'mdi-lock',
                        iconStyle: { color: 'rgb(200, 13, 26)' },
                        label: 'Disable scrolling in the calendar to allow for easier event creation',
                        tooltip:'Calendar Scroll Lock'
                    },
                    {  
                        value: 'on',
                        icon: 'mdi-lock-open',
                        iconStyle: { color: 'rgb(10, 175, 13)' },
                        label: 'Enable scrolling in the calendar',
                        tooltip:'Calendar Scroll Lock'
                    }

                ],
                functionToCall: 'toggleCalendarScrollLock',
				disabled: false,
				enabled: true,
                visible: true
            },
            zoomIn: {
                id: 9,
                name: 'zoomIn',
                type: 'button',
                label: 'Zoom in calendar to current time',
                // iconType: 'htmlEnt',
                // icon: '&#xe015;',
                icon: 'mdi-plus',
                tooltip: 'Zoom into current time',
                functionToCall: "timeZoomIn",
				disabled: false,
				enabled: true,
                visible: true
            },
            zoomOut: {
                id: 10,
                name: 'zoomOut',
                type: 'button',
                label: 'Zoom out calendar from current time',
                // iconType: 'htmlEnt',
                // icon: '&#xe016;',
                icon: 'mdi-minus',
                tooltip: 'Zoom out from current time',
                functionToCall: "timeZoomOut",
				disabled: false,
				enabled: true,
                visible: false
            }

            
			// infoDisplayBar: {
            //     id: 15,
            //     name: 'displayNoteStatus',
			// 	type: 'infoDisplay',
			// 	outputFormat: 'threeByTwo',
			// 	dataVariables: [
			// 		{
			// 			name: 'editMode',
			// 			variable: 'this.editMode',
			// 			monitor: true,
			// 			threshholdWarnValue: 'editYourFaceMode'
			// 		},
			// 		{
			// 			name: 'fullWidthMode',
			// 			variable: 'this.fullWidthMode',
			// 			monitor: false,
			// 			threshholdWarnValue: 'fullerThanFullWidthMode'
			// 		},
			// 		{
			// 			name: 'fullWidthSideClass',
			// 			value: 'this.fullWidthSideClass',
			// 			description: 'that current side that should be full width'
			// 		},
			// 		{
			// 			name: 'fullWidthSideClass',
			// 			value: 'this.fullWidthSideClass',
			// 			description: 'that current side that should be full width'
			// 		},
			// 		{
			// 			name: 'fullWidthSideClass',
			// 			value: 'this.fullWidthSideClass',
			// 			description: 'that current side that should be full width'
			// 		},
			// 		{
			// 			name: 'toolbarObjfullWidthSideClass',
			// 			value: "this.noteEditToolbarItems.fullWidthLeftRightToogle['states'][this.noteEditToolbarItems.fullWidthLeftRightToogle['curStateIndex']]['value']",
			// 			description: 'that current side that should be full width'
			// 		}
				
				
			// 	],
            //     label: 'Current State of your Note',
            //     icon: 'mdi-information-outline', 
            //     tooltip: "Current Note Stats.",
            //     functionToCall: "showNoteStats",
			// 	enabled: false,
            // }
		},
        draggingExistingEvent: false,
        dragInProgress: false,
        dragTime: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
        mouseDownX: 0,
        mouseDownY: 0,
        mouseOffsetX: 0,
        mouseOffsetY: 0,
        mouseScroll: false
        

    }),
    computed: {
        // ...mapGetters(["allNotes"]),
        ...mapGetters([ 'isDataLoadedFromAPI']),
        ...mapGetters('auth', {
            getCurrentUserId: 'getCurrentUserId',
            getCurrentUserName: 'getCurrentUserName'
        }),
        ...mapGetters('time', {
            // getCurrentTimestamp: 'getCurrentTimestamp',
            // getCurrentTimeObj: 'getCurrentTimeObj',
            getTopOfMinuteTimestamp: 'getTopOfMinuteTimestamp',
            getTopOfMinuteTimeObj: 'getTopOfMinuteTimeObj'
        }),
        ...mapGetters( 'tasks', {
            getNewCalendarEventObj: 'getNewCalendarEventObj',
            getEmptyTaskObj: 'getEmptyTaskObj',
            getTaskPriorityLevels: 'getTaskPriorityLevels',
            getTaskTypes: 'getTaskTypes',
            getTaskStatusTypes: 'getTaskStatusTypes',
            allTasks2Do: 'allTasks2Do',
            getCalendarEvents2Do: 'getCalendarEvents2Do',
            getEventsFromDate: 'getEventsFromDate',
            getTodaysTasks: 'getTodaysTasks',
            getDailyEvents: 'getDailyEvents',
            getSelectedTaskId: 'getSelectedTaskId',
            getSelectedTask: 'getSelectedTask'
            
        }),
        ...mapGetters('uiState', {
            // getAppState: 'getAppState',
            getComponentDisplayState: 'getComponentDisplayState',
            getWindowDimensions: 'getWindowDimensions',
            getFocusedInputId: 'getFocusedInputId',
            isInputFieldFocused: 'isInputFieldFocused'
        }),
        ...mapGetters('userSettings', {
            t2doDisplaySettings: 't2doDisplaySettings',
        }),
        title() {
            const { start, end } = this;
            if (!start || !end) {
                return "";
            }

            const startMonth = this.monthFormatter(start);
            const endMonth = this.monthFormatter(end);
            const suffixMonth = startMonth === endMonth ? "" : endMonth;

            const startYear = start.year;
            const endYear = end.year;
            const suffixYear = startYear === endYear ? "" : endYear;

            const startDay = start.day + this.nth(start.day);
            const endDay = end.day + this.nth(end.day);

            switch (this.type) {
                case "month":
                    return `${startMonth} ${startYear}`;
                case "week":
                case "custom-daily": this.maxDays = 5;
                    return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
                case "day":
                    return `${startMonth} ${startDay} ${startYear}`;
            }
            return "";
        },
        monthFormatter() {
            return this.$refs.calendar.getFormatter({
                timeZone: "UTC",
                month: "long"

                // timeZone: 'America/New_York', month: 'long',
                //  timeZone: 'Eastern Standard Time', month: 'long',
            });

        },
        calendarInstance() {
            return this.calendarReady ? this.$refs.calendar : null;
        },
        today() {
            return this.$datedJS.dated.getLocalISODate(this.$datedJS.dated.rightNow);
        },
        nowY() {
            // return this.calendarInstance ? this.calendarInstance.timeToY(this.calendarInstance.times.now) + 'px' : '-10px'
            let currentTimeY;
            if (this.calendarInstance) {
                currentTimeY = (this.calendarInstance.timeToY(this.getTopOfMinuteTimeObj.hour * 60 + parseInt(this.getTopOfMinuteTimeObj.minutes))).toFixed(0);
               
                console.log('currentTimeY: ' + currentTimeY);
                // if (this.getTopOfMinuteTimeObj.hour === 0) && (this.getTopOfMinuteTimeObj.min === 0) {
                if (currentTimeY === 0) {
                    // make sure to update the day once midnight hits otherwise the time indicator will stay on what is now yesterday
                    this.today = this.$datedJS.dated.getLocalISODate(this.$datedJS.dated.rightNow);
                    this.$refs.calendar.checkChange();
                    currentTimeY = (this.calendarInstance.timeToY(this.getTopOfMinuteTimeObj.hour * 60 + parseInt(this.getTopOfMinuteTimeObj.minutes))).toFixed(0);

                }
                console.log('currentTimeY: ' + currentTimeY);
                return  this.calendarInstance ? currentTimeY + 'px' : '69px';
                             
            } else {
               
                console.log('!!!!!!!!!!!!!!!!!!why are you returning 69px here?\n');
                return '69px';
            }

            //  return  this.calendarInstance ? this.calendarInstance.timeToY(this.$datedJS.dated.getCurrHour() * 60 + this.$datedJS.dated.getCurrMin()) + 'px' : '69px';
        },
        // nowY() {
        //     if (this.calendarInstance) {
        //         this.setCssVariables();
        //         return  this.calendarInstance ? (this.calendarInstance.timeToY(this.getTopOfMinuteTimeObj.hour * 60 + parseInt(this.getTopOfMinuteTimeObj.minutes))).toFixed(0) + 'px' : '69px';
        //     } else {
        //            this.setCssVariables();
        //         console.log('!!!!!!!!!!!!!!!!!!why are you returning 69px here?\n');
        //         return '69px';
        //     }
        // },
        current12HourTime() {
            // return `${this.getCurrentTimeObj.twelveHourTime}`; // ${this.getCurrentTimeObj['twelveHour']}:${this.getCurrentTimeObj['minutes']} ${this.getCurrentTimeObj['timePeriod']}`;
            return `${this.getTopOfMinuteTimeObj.twelveHourTime}`;

        },
        minimizeState() {
            // return (this.focusedWidget === this.componentName) ? 
            //     (this.minimizeInForeground === true ?
            //         true
            //         : false)
            //     : (this.minimizeInBackground === true) ?
            //         true
            //         : false; 
            return (this.focusedWidget === this.componentName) ? (this.minimizeInForeground === true ? true : false) : (this.minimizeInBackground === true) ? true : false; 
        },
        // priorityLevels() {
        //     console.log("getTaskPrioritiesArrObj: " + this.taskPriorityLevels);
        //     return this.taskPriorityLevels;
        // },
        currentEventPriorityLevelNum() {
            let eventPriorityLevelNum = this.currentEventPriorityLevelID > -this.currentEventPriorityLevelID - 1;
                return this.currentEventPriorityLevelID - 1;
        },
        newEventObj() {
            // let tempEventObject = this.$arrayObjUtils.arrayObjsDeepCopy(this.newCalendarEventObj);
            let tempEventObject  = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
            console.log("---> TEMP Event Object (LOCAL): " + JSON.stringify(tempEventObject, null, 2));
            tempEventObject.startISO = this.$datedJS.dated.getLocalISODateTime(new Date().addHours(0));
            tempEventObject.endISO = this.$datedJS.dated.getLocalISODateTime(new Date().addHours(2));
            tempEventObject.start = new Date(tempEventObject.startISO).getTime();
            tempEventObject.end = new Date(tempEventObject.endISO).getTime();
            tempEventObject.id = ''; //this.$jsUtils.genUUIDv4();
            tempEventObject.index = this.events.length + this.dailyEvents.length;
            
            tempEventObject.owner_screen_name = this.getCurrentUserName;
            tempEventObject.created_by_id = this.getCurrentUserId;
            tempEventObject.created_for_id = this.getCurrentUserId;
            return tempEventObject;

        },
        // task2DoSummary(event) {
        //     console.log("task2Do event Summary  start time: " + JSON.stringify(event.start, null, 2));
        //     let startTime = this.$datedJS.dated.getTwelveHourTime(event.start, false);
        //     let endTime = this.$datedJS.dated.getTwelveHourTime(event.end, false);
        //     let htmlSummary = '';
        //     if (event.status_id === 10) {
        //         htmlSummary = `<v-icon>mdi-check</v-icon><span> ${event.name} <br class=""> ${startTime} - ${endTime}</span>`;
        //     } else if (event.status_id === 6) {
        //         htmlSummary = `<v-icon>mdi-close</v-icon><span> ${event.name} <br class=""> ${startTime} - ${endTime}</span>`;
        //     }  else {
        //         htmlSummary = `<span> ${event.name} <br class=""> ${startTime} - ${endTime}</span>`;
        //     }

        //     return htmlSummary;
        // },
        scrollLockCssValue() {
            return this.calendarScrollLocked === true ? 'hidden' : 'auto';
        },
        // calendarIntervalHeight() {
        //     console.log("window innerHiehgt: " + this.windowInnerHeight);
            
        //     let intervalHeight = parseInt((Math.floor(this.windowInnerHeight) - Math.floor(this.calendarBodyBoundingBox.top) - this.appFooterHeight) / this.calendarIntervalCount);
        //     console.log(" Math.floor(this.calendarBodyBoundingBox.top): " +  Math.floor(this.calendarBodyBoundingBox.top));
        //     console.log("comnputedIntervalHeight: \n\t intervalHeight: " + intervalHeight);
        //     return intervalHeight;
        // }
    },
    async beforeCreate() {
        // this.editEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
        // this.resetTempEventObj();
        // this.resetEditEventObj();
        // this.selectedEvent = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
        console.log('beforeCreated called in calendar.vue');
       

    },
    async created() {
         this.calendarCurrentDayButtonBoundingBox = {
            x: 0,
            y: 0,
            width: 0,
            height: 1,
            top: 1,
            right: 0,
            bottom: 1,
            left: 0
        };
        
        this.calendarFirstOfFocusedMonthTimestamp = this.$datedJS.dated.getFirstOfMonthTimestamp();
        this.calendarLastDayOfFocusedMonthTimestamp = this.$datedJS.dated.getLastOfMonthTimestamp(this.calendarFirstOfFocusedMonthTimestamp) 
        this.calendarCurMonth = this.$datedJS.dated.getMonthNum(this.calendarFirstOfFocusedMonthTimestamp);
        this.calendarNumDaysInCurMonth = this.$datedJS.dated.getNumberOfDaysInMonth(this.calendarFirstOfFocusedMonthTimestamp);   
        
        let calendarStartTimestamp = this.calendarFirstOfFocusedMonthTimestamp - this.$datedJS.dated.daysToMilliseconds(this.calendarInitialStartOffset);
        let calendarEndTimestamp = this.calendarLastDayOfFocusedMonthTimestamp + this.$datedJS.dated.daysToMilliseconds(this.calendarInitialStartOffset);

        this.calendarStartDate = calendarStartTimestamp; 
        this.calendarEndDate = calendarEndTimestamp;
        this.resetTempEventObj();
        this.resetEditEventObj();
        this.selectedEvent = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
        await this.setupTimedEventsForCalendar();
        await this.setCalendarCategories(['owner_screen_name', [ this.$jsUtils.capitalizeString(this.getCurrentUserName) ]]);   
        console.log("created calendar function called");

        

        

    
        this.calendarCategoryDays = ((this.calendarEndDate - this.calendarStartDate) / this.$datedJS.dated.inMilliSeconds.day).toFixed(0);
        

        this.isScrolling = false;
        this.syntheticScrollEvent = null;
        this.syntheticScrollEventDetails = {};
        this.calendarHorizontalScrollAmount = 0;
        this.calendarVerticalScrollAmount = 0;

        this.touchDragTriggerDuration = 450000;
        this.touchStartTime = 0;
        this.touchStartDragTime = 0;
        this.touchMoveTime = 0;
        this.touchDragTiggered = false;
        this.touchDragEvent = null;
        this.touchDragTimed = null;

        this.calendarEventsParentElement = [];
        // this.calendarCurrentDayButton = null;

        this.calendarMainElement = null;
        this.calendarDailyDayContainerElement = null;
        // this.calendarIntervalBodyElement = null;
        // this.calendarDailyPaneElement = null;
        // this.calendarDailyHeadElement = null;
        // this.currentTimeIndicatorElement = null;
       
        console.log('this.calendarFirstOfFocusedMonthTimestamp: ' + this.calendarFirstOfFocusedMonthTimestamp);
        console.log('this.calendarLastDayOfFocusedMonthTimestamp: ' + this.calendarLastDayOfFocusedMonthTimestamp);
        console.log('calendarCategoryDays from created: ' + this.calendarCategoryDays + '\n\tcalendarCurrentMonth: ' + this.calendarCurMonth + '\n');
        
        // await this.getAllEvents();
        
    },
    async beforeUpdate() {
        // this.setCssVariables();
       
    },
    async mounted() {
        //  this.getFirebaseEvents();
        // await this.getAllEvents();
        
        console.log("------------------------------------------\nCalendar mounted method called.);\n---------------------");
      
        // this.resetEditEventObj();
        
          
       window.addEventListener('resize', this.onResizeWindow);
        // window.addEventListener('scroll', this.onScrollWindow, true); // {passive: false} );

           // this.$nextTick(async () => {
                //this is called in the getAllEvents method --> 
        // this.$refs.calendar.checkChange();
                  
        while (this.calendarDailyPaneElement === null) {
            this.calendarDailyPaneElement = document.getElementsByClassName("v-calendar-daily__pane");
            await (this.calendarDailyPaneElement !== null);
            this.calendarReady = true;
        }
        // this.$nextTick(async () => {
            // await this.updateTime(); <-- this method uses setInterval every sixty seconds to update the css timer variable, the realtime clock setInterval
            // is passed as a prop to the calendar, so just update the css variable when the seconds === 0
            

                this.calendarMainElement = await document.querySelector('.v-calendar.v-calendar-daily.v-calendar-events');
                this.calendarDailyHeadElement = await document.querySelector('.v-calendar-daily__head');
                this.calendarDailyDayContainerElement = await document.querySelector('.v-calendar-daily__day-container');
                this.calendarIntervalBodyElement = await document.querySelector('.v-calendar-daily__intervals-body');
                this.calendarCurrentDayButton =  await document.querySelector(".v-calendar-daily_head-day.v-present");
                this.currentTimeIndicatorElement = await document.getElementById('current-time-indicator-id');

            // access DOM elements in $nextTick method to ensure view has been rendered.
            this.$nextTick(async () => {
            // this.$nextTick(async function () {    
                
                // while (this.calendarDailyPaneElement === null) {
                //     this.calendarDailyPaneElement = document.getElementsByClassName("v-calendar-daily__pane");
                //     this.calendarCurrentDayButton = document.querySelector(".v-calendar-daily_head-day.v-present");
                //     await (this.calendarDailyPaneElement !== null);
                // }
                
                let calBodyBoundingBox = this.calendarDailyPaneElement[0].getBoundingClientRect();

                this.calendarBodyBoundingBox.top = calBodyBoundingBox.top;
                this.calendarBodyBoundingBox.bottom = calBodyBoundingBox.bottom;
                this.calendarBodyBoundingBox.width = calBodyBoundingBox.width;
                this.calendarBodyBoundingBox.height = parseInt(Math.floor(window.innerHeight) - this.calendarBodyBoundingBox.top);
                this.calendarDayColumnWidth = (this.calendarBodyBoundingBox.width / this.calendarNumDaysInView).toFixed(0);
                this.calendarBodyDaysWidth = (this.calendarCategoryDays * this.calendarDayColumnWidth).toFixed(0) + 'px';
                
                let calCurrentDayButtonBoundingBox = this.calendarCurrentDayButton.getBoundingClientRect();
                this.calendarCurrentDayButtonBoundingBox.top = calCurrentDayButtonBoundingBox.top;
                this.calendarCurrentDayButtonBoundingBox.bottom = calCurrentDayButtonBoundingBox.bottom;
                this.calendarCurrentDayButtonBoundingBox.left = calCurrentDayButtonBoundingBox.left;
                this.calendarCurrentDayButtonBoundingBox.right = calCurrentDayButtonBoundingBox.right;
                this.calendarCurrentDayButtonBoundingBox.x = calCurrentDayButtonBoundingBox.x;
                this.calendarCurrentDayButtonBoundingBox.y = calCurrentDayButtonBoundingBox.y;                
                
                await this.onResizeWindow();

                console.log('###############################\n\tthis.onResizeWindow called from mounted should have finished executing ');
                if ((this.calendarDailyPaneElement[0] !== null) && (this.calendarScrollLocked === false)) {
                    await this.calendarDailyPaneElement[0].addEventListener('scroll', this.onScrollWindow, false); // {passive: true} );
                    // this.calendarDailyPaneElement[0].removeEventListener('scroll', this.onScrollWindow, true); 
                    // this.calendarDailyPaneElement[0].addEventListener('scroll', this.onScrollWindow, true); 
                
                    console.log('calling this.moveTo(this.calendarInitialStartOffset)');
                    // await this.calendarDailyDayContainerElement.addEventListener('scroll', this.onScrollWindow, false);

                    // await this.moveTo(this.calendarInitialStartOffset);
                    this.setCssVariables();
                    await this.horizontalScrollToday();
                    await this.scrollToTime();
                    await this.$refs.calendar.checkChange(); 
                    await this.getInProgressEventElements();
                    
                } else {
                    console.log('!!!!!!!!!!!!/n/t The calendarDailyPaneElement[0] is null\n Didn\'t execute initial scroll movements \n !!!!!!!!!!!!!!!!');
                }
                
            });
        // });
           
        // });

        // this.$nextTick(function () {
        //      let scrollAmount = (this.calendarDailyPaneElement[0].offsetWidth / 5).toFixed(0);
        //     console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! \n\t calendarDailyPaneElement.scrollLeft: ' + this.calendarDailyPaneElement[0].offsetWidth  + '\n\t scrollAmount: ' + scrollAmount + '!!!!!!!!!!!!!!!\n\n');
        //     // this.calendarDailyPaneElement[0].parentNode.scrollLeft = scrollAmount;
        // });

        // this.calendarHorizontalScrollAmount = (this.this.calendarDailyPaneElement[0].offsetWidth / 5).toFixed(0);


    },
    async updated() {
        console.log("UUUUUUUUUUUPPPPPPPDDDDDAAAAAATTTTTTEEEEEE\n\tcaldendar.vue updated method called!");
        if (this.focusedWidget !== this.componentName) {
            // This resets the foreground state to ensure widget isn't minimized when regaining focus
            this.minimizeInForeground = false;
        }
        // this.setupTimedEventsForCalendar();
                          
        /**
         * //TODO: move the css variables assignments to a seperate function
         * 
         */
        

        // this.calendarDailyPaneElement[0].style.setProperty('height',  `${(this.calendarIntervalHeight * this.calendarIntervalCount) + 16}px`);
        // this.calendarDailyPaneElement[0].style.setProperty('height',  `${Math.floor(this.calendarBodyBoundingBox.height)}px`);
        if (this.calendarDailyPaneElement !== null) {
            if (this.calendarTimeZoomed === true) {
                this.calendarDailyPaneElement[0].classList.add('zoomed-in');
                this.calendarDailyPaneElement[0].classList.remove('zoomed-out');
            } else {
                this.calendarDailyPaneElement[0].classList.add('zoomed-out');
                this.calendarDailyPaneElement[0].classList.remove('zoomed-in');
            }
            this.setCssVariables();
        }
       

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResizeWindow);
        if (this.calendarDailyPaneElement[0]) {
            this.calendarDailyPaneElement[0].removeEventListener('scroll', this.onScrollWindow, false); 
        }
        

        if(this.updateIntervalId) {
            clearInterval(this.updateIntervalId);
        }

    },
    methods: {
        ...mapActions([ 'fetchAllFromAPI', 'refreshDataFromAPI', 'initAppState' ]),
        ...mapActions( 'tasks', {
            createTask2Do: 'createTask2Do',
            updateTask2Do: 'updateTask2Do',
            selectTaskObjById: 'selectTaskObjById',
            copyToSelectedTaskObj: 'copyToSelectedTaskObj',
            resetSelectedTaskObj: 'resetSelectedTaskObj'
        }),
        ...mapActions( 'uiState', {
            setFocusedWidget: 'setFocusedWidget',
            toggleDaySummary: 'toggleDaySummary',
            toggleShowWidget: 'toggleShowWidget',
            openTaskSummary: 'openTaskSummary',
            closeTaskSummary: 'closeTaskSummary'
        }),
        ...mapMutations( 'uiState', {
            SET_WINDOW_DIMENSIONS: 'SET_WINDOW_DIMENSIONS'
        }),
        resetTempEventObj() {
            // this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.newCalendarEventObj);
            this.tempEventObj = {};
            this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
            this.tempEventObj['tempObj'] = false;

        },
        resetEditEventObj() {
            this.editEventObj = {};
            this.editEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
            this.editEventObj['editObj'] = false;            
        },
        setCssVariables() {
            console.log('setCssVariables called');
            document.documentElement.style.setProperty('--calendar-scrollbar-width', '0px'); //`${scrollbarWidth}px`);
            document.documentElement.style.setProperty('--calendar-visible-events__width', this.calendarBodyDaysWidth);      
            document.documentElement.style.setProperty('--calendar-num-events', this.calendarCategoryDays);      
            document.documentElement.style.setProperty('--calendar-zoomed-height', `${this.calendarDayBodyHeight}px`);
            document.documentElement.style.setProperty('--calendar-interval-height', `${this.calendarIntervalHeight}px`);
            document.documentElement.style.setProperty('--calendar-intervals-top', `${this.calendarBodyBoundingBox.top}px`);
            document.documentElement.style.setProperty('--calendar-interval-width', `${this.calendarIntervalWidth}px`);
            document.documentElement.style.setProperty('--calendar-interval-count', `${this.calendarIntervalCount}`);
            document.documentElement.style.setProperty('--calendar-day-column-width', `${this.calendarDayColumnWidth}px`);
            // document.documentElement.style.setProperty('--current-time', `"${this.currentTimeObj['twelveHour']}:${this.currentTimeObj['minutes']} ${this.currentTimeObj['timePeriod']}"`);
            document.documentElement.style.setProperty('--current-time', `"${this.current12HourTime}"`);
            document.documentElement.style.setProperty('--current-time-left-possition', `calc(${this.calendarCurrentDayButtonBoundingBox.left}px - 13%)`);           
            
            document.documentElement.style.setProperty('--calendar-scroll-lock', this.scrollLockCssValue);

            // this.setEventParentClass('needs-immediate-attention');

        },
        horizontalScrollToday() {
            // await setTimeout(function() {
            
            console.log('horizontalScroll calendarBodyDaysWidth: ' + parseInt(this.calendarBodyDaysWidth));
            // this.calendarDayColumnWidth = (parseInt(this.calendarBodyDaysWidth) / this.calendarCategoryDays).toFixed(0);
            console.log('\tcalendarDayColumnWidth: ' + this.calendarDayColumnWidth + '\n');

            // console.log('this.$datedJS.dated.getDayNumber(): ' + this.$datedJS.dated.getDayNumber() + '\n\tthis.calendarInitialStartOffset: ' + this.calendarInitialStartOffset);

            this.calendarHorizontalScrollAmount = (this.calendarInitialStartOffset + this.$datedJS.dated.getDayNumber() - 2) * this.calendarDayColumnWidth;

            this.syntheticScrollEventDetails = {scrollLeft: this.calendarHorizontalScrollAmount, detail:{scrollLeft: this.calendarHorizontalScrollAmount, initiator: "horizontalScrollToday"}};

            this.syntheticScrollEvent = new CustomEvent("scroll", this.syntheticScrollEventDetails);
            this.calendarDailyPaneElement[0].dispatchEvent(this.syntheticScrollEvent);
            // this.calendarHorizontalScrollAmount = ((this.calendarDailyPaneElement[0].offsetWidth / 5) - (this.calendarDailyPaneElement[0].offsetWidth / this.calendarCategoryDays)).toFixed(0);

            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! \n\t calendarDailyPaneElement.scrollLeft: ' + this.calendarDailyPaneElement[0].offsetWidth  + '\n\t scrollAmount: ' + this.calendarHorizontalScrollAmount + '!!!!!!!!!!!!!!!\n\n');

        },
        scrollToPrevMonth(event) {
            // console.log('this.calendarFirstOfFocusedMonthTimestamp:' +  this.calendarFirstOfFocusedMonthTimestamp);

            this.calendarFirstOfFocusedMonthTimestamp = this.$datedJS.dated.getFirstOfPrevMonthTimestamp(this.calendarFirstOfFocusedMonthTimestamp);
            this.calendarLastDayOfFocusedMonthTimestamp = this.$datedJS.dated.getLastOfMonthTimestamp(this.calendarFirstOfFocusedMonthTimestamp);

            // console.log('\n HEY LOOK!!! We triggered a scrollBack to: \n\tFirstOfMonth:' + new Date(this.calendarFirstOfFocusedMonthTimestamp) + '/n/t LastOfMonth: ' + new Date(this.calendarLastDayOfFocusedMonthTimestamp));


            // console.log('\n\tthis.calendarLastDayOfFocusedMonthTimestamp: ' + this.calendarLastDayOfFocusedMonthTimestamp + '\n');

            // console.log('this.$datedJS.dated.getDayNumber(this.calendarFirstOfFocusedMonthTimestamp) - 1): ' + (this.$datedJS.dated.getDayNumber(this.calendarLastDayOfFocusedMonthTimestamp) + 1) + '\n');

            // this.calendarDailyPaneElement[0].scrollTo(this.calendarHorizontalScrollAmount, this.calendarDailyPaneElement[0].scrollTop);

            let calendarStartTimestamp = this.calendarFirstOfFocusedMonthTimestamp - this.$datedJS.dated.daysToMilliseconds(this.calendarInitialStartOffset);
            let calendarEndTimestamp = this.calendarLastDayOfFocusedMonthTimestamp + this.$datedJS.dated.daysToMilliseconds(this.calendarInitialStartOffset);

            this.calendarCurMonth = this.$datedJS.dated.getMonthNum(this.calendarFirstOfFocusedMonthTimestamp);
            this.calendarNumDaysInCurMonth = this.$datedJS.dated.getNumberOfDaysInMonth(this.calendarFirstOfFocusedMonthTimestamp);
            this.calendarStartDate = calendarStartTimestamp; 
            this.calendarEndDate = calendarEndTimestamp;
            this.calendarCategoryDays = ((this.calendarEndDate - this.calendarStartDate) / this.$datedJS.dated.inMilliSeconds.day).toFixed(0);

            this.calendarHorizontalScrollAmount = (this.calendarInitialStartOffset + this.calendarNumDaysInCurMonth - this.calendarNumDaysInView + 1) * this.calendarDayColumnWidth;
            

            // this.$nextTick(function () {
                this.syntheticScrollEventDetails = { scrollLeft: this.calendarHorizontalScrollAmount, target: { scrollLeft: this.calendarHorizontalScrollAmount }, detail:{scrollLeft: this.calendarHorizontalScrollAmount, initiator: "scrollToPrevMonth"}};

                this.syntheticScrollEvent = new CustomEvent("scroll", this.syntheticScrollEventDetails);
                this.calendarDailyPaneElement[0].dispatchEvent(this.syntheticScrollEvent);     
              
            // });

        },
        scrollToNextMonth(event) {
            // console.log('this.calendarFirstOfFocusedMonthTimestamp:' +  this.calendarFirstOfFocusedMonthTimestamp);
            // console.log('\n\tthis.$datedJS.dated.getLocalISODate(this.calendarFirstOfFocusedMonthTimestamp): ' + this.$datedJS.dated.getLocalISODate(this.calendarFirstOfFocusedMonthTimestamp) + ' in human readable form');

            this.calendarFirstOfFocusedMonthTimestamp =  this.$datedJS.dated.getFirstOfNextMonthTimestamp(this.calendarFirstOfFocusedMonthTimestamp);
            this.calendarLastDayOfFocusedMonthTimestamp =  this.$datedJS.dated.getLastOfMonthTimestamp(this.calendarFirstOfFocusedMonthTimestamp);

            // console.log('\n HEY LOOK!!! We triggered a scrollForward to: ' + new Date(this.calendarFirstOfFocusedMonthTimestamp));
            // console.log('\n\tthis.calendarLastDayOfFocusedMonthTimestamp: ' + this.calendarLastDayOfFocusedMonthTimestamp + '\n');
          
            // console.log('\n\tscrollToNextMonth scrollAmount: ' + this.calendarHorizontalScrollAmount + '!!!!!!!!!!!!!!!\n\n');

            let calendarStartTimestamp = this.calendarFirstOfFocusedMonthTimestamp - this.$datedJS.dated.daysToMilliseconds(this.calendarInitialStartOffset);
            let calendarEndTimestamp = this.calendarLastDayOfFocusedMonthTimestamp + this.$datedJS.dated.daysToMilliseconds(this.calendarInitialStartOffset);

            this.calendarCurMonth = this.$datedJS.dated.getMonthNum(this.calendarFirstOfFocusedMonthTimestamp);
            this.calendarNumDaysInCurMonth = this.$datedJS.dated.getNumberOfDaysInMonth(this.calendarFirstOfFocusedMonthTimestamp);
            this.calendarStartDate = calendarStartTimestamp; 
            this.calendarEndDate = calendarEndTimestamp;
            this.calendarCategoryDays = ((this.calendarEndDate - this.calendarStartDate) / this.$datedJS.dated.inMilliSeconds.day).toFixed(0);
            
            this.calendarHorizontalScrollAmount = (this.calendarInitialStartOffset - 1) * this.calendarDayColumnWidth;

            // console.log('categoryDays from scrollToNextMonth: ' + this.calendarCategoryDays);


            // this.$nextTick(function () {
                
                this.syntheticScrollEventDetails = { scrollLeft: this.calendarHorizontalScrollAmount, target: { scrollLeft: this.calendarHorizontalScrollAmount }, detail:{scrollLeft: this.calendarHorizontalScrollAmount, initiator: "scrollToNextMonth"}};

                this.syntheticScrollEvent = new CustomEvent("scroll", this.syntheticScrollEventDetails);
                this.calendarDailyPaneElement[0].dispatchEvent(this.syntheticScrollEvent);

                // console.log('calendarCategoryDays: ' + this.calendarCategoryDays + '\n\tcalendarCurrentMonth: ' + this.calendarCurMonth + '\n');
            // });

            
        },
       onScrollWindow(event) {
           
            if ((!event.detail) && (event.target._prevClass === 'v-calendar-daily__pane')) {
                event.stopPropagation();
             
            //  "_prevClass": "
                // let eventHorzScrollPos = event.target.scrollLeft;
                

                // console.log('event: ' + JSON.stringify(event.target, this.$jsUtils.jsonCircularReplacer(), 2));
                // console.log('event.scrollLeft: ' + eventHorzScrollPos);
                //  console.log('event.target.detail.initiator: ' + event.target.detail.initiator);
                // if(!this.isScrolling) {
                //     setTimeout(async () => {
                        // let calendarBody = document.querySelector('.v-calendar-daily__day-container');
                        // let scrollAmount = element.target.scrollTop;
                        
                        // let intervalBody = await document.querySelector('.v-calendar-daily__intervals-body');
                        // intervalBody.scrollTo({top: scrollAmount + 'px', left: 0, behavior: 'smooth'});
                      
                        // this.calendarIntervalBodyElement.scrollTo({ left: 0, top: this.calendarVerticalScrollAmount, behavior: 'auto' });
                        // this.calendarDailyHeadElement.scrollTo({ left: this.calendarHorizontalScrollAmount, top: 0, behavior: 'auto' });
                    
                    // this.$nextTick(function () {
                        this.calendarVerticalScrollAmount = this.calendarDailyPaneElement[0].scrollTop;
                        this.calendarHorizontalScrollAmount = this.calendarDailyPaneElement[0].scrollLeft;
                       
                        // this.calendarIntervalBodyElement.scrollTop = this.calendarVerticalScrollAmount;
                        // this.calendarDailyHeadElement.scrollLeft = this.calendarHorizontalScrollAmount;
                        this.calendarIntervalBodyElement.scrollTo({ left: 0, top: this.calendarVerticalScrollAmount, behavior: 'auto' });
                        this.calendarDailyHeadElement.scrollTo({ left: this.calendarHorizontalScrollAmount, top: 0, behavior: 'auto' });

                        let numDaysToTriggerPrevMonth = (this.calendarInitialStartOffset - this.calendarNumDaysInView + 1);
                        let numDaysToTriggerNextMonth = (this.calendarInitialStartOffset + this.calendarNumDaysInCurMonth - 1); 
                        // console.log('this.calendarNumDaysInCurMonth: ' + this.calendarNumDaysInCurMonth);
                        // console.log('scroll left amount: ' + this.calendarHorizontalScrollAmount);
                        // console.log('\nprevMonth trigger value: ' + numDaysToTriggerPrevMonth * this.calendarDayColumnWidth);
                        // console.log('\nnextMonth trigger value: ' + numDaysToTriggerNextMonth * this.calendarDayColumnWidth);                      

                        if (this.calendarHorizontalScrollAmount < (numDaysToTriggerPrevMonth * this.calendarDayColumnWidth)) {
                            this.scrollToPrevMonth(event);
                            // if (this.calendarHorizontalScrollAmount < 350) {
                                // this.calendarHorizontalScrollAmount = (this.calendarInitialStartOffset - this.calendarNumDaysInView) * this.calendarDayColumnWidth;
                                // console.log('the horizontalScrollAmount did not get set yet, so calling scrollTo to keep an infiniteLoop from happening by continuing to call scrollToPrevMonth because amount is under 350');
                                    // this.calendarDailyPaneElement[0].scrollTo(this.calendarHorizontalScrollAmount, this.calendarDailyPaneElement[0].scrollTop);
                            // }
                            
                        }

                        if (this.calendarHorizontalScrollAmount > (numDaysToTriggerNextMonth * this.calendarDayColumnWidth)) {
                            this.scrollToNextMonth(event);
                        }

                        /**
                         *  P A R A L A X    S C R O L L    B A C K G R O U N D    E F F E C T
                         */
                        // let scrollPercentage = -((this.calendarHorizontalScrollAmount / (numDaysToTriggerNextMonth * this.calendarDayColumnWidth) * 160).toFixed(0)) + 80;
                        // console.log('scroll percentage: ' + scrollPercentage);
                        // document.documentElement.style.setProperty('--background-position-x', scrollPercentage + 'vw');

                    // });
                        
                        // this.isScrolling = true;
                      
                    // }, 10);
                

                // }
                // this.isScrolling = false;
                
        
            } else if (event.detail) {
            
                // console.log(' event.target.scrollLeft: ' + JSON.stringify(event.target.scrollLeft, null, 2));
                // console.log(' event.scrollLeft: ' + JSON.stringify(event.scrollLeft, null, 2));
                // console.log(' event.detail.scrollLeft: ' + JSON.stringify(event.detail.scrollLeft, null, 2));

                // console.log('event.target: ' + JSON.stringify(event.target, this.$jsUtils.jsonCircularReplacer(), 2));
                // console.log('event.detail: ' + JSON.stringify(event.detail, null, 2));
                
                
                if ((event.detail.initiator === 'scrollToPrevMonth') || (event.detail.initiator === 'scrollToNextMonth') || (event.detail.initiator === 'horizontalScrollToday') || (event.detail.initiator === 'changeZoom')) {
                    // console.log('scrollingTo target.scrollLeft from initiator: ' + event.detail.initiator);
                    
                    this.calendarDailyPaneElement[0].scrollTo(event.detail.scrollLeft, this.calendarDailyPaneElement[0].scrollTop);
                    // this.calendarDailyHeadElement.scrollTo({ left: event.detail.scrollLeft, top: 0, behavior: 'auto' });
                    this.calendarDailyHeadElement.scrollLeft = this.calendarHorizontalScrollAmount;
                }
               
            } else {
                event.stopPropagation();
                // console.log('event.target._prevClass :' + event.target._prevClass );
                
                // console.log('event didn\'t match v-calendar-daily__pane class \n\tevent.target: ' + JSON.stringify(event.target, this.$jsUtils.jsonCircularReplacer(), 2));
            
            }
            this.setCssVariables();

        },
        onResizeWindow() {
            this.SET_WINDOW_DIMENSIONS({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
            this.$emit("updateCurMonthBackground", { curMonth: parseInt(this.calendarCurMonth), yPosition: 'center', backgroundColor: 'transparent', backgroundSize: 'cover' } );
            let calBodyBoundingBox = this.calendarDailyPaneElement[0].getBoundingClientRect();
            
            this.calendarBodyBoundingBox.top = calBodyBoundingBox.top;
            this.calendarBodyBoundingBox.bottom = calBodyBoundingBox.bottom;
            this.calendarBodyBoundingBox.width = calBodyBoundingBox.width;
            // this.calendarBodyBoundingBox.height = calBodyBoundingBox.height;
            this.calendarBodyBoundingBox.height = parseInt(Math.floor(window.innerHeight) - this.calendarBodyBoundingBox.top);
                       
            if (this.calendarTimeZoomed === false) {
                this.calendarIntervalHeight = parseInt(Math.floor(this.calendarBodyBoundingBox.height) / this.calendarIntervalCount).toFixed(0);

            } else {
                // divide by 16, because I want to show a 8 hour day with 30 minute intervals = 16 intervals
                this.calendarIntervalHeight = parseInt(Math.floor(this.calendarBodyBoundingBox.height) / this.calendarNumIntervalsOnScreen).toFixed(0);

            }

            this.calendarDayBodyHeight = (this.calendarIntervalCount * this.calendarIntervalHeight) + this.calendarBodyBoundingBox.top;
            this.setCssVariables();
            console.log("this.calendarIntervalHeight: " + this.calendarIntervalHeight );
            console.log("this.calendarBodyBoundingBox: " +  JSON.stringify(this.calendarBodyBoundingBox, null, 2));
            console.log("calendarIntervalHeight: " + this.calendarIntervalHeight);

            // this.$set(this.calendarBodyBoundingBox, 'top',  calBodyBoundingBox.top);
            console.log(" Math.floor(this.calendarBodyBoundingBox.top): " +  Math.floor(this.calendarBodyBoundingBox.top));
            console.log("onResizeWindow method call -- calendarIntervalHeight: " + this.calendarIntervalHeight);
            console.log(" Math.floor(this.calendarBodyBoundingBox.height): " +  Math.floor(this.calendarBodyBoundingBox.height));

            //  this.$nextTick(function () {
                // console.log("Scrollbar width: " + scrollbarWidth);
                
                // this.scrollToTime();
                console.log("---> TEMP Event Object: " + JSON.stringify(this.tempEventObj, null, 1));
                console.log('this.scrollToTime called from onWindowResize');
                  
            // });
    
        },
        minimize() {
            this.minimizeInForeground = !this.minimizeInForeground;
            this.minimizeInBackground = this.minimizeInForeground;

            if (this.maximizeState === true) {
                this.maximizeState = false;
            }
        },
        maximize() {
            if (this.maximizeState === false) {
                this.maximizeState = true;
                this.minimizeInForeground = false;
                this.minimizeInBackground = this.minimizeInForeground;
            } else {
                this.maximizeState = false;
            }
        },
        closeWidget() {
            this.toggleShowWidget(this.componentName);
            // if (this.t2doDisplaySettings.componentsDisplayState.focusedWidget === this.name) {
                /******************************************************************
                 * // TODO: the open widgets should be stored in an array instead of
                 * keeping track of a xcrap ton a variables. This will also have the 
                 * benefit of moving focus to the next widget.
                 * *****************************************************************/
            //     this.setFocusedWidget(this.name);
            // }

        },
        viewDay( { date } ) {
            // this.focus = date;
            date = date + ' 12:00';
            console.log('\ntoggleDaySummary called with date: ' + date);
            // let dateToToggle = new Date(date).toISOString();
            // let dateToToggle = this.$datedJS.dated.getNoonOnDate(date);
            // console.log('\ntoggleDaySummary called with dateToToggle: ' + dateToToggle);
            
            
            this.toggleDaySummary( date );
            this.setCalendarCategories(['owner_screen_name', [ this.$jsUtils.capitalizeString(this.getCurrentUserName) ]]);
            console.log('events that match that date: ' + JSON.stringify(this.getEventsFromDate( date ), null, 1));

        },
        getInProgressEventElements() {
            let calendarInProgressElements = document.getElementsByClassName("v-event-draggable task-in-progress");
           
            for (let i = 0, numElements = calendarInProgressElements.length | 0;  i < numElements; i = i + 1 | 0){
              
                this.calendarInProgressEvents[i] = calendarInProgressElements[i].parentElement;
                this.calendarInProgressEvents[i].classList.add('task-in-progress');

            }
        },
        setEventParentClass(eventClassName) {
            let calendarEventParentElements = document.getElementsByClassName('v-event-draggable ' + eventClassName);
            console.log("calendarInProgressElements: " + calendarEventParentElements);
            for (let i = 0, numElements = calendarEventParentElements.length | 0;  i < numElements; i = i + 1 | 0){

                console.log('updating parent class for: ' + calendarEventParentElements[i].parentElement.tagName);
                this.calendarEventsParentElement[i] = calendarEventParentElements[i].parentElement;
                this.calendarEventsParentElement[i].classList.add(eventClassName);

            }
        },
        getCurrentTime() {
            // return this.calendarInstance ? this.calendarInstance.times.now.hour * 60 + this.calendarInstance.times.now.minute : 0;
            return this.$datedJS.dated.getCurrHour() * 60 + this.$datedJS.dated.getCurrMin();
        },
        scrollToTime () {
            const time = this.getCurrentTime();
            console.log('scrollToTime method - getCurrentTime: ' + time);
            const first = Math.max(0, time - (time % 30) - 30);
            console.log('scrollToTime first value: ' + first);

            this.calendarVerticalScrollAmount = this.calendarIntervalHeight * (this.$datedJS.dated.getCurrHour() + 1); //* parseInt(this.calendarIntervalCount / 25)
            let maxScrollAmount = parseInt((this.calendarDayBodyHeight - this.calendarBodyBoundingBox.top ) / 2);
            console.log('scrollAmount in scrollToTime before max: ' + this.calendarVerticalScrollAmount);
            this.calendarVerticalScrollAmount = (this.calendarVerticalScrollAmount < maxScrollAmount) ? this.calendarVerticalScrollAmount: maxScrollAmount;
            console.log('scrollAmount in scrollToTime after max: ' + this.calendarVerticalScrollAmount);
           
            this.calendarDailyPaneElement[0].scrollTo(this.calendarHorizontalScrollAmount, this.calendarVerticalScrollAmount);

            // return this.calendarInstance ? this.calendarInstance.scrollToTime(first) : 0;

        },
        updateTime() {

            if(!this.updateIntervalId) {
                this.updateIntervalId = setInterval(() => {
                    // this.calendarInstance.updateTimes();

                    // this.currentTimeIndicatorElement = document.querySelector('#task2Do-app-id .v-calendar-daily__day.v-present .v-current-time.first');
                    // this.currentTimeIndicatorElement = document.getElementById('current-time-indicator-id');
                    
                    
                    // this.currentTimeIndicatorElement = document.querySelector('#task2Do-app-id .v-calendar-daily__day.v-present .v-current-time.first');
                    // this.currentTimeIndicatorElement.style.setProperty('--current-time', `"${this.currentTimeObj.twelveHour}:${this.currentTimeObj.minutes} ${this.currentTimeObj.timePeriod}"`);

                    if (this.calendarCurrentDayButton !== null) {

                        let calCurrentDayButtonBoundingBox = this.calendarCurrentDayButton.getBoundingClientRect();
                        this.calendarCurrentDayButtonBoundingBox.top = calCurrentDayButtonBoundingBox.top;
                        this.calendarCurrentDayButtonBoundingBox.bottom = calCurrentDayButtonBoundingBox.bottom;
                        this.calendarCurrentDayButtonBoundingBox.left = calCurrentDayButtonBoundingBox.left.toFixed(0);
                        this.calendarCurrentDayButtonBoundingBox.right = calCurrentDayButtonBoundingBox.right;
                        this.calendarCurrentDayButtonBoundingBox.x = calCurrentDayButtonBoundingBox.x;
                        this.calendarCurrentDayButtonBoundingBox.y = calCurrentDayButtonBoundingBox.y;
                    } else {
                        this.calendarCurrentDayButtonBoundingBox.top = 0;
                        this.calendarCurrentDayButtonBoundingBox.bottom = 0;
                        this.calendarCurrentDayButtonBoundingBox.left = 0;
                        this.calendarCurrentDayButtonBoundingBox.right = 0;
                        this.calendarCurrentDayButtonBoundingBox.x = 0;
                        this.calendarCurrentDayButtonBoundingBox.y = 0;
                    }
                    // document.documentElement.style.setProperty('--current-time', `"${this.currentTimeObj['twelveHour']}:${this.currentTimeObj['minutes']} ${this.currentTimeObj['timePeriod']}"`);

                    // document.documentElement.style.setProperty('--current-time-left-possition', `calc(${this.calendarCurrentDayButtonBoundingBox.left}px - 13%)`);
                     this.setCssVariables();
                  
                },  60 * 1000);
            }

        },
        async updateRange({ start, end }) {
            // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
           
           console.log('updateRange start: ' + JSON.stringify(start, null, 1) + ' \nupdateRange end: ' + JSON.stringify(end, null, 1));

            // this.calendarFirstOfFocusedMonthTimestamp = this.$datedJS.dated.getFirstOfMonthTimestamp();
            // this.calendarLastDayOfFocusedMonthTimestamp = this.$datedJS.dated.getLastOfMonthTimestamp(this.calendarFirstOfFocusedMonthTimestamp) 

            // let calendarStartTimestamp = this.calendarFirstOfFocusedMonthTimestamp - this.$datedJS.dated.daysToMilliseconds(this.calendarInitialStartOffset);
            // let calendarEndTimestamp = this.calendarLastDayOfFocusedMonthTimestamp + this.$datedJS.dated.daysToMilliseconds(this.calendarInitialStartOffset);

            // this.calendarStartDate = calendarStartTimestamp; 
            // this.calendarEndDate = calendarEndTimestamp;


         
            // console.log("Calendar this.start: " + JSON.stringify(this.start, null, 2));
            // console.log("Calendar this.end: " + JSON.stringify(this.end, null, 2));
            if (this.calendarCurMonth) {
              
                this.$emit("updateCurMonthBackground", { curMonth: parseInt(this.calendarCurMonth), yPosition: '1.5rem', backgroundColor: 'transparent', backgroundSize: 'cover' } );
                console.log("calendar this.calendarCurMonth: " + this.calendarCurMonth);
            }
           
            // this.addWeekDayTimeslots();
            // this.remWeekDayTimeslots();
            // this.addWeekDayTimeslots();
            this.value = '';
            this.today = this.$datedJS.dated.getLocalISODate(this.$datedJS.dated.rightNow)
            this.$refs.calendar.checkChange();
            // await this.onResizeWindow();
            this.$nextTick(async function () {
                this.setCssVariables();
                this.scrollToTime();
                    
            });
           
            //await this.getLocalTasks2Do();
            //this.events = await [...this.apiEvents];
            //   await this.fetchTasks2Do();
            //   this.events = await [...this.allTasks2Do]; //, ...this.getLocalTasks2Do()];
            //   this.events.push(this.allTasks2Do);
            // this.events = events
        },
        createNewAppointment() {
            // this.addEventDialog = true;
            
            // this.tempEventObj = this.selectedEvent;
            this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.newEventObj);
            this.editEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.tempEventObj);
            // this.editEventObj.time_data =  { timed: null, times: [ { start: null, end: null, start_unix_timestamp: 0, end_unix_timestamp: 0, prep_time: 0 } ] };
            this.editEvent(this.editEventObj);
        },
        editSelectedAppointment() {
            console.log("editSelectedAppointment method called");
        },
        displayTasks2DoToo() {
            console.log("displayTasks2DoToo method called");
        },
        deleteSelectedAppointment() {
            console.log("deleteSelectedAppointment method called");
        },
        toPrevTimePeriod() {
            console.log("Prev time period button pressed");
            this.prev();
        },
        toNextTimePeriod() {
            console.log("Prev time period button pressed");
            this.next();
        },
        setToday() {
            // this.focus = ''; // this.today;
            this.today = this.$datedJS.dated.getLocalISODate(this.$datedJS.dated.rightNow)
            this.value = '';
        
            if(this.calendarDisplayType === 'category') {
                // this.$nextTick(function () {
                    this.setCalendarCategories(['owner_screen_name', [ this.$jsUtils.capitalizeString(this.getCurrentUserName) ]]);
                    this.$nextTick(async function () {
                        await setTimeout(this.horizontalScrollToday, 100);
                        this.scrollToTime();
                        
                    });
                // });
            }
           
        },
        moveTo(daysFromCurrentOffset) {
            
            this.calendarStartOffset = this.calendarStartOffset + daysFromCurrentOffset;
            let originalCalendarDisplayType = this.calendarDisplayType;
            this.calendarDisplayType = "day";
            this.$nextTick(function () {
                 this.$refs.calendar.move(daysFromCurrentOffset);
                 this.calendarDisplayType = originalCalendarDisplayType;
            });

        },
        prev() {
            console.log("prev method called");
            this.remWeekDayTimeslots();
            /** This is a HACK to get the 4day view to move by only  days without modifiying vuetify */
            
            switch (this.calendarDisplayType) {
              
                case '4day': 
                    //this is the hack: need to switch to day view breifly to be able to move by individual days then wait a tick for calendar to switch mode then move(x) number of days in either direction
                    this.moveTo(-1);
                    // this.calendarDisplayType = "day";
                    // this.$nextTick(function () {
                    //     this.$refs.calendar.move(-1);
                    //     this.calendarDisplayType = "4day";
                    // });
                    break;
                case 'category': 
                    this.moveTo(-1);
                    // this.calendarDisplayType = "day";
                    // this.$nextTick(function () {
                    //     this.$refs.calendar.move(-1);
                    //     this.calendarDisplayType = "category";
                    // });
                    break;
                case 'week':
                    this.moveTo(-1);
                    // this.calendarDisplayType = "day";
                    // this.$nextTick(function () {
                    //     this.$refs.calendar.move(-1);
                    //     this.calendarDisplayType = "week";
                    // });
                    break;
                default: this.$refs.calendar.prev();

            }
            
            // if (this.calendarDisplayType === '4day'){
            //     this.calendarDisplayType = "day";
            //     this.$nextTick(function () {
            //         this.$refs.calendar.move(-1);
            //         this.calendarDisplayType = "4day";
            //     });              
            // } else {
            //     this.$refs.calendar.prev();
            // }           
        },
        next() {
            this.remWeekDayTimeslots();
            /** This is a HACK to get the 4day view to move by only  days without modifiying vuetify */
           
            switch (this.calendarDisplayType) {
              
                case '4day': 
                    //this is the hack: need to switch to day view breifly to be able to move by individual days then wait a tick for calendar to switch mode then move(x) number of days in either direction
                    this.moveTo(2);
                    // this.calendarDisplayType = "day";
                    // this.$nextTick(function () {
                    //     this.$refs.calendar.move(2);
                    //     this.calendarDisplayType = "4day";
                    // });
                    break;
                case 'category': 
                    this.moveTo(2);
                    // this.calendarDisplayType = "day";
                    // this.$nextTick(function () {
                    //     this.$refs.calendar.move(2);
                    //     this.calendarDisplayType = "category";
                    // });
                    break;
                case 'week':
                    this.moveTo(2);
                    // this.calendarDisplayType = "day";
                    // this.$nextTick(function () {
                    //     this.$refs.calendar.move(2);
                    //     this.calendarDisplayType = "week";
                    // });
                    break;
                default: this.$refs.calendar.next();

            }

            // if (this.calendarDisplayType === '4day'){
            //     this.calendarDisplayType = "day";
            //     this.$nextTick(function () {
            //         this.$refs.calendar.move(2);
            //         this.calendarDisplayType = "4day";
            //     });
            // } else {
            //     this.$refs.calendar.next();
            // }

        },
        async zoomInToCurrentTime() {
            let paddedHours = 6;
            
            // let zoomedStartInterval = this.currentTimeObj.hour - (paddedHours / 1.5).toFixed(0);
            // zoomedStartInterval = zoomedStartInterval >= 0 ? zoomedStartInterval : 0;

            // let zoomedIntervalCount = 24; //paddedHours * 2;
            this.calendarTimeZoomed = true;
            // when zooming in and out, its probably best to disable event edits due to inadvertant touches or gestures during the compenent redraw
            // let resetcalendarScrollLock = this.calendarScrollLocked === true ? this.toggleCalendarScrollLock() : '';
            // (this.calendarScrollLocked === true) && (this.toggleCalendarScrollLock());

            // OMG, what is wrong with your brain. The previous 2 statements were overly complicated methods to do the following:
            // this.calendarScrollLocked = false;

            // Actually my brain might be OK. I need to call the toggleCalendarScrollLock so the toolbar and CSS variable get updated.
            (this.calendarScrollLocked === true) && (this.toggleCalendarScrollLock());
            
            this.calendarEditToolbarItems.zoomIn.visible = !this.calendarTimeZoomed;
            this.calendarEditToolbarItems.zoomOut.visible = this.calendarTimeZoomed;
            this.calendarIntervalMinutes = 30;

            console.log('\n\tparseInt(this.calendarBodyBoundingBox.width):' + parseInt(this.calendarBodyBoundingBox.width));

            if (parseInt(this.calendarBodyBoundingBox.width) >= 1024) {
                this.calendarNumDaysInView = 4;
            } else if (parseInt(this.calendarBodyBoundingBox.width) > 790) {
                this.calendarNumDaysInView = 4;
            } else {
                this.calendarNumDaysInView = 3;
            }

            if (this.calendarBodyBoundingBox.height > 590) {
                // this.calendarNumIntervalsOnScreen = 24; // 12 hours displayed on screen in 30 minute timeslots
                this.calendarNumIntervalsOnScreen = 20;
            } else {
                // this.calendarNumIntervalsOnScreen = 18; // 9 hours displayed on screen in 30 minute timeslots
                this.calendarNumIntervalsOnScreen = 16;
            }
            
            
            this.calendarIntervalCount = 1440 / this.calendarIntervalMinutes + 1;
            // this.calendarIntervalHeight = parseInt(Math.floor(this.calendarBodyBoundingBox.height) / 16).toFixed(0);
                                
            // calculate the day column width based on the viewport width divided by the number of days to show in that viewport
            this.calendarDayColumnWidth = (this.calendarBodyBoundingBox.width / this.calendarNumDaysInView).toFixed(0);
            console.log('this.calendarBodyBoundingBox.height: ' + this.calendarBodyBoundingBox.height );

            // set current scale to current width of BodyDays container
            this.currentTimeWidthScale = parseInt(this.calendarBodyDaysWidth);

            // To calculate width of calendar container including off screen elements)
            // multiple the day column width calculated above by the number of calandar days in the whole 'scrollable' view.
            this.calendarBodyDaysWidth =  (this.calendarDayColumnWidth * this.calendarCategoryDays).toFixed(0) + 'px';

            // recalculate the current TimeWidthScale. (this give a percentage of change from before zoom to after zoom)
            this.currentTimeWidthScale = parseInt(this.calendarBodyDaysWidth) / this.currentTimeWidthScale;

            console.log('calendarDailyPaneElement[0].scrollLeft before applying scale: ' + this.calendarDailyPaneElement[0].scrollLeft);
            console.log('calendarHorizontalScrollAmount before applying scale: ' + this.calendarHorizontalScrollAmount);
            console.log('currentTimeWidthScale = ' + this.currentTimeWidthScale);

            // the percentage change in width is then applied to the current scroll amount so the view is still
            // scrolled to the same day before the zoom change despite the overall container width increasing.
            this.calendarHorizontalScrollAmount *= this.currentTimeWidthScale; 
            console.log('calendarHorizontalScrollAmount: ' + this.calendarHorizontalScrollAmount);

            this.syntheticScrollEventDetails = { scrollLeft: this.calendarHorizontalScrollAmount, target: { scrollLeft: this.calendarHorizontalScrollAmount }, detail:{scrollLeft: this.calendarHorizontalScrollAmount, initiator: "changeZoom"}};

            this.syntheticScrollEvent = new CustomEvent("scroll", this.syntheticScrollEventDetails);
            this.calendarDailyPaneElement[0].dispatchEvent(this.syntheticScrollEvent);              

            this.calendarIntervalStartTime = '00:00';   //((zoomedStartInterval < 10) ? ('0' + zoomedStartInterval) :  zoomedStartInterval ) + ':00';
            
            // TODO: here's another reason to create a cssSetVariables function
            this.setCssVariables();
            // this.today = this.$datedJS.dated.getLocalISODate(this.$datedJS.dated.rightNow);

            // the calendar start date should not be modified when zooming in/out. I'm not sure my logic for thinking this was necessary
            // TODO: remove this line if I can't think of a real good reason I should be doing this --> await this.moveTo(-this.calendarNumPastDaysToShow);
            
            this.$nextTick(async function () {           
                await this.onResizeWindow();
                await this.scrollToTime();
                // this.calendarDailyPaneElement[0].scrollTo(this.calendarHorizontalScrollAmount, this.nowY);
                // this.horizontalScrollToday();
            });

        },
        async zoomOutOfCurrentTime() {
            this.calendarTimeZoomed = false;

            // when zooming in and out, its probably best to disable event edits due to inadvertant touches or gestures during the compenent redraw
            (this.calendarScrollLocked === true) && (this.toggleCalendarScrollLock());
            this.calendarEditToolbarItems.zoomIn.visible = !this.calendarTimeZoomed;
            this.calendarEditToolbarItems.zoomOut.visible = this.calendarTimeZoomed;
            this.calendarIntervalMinutes = 60;
            this.calendarIntervalCount = 1440 / this.calendarIntervalMinutes + 1;
            // this.calendarIntervalHeight = parseInt(Math.floor(this.calendarBodyBoundingBox.height) / this.calendarIntervalCount).toFixed(0);
            
            if (parseInt(this.calendarBodyBoundingBox.width) >= 1024) {
                this.calendarNumDaysInView = 10;
            } else if (parseInt(this.calendarBodyBoundingBox.width) > 790) {
                this.calendarNumDaysInView = 7;
            } else {
                this.calendarNumDaysInView = 5;
            }

            console.log('this.calendarBodyBoundingBox.height: ' + this.calendarBodyBoundingBox.height );
            
            this.calendarDayColumnWidth = (this.calendarBodyBoundingBox.width / this.calendarNumDaysInView).toFixed(0);
            
            this.currentTimeWidthScale = parseInt(this.calendarBodyDaysWidth);
            this.calendarBodyDaysWidth =  (this.calendarDayColumnWidth * this.calendarCategoryDays).toFixed(0) + 'px'; // '10080px'; //'5600px'; //'11200px'; //'7--rem';

            this.currentTimeWidthScale = parseInt(this.calendarBodyDaysWidth) / this.currentTimeWidthScale;
            console.log('calendarDailyPaneElement[0].scrollLeft before applying scale: ' + this.calendarDailyPaneElement[0].scrollLeft);
            console.log('calendarHorizontalScrollAmount before applying scale: ' + this.calendarHorizontalScrollAmount);
            console.log('currentTimeWidthScale = ' + this.currentTimeWidthScale);
            this.calendarHorizontalScrollAmount *= this.currentTimeWidthScale;
            console.log('calendarHorizontalScrollAmount: ' + this.calendarHorizontalScrollAmount);
            
            this.syntheticScrollEventDetails = { scrollLeft: this.calendarHorizontalScrollAmount, target: { scrollLeft: this.calendarHorizontalScrollAmount }, detail:{scrollLeft: this.calendarHorizontalScrollAmount, initiator: "changeZoom"}};

            this.syntheticScrollEvent = new CustomEvent("scroll", this.syntheticScrollEventDetails);
            this.calendarDailyPaneElement[0].dispatchEvent(this.syntheticScrollEvent); 
        
            this.calendarIntervalStartTime = '00:00';
            
            // this.calendarIntervalHeight = parseInt((Math.floor(window.innerHeight) - Math.floor(this.calendarBodyBoundingBox.top) - this.appFooterHeight) / this.calendarIntervalCount);
            // TODO: here's another reason to create a cssSetVariables function
            this.setCssVariables();
            // this.today = this.$datedJS.dated.getLocalISODate(this.$datedJS.dated.rightNow);
            
            // the calendar start date should //NOT be modified when zooming in/out. I'm not sure my logic for thinking this was necessary
            // TODO: remove this line if I can't think of a real good reason I should be doing this --> await this.moveTo(-this.calendarNumPastDaysToShow);

            this.$nextTick(async function () {
                await this.onResizeWindow();
                await this.scrollToTime();
                // this.calendarDailyPaneElement[0].scrollTo(this.calendarHorizontalScrollAmount, this.nowY);
                // this.horizontalScrollToday();
            });
        },
        toggleCalendarScrollLock() {

            this.calendarEditToolbarItems.calendarScrollLock.curStateIndex = this.calendarEditToolbarItems.calendarScrollLock.curStateIndex === 0 ? 1 : 0;
            this.calendarScrollLocked = this.calendarEditToolbarItems.calendarScrollLock.curStateIndex === 1 ? true : false;
            if (this.calendarScrollLocked === true) {
                if (this.calendarDailyPaneElement[0]) {
                    this.calendarDailyPaneElement[0].removeEventListener('scroll', this.onScrollWindow, false); 
                }
            } else if (this.calendarDailyPaneElement[0]) {
                this.calendarDailyPaneElement[0].addEventListener('scroll', this.onScrollWindow, false); // {passive: true} );
            }
           
           
            // let overflowValue = this.calendarScrollLocked === true ? 'hidden' : 'auto'; //document.documentElement.style.getPropertyValue('--calendar-scroll-lock');
            // console.log('calendar-scroll-lock value: ' + overflowValue);
            // overflowValue = overflowValue === 'auto' ? 'visible' : 'auto';
            console.log('calendar-scroll-lock new value: ' + this.scrollLockCssValue);
            document.documentElement.style.setProperty('--calendar-scroll-lock', this.scrollLockCssValue);
            return this.calendarScrollLocked;
              
        },
        setTypeDay() {
            this.calendarDisplayType = 'day';
        },
        setType4Day() {
            this.calendarDisplayType = '4day';
        },
        setTypeWeek() {
            this.calendarDisplayType = 'week';
        },
        setTypeMonth() {
            this.calendarDisplayType = 'month';
        },
        async setCalendarType(calendarDisplayType) {
            // await this.getAllEvents();
            this.calendarDisplayType = calendarDisplayType;
            this.calendarDisplayTypeLabel = calendarDisplayType;
            this.setCssVariables();
        
        },
        async setCalendarCategories(calendarCategories) {
            console.log('calendarCategories argument: ' + JSON.stringify(calendarCategories, null, 2));

            switch (calendarCategories[0]) {
                    case 'planed_vs_reality': {
                            const filteredTasks = this.events.filter(event => (this.getTaskTypes[event.type_id].typeName === calendarCategories[1][0]));
                            this.events = this.$arrayObjUtils.arrayObjsDeepCopy(filteredTasks);
                            // this.dailyEvents = []; 
                        }
                        break;
                    case 'status_id': {
                            const filteredTasks = this.events.filter(event => ((event.status_id >= calendarCategories[1][0])
                            && (event.status_id <= calendarCategories[1][1])
                            && (this.getTaskTypes[event.type_id].typeName !== 'Rest')));
                            this.events = this.$arrayObjUtils.arrayObjsDeepCopy(filteredTasks);
                            this.dailyEvents = [];
                        }
                        break;
                    case 'owner_screen_name': {
                            this.calendarPropertyCategoryFilter = calendarCategories[0];
                            if (calendarCategories[1][0] === 'getCurrentUserName') {
                                this.calendarCategories = this.$jsUtils.capitalizeString(this.getCurrentUserName);
                            } else {
                                this.calendarCategories = calendarCategories[1][0];
                            }
                            
                            // this.calendarDisplayType = "day";
                            // this.$nextTick(function () {
                                // this.$refs.calendar.move(this.calendarStartOffset);
                                //  this.$nextTick(async function () {
                                    this.calendarDisplayType = 'category';
                                    this.calendarDisplayTypeLabel = 'Timed';
                                    this.setCssVariables();
                                    // await setTimeout(this.horizontalScrollToday, 1000);
                                //  });
                            // });
  
                        }
                        break;
                   default: {
                        this.calendarPropertyCategoryFilter = calendarCategories[0];
                        this.calendarCategories = calendarCategories[1][0];
                        // this.calendarDisplayType = "day";
                        // this.$nextTick(function () {
                            // this.$refs.calendar.move(this.calendarStartOffset);
                            this.calendarDisplayType = 'category';
                            this.calendarDisplayTypeLabel = 'Category';
                            this.setCssVariables();
                        // });
                        
                   }

                }
            // if (calendarCategories[0] === 'status_id') {
            //     const filteredTasks = this.events.filter(event => ((event.status_id >= calendarCategories[1][0])
            //      && (event.status_id <= calendarCategories[1][1])
            //      && (this.getTaskTypes[event.type_id].typeName !== 'Rest')));
            //     this.events = this.$arrayObjUtils.arrayObjsDeepCopy(filteredTasks);
            //     this.dailyEvents = [];
            // } else {
            //     await this.getAllEvents();
            //     this.calendarPropertyCategoryFilter = calendarCategories[0];
            //     this.calendarCategories = calendarCategories[1];
            //     this.calendarDisplayType = "day";
            //     this.$nextTick(function () {
            //         this.$refs.calendar.move(-5);
            //           this.calendarDisplayType = 'category';
            //     }); 
            
            // }
        },
        textFieldFilter (item, queryText, itemText) {
            let textOne = item.name.toLowerCase();
            // let textTo = item.abbr.toLowerCase();
            let searchText = queryText.toLowerCase();
            return textOne.indexOf(searchText) > -1;
        },
        async getAllEvents() {
           
            console.log('#####     getAllEventscalled    #####');
            //await this.getFirebaseEvents();
            // this.getTasks2Do(); apiData = this.task2Dos.json();

            // console.log(
            //     "getAllEvents: task-todos-inside calendar: " +
            //     JSON.stringify(this.task2Dos, null, 2)
            // );

            //await this.getLocalTasks2Do();
            // await this.fetchTasks2Do();
            // this.events = await [...this.allTasks2Do]; //, ...this.getLocalTasks2Do()];

            //TODO: NEED TO CHECK Tasks for valid timestamps before adding to calendar. 
                //For gerneral purpose todos, just set the start timestamp to now() and ending timestamp to now() + 1 hour

            // this.allTasks2Do.forEach(task => {

            /********************************************************************************************************
             * // TODO: Is it possible to get a returned value from emitting an event on the parent component?
             ********************************************************************************************************/
            console.log('timestamp before await this.$emit("refreshApiData: ' + new Date().getTime());
            // await this.$emit("getUpdatedDataFromAPI", true);
            console.log('timestamp after await this.$emit("refreshApiData: ' + new Date().getTime());
            /********************************************************************************************************
             * // TODO: How would await know when the function completes on the parent
             ********************************************************************************************************/
            
            // if (refreshDataResult === false) {
            //     console.log('\n\tRefreshDataResult is FALSE, calling geteAllEvents in 15 seconds');
            //     setTimeout(this.getAllEvents, 15000);
            // } else {
            //     await this.setupTimedEventsForCalendar();
            // }
            
            
            // this.events.push(this.allTasks2Do);
            // await this.getTasks2Do();
            // this.events = await [...this.apiEvents]
            // this.events = await [...this.apiEvents, ...this.firebaseEvents];
            // this.events = await [...this.task2Dos, ...this.apiEvents];
            // await this.setupTimedEventsForCalendar();
            // this.$nextTick(async function () {
                
            //     this.$refs.calendar.checkChange();
            // });
        },
        setupTimedEventsForCalendar() {

            // let tasks = [];

            // let tasks = this.$arrayObjUtils.arrayObjsDeepCopy([...this.allTasks2Do]);
            let tasks = this.$arrayObjUtils.arrayObjsDeepCopy([...this.getCalendarEvents2Do]);
            let processedEvents = [];
            let processedDailyEvents = [];
            

            tasks.forEach(task => {

                // TODO: Need to assign the original index to task and not the index within the v-calendar events.
                // Reason being is that daily & reoccuring events cause v-calendar event indexes to be out of sync with allTasks2Do indexes
                // Also make sure to set index 1st, before making any changes to a task otherwise index will be set to -1
                // task.index = this.allTasks2Do.indexOf(task);
                // console.log('taskIndex: ' + task.index);

                // console.log("\t------------Current task: " + task.name + "\n\tCurrent Task Type: " + task.type_id + "\n\tTask Categories: " + task.categories + "\n\tTask Start: " + task.start + "\n\n");
                const fullDateTimeRegEx = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/;
                // const fullDateTimeRegEx = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}/;
                
                // if (task.start === undefined) || (task.start === null)
                if ((task.start === undefined)) { // } || (!(fullDateTimeRegEx.test(task.start)))) {
                    // if we don't have a valid datetime stamp just use current time plus and hour for start & end times respectivley
                    console.log("task.start: (" + task.start + ") is undefined or not valid datetime stamp for the task: " + task.name + "\n\n");

                    task.start = this.$datedJS.dated.getLocalISODateTime(new Date());
                    // this.localISOTime(new Date().addHours(2));
                    // this.$datedJS.localISOTime(calData.start.substr(0,16));

                    task.end = this.$datedJS.dated.getLocalISODateTime(new Date().addHours(1));
                    console.log("\tUpdated task.start = " + task.start + " & task.end: " + task.end + "\n");
                    
                } else {
                    task.start = this.$datedJS.dated.getLocalISODateTime(task.start);
                    task.end = this.$datedJS.dated.getLocalISODateTime(task.end);
                    
                }

                /** TODO: Add check for event types specifically daily and routine. Add events in calendar for each day that 
                 * fakks within the start/end span of the daily event 
                 * 
                 * */

                // console.log("#######################\nTaskType" + this.getTaskTypes[task.type_id].typeName + " \n");

                if ((this.getTaskTypes[task.type_id].typeName === 'Daily') && (this.calendarDisplayType !== 'month')) {
                    // console.log("Daily task original start: " + task.start);

                    let startDateSpan = new Date(task.start).getTime();
                    let endDateSpan = new Date(task.end).getTime();

                    // console.log("Dated miliseconds in a day: " + this.$datedJS.dated.inMilliSeconds.day);

                    let numDays = Math.ceil(((endDateSpan - startDateSpan) / this.$datedJS.dated.inMilliSeconds.day));

                    // console.log("Number of days to span daily appointment: " + numDays);
                    
                    let startTime = this.$datedJS.dated.getLocalISOTime(task.start);
                    let endTime = this.$datedJS.dated.getLocalISOTime(task.end);
                    let startDate =  this.$datedJS.dated.getLocalISODate(task.start);
                    let endDate =  this.$datedJS.dated.getLocalISODate(task.end);
                    let endDurationMilliSeconds = 0;
                    
                    if (parseInt(endTime) < parseInt(startTime)) {
                        let durationDate = this.$datedJS.dated.getLocalISODate(startDateSpan + this.$datedJS.dated.inMilliSeconds.day);
                        let endDailyDuration = durationDate + " " + endTime;

                        // console.log("end duration: " + endDailyDuration);
                        endDurationMilliSeconds = new Date(endDailyDuration).getTime();

                    } else {
                        let durationDate = this.$datedJS.dated.getLocalISODate(startDateSpan);
                        let endDailyDuration = durationDate + " " + endTime;

                        // console.log("end duration: " + endDailyDuration);
                        endDurationMilliSeconds = new Date(endDailyDuration).getTime();
                    }
                    let duration = endDurationMilliSeconds - startDateSpan;

                    console.log("Task start date: " + startDate + "\tTask start time: " + startTime + " \n\tDailyTask end date: " + endDate + "\tDaily task end time: " + endTime + "\n");

                    for(let i = 0; i < numDays; i++) {
                        let newDailyEvent = this.$arrayObjUtils.arrayObjsDeepCopy(task);
                        let dailyEventStartTime = startDateSpan + (this.$datedJS.dated.inMilliSeconds.day * i)
                        newDailyEvent.startISO = this.$datedJS.dated.getLocalISODateTime(dailyEventStartTime);
                        
                        // console.log("newDailyvent.start = " + newDailyEvent.start);
                        
                        newDailyEvent.endISO = this.$datedJS.dated.getLocalISODateTime(dailyEventStartTime + duration);
                        
                        // console.log("newDailyvent.end = " + newDailyEvent.end);
                        // console.log("\nnewDailyvent: " + JSON.stringify(newDailyEvent, null, 2));
                        
                        newDailyEvent.start_unix_timestamp = new Date(newDailyEvent.startISO).getTime();
                        newDailyEvent.end_unix_timestamp = new Date(newDailyEvent.endISO).getTime();

                        processedDailyEvents.push(newDailyEvent);
                       
                    }

                } else if ((this.getTaskTypes[task.type_id].typeName !== 'Daily') && (!this.ignoredTaskTypes.includes(this.getTaskTypes[task.type_id].typeName))) {
                    
                    processedEvents.push(task);
                }
                
                // task.start = task.start.toString().substr(0, 19);
                // task.end = task.end.toString().substr(0, 19);
                
                // this.events[this.events.length - 1].start = new Date(task.start).getTime();
                // this.events[this.events.length - 1].end = new Date(task.end).getTime();

                // this.events[this.events.length - 1].start = Date.parse(task.start.toString());
                // this.events[this.events.length - 1].end = Date.parse(task.end.toString());

                // console.log("!!!!!!!!!!!!!!!\nEvent.color before: " + this.events[this.events.length - 1].color + "\n");
                processedEvents[processedEvents.length - 1].color = this.getEventColor(processedEvents[processedEvents.length - 1]);
                // console.log("!!!!!!!!!!!!!!!\nEvent.color after: " + this.events[this.events.length - 1].color + "\n");
                // console.log("-----New Event.end: " + this.events[this.events.length - 1].end);
            
            });
            this.dailyEvents = this.$arrayObjUtils.arrayObjsDeepCopy(processedDailyEvents);
            this.events = this.$arrayObjUtils.arrayObjsDeepCopy(processedEvents);
        },
        async getLocalTasks2Do() {
        let apiData = this.task2Dos;
            let apiTask2DoData = [];

            this.apiEvents = [];

            await this.apiData.forEach(task => {
                // window.console.log("Data from local task2Dos: " + JSON.stringify(task));
                let calData = task;

                if (calData.type === "event") {
                    window.console.log("App Data start before substr: " + calData.start);
                    const numRegEx = /^[0-9]{4}/;
                    const fullDateTimeRegEx = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/; //seconds included
                    //const fullDateTimeRegEx = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}/;

                    
                        let eventStartString = calData.start.substr(0, 4);
                        let startRegExResult = numRegEx.test(eventStartString);
                        let eventEndString = calData.end.substr(0, 4);
                        let endRegExResult = numRegEx.test(eventEndString);
                        console.log(
                            "Task: " +
                            calData.name +
                            ", has a 4 char substring of: " +
                            eventStartString +
                            " Results form regular expression: " +
                            startRegExResult
                        );

                        if (startRegExResult !== true) {
                            console.log("Current task doesn't start with 4 digets \n");

                            calData.start = new Date().addHours(2);
                        }
                        if (endRegExResult !== true) {
                            console.log("Current task doesn't start with 4 digets \n");
                            calData.end = new Date().addHours(6);
                        }
                        console.log(
                            "[ Inside !== undefined condition ] calData.start: " +
                            calData.start +
                            "\n"
                        );
                        //  calData.start = this.$datedJS.localISOTime(calData.start.substr(0,16));
                        //  calData.end = this.$datedJS.localISOTime(calData.end.substr(0,16));

                        if (typeof calData.start === "string") {
                            let startString = calData.start.substr(0, 16);
                            calData.start = this.$datedJS.dated.getLocalISODateTime(calData.start);
                            calData.end = this.$datedJS.dated.getLocalISODateTime(calData.end);
                            console.log("calData substr: " + calData.start);
                        }

                        console.log(
                            "[ Inside !== undefined condition ] calData.end: " +
                            calData.end +
                            "\n"
                        );

                    if (calData.start === undefined) {
                        calData.start = this.$datedJS.dated.getLocalISODateTime(new Date().addHours(0));
                        calData.end =  this.$datedJS.dated.getLocalISODateTime(new Date().addHours(2));
                    }

                    // window.console.log("App Data start time section: " + calData.start);
                    // window.console.log("App Data end after substr: " + calData.end);

                    if (calData.priorityLevel !== undefined) {
                        // calData.color = this.priorityLevelLookup[calData.priorityLevel];
                        calData.color = this.getTaskPriorityLevels[calData.priorityLevel].levelColor;
                        
                        //    window.console.log("\tpriorityLevel Object: " + 
                        //    JSON.stringify(this.priorityLevelLookup, null, ' ') + "\n");

                        // window.console.log("\tApp Data priority level " +
                            // calData.priorityLevel + "\n - priority level color: " + calData.color);
                    
                    } else {
                        // calData.color = this.priorityLevelLookup[0];
                        calData.color = this.getTaskPriorityLevels[0].levelColor;

                    }

                    this.apiEvents.push(calData);
                }
            });
        },
        async getTasks2Do() {
            // let priorityLevel = ['#00BB00', '#009900', '#337700', '#668800', '#779900', '#BBBB00', '#FFBB00', '#FF7700', '#FF3300', '#FF0000'];
            
            let fetchParams = {
                method: "GET",
                headers: new Headers({
                Authorization: "Bearer " + this.jwt + "'"
                })
            };
            let response = await fetch(
                `https://ts-api.t2do.io/t2do-api/tasks/query/all`,
                fetchParams
            );
            let apiData = await response.json();

            let apiTask2DoData = [];

            this.apiEvents = [];
            if (apiData["user_screen_name"]) {
                this.task_owner_screen_name = apiData["user_screen_name"];
                // if the user_screen_name is set, then user is logged in
                apiTask2DoData = apiData["task_data"];
            } else {
                apiTask2DoData = apiData;
                // /* eslint-disable no-debugger */
                
            }
            apiTask2DoData.forEach(task => {
                window.console.log("Data from Trinix API: " + JSON.stringify(task));
                let calData = task;

                window.console.log("App Data start before substr: " + calData.start);
                // calData.start = calData.start.substr(11,5) === '00:00' ? calData.start.substr(0,10) : (calData.start.substr(0,5) + calData.start.substr(6,4) + " " + calData.start.substr(12,4)); //)calData.start.substr(0,16);
                // // calData.end = calData.end.substr(11,5) == '00:00' ?  calData.end.substr(0,10) : calData.end.substr(0,16);
                // calData.end = calData.end.substr(11,5) === '00:00' ? calData.end.substr(0,10) : (calData.end.substr(0,5) + calData.end.substr(6,4) + " " + calData.end.substr(11,5)); //)calData.start.substr(0,16);
                // calData.time = calData.end.substr(11,5);
                //calData.end = calData.end.substr(0,10);
                let numRegEx = /^[0-9]{4}/;
                let regExResult = numRegEx.test(calData.start.substr(0, 4));
                // let numRegEx = numRegEx.test(calData.start.substr(0, 4));
                console.log(
                    "Task: " +
                    calData.name +
                    ", has a 4 char substring of: " +
                    calData.start.substr(0, 4) +
                    " Results form regular expression: " +
                    regExResult
                );

                if (regExResult !== true) {
                    calData.start = this.$datedJS.dated.getLocalISODateTime(new Date().addHours(2));
                }
                if (regExResult !== true) {
                    calData.start = this.$datedJS.dated.getLocalISODateTime(new Date().addHours(6));
                }

                if (typeof calData.start === "string") {
                    calData.start = this.$datedJS.dated.getLocalISODateTime(calData.start.substr(0, 16));
                    calData.end = this.$datedJS.dated.getLocalISODateTime(calData.end.substr(0, 16));
                    console.log("calData substr: " + calData.start.substr(0, 16));
                }

                calData.color = this.getTaskPriorityLevels[calData.priority_lvl_index].levelColor;
                calData.backend = "Task2DoAPI";
                window.console.log("App Data start time section: " + calData.start);
                window.console.log("App Data end after substr: " + calData.end);

                // window.console.log("App Data priority level " + calData.priority_lvl_index +
                //     "\n - priority level color: " + calData.color);

                this.apiEvents.push(calData);
                // apiEvents.push(calData);
        });
        // if (this.events) {
        //   this.events.push(apiEvents);
        // } else {
        //   this.events = apiEvents;
        // }
        },
        async addTask2Do() {
            
            let createdOnTimestamp = this.$datedJS.dated.getLocalISODateTime(new Date().addHours(0));
            createdOnTimestamp = createdOnTimestamp.toString();
            createdOnTimestamp = createdOnTimestamp.substr(0,10) + " " + createdOnTimestamp.substr(11,8);
        
            let tempEndDatetime = this.$datedJS.dated.getLocalISODateTime(new Date().addHours(2));
            tempEndDatetime = tempEndDatetime.toString();
             
            console.log(`start time: ${this.startDatetime} \n end time: ${this.endDatetime}\n`);

            if (this.name && this.startDatetime && this.endDatetime) {
                let serializedData = JSON.stringify({
                    owner_screen_name: this.getCurrentUserName,
                    created_by_id: this.getCurrentUserId,
                    created_for_id: this.getCurrentUserId,
                    created_on: createdOnTimestamp,
                    edited_on: createdOnTimestamp,
                    name: this.name,
                    body: this.body,
                    start: this.$datedJS.dated.getLocalISODateTime(this.startDatetime), // this.localISOTime(this.startDatetime).substr(0, 16), //
                    end: this.$datedJS.dated.getLocalISODateTime(this.endDatetime), // this.localISOTime(this.endDatetime).substr(0, 16), // 
                    priority_lvl_id: this.currPriorityLevelId,
                    type_id: this.currentEventTaskTypeID,
                    categories:  '[ "Appointment" ]',
                    /* TODO
                    //category_key_path: 0,
                    */

                    progress_percent: 0,
                    note: "no notes",
                    status: 2,
                    subject: "Appointment",
                });

                let newTask2DoObj = JSON.parse(serializedData);
                window.console.log("Serialized Form Data: " + serializedData);
                window.console.log("Form Action URL: https://ts-api.t2do.io/t2do-api/tasks/add");
                
                this.createTask2Do(newTask2DoObj);
                // if (this.backendSelection === "Task2DoAPI") {
                //   let fetchParams = {
                //     method: "POST",
                //     headers: new Headers({
                //       Authorization: "Bearer " + this.jwt + "'",
                //       "Content-Type": "application/json; charset=UTF-8"
                //     }),
                //     body: serializedData
                //   };
                //   let response = await fetch(
                //     `https://ts-api.t2do.io/t2do-api/tasks/add`,
                //     fetchParams
                //   );
                //   window.console.log("Response from API" + response.json());
                // this.getAllEvents();

                this.name = "";
                this.body = "";
                this.start = "";
                this.end = "";
                this.priority_lvl_id = 5;
            }

        //     if (this.backendSelection === "FirebaseFirestore") {
        //       await db.collection("calEventBrando").add({
        //         categoryId: 2,
        //         name: this.name,
        //         details: this.body,
        //         start: this.start,
        //         end: this.end,
        //         priorityLevel: this.priorityLevel.levelNum
        //       });
        //       this.getAllEvents();
        //       this.name = "";
        //       this.body = "";
        //       this.start = "";
        //       this.end = "";
        //     }
        //   } else {
        //     alert("Name, start and end date are required");
        //   }
        },

        // getEventColor (event) {
        //     return event.color;
        // },
        // setToday () {
        //     this.focus = this.today;
        // },
        // prev () {
        //     // this.remWeekDayTimeslots();
        //     this.$refs.calendar.prev();
        // },
        // next () {
        //     // this.remWeekDayTimeslots();
        //     this.$refs.calendar.next();

        // },
        // editEvent(ev) {
        //     this.tempEventObj = ev.id;
        // },
        // showEvent ({ nativeEvent, event }) {
        //     const open = () => {
        //         this.selectedEvent = event;
        //         this.selectedElement = nativeEvent.target;
        //         setTimeout(() => (this.selectedOpen = true), 10);
        //     }

        //     if (this.selectedOpen) {
        //         this.selectedOpen = false
        //         setTimeout(open, 10);
        //     } else {
        //         open();
        //     }
        //     nativeEvent.stopPropagation()
        // },
        // updateRange ({ start, end }) {
        //     // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
        //     this.start = start
        //     this.end = end

        // },
        // nth (d) {
        //     return d > 3 && d < 21
        //     ? 'th'
        //     : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
        // },
        /****************************************************************************************
        *                                       getEventColor
        ****************************************************************************************/
        getEventColor(event) {
            
            if ((event.index === this.selectedEventIndex) && (!event.hasOwnProperty('tempObj'))) {
                return "rgba(96, 96, 96, .9)";
            }
            if(event.status_id === 10) {
                return "rgba(13, 64, 13, .75)";
            }else if (event.status_id === 6) {
                    return "rgba(128, 13, 13, .85)"; 
            } else if(event.priority_lvl_id > 1) {
                // if ((event.type_id !== 2) && (event.type_id !== 3)) {
                
                // } else {
                // console.log("priorityLevelColor: " + JSON.stringify(this.taskPriorityLevels[parseInt(event.priority_lvl_id) - 1], null, 2));
                return this.getTaskPriorityLevels[parseInt(event.priority_lvl_id) - 1].levelColor;
            } else if (event.type_id) {
                 return this.getTaskTypes[event.type_id].typeColor;
            } else {
                return this.getTaskPriorityLevels[this.defaultPriorityLevel].levelColor;
            }
                    
            //   return event.color;
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event;
                console.log('showEvent open function called');
                // Object.assign(this.tempEventObj, event);
                this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(event);
                this.selectedEventIndex = event.index;
                this.selectedElement = nativeEvent.target;
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
            }

            if (this.selectedOpen) {
                this.selectedOpen = false;
                this.selectedEventIndex = null;
                this.resetTempEventObj();
                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            } else {
                open();
            }

            nativeEvent.stopPropagation()
        },
        editEvent(event) {
            if (this.editingExistingEvent === true) {
                // let editEventOriginalIndex = indexOf()
                this.editEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.allTasks2Do[event.index]);
                console.log('editEvent was passed the following event' + JSON.stringify(this.allTasks2Do[event.index], null, 2));
            } else {
                console.log('editEvent was passed the following new event' + JSON.stringify(event, null, 2));
                this.editEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(event);
            }

            this.editEventObj.start = this.$datedJS.dated.getLocalISODateTime(event.start_unix_timestamp);
            this.editEventObj.end = this.$datedJS.dated.getLocalISODateTime(event.end_unix_timestamp);
            this.editEventObj.startISO = this.$datedJS.dated.getLocalISODateTime(event.start_unix_timestamp);
            this.editEventObj.endISO = this.$datedJS.dated.getLocalISODateTime(event.end_unix_timestamp);
            this.editEventObj.duration = ((this.tempEventObj.end_unix_timestamp / 60000) - (this.tempEventObj.start_unix_timestamp / 60000)).toFixed(0);

            if (this.editEventObj.id !== '') {
                if (this.editEventObj.id === event.id){
                    console.log("editEvent called with tempEventObj = to event obj passed with index" + this.tempEventObj.index + "\n");

                    // Yeah, this seems like dumb logic too, either one of these should already be set & setting one shouldn't really depend on the other
                    // or should it? I don't know, but this should be looked at
                    if (!this.editingNewEvent) {
                        console.log('editing existing event');
                        this.editingExistingEvent = true;
                    } else {
                        console.log('editing new event');
                        this.editingNewEvent = true;
                    }

                    /* WHAT THE FUCK IS THIS?!  
                    if (this.taskTypes[this.tempEventObj.type_id].typeName === 'Daily') {
                        // this.editEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.allTasks2Do[this.tempEventObj.index]);
                        this.editEventObj.start =  this.$datedJS.dated.getLocalISODateTime(this.editEventObj.start);
                        this.editEventObj.end =  this.$datedJS.dated.getLocalISODateTime(this.editEventObj.end);
                    } else {
                        this.editEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.tempEventObj);
                    }
                    */
                   
                    this.selectedEventIndex = this.editEventObj.index; 
                //    this.selectedOpen = true;
                    this.editFormOpen = true;
                    this.selectedOpen = false;
                    this.dragInProgress = false;
                } else {
                    console.log("*********************************\n\tFor some reason the tempEventObj.id and event.id passed don't match!\n\t this.tempEventObj.id = ' + this.tempEventObj.id + 'and event.id = ' + event.id + '\n******************************************");
                    
                    this.selectedEventIndex = null;
                    this.selectedOpen = false;
                    this.dragInProgress = false;
                }
            } else {
                // tempEventObj is not set, set it to a copy
                console.log("editEvent called without editEventObj.id set");
                console.log("basically an error or something to that effect shouild be thrown here, this shouldn't be called without a propper event Object being passsed");
                console.log("Event selected = " + JSON.stringify(event, null, 2));                // this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(event);


                // this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(event);
                // this.tempEventObj.tempObj = true;
// Nope, don't do ^^^^^^^^^^^^^^^^^^^^^^^^^^    this    ^^^^^^^^^^^^^^^^^^^^^^^^^^^, because your resetting the tempEventObj after coping it to the editEvent Obj
               
                
                // where you on something when you wrote this shit?! This is the same statement you just made above!?
                // this.tempEventObj['tempObj'] = true;

                // this.selectedEventIndex = this.editEventObj.index;
                // this.editEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.tempEventObj);

                // this.editingExistingEvent = true;
                this.selectedOpen = false;
                
                let statusObj =  { statusCode: 666, status: 'your fucked because there was no editObj passed to the editEvent function', name: 'your-fucked', message: 'your-fucked-again--incase-you-didnt-see-it-in-the-other-variables'};
                throw ({ data: statusObj, status: 666});

            }
            console.log("edit Event callee: " + this.callee);
            // this.tempEventObj.id = event.id;
            console.log("---> TEMP Event Object: " + JSON.stringify(this.tempEventObj, null, 1));
            
            // this.editingExistingEvent = true;
          
            console.log("tempEventIndex: " + this.tempEventObj.index);   

        },
        closeEventSummary() {
            this.selectedOpen = false;
            this.selectedEvent = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
            this.selectedEventIndex = null;
            this.selectedElement = null;
        },
        closeEventEdit() {
            if(this.editFormOpen === true) {
                // this.events[this.tempEventObj.index].priority_level_id = this.currentEventPriorityLevelID;
                this.editFormOpen = false;
                this.dragInProgres = false;
                this.editingNewEvent = false;
                this.editingExistingEvent = false;
                this.creatingEvent = false;
                this.selectedEvent = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
                this.selectedEventIndex = null;
                this.selectedOpen = false;
                this.createEvent = null;
                this.createStart = null;
                this.touchStartTime = 0;
                this.touchStartDragTime = 0;
                this.touchMoveTime = 0;
                (this.calendarScrollLocked === true) && (this.toggleCalendarScrollLock());
                this.resetEditEventObj();
                this.resetTempEventObj();
                this.resetSelectedTaskObj();
                this.$refs.calendar.checkChange();
            }
        },
        cancelEventEdit() {   
            if(this.editFormOpen === true) {        
                this.editFormOpen = false;
                this.dragInProgres = false;
                this.editingNewEvent = false;
                this.editingExistingEvent = false;
                this.creatingEvent = false;
                this.selectedEvent = this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
                this.selectedEventIndex = null;
                this.selectedOpen = false;
                this.createEvent = null;
                this.createStart = null;
                this.touchStartTime = 0;
                this.touchStartDragTime = 0;
                this.touchMoveTime = 0;
                (this.calendarScrollLocked === true) && (this.toggleCalendarScrollLock());
                this.resetTempEventObj();
                this.resetEditEventObj();
                this.resetSelectedTaskObj();
            }
            
        },
        nth(d) {
        return d > 3 && d < 21
            ? "th"
            : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
        },
        // touchStartDrag ( {event, timed }) {

        // },
        mouseDownTask({event, timed, nativeEvent}) {
            // startDrag ({ event, timed }) {
                 console.log('mouseDownTask native event object: ' + JSON.stringify(nativeEvent.target, this.$jsUtils.jsonCircularReplacer(), 1)); //JSON.stringify(e, this.$jsUtils.jsonCircularReplacer(), 2));
            if (this.calendarScrollLocked === true) {
                console.log('startDrag called');
                if (event && timed) {
                    
                    this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(event);
                                   
                    // Object.assign(this.tempEventObj, event);
                    this.tempEventObj.tempObj = true;   // this is used to determine if tempEventObj is being used
                    this.selectedEventIndex = this.tempEventObj.index; //this.events.indexOf(event, 0);
                    console.log("VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVvv\n\tmouseDownTask called and assigned the following to tempEventObj: " +  JSON.stringify(this.tempEventObj, null, 1));
                    console.log('MOUSEDOWN: selectedEventIndex: ' + this.selectedEventIndex + '\n\t event.index: ' + event.index);

                
                // let currPriorityLevelId = this.events[this.tempEventObj.index].priority_lvl_id;
                    // this.currentEventPriorityLevelID = currPriorityLevelId;
                    
                    
                    // this.tempEventObj.priority_lvl_id = 1;
                    // this.events[this.tempEventObj.index].color = 'rgba(127, 127, 127, .5)';
                    
                    // this.events[this.tempEventObj.index].priority_lvl_id = 11;
                    
                    this.draggingExistingEvent = true;
                    // this.editingExistingEvent = true;
                    this.dragTime = null;
                    this.extendOriginal = null;
                    this.dragInProgress = true;
                }
            } else {
                // console.log('calendar is not scroll-locked, event editing (startDrag) disabled');
                if (this.mouseScroll === false) {
                    this.mouseScroll = true;
                    this.mouseDownX = nativeEvent.clientX;
                    this.mouseDownY = nativeEvent.clientY;
                    this.mouseDownEventId = event.id;
                  
                    // console.log('this.mouseDownX: ' + this.mouseDownX + '\nthis.mouseDownY: ' + this.mouseDownY);
                }

            }
            
        },
        touchStartDrag({ event, timed, nativeEvent}) {
            nativeEvent.stopPropagation();
            if ((this.calendarScrollLocked === true) && (this.dragInProgress === false)) {
                if(this.touchStartDragTime === 0) {
                    this.touchStartDragTime = new Date().getTime();
                    this.touchDragEvent = event;
                    this.touchDragTimed = timed;
                    this.touchMoveTime = this.touchStartDragTime;
                }
                console.log('touchStartDrag called, this.touchStartDragTime: ' + this.touchStartDragTime);

                if(this.touchDragTiggered === true) {
                
                    if (event && timed) {
                        
                        this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(event);
                        // Object.assign(this.tempEventObj, event);
                        this.tempEventObj.tempObj = true;
                        this.selectedEventIndex = this.tempEventObj.index; //this.events.indexOf(event, 0);
                        console.log("start drag on the following object: " +  JSON.stringify(this.tempEventObj, null, 2));
                    
                    // let currPriorityLevelId = this.events[this.tempEventObj.index].priority_lvl_id;
                        // this.currentEventPriorityLevelID = currPriorityLevelId;
                        
                        
                        // this.tempEventObj.priority_lvl_id = 1;
                        // this.events[this.tempEventObj.index].color = 'rgba(127, 127, 127, .5)';
                        
                        // this.events[this.tempEventObj.index].priority_lvl_id = 11;
                        
                        this.draggingExistingEvent = true;
                        // this.editingExistingEvent = true;
                        this.dragTime = null;
                        this.extendOriginal = null;
                        this.dragInProgress = true;
                    }
                }
               
            }
        },
        touchStart (tms, nativeEvent) {
             nativeEvent.stopPropagation();
            if ((this.calendarScrollLocked === true) && (this.dragInProgress === false)) {
                const mouse = this.toTime(tms)
                if(this.touchStartTime === 0) {
                    this.touchStartTime = mouse; // new Date().getTime();
                    this.touchMoveTime = this.touchStartTime;
                }
                console.log('touchStart called, this.touchStartTime: ' + this.touchStartTime);

                if(this.touchDragTiggered === true) {

                    console.log('touch start event: object passed = ' + JSON.stringify(nativeEvent, this.$jsUtils.jsonCircularReplacer(), 2));
                  

                    if (this.draggingExistingEvent === true && this.dragTime === null) {
                        const start = this.tempEventObj.start_unix_timestamp;
                        this.dragInProgress = true;
                        this.dragTime = mouse - start;
                    } else {

                        console.log("startTime called with mouse set to: " + mouse + "\n");
                        this.createStart = this.roundTime(mouse);
                        console.log("createStart set to: " + this.createStart + "\n");
                        this.creatingEvent = true;
                        this.dragInProgress = true;
                        // this.createEvent = {
                        //     name: `Event #${this.events.length}`,
                        //     color: this.rndElement(this.colors),
                        //     start: this.createStart,
                        //     end: this.createStart,
                        //     timed: true,
                        // }
                
                    }
                }
               
            }
          
        },
        startTime (tms, nativeEvent) {
            console.log('starttime nativeEvent: ' + JSON.stringify(nativeEvent, null, 2)); 
            
             if (this.calendarScrollLocked === true) {
                const mouse = this.toTime(tms)

                if (this.draggingExistingEvent === true && this.dragTime === null) {
                    console.log('how can we drag and existing event if the initial mouse down is on a open time slot and not an event? Answer: this condition will never be met');
                    const start = this.tempEventObj.start_unix_timestamp;
                    this.dragInProgress = true;
                    this.dragTime = mouse - start;
                } else {

                    console.log("startTime called with mouse set to: " + mouse + "\n");
                    this.createStart = this.roundTime(mouse);
                    console.log("*************************************\ncreateStart set to: " + this.createStart + "\n");
                
                    this.creatingEvent = true;
                    this.dragInProgress = true;
                    // this.createEvent = {
                    //     name: `Event #${this.events.length}`,
                    //     color: this.rndElement(this.colors),
                    //     start: this.createStart,
                    //     end: this.createStart,
                    //     timed: true,
                    // }
            
                }
            } else {
                if (this.mouseScroll === false) {
                    this.mouseScroll = true;
                    this.mouseDownX = nativeEvent.clientX;
                    this.mouseDownY = nativeEvent.clientY;
                    // console.log('this.mouseDownX: ' + this.mouseDownX + '\nthis.mouseDownY: ' + this.mouseDownY);
                }
                // console.log('calendar is not scroll-locked, event creation startTime disabled');
            }
          
        },
        logEvent ( MouseEvent ) {
            console.log(`MouseEvent.target: ${ JSON.stringify(MouseEvent.target, this.$jsUtils.jsonCircularReplacer(), 2) } \n mouseEvent: ${ JSON.stringify(MouseEvent, null, 2) } \n`);
            MouseEvent.stopPropagation();

        },
        extendBottom (event) {
            if (this.calendarScrollLocked === true) {
                console.log("ExtendedBottom called");
                // Object.assign(this.tempEventObj, event);
                this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(event);
                this.tempEventObj.tempObj = true;
                this.copyToSelectedTaskObj(this.tempEventObj);
                // this.tempEventObj.start_unix_timestamp = event.start_unix_timestamp;

                // createStart should just be used on existing events
                this.createStart = this.tempEventObj.start_unix_timestamp;
                
                this.extendOriginal = this.tempEventObj.end_unix_timestamp;
                this.editingExistingEvent = true;
                this.dragInProgress = true;
                console.log("extendBottom tempEventObj.index: " + this.tempEventObj.index + "\n");
                this.selectedEventIndex = this.tempEventObj.index; //indexOf(event, 0);
                console.log("extendBottom selectedEventIndex: " + this.selectedEventIndex  + "\n");
            } else {
                console.log('calendar is not scroll-locked, event creation disabled');
            }
        },
        touchMove (tms, nativeEvent) {
            nativeEvent.stopPropagation();
            if ((this.calendarScrollLocked === true) && ((this.touchStartTime > 0) || (this.touchStartDragTime > 0))) {
               
                this.touchMoveTime =  this.toTime(tms); //new Date().getTime();
                console.log('touchMove called, this.touchMovetime: ' + this.touchMoveTime + ' \n\tthis.touchStartTime: ' + this.touchStartTime);
                console.log('\ntouchMove distance: ' + Math.abs(this.touchMoveTime - this.touchStartTime));
                // if (Math.abs(this.touchMoveTime - this.touchStartTime) > this.touchDragTriggerDuration) {
                if (this.dragInProgress === false) {   
                    this.touchDragTiggered = true; 
                   
                    if ((this.touchStartDragTime > 0) && (Math.abs(this.touchMoveTime - this.touchStartDragTime) >= this.touchDragTriggerDuration)) { 
                        // let touchDragEvent = this.touchDragEvent;
                        // let touchDragTimed = this.touchDragTimed;
                        this.touchStartDrag( { event: this.touchDragEvent, timed: this.touchDragTimed, nativeEvent: nativeEvent } );
                        this.mouseMove(tms, nativeEvent);
                    } else if ((this.touchStartTime > 0) && (Math.abs(this.touchMoveTime - this.touchStartTime) >= this.touchDragTriggerDuration)) {
                        this.touchStart(tms, nativeEvent);
                        this.mouseMove(tms, nativeEvent);
                    }
                  
                } else if (this.dragInProgress === true) {
                    this.mouseMove(tms, nativeEvent);
                   
                }
                
                
            }
           
            // const touch = this.toTime(tms);
        },
        mouseMove (tms, nativeEvent) {
            
            if ((this.calendarScrollLocked === true) && (this.dragInProgress === true)) {
                const mouse = this.toTime(tms);
                const mouseRounded = this.roundTime(mouse, false);
                const min = Math.min(mouseRounded, this.createStart);
                const max = Math.max(mouseRounded, this.createStart);

                if (this.draggingExistingEvent === true  && this.dragTime !== null) {
                    const start = this.tempEventObj.start_unix_timestamp;
                    const end = this.tempEventObj.end_unix_timestamp;
                    const duration = end - start
                    const newStartTime = mouse - this.dragTime
                    const newStart = this.roundTime(newStartTime)
                    const newEnd = newStart + duration
                    const startDelta = Math.abs(newStart - start);
                
                    if (startDelta > 900000) {
                        // 900000 miliseconds = 15min. This is the threshold of movment to edit event
                        this.tempEventObj.start_unix_timestamp = newStart;
                        this.tempEventObj.end_unix_timestamp = newEnd;
                        this.tempEventObj.duration = ((this.tempEventObj.end_unix_timestamp / 60000) - (this.tempEventObj.start_unix_timestamp / 60000)).toFixed(0);
                        this.tempEventObj.start = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.start_unix_timestamp);
                        this.tempEventObj.end = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.end_unix_timestamp);
                        this.editingExistingEvent = true;
                        console.log("MOUSEMOVE called,dragging event delta threshold met: " + startDelta);
                        // we're dragging an event, lets check to see if the event is in current day:
                        
                        if(this.$datedJS.dated.getLocalISODate(this.tempEventObj.start === this.today)) {
                            console.log('why was this condition met, whats the pupose of this class being added "dragging-event"');
                            let currentDayHtmlElement = document.querySelector(".v-calendar-daily_head-day.v-present"); 
                            currentDayHtmlElement.classList.add('dragging-event');
                        }
                        
                    }
                    
                } else if (this.createStart !== null) {
                    console.log("Mouse move event with createStart set");
                    
                    console.log("\tCreateStart: " + this.createStart + "\n\tmouseRounded: " + mouseRounded);
                
                    if (this.creatingEvent === true) {
                        if ((min < (max - 900000)) || (max > (min + 900000))) {
                            // Create new event (create event after pointer movement to reduce accidental touches/clicks causing event creation)
                            this.tempEventObj =  this.$arrayObjUtils.arrayObjsDeepCopy(this.getEmptyTaskObj);
                            this.tempEventObj.owner_screen_name = this.$jsUtils.capitalizeString(this.getCurrentUserName);
                            this.tempEventObj.created_by_id = this.getCurrentUserId;
                            this.tempEventObj.created_for_id = this.getCurrentUserId;
                            this.tempEventObj.tempObj = true;
                            this.tempEventObj.id = this.$jsUtils.genUUIDv4();
                            this.tempEventObj.start_unix_timestamp = this.roundTime(mouse);
                            this.tempEventObj.end_unix_timestamp = this.tempEventObj.start_unix_timestamp + 1800000;
                            this.tempEventObj.duration = ((this.tempEventObj.end_unix_timestamp / 60000) - (this.tempEventObj.start_unix_timestamp / 60000)).toFixed(0);
                            this.tempEventObj.index = this.events.length + this.dailyEvents.length - 1;  //this.allTasks2Do.length;
                            this.tempEventObj.name = 'Event name';
                            this.tempEventObj.priority_lvl_id = this.defaultPriorityLevel;
                            this.tempEventObj.type_id = this.defaultTaskType;
                            this.tempEventObj.color = this.getEventColor(this.tempEventObj);
                            this.tempEventObj.timed = true;
                            // done this way to prevent creating events via inadvertant clicks on calendar times
                            this.tempEventObj.start = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.start_unix_timestamp);
                            this.tempEventObj.startISO = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.start_unix_timestamp);

                            this.tempEventObj.end = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.end_unix_timestamp);
                            this.tempEventObj.endISO = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.end_unix_timestamp);
                            console.log('creating new event with index of: ' + this.tempEventObj.index);
                            this.copyToSelectedTaskObj(this.tempEventObj);
                            this.creatingEvent = false;
                            this.dragInProgress = true;
                            this.editingNewEvent = true;
                            console.log("MOUSEMOVE called, creatingEvent max: " + max);
                            if(this.$datedJS.dated.getLocalISODate(this.tempEventObj.start === this.today)) {
                                let currentDayHtmlElement = document.querySelector(".v-calendar-daily_head-day.v-present"); 
                                currentDayHtmlElement.classList.add('dragging-event');
                            }
                                    // this.events.push(this.tempEventObj);
                        }
                    } else if (this.editingNewEvent === true || this.editingExistingEvent === true) { // && (this.tempEventObj.start_unix_timestamp !== null)) {
                        // we're already dragging an event
                        this.tempEventObj.start_unix_timestamp = min;
                        this.tempEventObj.end_unix_timestamp = max;
                        this.tempEventObj.duration =  ((this.tempEventObj.end_unix_timestamp / 60000) - (this.tempEventObj.start_unix_timestamp / 60000)).toFixed(0);
                        this.tempEventObj.start = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.start_unix_timestamp);
                        this.tempEventObj.startISO = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.start_unix_timestamp);

                        this.tempEventObj.end = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.end_unix_timestamp);
                        this.tempEventObj.endISO = this.$datedJS.dated.getLocalISODateTime(this.tempEventObj.end_unix_timestamp);
                        console.log("MOUSEMOVE called, editingNewEvent max: " + max);
                    }
                }
                // nativeEvent.stopPropagation();
            
            } else {
                if (this.mouseScroll === true) {
                    this.mouseOffsetX = nativeEvent.clientX - this.mouseDownX;
                    this.mouseOffsetY = nativeEvent.clientY - this.mouseDownY;
                    this.calendarDailyPaneElement[0].scrollLeft -= this.mouseOffsetX;
                    this.calendarDailyPaneElement[0].scrollTop -= this.mouseOffsetY;
                    this.mouseDownX = nativeEvent.clientX;
                    this.mouseDownY = nativeEvent.clientY;
                    nativeEvent.stopPropagation();

                    

                }
                // console.log('Not moving events, calendar is not scroll-locked');
            }
            
        },
        dblClickTask(e) {
            console.log('\n\tDOUBLE-CLICK triggered with the following object: \n ' + JSON.stringify(e, null, 2) + '\n');

            if (this.selectedIndex === e.index) {
                this.selectedEvent = this.$arrayObjUtils.arrayObjsDeepCopy(this.allTasks2Do[this.selectedEventIndex]);
                this.selectedOpen = true;
            }
        },
        mouseUpTask(event, nativeEvent) {
            this.mouseScroll = false;
            console.log('MOUSE UP! --> mouseUpTask was called with the following nativeEvent argument: ' + JSON.stringify(nativeEvent, this.$jsUtils.jsonCircularReplacer(), 2));
            console.log('MOUSE UP! --> mouseUpTask was called with the following nativeEvent.target: ' + JSON.stringify(nativeEvent.target, this.$jsUtils.jsonCircularReplacer(), 2));
            console.log('$$$$$$$$$$$$ SelectedIndex = ' + this.selectedEventIndex + '\n\t mouseUpTask on event with index: ' + event.index + '\n');

            if (this.calendarScrollLocked === false) { // the calendar isn't scroll-locked, so edits are disabled, show task summary dialog
                // if (this.selectedIndex = )
                // this.selectedEventIndex = event.index;
                this.mouseUpEventId = event.id;
                if (this.mouseUpEventId === this.mouseDownEventId) {
                    this.selectedEventId = this.mouseUpEventId;
                } else {
                    this.selectedEventId = null;
                }
                this.mouseUpEventId = null;
                this.mouseDownEventId = null;
                console.log('MOUSEUPTASK: selectedEventId: ' + this.selectedEventId + '\n\t event.id: ' + event.id);
             

            } else if (this.calendarScrollLocked === true) {
                if (this.dragInProgress === true) { 
                    if ((this.editingNewEvent === true) || (this.editingExistingEvent === true)) {
                        if (this.tempEventObj.tempObj === true) {
                            this.editEvent(this.tempEventObj);
                            console.log('^^^^^^^^^^^MouseUp ^^^^^^^^^^^^^^\n\tmouseUpTask(event) has been called and editEvent has been called. dragInProgress is set within that function, so don\'t do it here.');
                        } else {
                            console.log('something went wrong...tempEventOb.tempObj isnt set to true. tempEventObj.tempObj: ' + tempEventObj.tempObj);
                        }
                        // this.dragTime = null;
                        // this.draggingExistingEvent = null;
                        // this.extendOriginal = null;
                        this.touchDragTiggered = false;
                        this.touchDragEvent = null;
                        this.touchDragTimed = null;
                        
                    }
                } else if (this.selectedIndex === event.index) {
                     console.log('MOUSEUPTASK: selectedEventIndex: ' + this.selectedEventIndex + '\n\t event.index: ' + event.index);
                } else if (this.dragInProgress === false) {
                    console.log('???????????????????????\ndragInProgress is false in nouseUpTask method. Should this have been called?\n???????????????????????????')
                    this.dragTime = null;
                    this.draggingExistingEvent = null;
                    this.extendOriginal = null;
                    this.touchDragTiggered = false;
                    this.touchDragEvent = null;
                    this.touchDragTimed = null;
                    // this.editingExistingEvent = false;
                    // this.editingNewEvent = false;
                }
            }
            // nativeEvent.stopPropagation();
        },
        endDrag () {
            this.mouseScroll = false;
            if (this.calendarScrollLocked === true) {
                console.log("End drag called");
                if (this.dragInProgress === true) { 
                    // if (this.editingNewEvent === true ) && (this.dragEvent)
                    if ((this.editingNewEvent === true) || (this.editingExistingEvent === true)) {
                        
                        // this.selectedEvent = this.$arrayObjUtils.arrayObjsDeepCopy(event);

                        if (this.editingExistingEvent === true) {
                            /**************************************************
                            /* TODO Change priority_lvl_id to color property
                            /**************************************************/ 
                            // this.events[this.selectedEventIndex].priority_lvl_id = this.currentEventPriorityLevelID;
                        }
                    
                        // this.tempEventObj.priority_lvl_id =  this.currentEventPriorityLevelID;
                        // this.selectedEvent = this.events[this.events.length - 1];
                        
                        // this.tempEventObj = this.selectedEvent;
                        
                        // this.editEventObj.time_data =  { timed: null, times: [ { start: null, end: null, start_unix_timestamp: 0, end_unix_timestamp: 0, prep_time: 0 } ] };

                        this.editEvent(this.tempEventObj);
                        this.dragTime = null;
                        this.draggingExistingEvent = null;
                        this.extendOriginal = null;
                        this.touchDragTiggered = false;
                        this.touchDragEvent = null;
                        this.touchDragTimed = null;
                        this.touchStartTime = 0;
                        this.touchStartDragTime = 0;
                        
                    }

                } else if (this.dragInProgress === false) {
                    console.log('???????????????????????\ndragInProgress is false in endDrag method. Should this have been called?\n???????????????????????????')
                    // this.editingExistingEvent = false;
                    // this.editingNewEvent = false;
                }
            } else {
                console.log('end drag called, but calendar is not scroll-locked');
            }
            
        },
        cancelDrag () {
            this.mouseScroll = false;
            if ((this.calendarScrollLocked === true) && (this.dragInProgress === true)) {
                console.log("CANCEL DRAG CALLED");
             
                if ((this.editingNewEvent === true) || (this.editingExistingEvent === true)) {
                    this.dragInProgress = false;
                    this.dragTime = null;
                    this.draggingExistingEvent = null;
                    this.selectedOpen = false;
                    this.touchDragTiggered = false;
                    this.touchDragEvent = null;
                    this.touchDragTimed = null;
                    this.touchStartTime = 0;
                    this.touchStartDragTime = 0;
                    this.touchMoveTime = 0;
                    
                    if (this.extendOriginal) {
                            this.createEvent.end = this.extendOriginal;
                    } else {
                        const i = this.tempEventObj.index; // this.events.indexOf(this.tempEventObj);
                        if (i !== -1) {
                            this.events.splice(i, 1);
                        }
                    }
            
                    // this.tempEventObj = {};
                    // this.resetTempEventObj();
                    // this.createStart = null;
                    // this.creatingEvent = false;
                    // this.editingNewEvent = false;
                    // this.editingExistingEvent = false;
                }

            } else {
                console.log('calendar is not scroll-locked, event creation disabled');
            }

        },
        roundTime (time, down = true) {
            const roundTo = 15 // minutes
            const roundDownTime = roundTo * 60 * 1000

            return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime))
        },
        toTime (tms) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },
        // getEventColor (event) {
        //     const rgb = parseInt(event.color.substring(1), 16)

        //     const r = (rgb >> 16) & 0xFF
        //     const g = (rgb >> 8) & 0xFF
        //     const b = (rgb >> 0) & 0xFF

        //     return event === this.dragEvent
        //     ? `rgba(${r}, ${g}, ${b}, 0.7)`
        //     : event === this.createEvent
        //         ? `rgba(${r}, ${g}, ${b}, 0.7)`
        //         : event.color
        // },
        getEvents ({ start, end }) {
            const events = []

            const min = new Date(`${start.date}T00:00:00`).getTime()
            const max = new Date(`${end.date}T23:59:59`).getTime()
            const days = (max - min) / 86400000
            const eventCount = this.rnd(days, days + 20)

            for (let i = 0; i < eventCount; i++) {
            const timed = this.rnd(0, 3) !== 0
            const firstTimestamp = this.rnd(min, max)
            const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000
            const start = firstTimestamp - (firstTimestamp % 900000)
            const end = start + secondTimestamp

            events.push({
                name: this.rndElement(this.names),
                color: this.rndElement(this.colors),
                start,
                end,
                timed,
            })
            }

            this.events = events;
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        rndElement (arr) {
            return arr[this.rnd(0, arr.length - 1)]
        },
        formatDate(a, withTime) {
            return withTime
                ? `${a.getFullYear()}-${a.getMonth() +
                    1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
                : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
        },
        remWeekDayTimeslots() {
            let monthDays = document.querySelectorAll(".daily-timeslot");
            for (let i = 0; i < monthDays.length; i++) {
                let monthDay = monthDays[i];
                monthDay.remove();
            }
        },
        addWeekDayTimeslots() {
            console.log("Creating daily time slots...");
            let htmlString =
                "<span class='daily-timeslot'>9AM</span><span class='daily-timeslot'>11AM</span><span class='daily-timeslot'>1PM</span><span class='daily-timeslot'>3PM</span><span class='daily-timeslot'>5PM</span>";
            let monthDays = document.querySelectorAll(
                ".v-calendar-weekly__day:not(.v-outside), .v-calendar-weekly__day.v-past:not(.v-outside), .v-calendar-weekly__day.v-future:not(.v-outside)"
            );
            for (let i = 0; i < monthDays.length; i++) {
                let monthDay = monthDays[i];
                monthDay.insertAdjacentHTML("beforeend", htmlString);
                console.log("..updated week days to include timeslots");
            }
            
        },
        computedInfoDisplayBarData() {
            let infoDisplayBarDataObj = [
                {
                    name: 'selectedEvent',
                    value: this.selectedEventIndex,
                    description: 'Keeps track current selected event'
                }
            ];
            return infoDisplayBarDataObj;
        }
    }

}


</script>

<style>

:root {
    
    --calendar-visible-events__width: 100%;
    --calendar-num-events: 50;
    --calendar-day-column-width: 102px;
    --calendar-intervals-top: 2.5rem; /* 175px; */
    --calendar-zoomed-height: 100%;
    /* --calendar-days-header__height: 3.15rem; */
    --calendar-days-header__height: 2.25rem;
    --calendar-scroll-lock: auto;

    --current-time-left-possition: 0px;
    --calendar-interval-height: 0px;
    --calendar-scrollbar-width: 0px;
    --calendar-interval-width: 0px;
    --calendar-interval-count: 25;
    --current-time--width-scale: 1;
    --current-time--width: calc(66% * var(--current-time--width-scale));
    
}

/* 
#task2do-app-id .v-calendar .v-calendar-weekly__day::before {
    content: " <span class='daily-timeslot'>9AM</span><span class='daily-timeslot'>11AM</span><span class='daily-timeslot'>1PM</span><span class='daily-timeslot'>3PM</span><span class='daily-timeslot'>5PM</span>";
    
} */


/* #task2do-app-id .v-calendar .v-calendar-daily_head-day.v-present {
  background: url(https://api.weather.gov/icons/land/day/sct?size=small) no-repeat center;
  background-position-y: center;
  background-size: auto;
  background-size: auto;
  background-size: cover;
  background-position-y: 25%;
} */

#task2do-app-id .calendar-background {
    position: absolute;
    background: var(--monthly-background-image);
    background-size: var(--background-size);
    width: 100%;
    height: 100%;
    transition: all 2.75s linear;
}


#task2do-app-id .v-calendar .v-calendar-daily_head-weekday,
#task2do-app-id .v-calendar .v-calendar-category__category {
    position: relative;
    left: unset; /* calc(50% - 2rem); */
    /* width: 4rem; */
    width: 100%;
    margin: 0 auto;
    color: rgb(225, 225, 225);
    font-weight: 900;
    font-size: 0.75rem;
    font-family: Tomorrow;
    text-shadow: .05rem .05rem .015rem rgba(13, 13, 13, .5);
}

#task2do-app-id .v-calendar-category .v-calendar-category__column, 
#task2do-app-id .v-calendar-category .v-calendar-category__columns .v-calendar-category__column-header {
    border-right: none;
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    min-width: 100%;

}


/* 
.v-calendar-category .v-calendar-category__columns {

}

.v-calendar-category .v-calendar-category__columns .v-calendar-category__column-header {

}
 */



/* #task2do-app-id .v-calendar .v-calendar-category__columns:nth-child(1) > .v-calendar-category__category {

    color: rgb(225, 0, 0) !important;
}

#task2do-app-id .v-calendar .v-calendar-category__columns:nth-child(2) > .v-calendar-category__category {
    color: rgb(0, 225, 0) !important;
} */

#task2do-app-id .v-calendar .v-calendar-category__columns .v-calendar-category__column-header:nth-child(1) .v-calendar-category__category {
  display: none;
  color: rgb(225, 0, 0);

}


#task2do-app-id .v-calendar .v-calendar-category__columns .v-calendar-category__column-header:nth-child(2) .v-calendar-category__category {
  color: rgb(0, 225, 0);
}


/* 
#task2do-app-id .v-calendar-category .v-calendar-daily__day-container .v-calendar-daily__day:nth-child(4n+2),
#task2do-app-id .v-calendar-category .v-calendar-daily__day-container .v-calendar-daily__day:nth-child(4n+3){
    background: rgba(100, 120, 150, .25) !important;
}
*/


#task2do-app-id .v-calendar-category .v-calendar-daily__day-container .v-calendar-daily__day:nth-child(odd) {
    background: rgba(5, 13, 26, .15);
}

#task2do-app-id .v-calendar:not(.v-calendar-category) .v-calendar-daily__day-container .v-calendar-daily__day:nth-child(2n+2) {
    background: rgba(10, 26, 13, .05);
}

:root {
    /* --day-shade: '1n+' + calc(var(--calendar-interval-count) / 4); */
    --day-shade: 1n+12;

}



/************************************************************************************
/*        These styles will adjust the background to mimic day/night times          *
*************************************************************************************/

/************************************************************************************
/*                                   Zoomed-in                                       *
*************************************************************************************/
#task2do-app-id .v-calendar .zoomed-in .v-calendar-daily__day-interval:nth-child(2n+1) {
    background-color: rgba(6, 6, 6, .40);
}

#task2do-app-id .v-calendar .zoomed-in .v-calendar-daily__day-interval:nth-child(2n+0) {
    background-color: rgba(6, 6, 6, .25);
}

#task2do-app-id .v-calendar .zoomed-in .v-calendar-daily__day-interval:nth-child(2n+13) {
    background-color: rgba(6, 6, 6, .01);
}

#task2do-app-id .v-calendar .zoomed-in .v-calendar-daily__day-interval:nth-child(2n+14) {
    background-color: rgba(6, 6, 6, .15);
}

#task2do-app-id .v-calendar .zoomed-in .v-calendar-daily__day-interval:nth-child(2n+38) {
    background-color: rgba(6, 6, 6, .45);
}

#task2do-app-id .v-calendar .zoomed-in .v-calendar-daily__day-interval:nth-child(2n+39) {
    background-color: rgba(6, 6, 6, .60);
}


/************************************************************************************
/*                                   Zoomed-out                                       *
*************************************************************************************/

#task2do-app-id .v-calendar .zoomed-out .v-calendar-daily__day-interval:nth-child(2n+1) {
    background-color: rgba(6, 6, 6, .40);
}

#task2do-app-id .v-calendar .zoomed-out .v-calendar-daily__day-interval:nth-child(2n+0) {
    background-color: rgba(6, 6, 6, .25);
}

#task2do-app-id .v-calendar .zoomed-out .v-calendar-daily__day-interval:nth-child(2n+8) {
    background-color: rgba(6, 6, 6, .01);
}

#task2do-app-id .v-calendar .zoomed-out .v-calendar-daily__day-interval:nth-child(2n+9) {
    background-color: rgba(6, 6, 6, .15);
}

#task2do-app-id .v-calendar .zoomed-out .v-calendar-daily__day-interval:nth-child(2n+20) {
    background-color: rgba(6, 6, 6, .45);
}

#task2do-app-id .v-calendar .zoomed-out .v-calendar-daily__day-interval:nth-child(2n+21) {
    background-color: rgba(6, 6, 6, .60);
}


#task2do-app-id .v-calendar-daily .v-calendar-daily__day-interval:nth-child(odd) {
    border-top: #9e9e9e 1px solid;
    border: none;
}

#task2do-app-id .v-calendar-daily .v-calendar-daily__day-interval:nth-child(even) {
    border-top: none;
}

#task2do-app-id .v-calendar .v-calendar-daily__day .v-calendar-daily__day-interval:hover {
    background-color: rgba(75, 75, 100, .75);
    border-top: .15rem solid rgba(210, 210, 241, 0.85);
}

#task2do-app-id .v-calendar .v-calendar-daily__day-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
        flex: 1;
    width: calc(var(--calendar-visible-events__width) - var(--calendar-interval-width));
    /* width: auto; */
    /* height: 100%; */
    height: calc(var(--calendar-zoomed-height) - var(--calendar-intervals-top)); /*  - var(--calendar-intervals-top))*/
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden; /* when set to visible, scrollbar still shown and days column would get offset from day column header */

}

#task2do-app-id .v-calendar-daily .v-calendar-daily__interval::after {
    border-top: rgb(150, 150, 150) .125rem solid;
}

#task2do-app-id .v-calendar-daily__interval::after {
    width: .25rem;
    position: absolute;
    height: .075rem;
    display: block;
    content: "";
    right: 0;
    bottom: -.075rem;
}

#task2do-app-id .v-calendar .v-calendar-daily__intervals-body .v-calendar-daily__interval-text {
    position: relative;
    display: block;
    top: -.35em;
    padding-right: .075rem;
    font-size: .66rem;
    font-family: Prompt;
    color: rgb(225, 225, 250);
}

#task2do-app-id .v-calendar .v-calendar-daily__interval {
    position: relative;
    text-align: right;
    padding-right: .125rem;
    border-bottom: none;
    
}

#task2do-app-id .v-calendar .v-calendar-daily__intervals-body::-webkit-scrollbar {
    display:none;
}

#task2do-app-id .v-calendar .v-calendar-daily__intervals-body {
    position: fixed;
    overflow-x: hidden;
    overflow-y: visible; /*var(--calendar-scroll-lock);*/
    left: var(--widget--inner-margin);
    top: var(--calendar-intervals-top);
    bottom: 0;
    width: var(--calendar-interval-width);
    height: calc(100vh - var(--calendar-intervals-top)); /* !important; */
    /* height: calc(100% - var(--calendar-intervals-top)); */
    z-index: 100;
    background: rgba(13, 13, 13, .90);
    scrollbar-width: none !important;
}

#task2do-app-id .v-calendar.v-calendar-daily {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    top: 0; /* var(--inner-container-gap); */
    overflow-y: hidden;
    overflow-x: hidden; /* setting this to hidden prevents the header from scrolling independently from the body */ /*visible; /*  var(--calendar-scroll-lock); */
    width: 100%;
    height: 100%;
    scrollbar-width: none !important;
}

#task2do-app-id .v-calendar.v-calendar-daily::-webkit-scrollbar {
    display: none;
}

#task2do-app-id .v-calendar .v-calendar-daily__head {
    position: absolute;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    overflow-x: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--calendar-days-header__height);
    margin: 0;
    padding: 0;
    z-index: 1;
    /* width: calc(calc( var(--calendar-visible-events__width) - var(--calendar-scrollbar-width)) - var(--calendar-interval-width));
    width: calc(var(--calendar-visible-events__width) - var(--calendar-scrollbar-width));
    width: calc(var(--calendar-visible-events__width) - var(--calendar-interval-width));
    width: var(--calendar-visible-events__width); */
    /* width: var(--calendar-visible-events__width); */
    /* left: var(--calendar-interval-width); */   
    background: rgba(13, 13, 13, 0.5);
    scrollbar-width: none !important;
}

#task2do-app-id .v-calendar .v-calendar-daily__head::-webkit-scrollbar {
    display: none;
}

#task2do-app-id .v-calendar .v-calendar-daily__intervals-head {
    position: fixed;
    width: var(--calendar-interval-width);
    height: var(--calendar-days-header__height);
    z-index: 100;
    /* left: calc(.25rem - 1px); */
    background: rgba(13, 13, 13, .90);
    border-right: 1px solid rgba(200, 200, 225, .99);
    /* border-bottom: 2px solid rgba(200, 200, 225, .99); */
    /* border-bottom: 1px solid rgba(130, 13, 13, 1); */
    
}

#task2do-app-id .v-calendar-category .v-calendar-daily_head-day {
    display: inline-block;
    min-width: var(--calendar-day-column-width);
    max-width: var(--calendar-day-column-width);
    width: var(--calendar-day-column-width);
    height: var(--calendar-days-header__height);
    margin: 0 auto;
    padding: 0;
    background-color: rgba(13, 13, 13, .75);
    /* 
        width: calc(var(--calendar-visible-events__width) / var(--calendar-num-events));
        max-width: calc(var(--calendar-visible-events__width) / var(--calendar-num-events));
        min-width: calc(var(--calendar-visible-events__width) / var(--calendar-num-events));
    */
}

#task2do-app-id .v-calendar-category .v-calendar-daily_head-day-label {
    position: relative;
    /* width: 4rem; */
    /* left: calc(50% - 2rem); */
    left: unset;
    margin: 0 auto;
}

#task2do-app-id .v-calendar .v-calendar-daily__body {
    position: absolute;
    display: flex;
    overflow-y: visible;
    overflow-x: visible;
    left: 0;
    top: var(--calendar-days-header__height);
    width: var(--calendar-visible-events__width);
    height: 100%;
    
    /* flex: none; */
    margin: 0;
    /* width: auto; */
    /* top: 0px; */
    
    /* width: calc(var(--calendar-visible-events__width) + var(--calendar-interval-width)); */
    
    /* width: calc(var(--calendar-visible-events__width) + 89.5rem); */
    /* min-height: 100%; */
    /* 
        height: calc(100vh - var(--calendar-intervals-top));
        height: calc(var(--calendar-zoomed-height) + calc(var(--calendar-intervals-top) - 0.5rem));
    */

    /* left: var(--calendar-interval-width); */
    /* width: 100%; */
   
    scrollbar-width: none !important;
}

#task2do-app-id .v-calendar .v-calendar-daily__body::-webkit-scrollbar {
    display: none;
}

#task2do-app-id .v-calendar .v-calendar-daily__scroll-area {
    overflow-y: visible;
    overflow-x: visible;
    height: 100%;

 }

/* 
#task2do-app-id .v-calendar .v-calendar-daily__pane {
    position: absolute;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  
    overflow-y: visible;
    overflow-x: visible;
    height: var(--calendar-zoomed-height);

}
 */

#task2do-app-id .v-calendar .v-calendar-daily__pane {
    position: fixed;
    display: flex;
    display: inline-block;
    overflow-y: var(--calendar-scroll-lock);
    overflow-x: var(--calendar-scroll-lock);
    /* top: var(--calendar-intervals-top); */
    width: 100%;
    height: calc(100vh - var(--calendar-intervals-top)) !important;
    flex: none;    
    align-items: flex-start;
    scrollbar-width: none !important;
    
}

#task2do-app-id .v-calendar .v-calendar-daily__pane::-webkit-scrollbar {
    display: none;
}

/* #task2do-app-id .v-calendar .v-calendar-daily__head > *:not(:first-child), */
#task2do-app-id .v-calendar .v-calendar-daily__day-container > *:not(:first-child) {
    /* left: var(--calendar-interval-width); */
    left: 0;
}



#task2do-app-id .v-calendar .v-calendar-daily_head-weekday.primary--text {
    color: rgb(192, 0, 0) !important;
    font-weight: 900;
    font-size: 0.85rem;
    font-family: Tomorrow;
    text-shadow: .05rem .05rem .015rem rgba(13, 13, 13, .75);
}

#task2do-app-id .v-calendar-category .v-calendar-daily__day {
    /* min-width: calc(var(--calendar-visible-events__width) / var(--calendar-num-events)); */
    /* width: calc(var(--calendar-visible-events__width) / var(--calendar-num-events)); */
    min-width: var(--calendar-day-column-width);
    width: var(--calendar-day-column-width);
    min-height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    position: relative;
    height: calc(100vh - var(--calendar-intervals-top));
}


#task2do-app-id .v-calendar .v-event-timed-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: .075rem;
    margin-right: .075rem;
    pointer-events: none;
}



#task2do-app-id .v-calendar .v-calendar-daily__day.v-present .v-event-timed.white--text:not(.dragging-event) {
    margin-left: .125rem !important;
    /************************************************************************************
    *    Check to see i setting this width is needed
    *************************************************************************************/
    width: calc(100% - .125rem);

}

#task2do-app-id .v-calendar-daily__day.v-present .v-event-timed-container {
  margin-left: calc(var(--calendar-interval-width) + .05rem);
/*   left: 56px; */

}

#task2do-app-id .v-calendar .v-event-timed.white--text {
    text-align: left !important;
    /* left: calc(var(--calendar-interval-height) + .125rem) !important;
    width: calc(100% - (calc(var(--calendar-interval-height) - .25rem))) !important; */
}

#task2do-app-id .v-calendar .v-event-timed.white--text::after {
    top: -2rem;
    left: 0;
    background: rgba(130, 130, 170, .75);
    border: 2px solid rgba(200, 200, 225, .9);
    position: absolute;
    display:block;
    width: 100%;
    height: 1rem;
    z-index: 5600;


}


/* 

#task2do-app-id .v-calendar .v-calendar-daily__day .v-calendar-daily__day-interval {
  
  background: transparent url("https://api.weather.gov/icons/land/day/bkn?size=small") no-repeat scroll center left / contain;
  background-blend-mode: overlay;
  opacity: 1;
} */


#task2do-app-id .v-calendar .v-calendar-daily__day.v-present .v-calendar-daily__day-interval {
  
  /* background: transparent url("https://api.weather.gov/icons/land/day/sct?size=small") no-repeat scroll center left / contain; */
  background-image: url("https://api.weather.gov/icons/land/day/sct?size=small");
  /* background-size: var(--calendar-interval-width); */
  background-size: contain;
  background-position: center left;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  opacity: 1;
}

/* 
https://api.weather.gov/icons/land/day/rain_showers,20?size=small

https://api.weather.gov/icons/land/night/tsra_hi?size=small

https://api.weather.gov/icons/land/night/tsra?size=large

https://api.weather.gov/icons/land/night/tsra_sct?size=large

https://api.weather.gov/icons/land/day/sct/rain_showers,30?size=large

https://api.weather.gov/icons/land/day/bkn?size=large

https://api.weather.gov/icons/land/night/bkn?size=large */






/* Calendar month view day header, turn round button into blue header */

/* .theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: rgba(13, 13, 200, .7);
    
} */

/* #task2do-app-id  .calendar .v-calendar-weekly__day .daily-timeslot { */
#task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot {
    text-align: left;
    display: inline-block;
    width: 100%;
    border-bottom: 2px outset rgba(100, 100, 100, .75);
    padding: .0875rem;
    font-size: .75rem;
    
}

/* #task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot {

    text-align: left;
    display: block;
    width: 100%;
    border-bottom: 2px outset rgba(100, 100, 100, .75);
    padding: 0 0 0 .5rem;
    font-size: .666rem;
    line-height: 1.05rem;
    margin: 0 auto;
    height: 1.125rem;
     transition: 0.95s color ease-in-out, 0.5s transform ease-in-out;
} */

#task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot {
    text-align: left;
    display: block;
    width: 100%;
    border-bottom: 2px outset rgba(100, 100, 100, .75);
    padding: 0 0 0 .25rem;
    font-size: .666rem;
    font-weight: 600;
    line-height: 1.0rem;
    margin: 0 auto;
    height: 0.925rem;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
              top 0.5s cubic-bezier(0.4, 0, 0.2, 1),
              color 2.0s cubic-bezier(0.69, 1.35, 0.85, 1),
              border-color 2.0s cubic-bezier(0.69, 1.35, 0.85, 1),
              filter 2.0s cubic-bezier(0.69, 1.35, 0.85, 1);
}

#task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot:hover {
    background-color: rgba(50, 175, 225, .9);
    color: rgba(13, 96, 26, 1);
    
    transform: translate(-.125rem, 0) scale(1.15);
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
          top 0.5s cubic-bezier(0.4, 0, 0.2, 1),
          color 2.0s cubic-bezier(0.69, 1.35, 0.85, 1),
          border-color 2.0s cubic-bezier(0.69, 1.35, 0.85, 1),
          filter 2.0s cubic-bezier(0.69, 1.35, 0.85, 1);

    
}


#task2do-app-id .calendar .v-calendar-weekly__day span:nth-child(2) {
    background: rgba(20, 20, 20, .5);
}

#task2do-app-id  .calendar .v-calendar-weekly__day span:nth-child(3) {
    background: rgba(40, 40, 40, .5);
}

#task2do-app-id  .calendar .v-calendar-weekly__day span:nth-child(4) {
    background: rgba(60, 60, 60, .5);
}

#task2do-app-id .calendar .v-calendar-weekly__day span:nth-child(5) {
    background: rgba(80, 80, 80, .5);
}

#task2do-app-id  .calendar .v-calendar-weekly__day span:nth-child(6) {
    background: rgba(100, 100, 100, .5);
}



#task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot:nth-child(2) {
    background: rgba(20, 20, 20, .5);
}

#task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot:nth-child(3) {
    background: rgba(40, 40, 40, .5);
}

#task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot:nth-child(4) {
    background: rgba(60, 60, 60, .5);
}

#task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot:nth-child(5) {
    background: rgba(80, 80, 80, .5);
}

#task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot:nth-child(6) {
    background: rgba(100, 100, 100, .5);
}




/* #task2do-app-id .widget .v-calendar-monthly.v-calendar-weekly.v-calendar span.daily-timeslot:hover {

    background-color: rgba(50, 175, 225, .9);
    color: rgba(13, 96, 26, 1);current-time
    font-weight: 900;
    transform: translate(1.125rem, 0) scale(1.25);
    transition: .26s color ease-in-out, .13s transform ease-in-out;
    
} */


/* .v-calendar-daily__day.v-present .v-current-time .first::after {
	content: var(--current-time);
	margin-left: -1.5rem;
	margin-top: -0.5rem;
	line-height: 1.1;
	top: 0;
	z-index: 15000;
	position: absolute;
	font-weight: 900;
	font-family: Tomorrow;
	color: rgba(250, 0, 0, .95);
	background: rgba(13, 13, 13, .9);
	background: rgba(250, 0, 0, .95);
	color: rgb(13, 13, 13);
} */



.v-calendar-daily__day.v-present .v-current-time {
    height: 4px;
    background-color: rgba(0, 225, 225, .85);
    background-color: rgba(250, 0, 0, .85);
    position: absolute;
    left: -1px;
    left: var(--calendar-interval-height);
    right: 0;
    pointer-events: none;
    z-index: 1;
}


/* 
.v-calendar-daily__day.v-present .v-current-time.first::before {
        content: ""; 
        position: absolute;
        background-color: rgba(250, 0, 0, 1);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
        z-index: 1000;
} 
*/


.v-calendar-daily__day.v-present .v-current-time.first::after {
    position: absolute;
    display: block;
    content: var(--current-time);
    width: 80%; /* var(--current-time--width); */
    top: 0;
    margin-top: calc(calc(var(--calendar-interval-height) / 4) * -1);
    /* margin-left: calc(var(--current-time--width) * .5); */
    right: .5rem;
    padding: .125rem .5rem;
    /* line-height: 1.1; */
    font-size: calc(var(--calendar-interval-height) / 2.25);
    font-weight: 700;
    font-family: Tomorrow;
    color: rgba(250, 0, 0, .95);
    border-radius: .35rem;
    background: rgba(13, 13, 13, .9);
    background: rgba(250, 0, 0, .9);
    color: rgb(13, 13, 13);
    animation: pulse-animation-red 2.5s infinite;
}

</style>


<style lang="scss">


.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

// .v-event-drag-bottom {
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 4px;
//   height: 4px;
//   cursor: ns-resize;

//   &::after {
//     display: none;
//     position: absolute;
//     left: 50%;
//     height: 4px;
//     border-top: 1px solid white;
//     border-bottom: 1px solid white;
//     width: 16px;
//     margin-left: -8px;
//     opacity: 0.8;
//     content: '';
//   }

//   &:hover::after {
//     display: block;
//   }
// }


.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    position: absolute;
    left: 50%;
    height: 0.75rem;
    border-top: 2px solid rgb(255, 255, 255);
    border-bottom: 2px solid rgb(255, 255, 255);
        border-bottom-color: rgb(255, 255, 255);
        border-bottom-style: solid;
        border-bottom-width: 2px;
    width: 4rem;
    margin-left: 0;
    opacity: 0.8;
    content: "";
    transform: translate(-50%, -50%);
    top: -50%;
    padding: .05rem .25rem .35rem;
    background: rgba(32, 32, 32, .25);

  }

  &:hover::after {
    display: block;
  }
}

.v-calendar-daily__day.v-present .v-current-time {
height: 5px;
   background-color: rgba(0, 225, 225, .85);
   background-color: rgba(250, 0, 0, .85);
   position: absolute;
   left: -1px;
   right: 0;
   pointer-events: none;

     &.first::before {
      
         position: absolute;
         background-color: rgba(0, 225, 225, .95);
         background-color: rgba(250, 0, 0, 1);
         width: 15px;
         height: 15px;
         border-radius: 50%;
         margin-top: -5px;
         margin-left: -6.5px;
         z-index: 1000;
     }
    //  &.first::after {

    //      content: var(--current-time);
    //      margin-left: -1.5rem;
    //      margin-top: -0.5rem;
    //      line-height: 1.1;
    //      top: 0;
    //      z-index: 15000;
    //      position: absolute;
    //      font-weight: 900;
    //      font-family: Tomorrow;
    //      color: rgba(250, 0, 0, .95);
    //      background: rgba(13, 13, 13, .9);
    //      background: rgba(250, 0, 0, .95);
    //      color: rgb(13, 13, 13);
    //  }

 }

</style>