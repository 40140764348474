<template>
    <v-dialog v-model="displayDialog" persistent max-width="500" class="save-changes-dialog"> 
        <v-card>
            <v-card-title><span class="mdi mdi-alert orange-alert-icon">You have unsaved changes!</span>
            </v-card-title>
            <v-card-text v-html="unsavedItem.name">
               
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                {{ dialogMessage }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                Would you like to save your edits?
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="onDiscard">Discard</v-btn>             
                <v-btn color="primary" text @click="onCancel">Cancel</v-btn>
                <v-btn color="primary" text @click="onSave">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "SaveChangesDialog",
    props: [
        "showSaveChangesDialog",
        "unsavedItem",
        "unsavedItemType",
        "dialogMessage"
    ],
    data: () => ({
        textField: "The following record has been modified. Before creating a new record you need to save you changes or they will be disguarded.",
      
        shaped: false,
        outlined: false,
        rounded: false,
        // dense: false,
        autofocus: true
    }),
    created: function() {
    
        console.log("Dialog message body: " + this.dialogMessage);
        console.log("unsaved Item name: " + this.unsavedItem.name);
    },
    computed: {
        displayDialog() {
            return this.showSaveChangesDialog;
            
        }
    },
    methods: {
        onDiscard() {
            let functionToCall = `discard${this.unsavedItemType}Changes`;
            console.log("Function to Call: " + functionToCall);

            this.$emit(`${functionToCall}`);
        },
        onCancel() {
            this.$emit(`cancel${this.unsavedItemType}Changes`);
        },
        onSave() {
            this.$emit(`save${this.unsavedItemType}Changes`);
        }
    }
};
</script>

<style>


</style>