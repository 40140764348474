<template>
    <v-container class="widget" :class="[ minimizeState ? minimizeClassName : ' ',
        maximizeState ? maximizeClassName : ' ', focusedWidget === 'NotesWidget' ? ' focusedWidget ' : ' backgroundWidget ', fixedWidget ? ' fixedWidget ' : '' ]" >
		<div style="display: block; background: unset; opacity: 0; left: 0; top:0; width: 100%; height: 100%; z-index: 1500;"
			v-if="focusedWidget !== 'NotesWidget'" 
			@click.stop="$emit('bring-to-front', 'NotesWidget')">
		</div>
			
		
		<v-card class="widget-window-outer-container" :class="[ fixedWidget ? ' fixed-widget ' : '']" >
		<!-- <WindowBar :widgetLabel="widgetLabel"
					:minimizeState="minimizeState"
					v-on:minimize="minimize"
					:maximizeState="maximizeState"
					v-on:maximize="maximize"
					:notifications="unsavedEdits"
					:notificationType="notificationString" /> -->

		<SystemBar :widgetLabel="widgetLabel"
                        :minimizeState="minimizeState"
                        v-on:minimize="minimize"
                        :maximizeState="maximizeState"
                        v-on:maximize="maximize"
                        v-on:closeWidget="closeWidget"
                        :notifications="notificationsList"
                        :notificationType="notificationString"
					:fixedWindow="fixedWidget" />

		<EditToolbar :editModeProp="editMode"
			:editToolbarItems="noteEditToolbarItems"
			@createNewNote="createNewNote"
			@toggleEditModeEvent="editMode = !editMode"
			@toggleViewSource="toggleViewSource"
			@returnToEditingNote="returnToEditingNote"
			@newNoteEvent="createNewNote"
			@editSelectedNote="editSelectedNote"
			@saveNoteChanges="saveNoteChanges"
			@deleteSelectedNote="deleteSelectedNote"
			@displayNotesList="displayNotesList"
			@fullWidthToogle="fullWidthToogle"
			@fullWidthLeftRightToogle="fullWidthLeftRightToogle"
			:infoDisplayBarData="computedInfoDisplayBarData"
			 />
 
		<v-container id="NotesWidgetContainerId" class="widget-inner-container" style="position: unset;display: block;padding: .125rem;margin: 0;width: 99.5%;" fluid>
		<!-- <v-row dense> -->
					
			<!-- <v-col id="notes-list-container-id" align="left" class="note-widget-inner-container left-container noteList" :class="[ (editMode === '') ? 'preview' : 'hide', (fullWidthMode === true) ? 'full-width' : '', fullWidthSideClass ]"> -->

			
			<v-col class="note-widget-inner-container left-container noteList" :class="[ (showNotesList) ? 'preview' : 'hide', (fullWidthMode === true) ? 'full-width' : '', fullWidthSideClass ]" >

				<!-- <v-container :apiNotes="apiNotes" v-bind:key="note.note_id" v-for="note in apiNotes"> -->
				<v-col style="margin: 0.025rem .125rem -.125rem 0; height: 1.75rem; width: 100%;">
					<v-row cols="12" style="margin: 0 0 0 .125rem;">
							<v-text-field
								ref="notesFilter"
								v-model="notesFilterString"
								placeholder="search notes"
								dense
								color="blue accent-3"
								
								class="notes-search-input"
								></v-text-field>
								<!-- undefined field, note sure what it's purpose was since it wasn't working?? -->
								<!-- @input="updateFilter" -->
					</v-row>
			
					<v-row cols="12" id="notes-list-container-id" class="noteList" style="overflow-y: auto;overflow-x: unset;position: absolute;display: block;height: calc(100% - 2.35rem);width: calc(100% - calc(0.75rem + calc(var(--inner-container-gap) * 2)));margin: -1rem 0.15rem 0.325rem 0.05rem;padding: .1rem .15rem .15rem 0;">
						<div v-for="note in filteredNotes" v-bind:key="note.id" class="note-item" :class="[ selectedNote.id === note.id ? 'note-item-selected' : '' ]" @click="selectNote(note)" >
							<span>{{ note.start.substring(0, note.start.length - 3) }} -</span>
							<span v-html="note.name"></span>
							<br class="" />
							<!-- <h4><span v-html=note.note_name></span> </h4>
											<div> {{ note.note_datetime_stamp }} - {{ notes</div> -->
						</div>
					</v-row>
				</v-col>
			</v-col>
				<!-- <v-col v-if="editMode" align="left" id="edit-notes-form-container-id" class="noteEditForm"> -->

				<!-- <v-col align="left" id="edit-notes-form-container-id" class="note-widget-inner-container left-container noteEditForm" :class="[ (editMode === '') ? 'preview' : editMode , (fullWidthContainerId === 'edit-notes-form-container-id' && fullWidthMode === true) ? 'full-width' :  (fullWidthMode === true) ? 'full-width' : '', fullWidthSideClass ]"> -->

			<v-col id="edit-notes-form-container-id" class="note-widget-inner-container left-container noteEditForm" :class="[  (showNotesList)  ? 'hide' : editMode, (fullWidthMode === true) ? 'full-width' : '', fullWidthSideClass ] " >
		
		<!-- <v-container> -->
				<!-- <form id="edit-note-form-id"> -->
			<!-- <v-form> -->


				<v-card  id="edit-note-form-id" ref="form">
					<span id="noteModalShowMode"></span>
					<h3 id="noteModalHeader" v-html="formEditPreviewNoteObj.name"><input type="text" id="noteHeaderId" name="note_name" style="color:rgba(13, 13, 13, 1); border: none; display: inline; font-family: inherit; font-size: inherit; padding: none; text-align: center; width: 90%;" disabled></h3> 
						<!-- <fieldset style="position: relative; width: 100%; margin: 0px; padding: .625rem .5rem; top: 0px; display: block;">		 -->
						<fieldset style="position: relative;width: 100%;margin: 0;padding: 0.5rem; display: block;height: 7rem;">				
						<!-- <v-container> -->
							<v-row>
								<!-- No valid reason to show id to end user -->
								<!-- <v-col dense cols="12" sm="6">
									<v-text-field
										ref="node_id"
										v-model="curEditingNoteFormObj.id"
										label="Record Id: "
										placeholder="Record Id: "
										outlined
										dense
										disabled
									></v-text-field>
								</v-col> -->
								<v-col dense cols="12" sm="3">
									<v-text-field
										ref="creator"
										v-model="curEditingNoteFormObj.owner_screen_name"
										label="Creator:"
										placeholder="Creator:"
										
										dense
										disabled
									></v-text-field>
								</v-col>
								<v-col dense cols="12" sm="6">
									<v-text-field
									ref="subject"
										v-model="curEditingNoteFormObj.subject"
										:rules="[rules.required]"
										label="Note subject"
										placeholder="Enter the subject mater of your note"
										dense
										color="blue accent-3"
										required

									>
									<!-- :rules="[() => !!subject || 'This field is required']" -->
									<!-- <template v-slot:label>
										<strong>Subject</strong> <v-icon>find_in_page</v-icon>
									</template> -->
									</v-text-field>
								</v-col>
								<v-col dense cols="12" sm="3">
									<v-text-field
										ref="name"
										v-model="curEditingNoteFormObj.edited_on"
										label="Timestamp:"
										placeholder="Timestamp:"
										
										dense
										disabled
										class="time-stamp-form-field"
									></v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										ref="name"
										v-model="curEditingNoteFormObj.name"
										:rules="[() => !!curEditingNoteFormObj.name || 'This field is required']"
										label="Name / Title of your new note"
										placeholder="Title of your new note"
										dense
										color="blue accent-3"
										required
									></v-text-field>
								</v-col>
							</v-row>
						<!-- </v-container> -->
						<!-- </v-card-text> -->
						<!-- </v-card> -->
					</fieldset>
					<fieldset style="height: calc(100% - calc(var(--upper-form-fieldset--height) + calc(1.5rem + calc(var(--inner-container-gap) * 2))));display: block;">
					<!-- <fieldset style="border: 0.125rem inset rgba(150, 150, 175, 0.75); padding: 0 0 0 .5rem; height: calc(96% - calc(var(--upper-form-fieldset--height) + calc(var(--inner-container-gap) * 2)));">  -->

							<!-- <v-card-text>
								<v-card> -->

							<!-- <v-row> -->

								<!-- <v-col cols="12" style="width: 100%; margin: 0.05rem; padding: 0.125rem 0.25rem;   display: flex;"> -->
									<v-textarea
										v-model="curEditingNoteFormObj.body"
										filled
										color="deep-purple"
										label="Note body"
										rows="13"  
										@input="compareNoteChanges"
										maxlength="49152"
										resize=false
										style="height: 100%;"
										></v-textarea>
								<!-- </v-col> -->
							<!-- </v-row> -->
						<!-- </v-container> -->
		<!-- 
							</v-card>
						</v-card-text> -->

					</fieldset>
						<!-- </v-card> -->
					<!-- </v-form> -->
					
				

		<!-- 
						<v-row>
							<v-col cols="12">
								<label>Record Id:   </label><input v-model="curEditingNoteFormObj.id" type="text" id="noteModalId" name="note_id" data-tcs-form-inputs-per-line="2" disabled>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<label>Timestamp:   </label><input v-model="curEditingNoteFormObj.datetime_stamp" type="text" id="noteModalDateTimeStamp" name="note_datetime_stamp" data-tcs-form-inputs-per-line="2" disabled>
							</v-col>
							<v-col cols="6">
								<label id="noteModalAvatar">Creator:     </label><input v-model="curEditingNoteFormObj.creator"  type="text" id="noteModalCreator" name="note_creator" data-tcs-form-inputs-per-line="2" disabled>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="8">
								<label>Name:    </label><input v-model="curEditingNoteFormObj.name" type="text" id="noteModalName" name="note_name" data-tcs-form-inputs-per-line="2">
							</v-col>
							<v-col cols="4">
								<label>Subject: </label><input v-model="curEditingNoteFormObj.subject" type="text" id="noteModalSubject" name="note_subject" data-tcs-form-inputs-per-line="2" >
							</v-col>
					
						</v-row> -->
					<!-- </fieldset> -->
					<!-- <fieldset> -->

						<!-- <v-row>
							<v-col cols="12"> -->
								<!-- <textarea id="edit-note-msg-textarea-id" data-tag-insertion-mode="0" name="note_msg" rows="8" maxlength="49152" v-model="curEditingNoteFormObj.msg"  @input="compareNoteChanges" ></textarea>  -->


								<!-- <textarea v-if="(curEditingNoteFormObj.msg !== '' ||  editingNewNote === true)" id="edit-note-body-textarea-id" data-tag-insertion-mode="0" name="note_msg" rows="9" maxlength="49152" v-model="curEditingNoteFormObj.msg" @input="compareNoteChanges"></textarea>  -->
							<!-- </v-col>
						</v-row> -->
<!-- 					
					</fieldset> -->
				<!-- </form> -->

			<!-- </v-container> -->
		
	
				</v-card>
			<!-- </v-container> -->
		
			</v-col>
					
		<!-- </v-container>
		<v-container fluid id="note-preview-id"> -->
			<!-- <v-col align="left" id="note-preview-id" data-form-mode="preview" data-note-state="unmodified" class="note-widget-inner-container right-container notePreview" :class="[ editMode === '' ? 'preview' : editMode, (fullWidthContainerId === 'note-preview-id' && fullWidthMode === true) ? 'full-width' : (fullWidthMode === true) ? 'full-width-edit' : '',  fullWidthSideClass]"> -->

			<v-col align="left" id="note-preview-id" data-form-mode="preview" data-note-state="unmodified" class="note-widget-inner-container right-container notePreview" :class="[ showNotesList ? 'preview' : editMode, (fullWidthMode === true) ? 'full-width' : '',  fullWidthSideClass]" style="user-select: text;  overflow-y: auto; ">
					
				<!-- The toolbar container needs to be within the form because of css selector: [data-form-mode="edit"] -->
				<!-- <toolbar id="noteEditToolbar">
				
				
				</toolbar> -->
				<!-- TODO: 
				- Add save and other styling tags with color to toolbar or form itself.
				- Maybe add show/hide Tag Inserter Toolbar button, since the toolbar had a close button anyway, might as well use it.
				
				- Set limits to the toolbar so it can't be moved off screen in a way that would make hard to grab a handel on the title bar
			
				- Maybe incvlude a center-attach mutton somewhere that will automaticaly move tool bar to horizontal center and at top edge of form
				- Try to add small 4pxX4px button to lower right corner to allow resizing ability. I will probably need flex 6box for buttons. Also, 
					just start by modifiying the X-axis on a corner drag function. The first thing it should handle is getting narrower should
					automatically cause the buttons to re-flow and the height of the toolbar to respond accordingly
				
				-->
				
				<!-- <div v-if="(editMode || editingNewNote === false)" id="notePreviewMsgDivId" v-html="formEditPreviewNoteObj.msg">
				
				</div> -->
				
								
				<div v-if="previewMode === 'rendered'" id="notePreviewBodyDivId" v-html="formEditPreviewNoteObj.body">
				
				</div>
				<div v-else-if="previewMode === 'source'" id="notePreviewBodyDivId">
					<pre> {{ formEditPreviewNoteObj.body }}</pre>
				</div>

			</v-col>
		<!-- </v-row> -->
		</v-container>
		
	</v-card>
	
	<SaveChangesDialog  :showSaveChangesDialog="showSaveChangesDialog" :unsavedItem="unsavedNoteObj" :unsavedItemType="unsavedItemType" :dialogMessage="unsavedNoteMsg" @discardNoteChanges="discardNoteChanges" @cancelNoteChanges="cancelNoteChanges" @saveNoteChanges="saveNoteChanges"></SaveChangesDialog>
	

	<ConfirmDeleteDialog :showConfirmDeleteDialog="showConfirmDeleteDialog" :selectedItem="selectedNote" :selectedItemType="selectedItemType" @cancelNoteDelete="cancelNoteDelete" @confirmNoteDelete="confirmNoteDelete"></ConfirmDeleteDialog>

	<Snackbar :showSnackbar="showSnackbar" :snackbarColor:="statusColor" :snackbarText="snackbarText" @updateSnackbarCondition="updateSnackbarCondition"> </Snackbar>
	</v-container>
	
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SaveChangesDialog from "@/components/dialogs/SaveChangesDialog";
import ConfirmDeleteDialog from "@/components/dialogs/ConfirmDeleteDialog";
import Snackbar from "@/components/widgets/components/Snackbar.vue";
import WindowBar from "@/components/widgets/components/WindowBar.vue";
import SystemBar from "@/components/widgets/components/SystemBar.vue";
import EditToolbar from "@/components/widgets/components/EditToolbar.vue";
import InformationDialog from "@/components/dialogs/InformationDialog";

export default {
    components: {
        WindowBar,
		SystemBar,
		EditToolbar,
		SaveChangesDialog,
		ConfirmDeleteDialog,
		InformationDialog,
		Snackbar
    },
    props: ["focusedWidget"
    ],
    data: () => ({
        widgetLabel: "Notes",
		componentName: "NotesWidget",
		innerContainers: [],
		leftContainers: [],
		rightContainers: [],
		fullWidthMode: false,
		fullWidthSide: ['show-left-container', 'show-right-container'],
		fullWidthSideClass: '',
        fullWidthContainerId: '',
		notesFilterString: '',
        selectedNoteId: '',
		editingNewNote: false,
		editingExistingNote: false,
		editMode: '',
		showNotesList: true,
		previewMode: 'rendered',
		unsavedEdits: false,
		unsavedEditMode: '',
		unsavedItemType: 'Note',
		selectedItemType: 'Note',
		unsavedNoteObj: {},
		unsavedNoteMsg: 'The following record has been modified. Before creating a new record you need to save you changes or they will be discarded.',
		showSaveChangesDialog: false,
		showConfirmDeleteDialog: false,
		showSnackbar: false,
		snackbarText: '',
		statusColor: 'success',
		pendingAction: '',
		// unsavedNewNote: false,
		minimizeInBackground: true,
        minimizeInForeground: false,
        minimizeClassName: "minimize-widget",
        maximizeClassName: "maximize-widget",
        maximizeState: false,
        notificationString: "Unsaved Edits",
        newNoteTemplate: {
            id: "",
            datetime_stamp: "",
            name: "",
            subject: "",
            category: "",
			body: "",
			msg: "",
            creator: "",
            category_id: "",
            category_keypath: ""
        },
        noteEditToolbarItems: {
            createNewNoteButton: {
                id: 1,
                name: 'createNewNoteButton',
                type: 'button',
                label: 'Add New Note',
                icon: 'mdi-note-plus-outline', 
                tooltip: 'Create a New Note',
                functionToCall: 'createNewNote',
				disabled: false,
				enabled: true,
                visible: true
            },
            // editNewNoteButton: {
            //     id: 2,
            //     name: 'editNewNoteButton',
            //     type: 'button',
            //     label: 'Edit New Note',
            //     icon: 'mdi-pencil', 
            //     tooltip: "Edit a New Note ",
            //     functionToCall: "editNewNote",
			// 	disabled: true,
			// 	enabled: false,
            //     visible: false
            // },
            editSelectedNoteButton: {
                id: 3,
                name: 'editSelectedNoteButton',
                type: 'button',
                label: 'Edit Selected Note',
                icon: 'mdi-pen', 
                tooltip: 'Edit currently selected note',
                functionToCall: 'editSelectedNote',
				disabled: true,
				enabled: false,
                visible: true
			}, 
			returnToEditingNoteButton: {
                id: 4,
                name: 'returnToEditingNoteButton',
                type: 'alert',
                label: 'Return to editing note',
				icon: 'warning',
                tooltip: 'Return to your note currently editing',
                functionToCall: 'returnToEditingNote',
				enabled: false,
                visible: false
			},          
            showNotesListButton: {
                id: 5,
                name: 'showNotesButton',
                type: 'button',
                label: 'Show Note List',
                icon: 'mdi-view-list', 
                tooltip: 'Show a list of all your notes.',
                functionToCall: 'displayNotesList',
				disabled: false,
				enabled: true,
                visible: false
            },
            saveNoteButton: {
                id: 6,
                name: 'saveNoteButton',
                type: 'button',
                label: 'Save the current note',
                icon: 'mdi-content-save', 
                tooltip: 'Save the current note edits.',
                functionToCall: 'saveNoteChanges',
				enabled: false,
                visible: true
			},
			deleteSelectedNoteButton: {
                id: 2,
                name: 'deleteSelectedNoteButton',
                type: 'button',
                label: 'Delete Note',
                icon: 'mdi-delete-forever', 
                tooltip: 'Delete Selected Note',
                functionToCall: 'deleteSelectedNote',
				disabled: false,
				enabled: false,
                visible: true
            },
            fullWidthToogle: {
                id: 7,
                name: 'showFullWidthButton',
                type: 'buttonToggle',
                label: 'Full Width Edit/Preview',
                curStateIndex: 0,
                states: [
                     {
                        value: 'off',
                        icon: 'mdi-arrow-expand-all',
                        label: 'Single Fullwidth View',
                        tooltip:'Switch to single full-width view'
                    },
                    {  
                        value: 'on',
                        icon: 'mdi-arrow-collapse-all',
                        label: 'Split View',
                        tooltip:'Switch to split view mode'
                    }

                ],
                functionToCall: 'fullWidthToogle',
				disabled: false,
				enabled: true,
                visible: true
            },
            fullWidthLeftRightToogle: {
                id: 8,
                name: 'showFullWidthButton',
                type: 'switch',
                label: 'Toggle Full-width Preview/Edit',
                curStateIndex: 0,
                states: [
                    {
						name: 'left-container',
						value: 'left-full-width',
                        icon: 'mdi-pencil-off',
                        label: 'Preview Note',
                        tooltip:'Switch to preview note mode'
                    },
                    {
						name: 'right-container',
						value: 'right-full-wdith',
                        // value: ['edit-notes-form-container-id', 'notes-list-container-id'],
                        icon: 'mdi-rename-box',
                        label: 'Show Edit Note Form',
                        tooltip:'Switch to preview edit note form',
                    }
                ],
                tooltip: 'Switch between full width preview and edit modes.',
                functionToCall: 'fullWidthLeftRightToogle',
				enabled: false,
                visible: true
			},
			empty: {
                id: 100,
                name: 'emptySpacer',
                type: 'spacer',
                label: ' ',
				icon: ' ',
                tooltip: '',
                functionToCall: '',
				// disabled: true,
				enabled: true,
                visible: true
			},
			// poopSomething: {
            //     id: 13,
            //     name: 'poopButton',
            //     type: 'button',
            //     label: 'Poopy-on-Youy',
            //     icon: 'mdi-emoticon-poop', 
            //     tooltip: "Howdy Ho Evra' Body!!! I'm Mr. Poopin.",
            //     functionToCall: "",
			// 	enabled: false,
            //     visible: true
            // },
			toggleViewSource: {
				id: 9,
                name: 'viewSourceToggle',
                type: 'buttonToggle',
                label: 'Toggle Source and Formatted Text Previews',
                curStateIndex: 0,
				states: [
					{
						value: 'rendered',
						icon: 'mdi-code-tags',
						label: 'View Note Source',
						tooltip: 'Preview Note Source'
					},
					{
						value: 'source',
						icon: 'mdi-note-text',
						label: 'View Rendered Note',
						tooltip: 'Preview Formatted Note'
					}
				],
                tooltip: 'Toogle between source and formatted preview of note text',
                functionToCall: 'toggleViewSource',
				disabled: false,
				enabled: true,
                visible: true
			},
			infoDisplayBar: {
                id: 15,
                name: 'displayNoteStatus',
				type: 'infoDisplay',
				outputFormat: 'threeByTwo',
				dataVariables: [
					{
						name: 'editMode',
						variable: 'this.editMode',
						monitor: true,
						threshholdWarnValue: 'editYourFaceMode'
					},
					{
						name: 'fullWidthMode',
						variable: 'this.fullWidthMode',
						monitor: false,
						threshholdWarnValue: 'fullerThanFullWidthMode'
					},
					{
						name: 'fullWidthSideClass',
						value: 'this.fullWidthSideClass',
						description: 'that current side that should be full width'
					},
					{
						name: 'fullWidthSideClass',
						value: 'this.fullWidthSideClass',
						description: 'that current side that should be full width'
					},
					{
						name: 'fullWidthSideClass',
						value: 'this.fullWidthSideClass',
						description: 'that current side that should be full width'
					},
					{
						name: 'toolbarObjfullWidthSideClass',
						value: "this.noteEditToolbarItems.fullWidthLeftRightToogle['states'][this.noteEditToolbarItems.fullWidthLeftRightToogle['curStateIndex']]['value']",
						description: 'that current side that should be full width'
					}
				
				
				],
                label: 'Current State of your Note',
                icon: 'mdi-information-outline', 
                tooltip: 'Current Note Stats.',
                functionToCall: 'showNoteStats',
				enabled: false,
                visible: false
            },
		
		},
		infoDisplayBarData: [],
        selectedNote: {},
        selectedNodeEdits: {},
        newNoteObj: {},
        newNoteEdits: {},
        formEditPreviewNoteObj: {},
		curEditingNoteFormObj: {},
		backgroundNoteObj: {},
		rules: {
			email: v => (v || '').match(/@/) || 'Please enter a valid email',
			length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
			password: v => (v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
			'Password must contain an upper case letter, a numeric character, and a special character',
			required: v => !!v || 'This field is required',
      },
		
    }),
	computed: {
		...mapGetters( 'notes', {
			getNotes: 'getNotes'
		}),
		filteredNotes() {
			if (this.notesFilterString === '') {
				return this.getNotes;
			} else {
				return this.getNotes.filter(note => note.name.toLowerCase().includes(this.notesFilterString.toLowerCase()));
			}
			
			// return this.getNotes.filter(note => note.name === this.filterNotesString);
		},
		minimizeState() {
			// basically apply foreground state when focused, otherwise apply background state
			return (this.focusedWidget === this.componentName) ? this.minimizeInForeground : this.minimizeInBackground; 
        }
	},	
    created() {
		this.buildInnerContainerList('.note-widget-inner-container');
		// this.fetchNotes();
		this.infoDisplayBarData = [];
		this.infoDisplayBarData.push(this.computedInfoDisplayBarData());
		this.initCodeBlockWidgets();
	
	},
	mounted() {
		
		// this.fetchNotes();
	},
    updated: function() {
		if (this.focusedWidget !== this.componentName) {
            // This resets the foreground state to ensure widget isn't minimized when regaining focus
            this.minimizeInForeground = false;
		}
		
		if (this.editMode !== '') {
			this.formEditPreviewNoteObj.name = this.curEditingNoteFormObj.name;
			this.formEditPreviewNoteObj.category = this.curEditingNoteFormObj.category;
			this.formEditPreviewNoteObj.subject = this.curEditingNoteFormObj.subject;
		}

		this.noteEditToolbarItems.saveNoteButton.enabled = this.unsavedEdits;
		this.noteEditToolbarItems.createNewNoteButton.enabled = !this.unsavedEdits;
		// this.noteEditToolbarItems.infoDisplayBarData
		// this.notesEditToolbarItems
		this.noteEditToolbarItems.showNotesListButton.visible = !this.showNotesList;

		this.noteEditToolbarItems.infoDisplayBar.dataVariables = this.computedInfoDisplayBarData();
		// this.infoDisplayBar = this.computedInfoDisplayBarData();
		
    },
    methods: {
		// ...mapActions(["addNote", "updateNote", "fetchNotes"]),

		buildInnerContainerList(innerElementsClassName) {
			// creating lookup table to prevent extra querySelectorAll calls
			// let innerElements = document.querySelectorAll(innerElementsClassName);
			this.innerContainers = document.getElementsByClassName(innerElementsClassName);
			for(innerContainer of this.innerContainers) {
				if (innerContainer.classList.contains('left-container')) {
					this.leftContainers.push(innerContainer);
				}
				else if (innerContainer.classList.contains('right-container')) {
					this.rightContainers.push(innerContainer);
				}
			}
			
		},
		computedInfoDisplayBarData() {
			let infoDisplayBarDataObj = [
				{
					name: 'editMode',
					value: this.editMode,
					description: 'Keeps track if any notes are currently being edited'
				},
				{
					name: 'unsavedChanges',
					value: this.unsavedEdits,
					description: 'the current state of the widgets fullWidthMode'
				},
				{
					name: 'pendingAction',
					value: this.pendingAction,
					description: 'Current action whats waiting on dialog'
				},
				{
					name: '# of Notes show',
					value: this.filteredNotes.length,
					description: 'Current action whats waiting on dialog'
				},
				{
					name: 'showNotesList',
					value: this.showNotesList,
					description: 'Current action whats waiting on dialog'
				},
				{
					name: 'this.selectedNodeId',
					value: this.selectedNote.id,
					description: 'The fullWidthSideClass value atttached to theeeee editbar object'
				}
			];
			return infoDisplayBarDataObj;
		
		},
        minimize() {
            this.minimizeInForeground = !this.minimizeInForeground;
            this.minimizeInBackground = this.minimizeInForeground;

            if (this.maximizeState === true) {
                this.maximizeState = false;
            }
        },
        maximize() {
            if (this.maximizeState === false) {
                this.maximizeState = true;
                this.minimizeInForeground = false;
                this.minimizeInBackground = this.minimizeInForeground;
            } else {
                this.maximizeState = false;
            }
        },
        createNewNote() {

			// copy the currently selected note to another object incase edits were made and not saved
			// TODO: test selected note for modifications before wasting memory duplicating objects
			// if there isn't a need to
			if (this.unsavedEdits === true) {

				this.unsavedNoteObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.curEditingNoteFormObj);
				this.pendingAction = 'createNewNote';
				this.showSaveChangesDialog = true;
				


			} else if (this.pendingAction === '') {

				this.newNoteObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.newNoteTemplate);
				this.newNoteEdits = this.$arrayObjUtils.arrayObjsDeepCopy(this.newNoteObj);

				// TODO: Store  UUID, current timestamp, and the current user as defualts to their respective note.properties

				// NO NO NO! allowing user to create id on front end is bad news!!
				// this.newNoteEdits.id = this.$jsUtils.genUUIDv4(); 

				this.newNoteEdits.owner_screen_name = this.$store.getters.currentUser;
				this.newNoteEdits.subject = '';
				let timeNow = this.$datedJS.dated.getLocalISOTime(new Date());
				this.newNoteEdits.created_on = timeNow;
			
				// this.newNoteObj =  this.$arrayObjUtils.arrayObjsDeepCopy(this.newNoteEdits);
				// this.curEditingNoteFormObj  = this.newNoteEdits;
				this.formEditPreviewNoteObj =  {};
				this.curEditingNoteFormObj = {};
				this.curEditingNoteFormObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.newNoteEdits);
				this.editMode = 'edit-new';
				this.showNotesList = false;

				// this.unsavedNewNote = true;
				// this.editingNewNote = true;
			
				console.log("***********************\ncreateNewNote method called at:\n\t" + timeNow);
				
				//  this.noteEditToolbarItems.editSelectedNoteButton.visible = false;
				
			}
        },
        selectNote(noteObj) {
			// TODO: Do a check for unsaved existing note edits before allowing user to select another exisiting note
			this.selectedNote = this.$arrayObjUtils.arrayObjsDeepCopy(noteObj);
            this.formEditPreviewNoteObj =  this.$arrayObjUtils.arrayObjsDeepCopy(this.selectedNote);
			if ((this.editMode === '') && (this.selectedNote.id !== '')) {
				this.noteEditToolbarItems.editSelectedNoteButton.enabled = true;
				this.noteEditToolbarItems.editSelectedNoteButton.visible = true;
				this.noteEditToolbarItems.deleteSelectedNoteButton.enabled = true;
				
			}

        },
        editSelectedNote() {
		
			// this.editingExistingNote = true;
			if (this.unsavedEdits  === true) {
				
				this.unsavedNoteObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.curEditingNoteFormObj);
				this.pendingAction = 'editSelectedNote';
				this.showSaveChangesDialog = true;
			
				// //copy noteObj to keep incase things go awry
				// this.newNoteObj =  this.$arrayObjUtils.arrayObjsDeepCopy(this.newNoteEdits);
				
			} else if (this.pendingAction === '') {

				this.selectedNodeEdits = this.$arrayObjUtils.arrayObjsDeepCopy(this.selectedNote);
				this.editingNewNote = false;
				this.editingExistingNote = true;
				this.curEditingNoteFormObj = {};
			
				// this.formEditPreviewNoteObj =  = this.newNoteEdits; // this.curEditingNoteFormObj;
				this.curEditingNoteFormObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.selectedNodeEdits);
				this.selectedNoteId = this.selectedNote.id;

				// this.formEditPreviewNoteObj = this.curEditingNoteFormObj;

				// this.formEditPreviewNoteObj.name = this.$arrayObjUtils.arrayObjsDeepCopy(this.selectedNote);
				this.editMode = 'edit-selected';
				this.noteEditToolbarItems.editSelectedNoteButton.visible = false;
				this.showNotesList = false;
			}
		
			// this.formEditPreviewNoteObj = this.curEditingNoteFormObj;
			// this.formEditPreviewNoteObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.curEditingNoteFormObj);
			// this.formEditPreviewNoteObj = {};
            
		},
		returnToEditingNote() {
			this.editMode = this.unsavedEditMode;
			this.unsavedEditMode = '';
			this.showNotesList = false;
		
			// this.noteEditToolbarItems.returnToEditingNoteButton.visible = false;
			// this.noteEditToolbarItems.returnToEditingNoteButton.enabled = false;
			// this.noteEditToolbarItems.editSelectedNoteButton.visible = false;
			// this.noteEditToolbarItems.showNotesListButton.visible = true;

			// this.curEditingNoteFormObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.unsavedNoteObj);
			this.formEditPreviewNoteObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.curEditingNoteFormObj);

		},
		compareNoteChanges() {
			if (this.editMode !== '') {
				if (this.curEditingNoteFormObj.body !== this.selectedNote.body) {
					console.log("*******************\nCHANGED HAVE BEEN MADE!\n*******************");
					
					this.formEditPreviewNoteObj.body = this.curEditingNoteFormObj.body;
											
					this.formEditPreviewNoteObj.name = this.curEditingNoteFormObj.name.replace(/<a.*>.*?<\/a>/ig,'');
					this.formEditPreviewNoteObj.name = this.curEditingNoteFormObj.name.replace(/<h[0-9]*>.*?<\/h[0-9]>/ig,'');

					this.unsavedEdits = true;
				
				
				} else {
					console.log("*******************\nSAME AS ORIGINAL!\n*******************");
					this.unsavedEdits = false;
					
				}
			}

		},
		discardNoteChanges() {
			
		
			if(this.pendingAction !== '') {
				let tempAction = this.pendingAction;
				console.log("Saved pending action to tempVar: " + tempAction );
				this.pendingAction = '';
				
				this.$emit(`${tempAction}`);

				this.unsavedEdits = false;
				this.unsavedEditMode = '';
				this.editMode = ''
				this.curEditingNoteFormObj = {};
				this.showSaveChangesDialog = false;
				this.editingExistingNote = false;

				// this.unsavedNewNote = true;
				// this.editingNewNote = true;
				this.noteEditToolbarItems.returnToEditingNoteButton.visible = false;
				this.noteEditToolbarItems.returnToEditingNoteButton.enabled = false;
				this.showNotesList = true;
			}

		},
		cancelNoteChanges() {
			// this.unsavedEdits = false;
			// this.curEditingNoteFormObj = {};
			
			// this.editingExistingNote = false;
			// this.unsavedNewNote = true;
			// this.editingNewNote = true;
			// this.editMode = ''
			if(this.pendingAction !== '') {
				let tempAction = this.pendingAction;
				this.$emit(tempAction);
				this.pendingAction = '';
				
			}

			this.showSaveChangesDialog = false;

			// this.showSaveChangesDialog = true;

		},
		saveNoteChanges() {
			// TODO: When saving changes to existing note, get the single note that's going to be saved.
			// Compare with version that's about to be pushed to server. If they differ, throw error
			// to user indicating the mismatch and recomend saving existing note as a new one, or confirm
			// that if they continue, any data loss is on them.
			
			// if(this.pendingAction !== '') {
			// 	this.$emit(this.pendingAction);
			// }
			// Pretty much follows the discard function (with exception this will call a API method), I smell a code smell, duplication going on, should set state of buttons in 1 function.

			let submitObj = {

				note_name: this.curEditingNoteFormObj.name,
				note_subject: this.curEditingNoteFormObj.subject,
				note_msg: this.curEditingNoteFormObj.body,
				note_creator: this.$store.getters.currentUser
			}

			if(this.editMode === 'edit-new') {
				this.addNote(submitObj);
				this.snackbarText = `<span class="mdi mdi-alert-outline"></span><span>The note: ${this.unsavedNoteObj.name} <br> was saved to the server!</span>`;
				this.showSnackbar = true;

					this.showNotesList = true;
					this.unsavedEdits = false;
					this.unsavedEditMode = '';
					this.editMode = ''
					this.curEditingNoteFormObj = {};
					this.showSaveChangesDialog = false;
					this.editingExistingNote = false;
					this.noteEditToolbarItems.returnToEditingNoteButton.visible = false;
					this.noteEditToolbarItems.returnToEditingNoteButton.enabled = false;

			}


			if(this.editMode === 'edit-selected') {
				submitObj.note_id = this.curEditingNoteFormObj.id;
				console.log("Editing object to send with PUT request: " + JSON.stringify(submitObj, null, ' '));

				this.updateNote(submitObj);
				this.snackbarText = `<span class="mdi mdi-alert-outline"></span><span>The note: ${this.unsavedNoteObj.name} <br> was updated and saved to the server!</span>`;
				this.showSnackbar = true;
				this.unsavedEdits = false;
				this.unsavedEditMode = '';
				this.noteEditToolbarItems.returnToEditingNoteButton.visible = false;
					this.noteEditToolbarItems.returnToEditingNoteButton.enabled = false;

			}
		
			if(this.pendingAction !== '') {
				let tempAction = this.pendingAction;
				console.log("Saved pending action to tempVar: " + tempAction );
				this.pendingAction = '';
			

				this.$emit(`${tempAction}`);
					this.showNotesList = true;
					this.unsavedEdits = false;
					this.unsavedEditMode = '';
					this.editMode = ''
					this.curEditingNoteFormObj = {};
					this.showSaveChangesDialog = false;
					this.editingExistingNote = false;

					// this.unsavedNewNote = true;
					// this.editingNewNote = true;
					this.noteEditToolbarItems.returnToEditingNoteButton.visible = false;
					this.noteEditToolbarItems.returnToEditingNoteButton.enabled = false;
					// this.showSnackbar = false;
			}

		},
		deleteSelectedNote() {

			if (this.selectedNote.name !== '') {
				console.log("Displaying confimation to delete note: " + this.selectedNote.name);
				this.pendingAction = 'deleteSelectedNote';
				this.showConfirmDeleteDialog = true;
			} else {
				console.log("Displaying confimation to delete note: " ); //+ selectedNote.name);
			} 

		},
		cancelNoteDelete() {
			this.pendingAction = '';
			this.showConfirmDeleteDialog = false;
		},
		confirmNoteDelete(selectedItemId) {
			console.log("Item delete with id: " + selectedItemId);
			this.$store.commit('removeNote', selectedItemId);
			this.pendingAction = '';
			this.showConfirmDeleteDialog = false;
		},
		updateSnackbarCondition(valueToSet) {
			console.log('updateSnackbarCondition was called');
			return this.showSnackbar = valueToSet;
		},
        displayNotesList() {
			this.compareNoteChanges();
			
			if (this.unsavedEdits === false) {
				// this.noteEditToolbarItems.editSelectedNoteButton.visible = true;
				this.noteEditToolbarItems.editSelectedNoteButton.visible = true;
				this.noteEditToolbarItems.createNewNoteButton.enabled = true;
				this.noteEditToolbarItems.returnToEditingNoteButton.visible = false;
				this.noteEditToolbarItems.returnToEditingNoteButton.enabled = false;
				this.editMode = '';
				this.showNotesList = true;
					console.log("DisplayNote called with unsavedEdits === false");
		

			} else if ((this.unsavedEdits === true) && (this.editMode !== '')) { 

				this.noteEditToolbarItems.createNewNoteButton.enabled = false;

				

				// if (this.unsavedEditMode === 'edit-new') {

					// this.noteEditToolbarItems.createNewNoteButton.visible = false;
					// this.noteEditToolbarItems.editNewNoteButton.visible = false;
					this.noteEditToolbarItems.editSelectedNoteButton.visible = false;
					// this.noteEditToolbarItems.showNotesListButton.visible = false;
					
					this.noteEditToolbarItems.returnToEditingNoteButton.visible = true;
					this.noteEditToolbarItems.returnToEditingNoteButton.enabled = true;

				// }
			

				this.unsavedEditMode = this.editMode;
				this.unsavedNoteObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.curEditingNoteFormObj);
				this.noteEditToolbarItems.returnToEditingNoteButton.tooltip = `<span class="mdi mdi-alert-outline"></span> ${this.unsavedNoteObj.name} Has Unsaved Changes!`;
				
				// this.editMode = '';
				this.pendingAction = "this.displayNotesList";
				this.showSaveChangesDialog = true;
				


			} else if (this.editMode === '') {
				this.showNotesList = true;
				console.log("If I made it here, then the displayNotesList was called");

				if (this.unsavedEditMode === 'edit-new') {

					this.noteEditToolbarItems.createNewNoteButton.enabled = false;

					// this.noteEditToolbarItems.editNewNoteButton.visible = true;
					// this.noteEditToolbarItems.editNewNoteButton.enabled = true;

					this.noteEditToolbarItems.editSelectedNoteButton.visible = false;
					// this.noteEditToolbarItems.showNotesListButton.visible = false;
				}

			}


		},
		toggleViewSource() {
			let curStateIndex = this.noteEditToolbarItems.toggleViewSource.curStateIndex;
			curStateIndex = (curStateIndex === 1) ? 0 : 1;
			this.noteEditToolbarItems.toggleViewSource.curStateIndex = curStateIndex;
			this.previewMode = this.noteEditToolbarItems.toggleViewSource['states'][curStateIndex].value;
			
			console.log("Current Preview State: " + this.previewMode);
			console.log("CurrentStateIndex: " + curStateIndex);
			console.log("Current value: " + this.noteEditToolbarItems.toggleViewSource['states'][curStateIndex].value);
			// this.previewMode = this.previewMode === 'source' ? 'rendered' : 'source';

		},
        fullWidthToogle() {
            
            let curStateIndex = this.noteEditToolbarItems.fullWidthToogle.curStateIndex;
            // let stateValue = this.noteEditToolbarItems.fullWidthToogle['states'][curStateIndex].value;
            let statesLength = this.noteEditToolbarItems.fullWidthToogle['states'].length; //[this.noteEditToolbarItems.fullWidthToogle.state];
            let newStateIndex = curStateIndex + 1;
            newStateIndex = (newStateIndex === statesLength) ? 0 : newStateIndex;
            
            this.noteEditToolbarItems.fullWidthToogle['curStateIndex'] = newStateIndex;
            console.log('this.noteEditToolbarItems.fullWidthToogle.state: ' + this.noteEditToolbarItems.fullWidthToogle['states'][newStateIndex].value);

			this.fullWidthMode = this.noteEditToolbarItems.fullWidthToogle['states'][newStateIndex].value === 'on' ? true : false;
			
			this.noteEditToolbarItems.fullWidthLeftRightToogle.enabled = this.fullWidthMode;

			console.log('fullWidthMode: ' + this.fullWidthMode );
			
			this.fullWidthContainerId = this.noteEditToolbarItems.fullWidthLeftRightToogle.states[this.noteEditToolbarItems.fullWidthLeftRightToogle.curStateIndex].value;
			
             console.log('fullWidthContainerId: ' + this.fullWidthContainerId );

        },
        fullWidthLeftRightToogle() {

            let curStateIndex = this.noteEditToolbarItems.fullWidthLeftRightToogle['curStateIndex'];
            //  let stateValue = this.noteEditToolbarItems.fullWidthLeftRightToogle['states'][curStateIndex].value;
            let statesLength = this.noteEditToolbarItems.fullWidthLeftRightToogle['states'].length;
            let newStateIndex = curStateIndex + 1;
            newStateIndex = (newStateIndex === statesLength) ? 0 : newStateIndex;
			this.noteEditToolbarItems.fullWidthLeftRightToogle['curStateIndex'] = newStateIndex;
			this.fullWidthSideClass = this.noteEditToolbarItems.fullWidthLeftRightToogle['states'][newStateIndex]['value'];

            // this.fullWidthSideClass = this.fullWidthSide[this.noteEditToolbarItems.fullWidthLeftRightToogle['states'][newStateIndex]];['value'];
            console.log("fullWidthSideClass: " + this.fullWidthSideClass);
        },
        toggleEditMode() {
			
			// this.noteEditToolbarItems.fullWidthToogle.state 


            this.editMode = (this.editMode === '' ) ? 'editExisting' : '';
            this.formEditPreviewNoteObj = this.selectedNote;

		},
		showNoteStats() {
			this.infoDisplayBarData = this.computedInfoDisplayBarData();		
			// this.noteEditToolbarItems.showEditStatus.dataVariables = this.computedInfoDisplayBarData();	
			this.noteEditToolbarItems.infoDisplayBar.dataVariables = this.computedInfoDisplayBarData();
		},
		/*********************************************************************************************************************
		*                                        Code block widget functinons 
		********************************************************************************************************************/
		initCodeBlockWidgets() {
			// This function will be responsible for initially displaying the code blocks in single or multi-line mode
			// along with setting up the data attribute variables that will be used for editing code block

			// Check display mode, since only <br> elements are added to the code, single line is the default
			// display mode for code blocks, check for blocks that have code-wrap set to true and update accordingly

			let tcsCodeBlocks = document.querySelectorAll('.TCS.cli-block span[id^="cli-block-"]');
			//let tcsCodeBlocks =  document.querySelectorAll('.TCS.code-block span code');

			console.log("querySelectorAll('.TCS.cli-block span[id^=\"cli-block\"'): " +  tcsCodeBlocks + "\n\n");
			//loop through buttons and assign event listeners 
			//data-tcs-form-widget-event-func="betterWebberSearch" data-search-range="all" value="">Search End Of Times</

			for (const tcsCodeBlock of tcsCodeBlocks) {
				console.log("tcsCodeBlock.dataset.codeWrap value: " + tcsCodeBlock.dataset.codeWrap + "\n");
				if (tcsCodeBlock.dataset.cliCommand) {

					// Get the computed length of the :before pseudo-element so it can be determined the distance the textarea needs to move left in multi-line mode
					let tmpPseudoBeforeLength = getComputedStyle(tcsCodeBlock.parentElement, ':before').length;
				
					let tmpTextareaFontSize = getComputedStyle(document.documentElement).getPropertyValue('--code-block-textarea--font-size');
					let tmpFontSizePercent = (tmpTextareaFontSize.charAt(tmpTextareaFontSize.length-1) === "%") ? (parseInt(tmpTextareaFontSize) * .01) : tmpTextareaFontSize;

					let remToPixelRatio = .0625; //  1/16;
					let tmpFontSizeInRem = (tmpPseudoBeforeLength * remToPixelRatio) * tmpFontSizePercent;
					tmpTextareaFontSize = tmpFontSizeInRem + "rem";
					let tmpPseudoCalcLength = (tmpPseudoBeforeLength * remToPixelRatio) + "rem";
					document.documentElement.style.setProperty('--code-block-before-content--length', (tmpFontSizeInRem * -1));
			
					// getComputedStyle(document.documentElement).getPropertyValue('--code-block-textarea--margin-left');
					//tmpPseudoCalcLength = tcsCodeBlock.style.getPropertyValue('--code-block-textarea--margin-left');
					console.log(" DID IT WORK? Results of my mad awesome width calculation on a pseudo element should come to 21.5625 are they?.....tada!?: " + tmpPseudoCalcLength + "\n");

					console.log("Textarea fontsize: " + tmpTextareaFontSize + "( " + tmpFontSizePercent + ")\n");
					
					tcsCodeBlock.dataset.textareaMarginLeft = tmpPseudoBeforeLength;
					console.log(" Computed margin stored in data attribute: " + tcsCodeBlock.dataset.textareaMarginLeft  + "\n");

					let tmpCmdLength = parseInt(tcsCodeBlock.dataset.cliCommand.length);

					console.log("tmpLength before padding: " + tmpCmdLength + "\n");
					let textareaCharPadding = parseInt(tcsCodeBlock.dataset.cliCmdCharPadding);
					console.log("textareaCharPadding: " + textareaCharPadding  + "\n");
					tmpCmdLength+= parseInt(textareaCharPadding);


					console.log("Updating innerHTML of elem: " + tcsCodeBlock.id + "\n");
					
					tcsCodeBlock.innerHTML = '<textarea cols="' + tmpCmdLength + '" rows="1" style="display: none;">' + tcsCodeBlock.dataset.cliCommand + '</textarea>' + tcsCodeBlock.dataset.cliCommand;
					//tcsCodeBlock.childNodes[1].cols = tmpCmdLength > 0 ? tmpCmdLength : 0;
					console.log("tcsCodeBlock.children[0].cols = " + tcsCodeBlock.children[0].cols + "\n");

					// tcsCodeBlock.childNodes[1].nodeValue = tcsCodeBlock.dataset.cliCommand;
					console.log("tcsCodeBlock.childNode[0].textContent = " +  tcsCodeBlock.childNodes[0].innerHTML + "\n");
				
					console.log("tcsCodeBlock.children[0].cols = " + tcsCodeBlock.childNodes[0].cols + "\n");

					if (tcsCodeBlock.dataset.numCodeWrapLines === "0") {
						// Need to calculate the number of lines if there are any breaks...if no breaks, set value to 1
						let tmpNumWrappedLines = 1 + (tcsCodeBlock.dataset.cliCommand.match(/  /g) || []).length;
						tcsCodeBlock.childNodes[0].rows = tmpNumWrappedLines;
						console.log("Number of calculated wrapped rows: " + tmpNumWrappedLines + "\n");
						console.log("codeBlockElement.childNodes[0].rows: " + tcsCodeBlock.childNodes[0].rows + "\n");
						tcsCodeBlock.dataset.numCodeWrapLines = tmpNumWrappedLines;

					}
					tcsCodeBlock.dataset.originalCommand = tcsCodeBlock.dataset.cliCommand.replace(/  /g," ");
					tcsCodeBlock.dataset.multilineCommand = tcsCodeBlock.dataset.cliCommand.replace(/  /g,"\\\n");
					// tcsCodeBlock.childNodes[0].textContent = tcsCodeBlock.dataset.cliCommand.replace(/  /g,"\\\n");
					// Remember, 

					tcsCodeBlock.childNodes[0].innerHTML = tcsCodeBlock.dataset.originalCommand;
					tcsCodeBlock.childNodes[1].nodeValue = tcsCodeBlock.dataset.originalCommand;
					console.log("ORIGINAL COMMAND: " + tcsCodeBlock.dataset.originalCommand + "\n");
					console.log("MULTILINE COMMAND: " + tcsCodeBlock.dataset.multilineCommand + "\n");
					console.log("TEXTAREA COMMAND: " + tcsCodeBlock.childNodes[0].innerHTML + "\n");
					
					if (tcsCodeBlock.dataset.codeWrap === "true") {
						console.log("The following elem has codeware ON: " + tcsCodeBlock.id + "\n");
						// The default is to display in a single row, but since the toggleCodeWrapMode toggles current codeWrap value, need to set it false
						// then toggle will reset to true. It's a stupid solution & I need to re-engineer. 
						tcsCodeBlock.dataset.codeWrap === "false";
						toggleCodeWrapMode(tcsCodeBlock);
					
					}   else  {
						console.log("dataset codewrap is false");
					}
				}
					
			}	

			return;
		}, // END OF initCodeBlockWidgets FUNCTION
		editCodeBlock(eTarget) {
			let targetEditCodeId = eTarget.dataset.elemIdToEdit;
			let codeBlockElemHandle = document.getElementById(targetEditCodeId);
			
			let originalCommand = codeBlockElemHandle.dataset.originalCommand;
			console.log("edit target ID: " + targetEditCodeId + "\n");
			console.log("Original Command: " + originalCommand + "\n");
		
			// 1st check to make sure we're working with the TEXTAREA node we expect, if not, bail
			if(codeBlockElemHandle.childNodes[0].nodeName === "TEXTAREA") {
				// toggle edit mode
				codeBlockElemHandle.dataset.editMode = (codeBlockElemHandle.dataset.editMode === "off") ? "on" : "off";
				codeBlockElemHandle.childNodes[0].focus();

			} else {
				return ("Expected TEXTAREA but found " + codeBlockElemHandle.childNodes[0].nodeName);
			}

			// codeBlockElemHandle.
			if (codeBlockElemHandle.dataset.editMode === "on") {
				//codeBlockElemHandle.innerHTML = "<textarea id='tcsEdittingCodeBlockId' rows='2' cols='" + sizeOfCodeEditArea + "'>" + originalCommand + "</textarea>";
				console.log("childNodes[1].nodeValue before clear: " + codeBlockElemHandle.childNodes[1].nodeValue + "\n");
				codeBlockElemHandle.childNodes[1].nodeValue = " ";
				console.log("childNodes[1].nodeValue after: " + codeBlockElemHandle.childNodes[1].nodeValue + "\n");
				let tmpNodeDisplayValue = codeBlockElemHandle.childNodes[0].style.display;

				codeBlockElemHandle.childNodes[0].style = "display: inline;";

				if (codeBlockElemHandle.dataset.modifiedCommand) {
					// the user has previously edited block, reload that version
					codeBlockElemHandle.childNodes[0].textContent = codeBlockElemHandle.dataset.modifiedCommand;
				} else if (codeBlockElemHandle.dataset.codeWrap === "true") {
					// Use multiline version
					console.log("updated textarea with original command: " +codeBlockElemHandle.dataset.multilineCommand + "\n");
					codeBlockElemHandle.childNodes[0].textContent = codeBlockElemHandle.dataset.multilineCommand;
				} else {
					console.log("updated textarea with original command: " + originalCommand + "\n");
					codeBlockElemHandle.childNodes[0].textContent = codeBlockElemHandle.dataset.originalCommand;
				}

				console.log("childNodes[0].textContent(TEXTAREA): " + codeBlockElemHandle.childNodes[0].textContent + "\n");
				
			} else { // edit mode must be off, or at least its not on, so any other value means off
				
				// Ummm, this maybe worthless stupid logic...It going to the set to the nodeValue of childNode[1] to the value of the textarea reguardless....think about it...
				
				if ((codeBlockElemHandle.children[0].value !== codeBlockElemHandle.dataset.originalCommand) || (codeBlockElemHandle.children[0].value !== codeBlockElemHandle.dataset.originalCommand )) {
					// command was updates, set the user data attribute.
					codeBlockElemHandle.childNodes[1].nodeValue = codeBlockElemHandle.childNodes[0].textContent; //replace textnode with textarea text
					codeBlockElemHandle.dataset.modifiedCommand = codeBlockElemHandle.childNodes[0].textContent;
				
				} else {
					codeBlockElemHandle.childNodes[1].nodeValue = codeBlockElemHandle.dataset.originalCommand;
				} 

				codeBlockElemHandle.childNodes[0].style = "display: none;";
				// ...did you get it? I the textarea isn't modified then it's...wait for it...equal to the originalCommand
				// so just set the nodeValue to the value of textarea... 
				// however, you still need to check for changes to the text area to know if you need to update the modified data attribute, so yeah, maybe it's not completely worthless logic after all

			}
			// Swap which one of the two elements to display:
			let tmpNodeDisplayValue = codeBlockElemHandle.childNodes[0].style.display;
			console.log("tmpDisplay value: " + tmpNodeDisplayValue + " -- childNode[0].")
				// codeBlockElemHandle.childNodes[0].style.display = codeBlockElemHandle.childNodes[1].style;
				// codeBlockElemHandle.childNodes[1].style.display = tmpNodeDisplayValue;
			
		},
		toggleCodeWrapEvent(eTarget) {
			let targetToggleId = eTarget.dataset.elemIdToToggle;
			console.log("Target toggle ID: " + targetToggleId + "\n");

			let tmpElemHandle = document.getElementById(targetToggleId);
			// Update button text
			console.log("Here is what the toggleCodeWrapMode function returned: " + toggleCodeWrapMode(tmpElemHandle) + "\n");
			eTarget.innerHTML = (tmpElemHandle.dataset.codeWrap === "true") ? "Single Line" : "Multi-Line";
			
			// return true;
		},
		copyCodeToClipboard(eTarget) {
			let tmpCodeBlockId = eTarget.dataset.elemIdToCopy;
			let tmpElemHandle = document.getElementById(tmpCodeBlockId);

			// let tmpTextAreaHandle = tmpElemHandle.children[0];
			console.log("Copy Function code block children notes: " + tmpElemHandle.childNodes[0].value + "\n");
			tmpElemHandle.childNodes[0].style = "display: inline;"
			tmpElemHandle.childNodes[0].select();
			document.execCommand('copy');
			tmpElemHandle.childNodes[0].style = "display: none;";

		},
		toggleCodeWrapMode(codeBlockElement) {

			// init vars
			
			// To switch to multiline
			// let brMatchString = / \\  /gi;   // match ' <br>' tag, a space before is required!
			let brMatchString = /  /gi;  // to keep the markup simple, when 2 spaces are found, a break will be inserted.
			// let brReplaceString = ' \\  <br>';
			let brReplaceString = '\<br>\n';
			// To switch to single line:
			// let bsMatchString = / \\  <br>/gi;     // match backslash '\ <br>'
			let bsMatchString = /<br>\n/gi;     // match backslash '\ <br>'

			let bsReplaceString = '  '; // just replace with the default of 2 spaces to indicate where to insert a break

			let currentWrapState = codeBlockElement.dataset.codeWrap;
			
			codeBlockElement.dataset.codeWrap = (codeBlockElement.dataset.codeWrap === "true") ? "false" : "true";  // Toggle the codeWrap state

			let codeBlockNodeValue = (codeBlockElement.dataset.codeWrap === "true") ? codeBlockElement.dataset.multilineCommand : codeBlockElement.dataset.originalCommand; // Determine which version of command to display

			if (codeBlockElement.dataset.editMode === "on") {
				console.log("codeBlock element: " + codeBlockElement.childNodes[0] + "\n");
				let curText = codeBlockElement.childNodes[0].textContent;
				if ((curText !== codeBlockElement.dataset.modifiedCommand) && (curText !== codeBlockElement.dataset.multilineCommand) &&  (curText !== codeBlockElement.dataset.originalCommand)) { 
					console.log("Value of textarea: " + curText + "\n");
					console.log("Multiline command: " + codeBlockElement.dataset.multilineCommand + "\n");
					console.log("Original command: " + codeBlockElement.dataset.originalCommand + "\n");

					console.log("Modified command: " + codeBlockElement.dataset.modifiedCommand + "\n");

					return 'Textarea changed'
				}
			}       
			// If the textarea hasn't been touched, then no point in any other textarea conditions

			// Next, the dataModified check should take precedence so we're not overwriting data
			if (codeBlockElement.dataset.modifiedCommand) {
				codeBlockElement.childNodes[0].textContent = codeBlockElement.dataset.modifiedCommand;
				codeBlockElement.childNodes[1].nodeValue = codeBlockElement.dataset.modifiedCommand;
				return 'updated to dataset.modifiedCommand';
			} 

			// If reached this point, it's just decision on which version of original command to use
			if (codeBlockElement.dataset.codeWrap == "true") {
					// the block is now in multi-line mode
					//let multiLineCode = codeBlockNodeValue.replace(brMatchString, brReplaceString);
					codeBlockElement.dataset.codeBeforeWrap = codeBlockElement.childNodes[1].nodeValue;
					codeBlockElement.childNodes[0].textContent = codeBlockElement.dataset.multilineCommand;
					codeBlockElement.childNodes[1].nodeValue = codeBlockElement.dataset.multilineCommand;
					return 'update to dataset.multilineCommand';
			} else {
					
				// the code block is in single line mode
				if(codeBlockElement.dataset.codeBeforeWrap) {
					codeBlockElement.childNodes[0].textContent = codeBlockElement.dataset.codeBeforeWrap;
					codeBlockElement.childNodes[1].nodeValue = codeBlockElement.dataset.codeBeforeWrap;
					return 'updated to dataset.codeBeforeWrap';
				} else {
					codeBlockElement.childNodes[0].textContent =  codeBlockElement.dataset.originalCommand;
					codeBlockElement.childNodes[1].nodeValue =  codeBlockElement.dataset.originalCommand;
					return 'updated to dataset.originalCommand';
				}
			
			}  

			// Return the updated state: 

			return 'don\'t think anything happened!?';

		}
		/***********************************************************************************************************************
		 *                                  End of code block widget functions 
		 **********************************************************************************************************************/
      
    }
};

</script>

