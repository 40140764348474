import * as arrayObjUtils from "../../lib/arrayObjUtils.js";

const state = {
    okToLoadWidgets: false,
    windowDimensions: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        ratio: (window.innerWidth / window.innerHeight).toFixed(2)
    },
    showErrorDialog: false,
    informationDialog: {
        show: false,
        title: '',
        message: '',
        timestamp: 0,
        displayTime: 60,
        status: 'inActive',
        statusTypes: [
            'inActive',
            'focused',
            'waitingForInput',
            'timedOut',
            'dismissed',
            'confirmed'
        ]
    },
    componentsDisplayState: {
        // CalendarWidget: true,
        // BlogsWidget: false,
        showAppSettingsWindow: false,
        openWidgets: [ 'CalendarWidget' ],
        focusedWidget: 'CalendarWidget',
        daySummary: {
            'show': false,
            'date': null
        },
        taskSummary: {
            'show': false,
            'eventId': null,
            'eventIndex': null
        }
    },
    navDrawer: {
        open: false,
        widgetsSubmenuOpen: false
    },
    inputFieldFocused: false,
    focusedInputFieldId: null,
    dataEntryFormOpened: false

};


const getters = {
    getInfoDialogState: state => { 
        return {
            'show': state.informationDialog.show,
            'title': state.informationDialog.title,
            'message': state.informationDialog.message,
            'timestamp': state.informationDialog.timestamp,
            'displayTime': state.informationDialog.displayTime,
            'status': state.informationDialog.status
            
        };

       
    },
    getAppState: state => state,
    getWindowDimensions: state => state.windowDimensions,
    isInputFieldFocused:  state => state.inputFieldFocused,
    isDataEntryFormOpened: state => state.dataEntryFormOpened,
    getFocusedInputId: state => state.focusedInputFieldId,
    isOkToLoadWidgets: state => state.okToLoadWidgets,
    isErrorDialogVisible: state => state.showErrorDialog,
    isDaySummaryVisible: state => state.componentsDisplayState.daySummary.show,
    getDaySummaryState: state => state.componentsDisplayState.daySummary,
    getTaskSummaryState: state => state.componentsDisplayState.taskSummary,
    getComponentDisplayState: state => state.componentsDisplayState
};

const actions = {
    
    toggleNavDrawer({ commit }) {
        commit('uiState/TOGGLE_NAV_DRAWER_MUTATION', !state.navDrawer.open, { root: true });
    },
    closeNavDrawer({ commit }) {
        commit('uiState/CLOSE_NAV_DRAWER_MUTATION', null, { root: true });
    },
    toggleNavDrawerWidgetSubmenu({ commit }) {
        commit('uiState/TOGGLE_NAV_DRAWER_WIDGET_SUBMENU_MUTATION', null, { root: true });
    },
    closeAppSettings({ commit }) {
        commit('uiState/CLOSE_APP_SETTINGS_MUTATION', null, { root: true });
    },
    toggleShowWidget({ dispatch, commit, getters, rootGetters }, widgetName ) {
        
        let widgetIndex = -1;
        
        for (let i = 0; i < state.componentsDisplayState.openWidgets.length; i++) {
            if (state.componentsDisplayState.openWidgets[i] === widgetName) {
                widgetIndex = i;
                // commit('removeFromOpenWidgetArray', i);
                break; //  there's no point in continuing on, there will only be one instance. 
            }
        }
        console.log('commiting UPDATE_OPEN_WIDGETS(' + widgetIndex + ', ' + widgetName + ');');
        commit('uiState/UPDATE_OPEN_WIDGETS', { widgetIndex, widgetName }, { root: true });
        dispatch('uiState/setFocusedWidget', { widgetName }, { root: true });
        // commit('toggleShowWidgetMutation', widgetName);
    },
    setFocusedWidget({ commit }, widgetName) {
        if (state.componentsDisplayState.openWidgets.indexOf(widgetName) === -1) {
            let updatedWidgetIndex = state.componentsDisplayState.openWidgets.length - 1;
            if (updatedWidgetIndex >= 0) {
                commit('uiState/SET_FOCUSED_WIDGET_MUTATION', state.componentsDisplayState.openWidgets[updatedWidgetIndex], { root: true } );
            } else {
                commit('uiState/SET_FOCUSED_WIDGET_MUTATION', '', { root: true } );
            }

        } else {
            commit('uiState/SET_FOCUSED_WIDGET_MUTATION', widgetName, { root: true } );
        }
        
    },
    toggleDataEntryFormOpened( { commit } ) {
        commit('uiState/TOGGLE_DATA_ENTRY_FORM_OPENED', null, { root: true });
    },
    toggleDaySummary({ commit }, summaryDate = new Date().toISOString() ) {
        commit('uiState/TOGGLE_DAY_SUMMARY_MUTATION', summaryDate, { root: true });
        
    },
    openTaskSummary({ dispatch, commit, getters, rootGetters }, taskId = null ) {
        if (taskId !== null) {
            dispatch('tasks/selectTaskObjById', taskId, { root: true } );
            commit('uiState/OPEN_TASK_SUMMARY_MUTATION', null, { root: true });
        }
    },
    closeTaskSummary( { dispatch, commit, getters, rootGetters }) {
        dispatch('tasks/selectTaskObjById', null, { root: true } );
        commit('uiState/CLOSE_TASK_SUMMARY_MUTATION', null, { root: true } );
    },
    updateInputFieldFocus( {commit }, { isInputFocused = false }) {
        commit('uiState/SET_INPUT_FIELD_FOCUS', isInputFocused, { root: true } );
    }, 
    onConfirmError( { dispatch, commit, getters, rootGetters }) {
        
        commit('uiState/CONFIRM_ERROR', true, { root: true });

        commit('uiState/SET_ERROR_DIALOG_VISIBLE', false, { root: true });

        // let statusObj = { 'statusCode': 200, 'status': 'ok', 'name': 'Error dismissed', 'message': 'User has dismissed error message'};
        let authStatusCode = rootGetters['auth/getAuthStatusCode'];
        if ((authStatusCode >= 400) && (authStatusCode <= 599)) {
            // some sort of authentication issues, log user out to make sure session ins't compromised
            // commit( 'auth/SET_AUTH_STATUS', {'statusObj': { 'statusCode': 200, 'status': '', 'name': 'Error dismissed', 'statusDetail': 'User has dismissed error message'} }, { root: true } );
            commit( 'auth/LOGOUT', null, { root: true } ); 
        } else {
            // some other program error we can hopefully recover from
            commit( 'auth/SET_AUTH_STATUS', {'statusObj': { 'statusCode': 200, 'status': 'ok', 'name': 'Error dismissed', 'statusDetail': 'User has dismissed error message'} }, { root: true } );
            
        }
        
    },
    async updateInfoDialog( {  dispatch, commit, getters, rootGetters }, infoDialogObj  ) {
        console.log('committing the following object from updateInfoDialog: ' + JSON.stringify(infoDialogObj, null, 1));
       
        commit( 'uiState/SET_INFO_DIALOG_OBJ', infoDialogObj, { root: true } );
        console.log('informationDialog object after commit: ' + JSON.stringify(getters.getInfoDialogState, null, 1));
        return await rootGetters['uiState/getInfoDialogState.status'] === 'confirmed';
      
    },
    async addNotification ( {dispatch, commit, getters, rootGetters }, notificationMessage ) {

        console.log('committing the following object from addNotification: ' + JSON.stringify(notificationMessage, null, 1));
        commit( 'uiState/SET_INFO_DIALOG_OBJ', notificationMessage, { root: true } );
        return await rootGetters['uiState/getInfoDialogState.status'] === 'confirmed';
    }
    
};


const mutations = {
    

    TOGGLE_NAV_DRAWER_MUTATION: (state, navDrawerState) => state.navDrawer.open = navDrawerState,

    CLOSE_NAV_DRAWER_MUTATION: (state) => state.navDrawer.open = false,

    TOGGLE_NAV_DRAWER_WIDGET_SUBMENU_MUTATION: (state) =>  state.navDrawer.widgetsSubmenuOpen = !state.navDrawer.widgetsSubmenuOpen,

    TOGGLE_DAY_SUMMARY_MUTATION(state,  daySummaryDate ) {

        state.componentsDisplayState.daySummary.show = !state.componentsDisplayState.daySummary.show;
        state.componentsDisplayState.daySummary.date = daySummaryDate;
    },
    TOGGLE_DATA_ENTRY_FORM_OPENED: (state) => state.dataEntryFormOpened = !state.dataEntryFormOpened,
    OPEN_TASK_SUMMARY_MUTATION: (state) => state.componentsDisplayState.taskSummary.show = true,
    CLOSE_TASK_SUMMARY_MUTATION: (state) => state.componentsDisplayState.taskSummary.show = false,

    UPDATE_OPEN_WIDGETS(state, { widgetIndex, widgetName }) {
        // widgetName = widgetName.toString();
        console.log("WIDGETNAME: " + widgetName + 'widget index: ' + widgetIndex + '  typeOf widgetIndex: ' + typeof(widgetIndex));

        if (widgetIndex < 0) {
            state.componentsDisplayState.openWidgets.push(widgetName);
        } else {
            state.componentsDisplayState.openWidgets.splice(widgetIndex, 1);
        }
    },

    REMOVE_FROM_OPEN_WIDGET_ARRAY: (state, widgetIndex) => state.componentsDisplayState.openWidgets.splice(widgetIndex, 1),

    SET_FOCUSED_WIDGET_MUTATION: (state, widgetName) => state.componentsDisplayState.focusedWidget = widgetName,

    SET_INPUT_FIELD_FOCUS: (state, inputFocusedState) => state.inputFieldFocused = inputFocusedState,

    SET_WINDOW_DIMENSIONS(state, { innerWidth = window.innerWidth, innerHeight = window.innerHeight }) {
        state.windowDimensions.innerWidth = innerWidth;
        state.windowDimensions.innerHeight = innerHeight;
        state.windowDimensions.ratio = innerWidth / innerHeight;
        console.log('window dimensions updated: ' + JSON.stringify(state.windowDimensions, null, 2));

    },
    SET_INFO_DIALOG_OBJ(state, infoDialogObj ) {
        console.log('infoDialogObj passed to commit: ' + JSON.stringify(infoDialogObj, null, 1));
        // state.informationDialog = arrayObjUtils.arrayObjsDeepCopy(infoDialogObj);
        state.informationDialog = infoDialogObj;
    },
    SET_ERROR_DIALOG_VISIBLE(state, isErrorShown) {
        state.showErrorDialog = isErrorShown;           

    },
    CONFIRM_ERROR(state, userDismissedError) {
           
        if (!state.userId) {
            state.status = 'logged-out';
        } else {
            state.statusCode = 200;
            state.status = 'ok';
        }

    },

    SET_OK_TO_LOAD_WIDGETS(state, okToLoadWidgetsValue) {
        state.okToLoadWidgets = okToLoadWidgetsValue;
    },
    CLOSE_APP_SETTINGS_MUTATION: (state) => state.componentsDisplayState.showAppSettingsWindow = false,

    TOGGLE_SHOW_WIDGET_MUTATION: (state, widgetName) => {
       
        state.componentsDisplayState[widgetName] = !state.componentsDisplayState[widgetName]
    },

};

export default  {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};