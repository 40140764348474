<template>
      <span class="appbar-realtime-clock" @click="sayCurrentTime()">{{ getCurrentTimeObj.twelveHourTimeWithSeconds }}<br class="">{{$datedJS.dated.getDateShort()}}</span> <!-- Hello {{ currentUser }} </p></span> -->
</template>

<script>

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "RealtimeClock",
    props: [
        "showInformationDialog",
        "informationTitle",
        "informationMessage",
        "informationTimeStamp",
    ],
    data: () => ({
        componentName: "RealtimeClock",

    }),
    computed: {
        ...mapGetters('time', {
            getCurrentTimestamp: 'getCurrentTimestamp',
            getCurrentTimeObj: 'getCurrentTimeObj',
            getTopOfMinuteTimestamp: 'getTopOfMinuteTimestamp',
            getTopOfMinuteTimeObj: 'getTopOfMinuteTimeObj'
        }),
    },
    methods: {
        ...mapActions( 'time', {
            init: 'init',
            killRealtimeClockInterval: 'killRealtimeClockInterval',
            sayCurrentTime: 'sayCurrentTime'
        }),
    }
};
</script>