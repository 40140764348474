/**
 * The following functions should probably be put into a seperate JS file. The 4 functions are all related to
 * arrays and objects, so they would probably be a good start for a array/object helper library.
 *
 */

export function arrayObjsDeepCopy(sourceObj) {
    let destObj;
    let objValue;
    let objKey;

    if (typeof sourceObj !== "object" || sourceObj === null) {
        return sourceObj; // Return the value of sourceObj if sourceObj is not an array or object
    }

    destObj = Array.isArray(sourceObj) ? [] : {};

    for (objKey in sourceObj) {
        // generate UUID for objects
        // if (objKey === 'id') {
        //     console.log("objectId: " + sourceObj[objKey] + "\n");
        //     sourceObj[objKey] = this.genUUIDv4();
        //     console.log("objectId assigned UUIDv4: \n\t" + sourceObj['name'] + ": " + sourceObj[objKey] + "\n");
        // }
        objValue = sourceObj[objKey];
        destObj[objKey] =
        typeof objValue === "object" && objValue !== null
            ? this.arrayObjsDeepCopy(objValue)
            : objValue;
    }

    return destObj;
}


export function reduceObjectProperties(...objectPropertiesToKeep) {
    return function (originalObject) {
        const newObject = {};
        objectPropertiesToKeep.forEach(propertyName => {
            newObject[propertyName] = originalObject[propertyName];

        });
        return newObject;
    }
}

export function buildNestedArrayPaths(
    nestedArray,
    parentNodePath = "",
    nodeLevel = 0,
    nodeIndex = 0,
    nestedNodeKeyName = "children"
) {
    let numElements = nestedArray.length;
    // nodeIndex will be used to target if needed..take out if it's determined to not be needed.
    for (let keyIndex = nodeIndex; keyIndex < numElements; ++keyIndex) {
        nestedArray[keyIndex].keyPath = parentNodePath + keyIndex;
        nestedArray[keyIndex].nodeLevel = nodeLevel;
        nestedArray[keyIndex].nodeIndex = nodeIndex;

        console.log(
        `Assigned path: ${
            nestedArray[keyIndex].keyPath
        } \n Current element stats:\n\t\t name: ${
            nestedArray[keyIndex].name
        } \n\t\t nodeLevel: ${(nestedArray[
            keyIndex
        ].nodeLevel = nodeLevel)} \n\t\t nodeIndex: ${(nestedArray[
            keyIndex
        ].nodeIndex = nodeIndex)} \n`
        );

        if (
        nestedArray[keyIndex][nestedNodeKeyName] !== undefined &&
        Array.isArray(nestedArray[keyIndex][nestedNodeKeyName])
        ) {
        let elmPathToNextNode = nestedArray[keyIndex].keyPath + "c";
        buildNestedArrayPaths(
            nestedArray[keyIndex][nestedNodeKeyName],
            elmPathToNextNode,
            nodeLevel + 1
        );
        }
        nodeIndex += 1;
    }
}

export function getNestedArrayObjectsByIndexKeyPath(nestedArray, pathString) {
    pathString = pathString.replace(/\[(\w+)\]/g, ".$1");
    pathString = pathString.replace(/^\./, "");
    let keyIndexes = [...pathString];
    let numKeyIndexes = keyIndexes.length;

    for (let i = 0; i < numKeyIndexes; ++i) {
        let keyIndex = keyIndexes[i];
        if (keyIndex === "c") {
        keyIndex = "children";
        }
        if (keyIndex in nestedArray) {
        nestedArray = nestedArray[keyIndex];
        } else {
        return;
        }
    }
    return nestedArray;
}

export function genUUIDv4() {
    let uuidV4 = "";
    let random;

    for (let i = 0; i < 32; i++) {
        random = (Math.random() * 16) | 0;

        if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuidV4 += "-";
        }
        uuidV4 += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(
        16
        );
    }

    return uuidV4;
}

export function buildCategoryKeyPaths(
    categoryArray,
    catetgoryTotalIndex = 0,
    nestDepth = 0
) {
    catetgoryTotalIndex += 1;
    console.log(
        "catetgoryTotalIndex: " +
        catetgoryTotalIndex +
        "\n\tcategoryArray: " +
        Object.getOwnPropertyNames(categoryArray[0])
    );
    console.log("\n\tcategoryArray length: " + categoryArray.length);
    for (
        let curLevelIndex = 0;
        curLevelIndex < categoryArray.length;
        curLevelIndex++
    ) {
        if (Array.isArray(categoryArray[curLevelIndex])) {
        nestDepth += 1;
        this.buildCategoryKeyPaths(
            categoryArray[curLevelIndex].children,
            nestDepth
        );
        }
        categoryArray.keyPath = "";
    }
}
