<template>

    <v-dialog
        v-model="showTimedEventForm"
        persistent
        max-width="98%"
        width="100%"
        min-width="25rem"
        dense
        >
        <v-card>
            <v-toolbar dense :color="timedEventObj.color" dark>
                <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                <v-toolbar-title
                    v-html="timedEventObj.name + ' - ' + timedEventObj.index"
                ></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                    <!-- <v-icon>mdi-pencil</v-icon> -->
                    <v-icon  @click="$emit('cancelTimedEventForm')">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <!-- <v-card-text>{{ selectedEvent.body }}</v-card-text> -->
            <v-card-text>
                <v-container>

                    <form v-if="showTimedEventForm" class="ma-0">
                    
                        <v-row class="mt-1">

                            <v-col cols="6" sm="4" lg="4" class="mx-auto mt-1">
                                <v-select
                                    v-model="timedEventObj.type_id"
                                    :items="getTaskTypes"
                                    item-text="typeName"
                                    item-value="typeId"
                                    item-id="typeId"
                                    label="Select Task Type"
                                    fullwidth
                                    @change="updateTaskProperties(timedEventObj)"
                                >
                                    <template v-slot:item="{ item }"> <!-- , index }"> -->
                                        <v-list-item v-if="item.typeName !== 'Undefined task type'"  :style="{ backgroundColor: item.typeColor }">
                                            {{ item.typeName }}
                                        </v-list-item>
                                        <v-divider v-if="item.typeName !== 'Undefined task type'" class="mt-2"></v-divider>
                                    </template>
                                        <!-- <v-divider class="mt-2"></v-divider> -->
                                </v-select>
                            </v-col>

                            <v-col cols="6" sm="4" lg="3" class="mx-auto mt-1">
                                <v-select
                                v-model="timedEventObj.want_or_need"
                                    :items="getWantVsNeedOptions"
                                    item-text="wantNeedName"
                                    item-value="wantNeedValue"
                                    item-id="wantNeedId"
                                    label="Is this a want or a need"
                                    @change="updateTaskProperties(timedEventObj)"
                                ></v-select>
                            </v-col>
                            <v-spacer></v-spacer>

                            <v-col cols="12" sm="12" lg="12" class="mx-auto">
                                <!-- Editing new or existimg task, use timedEventObj which will be a deep copy of tempEventObj -->
                                        <!-- v-model="timedEventObj.name" -->
                                 <!-- <AutoCompleteInput
                                    v-model="timedEventObj.name"
                                    inputLabel="Task name (required)"
                                    :inputValue="timedEventObj.name"
                                    inputVariableName="name"
                                    :suggestionsPool="getFrequentInputFields"
                                    @updateTextInput="updateTextInput"
                                    @loseFocus="[ timedEventObj.name = (timedEventObj.name === '')  ? 'Event name' : timedEventObj.name, updateInputFieldFocus(isInputFocused = false) ];"
                                /> -->
                                <AutoFillFormFields
                                    
                                    inputLabel="Task name (required)"
                                    :inputValue="timedEventObj.name"
                                    inputVariableName="name"
                                    :suggestionsPoolObjects="getFrequentInputFields"
                                    @updateTextInput="updateTextInput"
                                    @updateFormFields="updateFormFields"
                                    @loseFocus="[ updateInputFieldFocus( { 'isInputFocused': false } ) ];"
                                />
                                <!-- <v-text-field class="mt-4"
                                    v-model="timedEventObj.name"
                                    type="text"
                                    dense
                                    outlined
                                    label="Task name (required)"
                                    this.formTextareaHeight = 1;
                                    @blur="[ timedEventObj.name = (timedEventObj.name === '')  ? 'Event name' : timedEventObj.name, updateInputFieldFocus(isInputFocused = false), updateTaskProperties(timedEventObj) ];"
                                ></v-text-field> -->
                               


                                    <!-- 
                                    <v-combobox
                                        v-model="timedEventObj.name"
                                        :items="getFrequentInputFields"
                                        dense
                                        outlined
                                        type="text"
                                        label="Task name (required)"
                                        @focus="[ timedEventObj.name = (timedEventObj.name === 'Event name') ? '' : timedEventObj.name, updateInputFieldFocus(isInputFocused = true)]"
                                        @blur="[ timedEventObj.name = (timedEventObj.name === '')  ? 'Event name' : timedEventObj.name
                                        , updateInputFieldFocus(isInputFocused = false), updateTaskProperties(timedEventObj) ];"
                                        > 
                                    -->
                                    <!-- 
                                        <template v-slot:item>
                                        <v-list-item style="font-size: 95%;"> 
                                            {{ item }}
                                        </v-list-item>
                                    </template> 
                                    -->

                                    <!-- 
                                        <template v-slot:item="{ item, index }">
                                            <v-list-item style="font-size: 90%;"> 
                                                {{ item }}
                                            </v-list-item>
                                        </template>
                                        </v-combobox>
                                    -->

                            </v-col>

                            <v-spacer></v-spacer>
                        
                            <v-col cols="12" sm="12" lg="12" class="mx-auto">
                                <!-- <v-text-field v-model="body" type="text" label="Task details"></v-text-field> -->
                                <v-textarea class="my-0"
                                    v-model="timedEventObj.body"
                                    @focus=" [ formTextareaHeight = 5, updateInputFieldFocus( { 'isInputFocused': true } ) ];"
                                    @blur=" [ formTextareaHeight = 1, updateInputFieldFocus( { 'isInputFocused': false } ),  updateTaskProperties(timedEventObj) ];"
                                    label="Task body"
                                    :rows="formTextareaHeight" 
                                
                                    no-resize="true"
                                    placeholder="add note"
                                ></v-textarea>
                                <!-- :height="[ formTextareaHeight + 'rem' ]" -->
                            </v-col>

                                <!-- <v-datetime-picker
                                v-model="selectedEvent.start"
                                :datetime="$datedJS.dated.getLocalISODateTime(selectedEvent.start)"
                                :label="[ selectedEvent.start ? $datedJS.dated.getLocalISODateTime(selectedEvent.start) : 'Task start date/time' ]"> 
                                </v-datetime-picker>
                                -->
                            <!-- <v-spacer></v-spacer class="ma-2"> -->
                            <v-col cols="6" sm="4" lg="3" class="mx-auto">
                                <v-datetime-picker  v-model="timedEventObj.start" :datetime="timedEventObj.start"  label="Start Time" @input="updateEventTime(timedEventObj, 'start')">
                                    
                                    <template slot="actions" slot-scope="{ parent }">
                                        <v-btn color="error lighten-1" @click.native="parent.resetPicker">Cancel</v-btn>
                                        <v-btn color="success darken-1" @click="parent.okHandler">Done</v-btn>
                                    </template>
                                </v-datetime-picker>
                                    <!-- <div>Task start date/time: <span v-text="timedEventObj.startISO"></span></div> -->
                            </v-col>
                        
                            <v-col cols="6" sm="4" lg="3" class="mx-auto">
                                <v-datetime-picker  v-model="timedEventObj.end" :datetime="timedEventObj.end"  label="End Time" @input="updateEventTime(timedEventObj, 'end')">
                                    <template slot="actions" slot-scope="{ parent }">
                                        <v-btn color="error lighten-1" @click.native="parent.resetPicker">Cancel</v-btn>
                                        <v-btn color="success darken-1" @click="parent.okHandler">Done</v-btn>
                                    </template>
                                </v-datetime-picker>
                            </v-col>

                            <!-- <v-spacer></v-spacer class="mx-4"> -->
                            <v-col cols="5" sm="4" lg="3" class="mx-auto">
                                <!-- <v-text-field
                                    v-model="timedEventObj.duration"
                                    :value="timedEventObj.duration"
                                    @focus="$event.target.select()"
                                    type="text"
                                    label="Task duration"
                                    @blur="updateEventTime(timedEventObj, 'duration')"
                                > -->

                                <!-- v-model="timedEventObj.duration" -->

                                <AutoCompleteInput
                                    :modelValue="timedEventObj.duration"
                                    @update:modelValue="newValue => timedEventObj.duration = newValue"
                                    :inputValue="timedEventObj.duration"
                                    @updateTextInput="updateTextInput"
                                    @updateComputed=" [ updateEventTime(timedEventObj, 'duration') ]; "
                                    inputVariableName='duration'
                                    :suggestionsPool="getTimeIntervalOptions"
                                    suggestionsPoolPrefix='timeInterval'
                                    inputLabel="Task duration"
                                    @loseFocus="[  updateInputFieldFocus( { 'isInputFocused': false } ) ];"
                                />

                                <!-- <div slot="append">minutes</div>
                                </v-text-field> -->
                                    <!-- <div>Task end date/time: <span v-text="timedEventObj.endISO"></span></div> -->
                            </v-col>

                                                                
                            <v-col cols="6" sm="4" lg="2" class="mx-auto">
                                <v-select
                                    v-model="timedEventObj.time_data.times[0].prep_time"
                                    :items="getTimeIntervalOptions"
                                    item-text="timeIntervalLabel"
                                    item-value="timeIntervalMinutesValue"
                                    item-id="timeIntervalId"
                                    label="Add prep-time"
                                    @change="updateTaskProperties(timedEventObj)"
                                ></v-select>

                                  <!-- <AutoCompleteInput
                                    @updateTextInput="updateTextInput"
                                    inputVariableName="duration"
                                    :suggestionsPool="getTimeIntervalOptions"
                                    inputLabel="Task duration"
                                    @looseFocus="updateEventTime(timedEventObj, 'duration')"
                                /> -->

                            </v-col> 

                                <!--
                                    <v-datetime-picker
                                v-model="selectedEvent.end"
                                :datetime="selectedEvent.end"
                                label="Task end date/time"
                                >
                                </v-datetime-picker>
                                -->

                                <!--
                                    <v-row align="center">
                                    <v-col class="d-flex" cols="12" sm="6"> 
                                -->
                            <v-col cols="6" sm="4" lg="3" class="mx-auto">
                                <v-select 
                                    v-model="timedEventObj.priority_lvl_id"
                                    :items="getTaskPriorityLevels"
                                    item-text="labelText"
                                    item-value="levelId"
                                    item-color="levelColor"
                                    item-id="levelId"
                                    label="Select Priority Level"
                                    :color="getEventColor(timedEventObj)"
                                    :id="'priorityLevelList_'+ levelId"
                                    @change="updateEventPriorityLevel()"
                                >
                                    <template v-slot:item="{ item } "> <!-- , index }"> -->
                                        <v-list-item :style="{ backgroundColor: item.levelColor, opacity: 1 }" >
                                            {{ item.labelText }}
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-slot:selection="{ item }">
                                        <span style="opacity: 1; border: .0875rem solid rgba(175, 200, 225, .85);">{{ item.labelText }}</span>
                                    </template>
                                </v-select>  
                            </v-col>

                            <v-col cols="6" sm="4" lg="3" class="mx-auto">
                                <v-select
                                    v-model="timedEventObj.status_id"
                                    :items="getTaskStatusTypes"
                                    item-text="statusName"
                                    item-value="statusId"
                                    item-id="statusId"
                                    label="Select Task Status"
                                    @change="updateTaskProperties(timedEventObj)"
                                >

                                    <template v-slot selection="{ item, index }">
                                        <span style="opacity: .75;">{{ item.statusName }} [SELECTED]</span>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-subheader style="text-align: center;" class="mx-auto pl-0">
                                Percent complete:
                                </v-subheader>
                                <v-slider
                                    v-model="timedEventObj.progress_percent"
                                    :thumb-size="32"
                                    thumb-label="always"
                                    @change="updateTaskProperties(timedEventObj)"

                                > {{  timedEventObj.progress_percent }}%</v-slider>
                            </v-col>

                                <!-- <v-col cols="2" sm="2" class="mx-auto">
                                    <v-checkbox
                                    v-model="timedEventObj.is_finished"                                               
                                    @change="updateTaskProperties(timedEventObj)"
                                    >
                                        <template v-slot:label>
                                            <span style="font-family: Prompt; font-size: 1.15rem; font-weight: 500; color: rgb(225, 225, 225);">Status:
                                            <span v-if="timedEventObj.is_finished" style="color: rgb(0, 192, 0); font-weight: 900; font-size: 1.2rem;">FINISHED! :)</span>
                                            <span v-else style="color: rgb(192, 0, 0); font-weight: 900; font-size: 1.2rem;">Not Finished :'(</span>
                                    
                                            </span>
                                        
                                            </template>
                                    </v-checkbox>
                                </v-col> -->

                            <!-- ${timedEventObj.is_finished ? 'YES!' : 'Nope :('}`" -->
                        </v-row>

                    </form>
                </v-container>
                <!-- <span v-html="selectedEvent.details"></span>
                <span v-html="selectedEvent.body"></span> -->
            </v-card-text>
            <v-card-actions>
                <v-btn text color="secondary" @click="$emit('closeTimedEventForm')">
                    Close
                </v-btn>

                <!-- <v-btn text color="secondary"
                v-if="tempEventObj.id === selectedEvent.id"
                @click="cancelEventEdit()"> -->

                <v-btn text color="Primary"
                    v-if="((editingNewEventProp === true) || (editingExistingEventProp === true))"
                    @click="$emit('cancelTimedEventForm')">
                    Cancel
                </v-btn>
                
                <!-- <v-btn text color="secondary"
                v-if="tempEventObj.id !== selectedEvent.id"
                @click.prevent="editEvent(selectedEvent)"> -->

                <!-- <v-btn text color="Primary"
                    v-if="((editingNewEventProp === false) && (editingExistingEventProp === false) && (selectedEventIndex !== null))"  
                    @click.prevent="editEvent(allTasks2Do[selectedEventIndex])">
                    Edit
                </v-btn> -->

                <v-btn type="submit" color="Primary"
                    v-if="(editingNewEventProp === true)"
                    class="mr-4 TM-glowing-outline"
                    @click.stop="addTimedEvent(timedEventObj)">
                Add Event</v-btn>

                <v-btn type="submit" color="Primary"
                    v-if="(editingExistingEventProp === true)"
                    class="mr-4 TM-glowing-outline"
                    @click.stop="updateTimedEvent(timedEventObj)">
                Update Event</v-btn>


                <!-- <v-btn text v-else @click.prevent="updateFirebaseEvent(selectedEvent)">Update</v-btn> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
    
</template>
     


<script>
    import { mapGetters, mapActions, mapMutations } from "vuex";
    import AutoCompleteInput from "@/components/forms/AutoCompleteInput.vue";
    import AutoFillFormFields from "@/components/forms/AutoFillFormFields.vue";

    export default {
    name: "TimedEventForm",
    components: {
       AutoCompleteInput,
       AutoFillFormFields
    },
    props: [
        "showTimedEventForm",
        "task2DoObjProp",
        "editingNewEventProp",
        "editingExistingEventProp",
        "task2DoFormProp",
    ],
    data: () => ({
        taskFormObj: {},
        closeOnClick: true,
        selectedTaskType: 1,
        selectedPriorityLevel: 3,
        timedEventObj: {
            alert_id: null,
            name: '',
            body: null,
            categories: null,
            color: '',
            created_by_id: null,
            created_for_id: null,
            created_on: null,
            edited_on: null,
            start: 0,
            start_unix_timestamp: 0,
            startISO: '',
            end: 0,
            end_unix_timestamp: 0,
            endISO: '',
            data: null,
            duration: null,
            finished_on: null,
            has_alert: false,
            has_push_notification: false,
            id: '',
            index: null,
            in_reference_to: null,
            is_archived: false,
            is_finished: false,
            nested_level: null,
            note: '',
            owner_screen_name: null,
            parent_id: '',
            priority_lvl_id: 1,
            progress_percent: 0,
            status_id: 1,
            subject: null,
            summary: null,
            timed: null,
            time_data: {
                timed: null,
                times: [
                    {
                        start: null,
                        end: null,
                        start_unix_timestamp: 0,
                        end_unix_timestamp: 0,
                        prep_time: 0

                    }
                ]
            },
            location_data: {
                origin: 'home',
                destination: {
                    id: '',
                    name: '',
                    streetLine1: '',
                    streetLine2: '',
                    city: '',
                    state: '',
                    zip: ''
                },
                travelTime: 0,
                travelDistance: ''
            },
            type_id: 1,
            tempObj: true,
            want_or_need: 'want'
        },
        formTextareaHeight: 1
           
    }),
    computed: {
        ...mapGetters('tasks', {
            allTasks2Do: 'allTasks2Do',
            getTodaysTasks: 'getTodaysTasks',
            getTaskTypes: 'getTaskTypes',
            getTaskPriorityLevels: 'getTaskPriorityLevels',
            getTaskStatusTypes: 'getTaskStatusTypes',
            getWantVsNeedOptions: 'getWantVsNeedOptions',
            emptyTaskObj: 'emptyTaskObj',
            loadedDataFromAPI: 'loadedDataFromAPI'
        }),
        ...mapGetters('userData', {
            getFrequentInputFields: 'getFrequentInputFields'
        }),
        ...mapGetters('time', {
            getTimeIntervalOptions: 'getTimeIntervalOptions'
        }),
        ...mapGetters('uiState', {
            isDataEntryFormOpened: 'isDataEntryFormOpened'
        })

        
    },
    beforeCreate() {
        
    },
    created() {
        console.log('TimedEventForm.vue created method called');
        this.timedEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.task2DoObjProp);

    },
    async mounted() {
               
        // this.timedEventObj = await this.$arrayObjUtils.arrayObjsDeepCopy(this.task2DoObjProp);
        this.$set(this.timedEventObj, 'duration', ((this.timedEventObj.end_unix_timestamp / 60000) - (this.timedEventObj.start_unix_timestamp / 60000)).toFixed(0));
        let allTasks2DoIndex = this.allTasks2Do.indexOf(this.task2DoObjProp);
        console.log('TimedEventForm mounted method, allTasks2DoIndex: ' + allTasks2DoIndex);
        let allTask2DoObj = this.allTasks2Do[allTasks2DoIndex];
        console.log('TimedEventForm mounted method, allTask2DoObj: ' + JSON.stringify(allTask2DoObj, null, 2));
        // this.timedEventObj = Object.assign({}, this.timedEventObj);
        console.log('TimedEventForm.vue mounted method called');

        this.toggleDataEntryFormOpened();

    },
  methods: {
    // ...mapMutations([ 'uiState/SET_INPUT_FIELD_FOCUS' ]),
    ...mapActions([ 'fetchAllFromAPI', 'refreshDataFromAPI', 'initAppState' ]),
    ...mapActions( 'userData', {
        updateFrequentInputFields: 'updateFrequentInputFields'
    }),
    ...mapActions( 'uiState', {
        updateInputFieldFocus: 'updateInputFieldFocus',
        toggleDataEntryFormOpened: 'toggleDataEntryFormOpened'
    }),
    ...mapActions( 'tasks', {
        createTask2Do: 'createTask2Do',
        updateTask2Do: 'updateTask2Do',
        setupTodaysTaskList: 'setupTodaysTaskList',
        fetchTasks2Do: 'fetchTasks2Do',
        fetchTaskPropertiesData: 'fetchTaskPropertiesData'
    }),
    updateTextInput( { inputVariableName = '', inputString = '' } = {} ) {
    /**
     *  this.timedEventObj[`'${inputVariableName}'`] = inputString;
     *  */
        if (inputVariableName !== '') {
            console.log('inputString: ' + inputString);
            this.timedEventObj[inputVariableName] = inputString;
            console.log('this.timedEventObj[' + inputVariableName + '] = ' + this.timedEventObj[inputVariableName]);
        }
        
        
    },
    updateFormFields(formFieldsObject) {
        this.timedEventObj.name = formFieldsObject.name;
        this.timedEventObj.want_or_need = formFieldsObject.wantOrNeed;
        this.timedEventObj.type_id = formFieldsObject.taskTypeId;
        this.timedEventObj.priority_lvl_id = formFieldsObject.priorityLevelId;
        this.timedEventObj.color = this.getTaskPriorityLevels[this.timedEventObj.priority_lvl_id - 1].levelColor; 

        console.log('updated frequent form fields with the following object: ' + JSON.stringify(formFieldsObject, null, 2));

    },
    updateEventPriorityLevel(event = this.timedEventObj) {
        if(event) {            
            event.color = this.getTaskPriorityLevels[event.priority_lvl_id - 1].levelColor; 
        }
        
    },
    updateEventTime(eventObj, fieldToUpdate) {
            console.log('updateEventTime called with field to update: ' + fieldToUpdate);
        // let startTimeCheck = this.$datedJS.dated.getLocalISODateTime(event.start_unix_timestamp);
        if (fieldToUpdate === 'start') {
            let milisecondDuration = this.timedEventObj.end_unix_timestamp - this.timedEventObj.start_unix_timestamp;    // make sure the duration is set before updating timestamp values
            
            console.log("updateEventTime called from datapicker update with start time of: " + this.timedEventObj.start);
                            
            this.timedEventObj.start_unix_timestamp = new Date(this.timedEventObj.start).getTime();
            // this.timedEventObj.start = this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.start_unix_timestamp);
            this.$set(this.timedEventObj, 'start',  this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.start_unix_timestamp));
            console.log("start time passed to getLocalISODateTime: " + this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.start_unix_timestamp));

            this.timedEventObj.startISO = new Date(this.timedEventObj.start_unix_timestamp).toISOString();

            this.timedEventObj.end_unix_timestamp = this.timedEventObj.start_unix_timestamp + milisecondDuration;
            this.timedEventObj.endISO = new Date(this.timedEventObj.end_unix_timestamp).toISOString();
            this.$set(this.timedEventObj, 'end', this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.end_unix_timestamp));
            // this.timedEventObj.end = this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.end_unix_timestamp);

            console.log("updateEventTime called from datapicker update with endtime time of: " + this.timedEventObj.end);       

            // this.timedEventObj.duration = ((this.timedEventObj.end_unix_timestamp / 60000) - (this.timedEventObj.start_unix_timestamp / 60000)).toFixed(0);
            // this.updateTextInput('duration', ((this.timedEventObj.end_unix_timestamp / 60000) - (this.timedEventObj.start_unix_timestamp / 60000)).toFixed(0));
            this.$set(this.timedEventObj, 'duration', ((this.timedEventObj.end_unix_timestamp / 60000) - (this.timedEventObj.start_unix_timestamp / 60000)).toFixed(0));
            
        }
        if (fieldToUpdate === 'end') {
            
            console.log("updateEventTime called with new endTime of: " + this.timedEventObj.end);
            this.timedEventObj.start_unix_timestamp = new Date(this.timedEventObj.start).getTime();
            this.timedEventObj.startISO = new Date(this.timedEventObj.start_unix_timestamp).toISOString();

            this.timedEventObj.end_unix_timestamp = new Date(this.timedEventObj.end).getTime();
            // this.timedEventObj.end = this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.end_unix_timestamp);
            this.timedEventObj.endISO = new Date(this.timedEventObj.end_unix_timestamp).toISOString();
            this.$set(this.timedEventObj, 'end', this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.end_unix_timestamp));
        
            this.$set(this.timedEventObj, 'duration', ((this.timedEventObj.end_unix_timestamp / 60000) - (this.timedEventObj.start_unix_timestamp / 60000)).toFixed(0));
            
            // this.updateTextInput('duration', ((this.timedEventObj.end_unix_timestamp / 60000) - (this.timedEventObj.start_unix_timestamp / 60000)).toFixed(0));
            console.log("duration set to: " + this.timedEventObj.duration);

            // this.timedEventObj.duration = ((this.timedEventObj.end_unix_timestamp / 60000) - (this.timedEventObj.start_unix_timestamp / 60000)).toFixed(0);
            
        }

        if (fieldToUpdate === 'duration') {
            console.log('!@#$%!@#%!^%#$ this.timedEvent.duration: ' + this.timedEventObj.duration);
            if (!this.timedEventObj.duration) {
                this.$set(this.timedEventObj, 'duration', ((this.timedEventObj.end_unix_timestamp / 60000) - (this.timedEventObj.start_unix_timestamp / 60000)).toFixed(0));
               
            }
            let milisecondDuration = (parseInt(this.timedEventObj.duration) * 60000).toFixed(0); 
            console.log("updateEventTime called with new duration of: " + this.timedEventObj.duration + ' minutes, which equals ' + milisecondDuration + ' miliseconds');  
            
            this.timedEventObj.start_unix_timestamp = new Date(this.timedEventObj.start).getTime(); 
            console.log('start_unix_timestamp: ' + this.timedEventObj.start_unix_timestamp + '\n\t end_unix_timestamp: ' +  this.timedEventObj.end_unix_timestamp );
            this.timedEventObj.end_unix_timestamp = new Date(parseInt(this.timedEventObj.start_unix_timestamp) + parseInt(milisecondDuration)).getTime();
            console.log('\n\t end_unix_timestamp with duration: ' +  this.timedEventObj.end_unix_timestamp + '(duration)' + milisecondDuration );
            this.timedEventObj.endISO = new Date(this.timedEventObj.end_unix_timestamp).toISOString();   
            console.log('endISO: ' + this.timedEventObj.endISO);

            // this.timedEventObj.end = this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.end_unix_timestamp);   
            this.$set(this.timedEventObj, 'end', this.$datedJS.dated.getLocalISODateTime(this.timedEventObj.end_unix_timestamp));
            
        }
    },
    updateTaskProperties(event) {
        if(event) {
            switch (event.status_id) {
                case 4:
                case 6: event.progress_percent = 0;
                    break;
                case 10: event.progress_percent = 100;
                    break;

            }
            // Object.assign(this.tempEventObj, event);
            // this.tempEventObj = this.$arrayObjUtils.arrayObjsDeepCopy(event);
        }
    },
    getEventColor(event) {
            
        if ((event.index === this.selectedEventIndex) && (!event.hasOwnProperty('tempObj'))) {
            return "rgba(96, 96, 96, .9)";
        }
        if(event.status_id === 10) {
            return "rgba(13, 64, 13, .75)";
        }else if (event.status_id === 6) {
            return "rgba(128, 13, 13, .85)"; 
        } else if(event.priority_lvl_id > 1) {
            return this.getTaskPriorityLevels[parseInt(event.priority_lvl_id) - 1].levelColor;
        } else if (event.type_id) {
            return this.getTaskTypes[event.type_id].typeColor;
        } else {
            return this.getTaskPriorityLevels[this.defaultPriorityLevel].levelColor;
        }
    },
    closeEventEdit() {

    },
    cancelEdit() {
        // this.textField = this.originalValue;
        // this.parentMethod(this.objToEdit, this.originalValue);
        this.toggleDataEntryFormOpened();
        this.$emit("closeTaskForm", this.task2DoFormProp, false);
    },
    async addTimedEvent(event) {
        event.created_on = this.$datedJS.dated.getLocalISODateTime(this.$datedJS.dated.rightNow);
        event.edited_on = this.$datedJS.dated.getLocalISODateTime(this.$datedJS.dated.rightNow);

        console.log("\n\t\tevent.start from addEvent method: " + event.start + "\n\n");
        console.log("\n\t\tevent.end from addEvent method: " + event.end + "\n\n");

        console.log("\n\t\tevent.startISO from addEvent method: " + event.startISO + "\n\n");
        console.log("\n\t\tevent.endISO from addEvent method: " + event.endISO + "\n\n");

        // event.start =  new Date(event.startISO).getTime();
        // event.end =  new Date(event.endISO).getTime();
        // event.start_unix_timestamp = event.start;
        // event.end_unix_timestamp = event.end;

        console.log("start time: " + event.start);
        console.log("end time:: " + event.end);

        console.log("\n\t\ttempEventObj.start_unix_timestamp from update-Event method: " + event.start_unix_timestamp + "\n\n");
        console.log("\n\t\tevent.start_unix_timestamp from update-Event method: " + event.start_unix_timestamp + "\n\n");

        // const newEvent = await this.$arrayObjUtils.arrayObjsDeepCopy(event);
        // this.events.push(newEvent);

        // await this.events.push(newEvent);
        // event.index = this.events.length + this.dailyEvents.length - 1;
        // let newIndex = this.events.indexOf(newEvent);

        // console.log("New event pushed to array: " + JSON.stringify(this.events[newIndex], null, 2) + " has index of: " + newIndex);

        await this.createTask2Do(event);
        
        let frequentFieldsObj = {
            name: event.name,
            wantOrNeed: event.want_or_need,
            taskTypeId: event.type_id,
            priorityLevelId: event.priority_lvl_id
        };

        if(event.name !== 'Event name') {
            this.updateFrequentInputFields(frequentFieldsObj);
        }
        // this.$emit('refreshData', true);
        await this.refreshDataFromAPI();
        this.toggleDataEntryFormOpened();
        this.$emit('closeTimedEventForm');
 
    },
    async updateTimedEvent(event) {
        event.start_unix_timestamp = new Date(event.start).getTime();
        event.end_unix_timestamp = new Date(event.end).getTime();
        event.startISO = this.$datedJS.dated.getLocalISODateTime(event.start);
        event.endISO = this.$datedJS.dated.getLocalISODateTime(event.end);

        event.edited_on = this.$datedJS.dated.getLocalISODateTime(this.$datedJS.dated.rightNow);
        
        console.log("\n\t\tevent.start_unix_timestamp from update-Event method: " + event.start_unix_timestamp + "\n\n");
        console.log("\nevent.start from update event method: " +  this.$datedJS.dated.getLocalISODateTime(event.start_unix_timestamp));
        // event.end = this.$datedJS.dated.getLocalISODateTime(event.start)+ "\n");

        console.log("\n\t\tevent.startISO from update-Event method: " + event.startISO + "\n\n");
        // event.endISO = this.$datedJS.dated.getLocalISODateTime(event.end_unix_timestamp);
        // event.start = this.$datedJS.dated.getLocalISODateTime(event.start_unix_timestamp); //new Date(event.startISO).getTime();
        // event.end =  this.$datedJS.dated.getLocalISODateTime(event.end_unix_timestamp); //new Date(event.endISO).getTime();

        console.log("Event index from updateEvent object: " + event.index + "\n");
        
        // event.start = event.startISO;
        // event.end = event.endISO;
        const eventIndex = event.index;

        // this.events[eventIndex] = this.$arrayObjUtils.arrayObjsDeepCopy(event);
        // const updatedEvent = this.$arrayObjUtils.arrayObjsDeepCopy(event);

        // console.log("PUTTING TING THE FOLLOWING UPDATED TASK OBJECT: " + JSON.stringify(this.events[eventIndex], null, 2));
        console.log("PUTTING TING THE FOLLOWING UPDATED TASK OBJECT: " + JSON.stringify(event, null, 2));
        
        await this.updateTask2Do(event);
        await this.refreshDataFromAPI();
        // this.$emit('refreshData', true);
        this.toggleDataEntryFormOpened();
        this.$emit('closeTimedEventForm');
    }
  }
};
</script>
