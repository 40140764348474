<template>
    <v-container class="widget" :class="[ minimizeState ? minimizeClassName : ' ',
        maximizeState ? maximizeClassName : ' ', focusedWidget === 'TasksWidget' ? ' focusedWidget ' : ' backgroundWidget ', fixedWidget ? ' fixedWidget ' : '' ]" >
		<div style="position: absolute; display: block; background: unset; opacity: 0; left: 0; top:0; width: 100%; height: 100%; z-index: 1500;"
			v-if="focusedWidget !== 'TasksWidget'" 
			@click.stop="$emit('bring-to-front', 'TasksWidget')">
		</div>

        <v-card class="widget-window-outer-container" :class="[ fixedWidget ? ' fixed-widget ' : '']" style="background: rgba(26, 26, 26, .95);">
            <!-- <WindowBar :widgetLabel="widgetLabel"
                        :minimizeState="minimizeState"
                        v-on:minimize="minimize"
                        :maximizeState="maximizeState"
                        v-on:maximize="maximize"
                        :notifications="notificationsList"
                        :notificationType="notificationString" /> -->

            <SystemBar :widgetLabel="widgetLabel"
                :minimizeState="minimizeState"
                v-on:minimize="minimize"
                :maximizeState="maximizeState"
                v-on:maximize="maximize"
                v-on:closeWidget="closeWidget"
                :notifications="notificationsList"
                :notificationType="notificationString"
                :fixedWindow="fixedWidget" />

            <v-container id="tasksWidgetContainerId" class="widget-inner-container" style="max-height: calc(90vh - 1rem); height: 100%; margin: -0.125rem auto .5rem; padding: .125rem;">
            
                <v-row>
                    <v-col cols="12">
                    <!-- <v-sheet align="left" style="overflow-y: auto;">  -->
                    <!-- <div v-for="task in allTasks2Do" v-bind:key="task.id" class="task2Dos">
                        <span> {{ task.name }} </span><br class="" />
                    </div> -->
                    <Categories
                        :addSubCat="addSubCat"
                        :addTask="addTask"
                        :editTaskCat="editTaskCat"
                    />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Categories from "@/components/Categories";
import WindowBar from "@/components/widgets/components/WindowBar.vue";
import SystemBar from "@/components/widgets/components/SystemBar.vue";

export default {
    name: "TasksWidget",
    components: {
        WindowBar,
        SystemBar,
        Categories
    },
    props: [
        "focusedWidget",
        "fixedWidget",
        "task2DoCats",
        "addSubCat",
        "addTask",
        "editTaskCat",
        "priorityLevelLookup"
    ],
    data: () => ({
        widgetLabel: "Task2Do(s)",
        componentName: "TasksWidget",
        minimizeInBackground: true,
        minimizeInForeground: false,
        minimizeClassName: "minimize-widget",
        maximizeClassName: "maximize-widget",
        maximizeState: false,
        notificationsList: [],
        notificationString: "",
        
    }),
    computed: {
        ...mapGetters( 'tasks', {
            allTasks2Do: 'allTasks2Do',
            calendarTasks2Do: 'calendarTasks2Do',
            taskPriorityLevels: 'taskPriorityLevels',
            taskTypes: 'taskTypes',
            taskStatusTypes: 'taskStatusTypes',
            emptyTaskObj: 'emptyTaskObj',
            newCalendarEventObj: 'newCalendarEventObj',
            wantVsNeedOptions: 'wantVsNeedOptions',
            
        }),
        minimizeState() {
            // basically apply foreground state when focused, otherwise apply background state
           
            return (this.focusedWidget === this.componentName) ? (this.minimizeInForeground === true ? true : false) : (this.minimizeInBackground === true) ? true : false; 
        }

    },
    created() {
        // this.fetchTasks2Do();
        console.log('created called in Tasks.vue');
       
    },
    mounted() {
      
    },
    updated: function() {
        if (this.focusedWidget !== this.componentName) {
            // This resets the foreground state to ensure widget isn't minimized when regaining focus
            this.minimizeInForeground = false;
        }
                
    },
    methods: {
        ...mapActions( 'tasks', {
            createTask2Do: 'createTask2Do',
            updateTask2Do: 'updateTask2Do'
        }),
        ...mapActions( 'uiState', {
            setFocusedWidget: 'setFocusedWidget',
            toggleShowWidget: 'toggleShowWidget'
        }),
        
        minimize() {
            this.minimizeInForeground = !this.minimizeInForeground;
            this.minimizeInBackground = this.minimizeInForeground;

            if (this.maximizeState === true) {
                this.maximizeState = false;
            }
        },
        maximize() {
            if (this.maximizeState === false) {
                this.maximizeState = true;
                this.minimizeInForeground = false;
                this.minimizeInBackground = this.minimizeInForeground;
            } else {
                this.maximizeState = false;
            }
        },
        closeWidget() {
            this.toggleShowWidget(this.componentName);
            // if (this.t2doDisplaySettings.componentsDisplayState.focusedWidget === this.name) {
                /******************************************************************
                 * // TODO: the open widgets should be stored in an array instead of
                 * keeping track of a xcrap ton a variables. This will also have the 
                 * benefit of moving focus to the next widget.
                 * *****************************************************************/
            //     this.setFocusedWidget(this.name);
            // }

        },
    }
};
</script>

<style>

#tasksWidgetContainerId {
  max-height: calc(100% - 3rem); 
  height: 100%;
  overflow-y: auto; 
  margin: 1.0rem auto 1rem;
  padding: .125rem .25rem;

}



</style>
