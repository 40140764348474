var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.taskType === 'category')?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-arrow-expand-right" : "mdi-arrow-collapse-right")+" ")]):_vm._e(),_c('v-tooltip',{staticStyle:{"z-index":"10000"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((item.type === 'task') || (item.type === 'todo'))?_c('v-icon',_vm._g({model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}},on),[_vm._v(" mdi-format-list-checks ")]):(item.type === 'note')?_c('v-icon',_vm._g({model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}},on),[_vm._v(" mdi-note-text ")]):(item.type === 'event')?_c('v-icon',_vm._g({model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}},on),[_vm._v(" mdi-calendar-text ")]):(item.type === 'daily')?_c('v-icon',_vm._g({model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}},on),[_vm._v(" mdi-calendar-today ")]):(item.type === 'want2Do')?_c('v-icon',_vm._g({model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}},on),[_vm._v(" mdi-emoticon-poop ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$jsUtils.capitalizeString(item.type)))])])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.taskType === 'category')?_c('span',[_c('v-btn',{staticStyle:{"display":"inline"},attrs:{"data-keypath":item.keyPath},on:{"click":function($event){return _vm.addSubCat(item)}}},[_vm._v(_vm._s(item.keyPath)+" - Add Sub Category")])],1):_vm._e()]}}])},[_c('v-btn',{on:{"click":function($event){return _vm.addRootCat(_vm.task2DoCategories)}}},[_vm._v("Add Root Category")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }