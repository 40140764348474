<template>
  <div>
    <h1 style="display: none;">This is the members only area!</h1>
  </div>
</template>

<script>
export default {
  name: "restricted"
};
</script>
