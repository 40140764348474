<template>
    <!-- <div id="task2do-settings" class="task2do-app-view" style="overflow: hidden; z-index: 500;"> -->
    <v-dialog
        id="task2do-settings"
        class="task2do-app-settings-view"
        v-model="showAppSettingsWindow"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
        <v-card tile class="settings-widget">
        <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="$emit('closeSettings')">
            <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Settings</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <v-btn dark text @click="putUserPreferences()">Save</v-btn>
            </v-toolbar-items>
            <v-menu bottom right offset-y>
            <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(item, i) in items" :key="i" @click="() => {}">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>
        </v-toolbar>
        <v-card-text>
            <v-btn color="primary" dark class="ma-2" @click="dialog2 = !dialog2">
            Open Dialog 2
            </v-btn>
            <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-btn class="ma-2" v-on="on">Tool Tip Activator</v-btn>
            </template>
            Tool Tip
            </v-tooltip>
            <v-list three-line subheader>
            <v-subheader>App Data Settings</v-subheader>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Task Types</v-list-item-title>
                <v-list-item-subtitle
                    >Set the content filtering level to restrict apps that can be
                    downloaded</v-list-item-subtitle
                >
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Priority Levels</v-list-item-title>
                <v-list-item-subtitle
                    >Specify custom priority level lables that you would like
                    displayed instead of the defaults.
                </v-list-item-subtitle>
                <v-list-item-action>
                    <v-btn
                    color="primary"
                    dark
                    class="ml-2"
                    @click="dialog2 = !dialog2"
                    >Restore Default</v-btn
                    >
                    <v-list-item-subtitle
                    >Reset the priority level labels to their default
                    values.</v-list-item-subtitle
                    >
                </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Password</v-list-item-title>
                <v-list-item-subtitle
                    >Change your password. (This requires reentry of your current
                    password &amp; 2-factor authentication.)</v-list-item-subtitle
                >
                </v-list-item-content>
            </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list three-line subheader>
            <v-subheader><h2>Display Settings</h2></v-subheader>

            <v-list-item>
                <v-list-item-content>
                <v-list-item-title><h3>Background Image</h3></v-list-item-title>
                <v-list-item-subtitle
                    ><p>
                    Choose the type of background image you would like for your
                    home screen:
                    </p></v-list-item-subtitle>

                <v-radio-group v-model="backgroundImageType">
                        <v-tooltip transition="fade-transition" open-delay="50" close-delay="250" right>
                            <template v-slot:activator="{ on }">    
                                <v-btn style="display:flex; justify-content: left;" text v-on="on">
                                    <v-radio label="Static" value="static"></v-radio>
                                </v-btn>
                            </template>
                            <span> {{ getAppData.settingsWindow["Tooltips"]["StaticBackgroundTip"] }} </span>
                        </v-tooltip>

                        <v-tooltip transition="fade-transition" open-delay="50" close-delay="250" right>
                            <template v-slot:activator="{ on }">    
                                <v-btn style="display:flex; justify-content: left;" text v-on="on">
                                    <v-radio id="animationRadioId" label="Animated" value="animated" :disabled="!userAcceptedAnimationRisk"></v-radio>
                                </v-btn>                        
                            </template>
                            <span> {{ getAppData.settingsWindow["Tooltips"]["EnableAnimationNotice"] }} </span>
                        </v-tooltip>
                    </v-radio-group>
                    
                <v-list-item-subtitle>
                    
                    <v-switch
                    v-model="userAcceptedAnimationRisk"
                    @change="animMsgSwitchChanged($event)"
                    :label="`${[
                        userAcceptedAnimationRisk
                            ? 'You acknowledged the risks of enabling a distracting feature for individuals that suffer from ADHD. Anyone who is more susceptible to distractions is also at risk for being less productive. You\'ve been warned!'
                            : 'You have wisely choosen not to enable a distracting visual settings at the cost of actually getting something done.'
                        ]} `"
                    
                    ></v-switch>
                
                </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

                <v-list-item>
                    <v-list-item-action>
                    <v-checkbox v-model="timeOfDayImageAdjustEnabled"></v-checkbox>
                    </v-list-item-action>
                </v-list-item>

                    <v-list-item-content>
                        <v-list-item-title> <h3>Current Settings Values</h3> </v-list-item-title>
                            <v-list-item-subtitle> *Need to move to Vuex before enabling changes </v-list-item-subtitle>

                            <v-list>
                                <ul>
                                    <li class="settingsList">Background: <span class="settingsValue"> {{ t2doDisplaySettings.background.backgroundSelection }} </span></li>

                                    <li class="settingsList">Dark Background enabled: <span class="settingsValue"> {{ t2doDisplaySettings.background.makeItDark }} </span></li>

                                    <li class="settingsList">Blur Background enabled: <span class="settingsValue"> {{ t2doDisplaySettings.background.blurrBackground }} </span></li>

                                    <li class="settingsList">Animated Background enabled: <span class="settingsValue"> {{ t2doDisplaySettings.background.animatedBackground }} </span></li>
                                </ul>
                        </v-list>

                    </v-list-item-content>


                <v-divider></v-divider>            

                <v-list-item>

                <v-list-item-content>
                <v-list-item-title
                    ><h3>
                    Time-of-Day Dynamically Adjusted Visuals
                    </h3></v-list-item-title
                >
                <v-list-item-subtitle>This feature will adjust various display settings and styling
                    to coincide with the tim eof day. This helps to reduce eyestrain
                    and give a more optimal viewing experience. Some of the more
                    'extreme' adjustments can be enabled/disabled sepratly. E.g.,
                    the background colors are inverted and brightness turned way
                    down to give a much darker apperance for viewing at
                    night.</v-list-item-subtitle
                >
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-action>
                <v-checkbox v-model="sound"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                <v-list-item-title>Sound</v-list-item-title>
                <v-list-item-subtitle
                    >Auto-update apps at any time. Data charges may
                    apply</v-list-item-subtitle
                >
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                <v-checkbox v-model="widgets"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                <v-list-item-title>Auto-add widgets</v-list-item-title>
                <v-list-item-subtitle
                    >Automatically add home screen widgets</v-list-item-subtitle
                >
                </v-list-item-content>
            </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list three-line subheader>
            <v-subheader>Categories and Subject Definitions</v-subheader>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Task Cats</v-list-item-title>
                <v-list-item-subtitle
                    >Task and Notes category definitions
                </v-list-item-subtitle>
                <Categories />
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Blog Subs</v-list-item-title>
                <v-list-item-subtitle
                    >Task, note and blog subjects
                </v-list-item-subtitle>
                <Subjects />
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Bookmark Categories</v-list-item-title>
                <v-list-item-subtitle
                    >Bookmark categories...duhhyeee
                </v-list-item-subtitle>
                <Categories />
                </v-list-item-content>
            </v-list-item>
            </v-list>
        </v-card-text>

        <div style="flex: 1 1 auto;"></div>
        </v-card>

        <!-- </div> -->
    </v-dialog>
</template>
<script>
    import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
    import Categories from "../components/Categories";
    import Subjects from "../components/Subjects";

    export default {
    name: "AppSettings",
    components: {
        Categories,
        Subjects
    },
    props: ["showAppSettingsWindow"],
    computed: {
        ...mapGetters('appData', {
           getAppData: 'getAppData'
        }),
        ...mapGetters('userSettings', {
        t2doAllSettings: 't2doAllSettings',
        t2doDisplaySettings: 't2doDisplaySettings',
        getUserPreferences: 'getUserPreferences',
        
            
        }),
        ...mapGetters('uiState', {
            getAppState: 'getAppState',           
            isInputFieldFocused: 'isInputFieldFocused',  
        })
    },
    data: () => ({
        /* data () {
        return { */
        userAcceptedAnimationRisk: false,
        timeOfDayImageAdjustEnabled: false,
        backgroundImageType: "static",
        staticChecked: true,
        animatedChecked: false,
        dialog: true,
        dialog2: false,
        dialog3: false,
        notifications: false,
        sound: true,
        widgets: false,
        items: [
            {
                title: "Click Me"
            },
            {
                title: "Click Me"
            },
            {
                title: "Click Me"
            },
            {
                title: "Click Me 2"
            }
        ],
        select: [
            { text: "State 1" },
            { text: "State 2" },
            { text: "State 3" },
            { text: "State 4" },
            { text: "State 5" },
            { text: "State 6" },
            { text: "State 7" }
        ]
        //   }
    }),
    methods: {
        ...mapActions( 'userSettings', {
            putUserPreferences: 'putUserPreferences'
        }),
        animMsgSwitchChanged(isChecked) {
            console.log("userAcceptedAnimationRisk: " + this.userAcceptedAnimationRisk);

        this.staticChecked = true;
        if (this.userAcceptedAnimationRisk === false) {
            console.log("value passed to animMsgSwitchChanged: " + isChecked);
            console.log(this.backgroundImageType);
            this.backgroundImageType = "static";
            this.$emit("input", "static");
        }
        }
    }
    };
</script>

<style>
    #task2do-app-id .task2do-cats .v-treeview-node__label {
        text-align: left;
        height: 2rem;
    }

    #task2do-app-id .task2do-cats .v-icon--link {
        font-size: 0.95rem;
        padding: 0;
        margin: 0;
        border: 1px dashed red;
        text-indent: unset;
        width: 1.125rem;
        height: 1.125rem;
    }

    #task2do-app-id .settingsList {
        text-align: left;
        font-family: "Roboto", sans-serif;
        font-family: Raleway;
        /* font-variant: small-caps; */
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: .125rem;
        height: 1.75rem;
    }

    #task2do-app-id .settingsList .settingsValue {
        font-family: Raleway;
        font-family: "Roboto", sans-serif;
        font-variant: small-caps;
        font-size: 1.5rem;
        font-weight: 900;
    }

    .v-list--three-line .v-list-item,
    .v-list-item--three-line {
        min-height: 2rem;
        /* max-height: 4rem; */
    }

    .v-treeview-node__root {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-height: 1.75rem;
        padding-left: 1rem;
        padding-right: 8px;
        position: relative;
    }
</style>