<template>
  <v-sheet class="user-account-container">
    <v-toolbar flat color="rgba(13, 13, 13, .9)">
      <h3 class="mr-4" align="center">User Registration</h3>
      <br class="" />

      <v-btn
        @click="$router.push({ name: 'Home' })"
        outlined
        class="container-close-btn  TM__red-glowing-outline"
        >X</v-btn
      >
    </v-toolbar>
    <v-card>
      <v-container class="form-container"> <!-- style="overflow: auto;"> -->
        <v-form @submit.prevent="registerUser">
          <!--{{ backendSelection }}"> -->
          <fieldset class="ts-c-user-forms">
            <legend>User Register</legend>
            <div class="TS L-form-group">
              <v-text-field
                v-model="screen_name"
                type="text"
                required
                autofocus
                label="Screen Name (required)"
              ></v-text-field>
              <!-- <span><i class="fas fa-user-tag"></i></span> -->
              <v-text-field
                v-model="password"
                type="password"
                required
                label="Password"
              ></v-text-field>
              <!-- <span><i class="fas fa-lock"></i></span><br class=""> -->

              <v-text-field
                v-model="password_confirm"
                type="password"
                required
                label="password-confirm"
              ></v-text-field>
              <!-- <span><i class="fas fa-lock"></i></span><br class=""> -->
              <v-text-field
                v-model="first_name"
                type="text"
                required
                label="First Name"
              >
              </v-text-field>
              <!-- <span><i class="fas fa-user-edit"></i></span><br class=""> -->
              <v-text-field
                v-model="last_name"
                type="text"
                required
                label="Last Name"
              >
              </v-text-field>
              <!-- <span><i class="fas fa-user-edit"></i></span><br class=""> -->
              <v-text-field
                v-model="email1"
                type="text"
                required
                label="Primary Email"
              >
              </v-text-field>
              <!-- <span><i class="fas fa-envelope"></i></span><br class=""> -->
              <v-text-field
                v-model="email2"
                type="text"
                label="Secondary Email (optional)"
              >
              </v-text-field>
              <!-- <span><i class="fas fa-envelope"></i></span><br class="">                   -->
            </div>
            <div class="TS L-form-group">
              <v-text-field
                v-model="avatar_url"
                type="text"
                label="Enter the path to your avatar image (optional)"
              >
              </v-text-field>
              <span><i class="fas fa-user-cog"></i></span> <br class="" />
              <v-text-field
                v-model="about"
                type="text"
                label="Write a little about yourself"
              >
              </v-text-field>
              <span><i class="fas fa-user-cog"></i></span> <br class="" />
            </div>

          </fieldset>
        </v-form>
           <v-divider></v-divider>
                    <v-card-actions class="form-button-group centered">
                      <v-btn type="submit" class="form-button TM__blue-glowing-outline ">Register</v-btn>
                    </v-card-actions>
                    
              <!-- @click.stop='registerUser'>Register</v-btn> -->
            
            
      </v-container>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  // data: () => ({
  //   first_name: null,
  //   last_name: null,
  //   screen_name: null,
  //   password: null,
  //   email1: null,
  //   email2: null,
  //   avatar_url: null,
  //   about: null
  // }),
  data: function() {
    return {
      first_name: "",
      last_name: "",
      screen_name: "",
      password: "",
      password_confirm: "",
      email1: "",
      email2: "",
      avatar_url: "",
      about: "",
      is_admin: null
    };
  },
  methods: {
    closeForm: function() {
      this.$emit("toggle-data-prop", "RegisterUserDialogOpen");
    },

    registerUser: function() {
      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        screen_name: this.screen_name,
        password: this.password,
        email1: this.email1,
        email2: this.email2,
        avatar_url: this.avatarUrl,
        about: this.about
      };
      this.$store
        .dispatch("registerUser", data)
        .then(() => this.$router.push("/login"))
        .catch(err => console.log(err));
    }
  }
};

//       }
//       async registerUser() {
//       if(this.screen_name && this.email1 && this.password) {
//           let serializedData = JSON.stringify({   first_name: this.first_name,
//                                                   last_name: this.last_name,
//                                                   screen_name: this.screen_name,
//                                                   email1: this.email1,
//                                                   password: this.password
//                                                   });
//           let fetchParams = {
//               method: 'POST',
//               headers:{
//               "Content-Type":"application/json; charset=UTF-8"
//               },
//               body: serializedData
//               };
//           let response = await fetch(`https://ts-api.t2do.io/t2do-api/users/register`, fetchParams);
//           window.console.log("Response from API" + response.json());
//                   this.first_name = "";
//                   this.last_name = "";
//                   this.screen_name = "";
//                   this.email1 = "";
//                   this.password = "";
//       } else {
//           alert('Please fill in the required fields.');
//       }

//       }
//     }
//  }
</script>
