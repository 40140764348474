import Vue from "vue";
// import axios from "axios";

// import store from "../../store";

import * as arrayObjUtils from "../../lib/arrayObjUtils.js";
import * as jsUtils from "../../lib/jsUtils.js";
import * as datedJS from "../../lib/dated.js";



// import vue as vue;
// const jsUtils = vm.$jsUtils;
function typecastTasks2DoResponse(apiResponse) {
    // console.log("apiTypeCastFunction timed property: " + JSON.stringify(apiResponse, null, 2));
    let indexNum = 0;
    console.log('F A L S E, fetchTasks2Do apiResponse: ' + apiResponse);
    if(!apiResponse) {
        return false;
    }

    apiResponse.forEach(task => {
        task['index']= apiResponse.indexOf(task);
        // console.log('apiResponse task' + task.name + ' has a index of: ' + task['index']);
        task.timed = (task.timed === "1") ? true : false;
        task.is_archived = (task.is_archived === "1") ? true : false;
        task.is_finished = (task.is_finished === "1") ? true : false;
        task.has_alert = (task.has_alert === "1") ? true : false;
        task.has_push_notification = (task.has_push_notification === "1") ? true : false;
        task.priority_lvl_id = parseInt(task.priority_lvl_id);
        task.type_id = parseInt(task.type_id);
        task.progress_percent = parseInt(task.progress_percent);
        task.status_id = parseInt(task.status_id);
        
        task.start_unix_timestamp = new Date(task.start).getTime(); // parseInt(task.start_unix_timestamp);
        task.end_unix_timestamp = new Date(task.end).getTime();     //parseInt(task.end_unix_timestamp);
        task.duration = parseInt(task.duration);
        
        if (task.data) {
            task.data = JSON.parse(task.data);
        }
        task.time_data = {};
        task.time_data['times'] = [];
        task.time_data['times'][0] = {};
        task.time_data['times'][0]['prepTime'] = 0;
        task.owner_screen_name = jsUtils.capitalizeString(task.owner_screen_name);
        // task[`${vm.$jsUtils.capitalizeString(task.owner_screen_name)}`] = true;

        // task.start = new Date(task.start).getTime();
        // task.end = new Date(task.end).getTime();
    });

    return apiResponse;
}

function typecastPriorityLevelsResponse(apiResponse) {
    const maxPriorityLevels = 10;
    
    for (let i = 0; i < apiResponse.task_priority_types.length; i++) {
        // if (i < maxPriorityLevels) {
            apiResponse.task_priority_types[i].priority_level_id = parseInt(apiResponse.task_priority_types[i].priority_level_id);
            apiResponse.task_priority_types[i].priority_level_num = parseInt(apiResponse.task_priority_types[i].priority_level_num);
        // } else {
            // apiResponse.task_priority_types.splice(i, 1);
        // }

    }

    // apiResponse.task_priority_types.forEach( priorityLevel => {
    //     priorityLevel.priority_level_id = parseInt(priorityLevel.priority_level_id);
    //     priorityLevel.level_num = parseInt(priorityLevel.level_num);

    // });

    return apiResponse;
}

function typecastTaskTypesResponse(apiResponse) {
    let taskTypes = [];
    // console.log("apiResponse.task_types: " +  JSON.stringify(apiResponse.task_types, null, 2));
    console.log("apiResponse.task_types.length: " + apiResponse.task_types.length);
    apiResponse.task_types.forEach( taskType => {
        taskType.type_id = parseInt(taskType.type_id);
        // let arrayIndex = 'T' + taskType.type_id;
        taskTypes[taskType.type_id] = taskType;

    });
    console.log("task_types.length: " + taskTypes.length);
    for (let i = 0; i < taskTypes.length; i++) {
        if (taskTypes[i] === undefined) {
            // taskTypes.splice(i, 1); //[i] = null;
            taskTypes[i] = {};
            taskTypes[i].type_id = 0;
            taskTypes[i].type_name = 'Undefined task type';
            taskTypes[i].type_desc = 'This task type has not been set';
            taskTypes[i].type_color = 'rgba(0, 0, 0, 0)';

        }
    }
    return taskTypes;
}

function adjustLevelRGB( rgbaString = '', factor = 1 ) {
    console.log('rgbaString: ' + rgbaString);
    console.log('typeof(rgbaString): ' + typeof(rgbaString));

    rgbaString = rgbaString.replace('rgba(', '');
    rgbaString = rgbaString.replace(')', '');
    rgbaString = rgbaString.replace(' ', '');

    
    let rgbElements = rgbaString.split(',');
    console.log('rgbaElements: ' + rgbElements);

    let red = parseInt(rgbElements[0]);
    let green = parseInt(rgbElements[1]);
    let blue = parseInt(rgbElements[2]);
    let alpha = parseFloat(rgbElements[3]);
    console.log('red: ' + red + 'green: ' + green + 'blue: ' + blue);
    red = red * factor;
    green = green * factor;
    blue = blue * factor;
    console.log('red: ' + red + 'green: ' + green + 'blue: ' + blue);

    rgbaString = 'rgba(' + red.toFixed(0) + ', ' + green.toFixed(0) + ', ' + blue.toFixed(0) + ', ' + alpha + ')';
    console.log('rgbaString: ' + rgbaString);
    return rgbaString;

}

const state = {
    
    tasks2Do: [],
    todaysTasks: [],
    selectedTaskId: null,
    selectedTask: {},
    emptyTaskObj: {
        alert_id: null,
        name: 'Event name',
        body: null,
        categories: null,
        color: '',
        created_by_id: null,
        created_for_id: null,
        created_on: null,
        edited_on: null,
        start: 0,
        start_unix_timestamp: 0,
        startISO: '',
        end: 0,
        end_unix_timestamp: 0,
        endISO: '',
        data: null,
        duration: null,
        finished_on: null,
        has_alert: false,
        has_push_notification: false,
        id: '',
        index: null,
        in_reference_to: null,
        is_archived: false,
        is_finished: false,
        nested_level: null,
        note: '',
        owner_screen_name: null,
        parent_id: '',
        priority_lvl_id: 1,
        progress_percent: 0,
        status_id: 1,
        subject: null,
        summary: null,
        timed: null,
        time_data: {
            timed: null,
            times: [
                {
                    start: null,
                    end: null,
                    start_unix_timestamp: 0,
                    end_unix_timestamp: 0,
                    prep_time: 0

                }
            ]
        },
        location_data: {
            origin: 'home',
            destination: {
                id: '',
                name: '',
                streetLine1: '',
                streetLine2: '',
                city: '',
                state: '',
                zip: ''
            },
            travelTime: 0,
            travelDistance: ''
        },
        type_id: 1,
        tempObj: true,
        want_or_need: 'want'
    },
    emptyCalendarEventObj: {
        alert_id: null,
        body: null,
        categories: null,
        color: '',
        created_by_id: null,
        created_for_id: null,
        created_on: null,
        data: null,
        duration: null,
        edited_on: null,
        end: 0,
        end_unix_timestamp: 0,
        endISO: '',
        finished_on: null,
        has_alert: null,
        has_push_notification: null,
        id: '',
        index: null,
        in_reference_to: null,
        is_archieved: null,
        is_finished: null,
        name: null,
        nested_level: null,
        note: null,
        owner_screen_name: null,
        parent_id: null,
        priority_lvl_id: 1,
        progress_percent: 0,
        start: 0,
        start_unix_timestamp: 0,
        startISO: '',
        status_id: 1,
        subject: null,
        summary: null,
        timed: null,
        type_id: 1,
        tempObj: true,
        want_or_need: ''
    },
    // taskPropertiesArrObj: [],
    taskPropertiesArrObj: {
        taskPriorityTypes: [
            {
                levelId: 1,
                levelNum: 0,
                labelText: '',
                levelColor: 'rgba(0, 0, 127, .5)'
            }
        ]
    },
    taskTypesArrObj: [],
    wantOrNeedOptions: [  
        {
            wantNeedId: 1,
            wantNeedIndex: 2,
            wantNeedName: 'Need 2 DO',
            wantNeedDesc: 'What you really should be doing',
            wantNeedValue: 'NEED',
            wantNeedColor: 'rgba(150, 125, 25, .95)'
        },
        {
            wantNeedId: 2,
            wantNeedIndex: 1,
            wantNeedName: 'want 2 do',
            wantNeedDesc: 'What you would rather be doing',
            wantNeedValue: 'want',
            wantNeedColor: 'rgba(6, 150, 13, .95)'            
        },
        {
            wantNeedId: 3,
            wantNeedIndex: 0,
            wantNeedName: 'Should do',
            wantNeedDesc: 'What you should be doing',
            wantNeedValue: 'Should',
            wantNeedColor: 'rgba(13, 26, 150, .95)'
        },
        {
            wantNeedId: 4,
            wantNeedIndex: 3,
            wantNeedName: 'MUST DO!',
            wantNeedDesc: 'Something you must do',
            wantNeedValue: 'MUST',
            wantNeedColor: 'rgba(200, 25, 25, .95)'
        }

    ],
    hardcoded_tasks2Do: [
    {
        id: "b74f72d1-9527-45a6-b25f-6c7ea43d6056",
        category_id: "2894febb-6b24-4bfd-88cc-9f5c270d3bc6",
        created_by_id: "Mr. Ass Hat",
        created_for_id: "Him self",
        type: "want2Do",
        name: "make list of crap designs",
        summary: "no summary for crap",
        body: "Make a list, do you really need to know more than that",
        categoryKeyPath: "3c0",
        is_finished: false,
        start: "2020-07-29 09:00:00",
        end: "2020-07-29 10:00:00"
    },
    {
        id: "3e329a72-dbd9-4a02-bca7-26771bb71237",
        category_id: "2894febb-6b24-4bfd-88cc-9f5c270d3bc6",
        created_by_id: "Mr. Ass Hat",
        created_for_id: "Him self",
        type: "task",
        name: "make list of your awesome designs",
        body: "Make a list, do you really need to know more than that",
        categoryKeyPath: "3c0",
        is_finished: false,
        start: "2020-07-29 10:00:00",
        end: "2020-07-29 11:00:00"
    },
    {
        id: "9c071ead-e204-44a7-bba2-b1d892feb6a2",
        name: "Get skills",
        category_id: "087043fa-2e05-445c-b839-b32d80aee43f",
        created_by_id: "Mr. Ass Hat",
        created_for_id: "Him self",
        type: "todo",
        body: "get skills, do you really need to know more than that",
        categoryKeyPath: "2c1c0",
        is_finished: false,
        taskCategoryOrder: 1,
        priorityLevel: 7,
        taskTimeFrame: "2 months",
        start: "2020-07-23",
        end: "2020-07-23"
    },
    {
        id: "228eb459-1276-4333-b6f3-88ce9c46c851",
        name: "Get Job",
        category_id: "087043fa-2e05-445c-b839-b32d80aee43f",
        created_by_id: "Mr. Ass Hat",
        created_for_id: "Him self",
        type: "todo",
        body: "Get Job, do you really need to know more than that",
        categoryKeyPath: "2c1c0",
        is_finished: false,
        taskCategoryOrder: 2,
        priorityLevel: 8,
        taskTimeFrame: "1 month",
        start: "after_3-1",
        end: "duration_1 month"
    },
    {
        id: "4bdb37e2-ea8e-4fb2-839a-51ccf9d8fe09",
        name: "Get Loot",
        category_id: "087043fa-2e05-445c-b839-b32d80aee43f",
        created_by_id: "Mr. Ass Hat",
        created_for_id: "Him self",
        type: "todo",
        body: "Get Job, do you really need to know more than that",
        categoryKeyPath: "2c1c0",
        is_finished: false,
        taskCategoryOrder: 3,
        taskTimeFrame: "immediately",
        priorityLevel: 9,
        start: "after_2",
        // if start refers to a todo that doesn't have an explicit end, then the start for this will equal the start of the task its refering to
        end: "unset"
    },
    {
        id: "1540b854-82ba-433f-aa75-ff23c6bd1372",
        name: "Sex",
        category_id: "87cc546b-d42c-4960-8022-3fbedad31ad6",
        created_by_id: "Brando",
        created_for_id: "Me and my best good friend",
        type: "event",
        categoryKeyPath: "0c0",
        is_finished: false,
        taskCategoryOrder: 1,
        priorityLevel: 9,
        start: "2020-07-20 10:00:00",
        end: "2020-07-20 11:00:00"
    },
    {
        id: "ee1526e2-c368-4c4d-be22-81d950c4ecff",
        category_id: "87cc546b-d42c-4960-8022-3fbedad31ad6",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "event",
        name: "Sleep",
        categoryKeyPath: "0c0",
        is_finished: true,
        taskCategoryOrder: 2,
        priorityLevel: 8,
        start: "2020-09-20 11:00:00",
        end: "2020-09-20 12:00:00"
    },
    {
        id: "540c6736-d405-4292-84f0-44c9bd32b827",
        category_id: "87cc546b-d42c-4960-8022-3fbedad31ad6",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "event",
        name: "Eat",
        categoryKeyPath: "0c0",
        is_finished: false,
        taskCategoryOrder: 3,
        priorityLevel: 6,
        start: "2020-09-20 12:00:00",
        end: "2020-09-20 13:00:00"
    },
    {
        id: "c3c5ad06-df5f-4115-9ad5-f9e4f8a58bbb",
        category_id: "87cc546b-d42c-4960-8022-3fbedad31ad6",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "event",
        name: "Drink",
        categoryKeyPath: "0c0",
        is_finished: true,
        taskCategoryOrder: 4,
        priorityLevel: 4,
        start: "2020-09-20 15:00:00",
        end: "2020-09-20 17:00:00"
    },
    {
        id: "6e5317c7-6b51-4cbe-8644-0c57c639350b",
        category_id: "87cc546b-d42c-4960-8022-3fbedad31ad6",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "event",
        name: "Dream",
        categoryKeyPath: "0c0",
        is_finished: true,
        taskCategoryOrder: 5,
        priorityLevel: 0,
        start: "2020-09-20 17:00:00",
        end: "2020-09-20 20:00:00"
    },
    {
        id: "c85cd279-2860-4d2f-8dc8-e12434d24dac",
        category_id: "87cc546b-d42c-4960-8022-3fbedad31ad6",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "want2Do",
        name: "Shit",
        categoryKeyPath: "0c0",
        is_finished: true,
        taskCategoryOrder: 6,
        priorityLevel: 10,
        start: "2020-05-31 17:00:00",
        end: "2020-05-31 20:00:00"
    },
    {
        id: "1b7dcf0d-6e62-4559-ae24-17e0b3d39af2",
        category_id: "ebcd889a-040d-409b-8075-d61b3e0d43ab",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "daily",
        name: "Do picking exercises",
        categoryKeyPath: "1c1c1c0",
        is_finished: false,
        taskCategoryOrder: 1,

        start: "17:00:00",
        end: "18:00:00"
    },
    {
        id: "00b248d6-e707-42b0-abb0-53d13e3e9a84",
        category_id: "ebcd889a-040d-409b-8075-d61b3e0d43ab",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "daily",
        name: "Build up hand strength with barr chords",
        categoryKeyPath: "1c1c1c0",
        is_finished: false,
        taskCategoryOrder: 2,

        start: "18:00:00",
        end: "19:00:00"
    },
    {
        id: "7706ce11-675b-4cf6-9a9e-717827f460cb",
        category_id: "eb8e8298-9c16-43b2-ab9e-283ed4f8a3e5",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "want2Do",
        name: "Master of Puppets",
        categoryKeyPath: "1c1c2c0",
        is_finished: false,
        taskCategoryOrder: 1,

        start: "noTimeFrame",
        end: "noTimeFrame"
    },
    {
        id: "f932964a-b63e-47f6-94d4-de7ba6439103",
        category_id: "eb8e8298-9c16-43b2-ab9e-283ed4f8a3e5",
        created_by_id: "Me",
        created_for_id: "Me",
        type: "want2Do",
        name: "Call of the Kutulu",
        categoryKeyPath: "1c1c2c0",
        is_finished: false,
        taskCategoryOrder: 2,

        start: "noTimeFrame",
        end: "noTimeFrame"
    },
    {
        id: "59724f5f-df85-4f8c-b584-f51bde1b986f",
        category_id: "2894febb-6b24-4bfd-88cc-9f5c270d3bc6",
        created_by_id: "Mr. Ass Hat",
        created_for_id: "Him self",
        type: "task",
        name: "How to design shit right",
        categoryKeyPath: "3c1c0",
        is_finished: false,
        taskCategoryOrder: 1,

        start: "noTimeFrame",
        end: "noTimeFrame"
    },
    {
        id: "dcf8eae3-0614-4674-8aec-a870866f3588",
        category_id: "0a10dfc4-0e1a-4062-aa90-1aaf0fd53ae0",
        created_by_id: "Mr. Ass Hat",
        created_for_id: "Him self",
        type: "task",
        name: "Advanced techniques",
        categoryKeyPath: "3c1c0",
        is_finished: false,
        taskCategoryOrder: 2,
        start: "noTimeFrame",
        end: "noTimeFrame"
    },
    {
        id: "ef46984a-1e0f-43c3-8674-bffbd98dd216",
        category_id: "0a10dfc4-0e1a-4062-aa90-1aaf0fd53ae0",
        created_by_id: "Mr. Ass Hat",
        created_for_id: "Him self",
        type: "task",
        name: "All about app",
        categoryKeyPath: "3c1c0",
        is_finished: false,
        taskCategoryOrder: 3,
        start: "noTimeFrame",
        end: "noTimeFrame"
    }
    ]
};

const getters = {
    
    allTasks2Do: state => state.tasks2Do,
    getJournals: state => state.tasks2Do.filter(journal => journal.type_id === 20),
    getNotes: state => state.tasks2Do.filter(notes => ((notes.type_id >= 29 ) && (notes.type_id <= 36))),
    getTodaysTasks: state => state.todaysTasks,
    getSelectedTaskId: state => state.selectedTaskId,
    getSelectedTask: state => state.selectedTask,
    getEventsFromDate: (state) => (dateToGetEvents) => {
        if (!dateToGetEvents) {
            dateToGetEvents = new Date();
        }
        return state.tasks2Do.filter(task => ((datedJS.dated.getYyyyMmDdDate(task.start)) === (datedJS.dated.getYyyyMmDdDate(dateToGetEvents))));
        
    },
    getTodos: state => state.tasks2Do.filter(todo => todo.type_id === 3),
    getFinishedTodos: state => state.tasks2Do.filter(todo => ((todo.type_id === 3) && (todo.progress_percent === 100))),
    getUnFinishedTodos: state => {
        
        let unfinishedTodos = state.tasks2Do.filter(todo => ((todo.type_id === 3) && (todo.progress_percent < 100)));
        unfinishedTodos.sort(function(a, b) {
            return b.end_unix_timestamp - a.end_unix_timestamp;
        });
        return unfinishedTodos;
    },
    getDailyEvents: (state) => {
        let dailyEvents = [];
        const dailyParentEvents = state.tasks2Do.filter(todo => (state.taskPropertiesArrObj.taskTypes[todo.type_id].typeName === 'Daily'));
        console.log('dailyParentEvents: ' + dailyParentEvents);

        
        dailyParentEvents.forEach(dailyParentEvent => {

            // console.log("Daily task original start: " + task.start);

            let startDateSpan = new Date(dailyParentEvent.start).getTime();
            let endDateSpan = new Date(dailyParentEvent.end).getTime();

            // console.log("Dated miliseconds in a day: " + datedJS.dated.inMilliSeconds.day);

            let numDays = Math.ceil(((endDateSpan - startDateSpan) / datedJS.dated.inMilliSeconds.day));

            console.log("Number of days to span daily appointment: " + numDays);
            
            let startTime = datedJS.dated.getLocalISOTime(dailyParentEvent.start);
            let endTime = datedJS.dated.getLocalISOTime(dailyParentEvent.end);
            let startDate =  datedJS.dated.getLocalISODate(dailyParentEvent.start);
            let endDate =  datedJS.dated.getLocalISODate(dailyParentEvent.end);
            let endDurationMilliSeconds = 0;
            
            if (parseInt(endTime) < parseInt(startTime)) {
                let durationDate = datedJS.dated.getLocalISODate(startDateSpan + datedJS.dated.inMilliSeconds.day);
                let endDailyDuration = durationDate + " " + endTime;

                console.log("end duration: " + endDailyDuration);
                endDurationMilliSeconds = new Date(endDailyDuration).getTime();

            } else {
                let durationDate = datedJS.dated.getLocalISODate(startDateSpan);
                let endDailyDuration = durationDate + " " + endTime;

                console.log("end duration: " + endDailyDuration);
                endDurationMilliSeconds = new Date(endDailyDuration).getTime();
            }
            let duration = endDurationMilliSeconds - startDateSpan;

            console.log("Task start date: " + startDate + "\tTask start time: " + startTime + " \n\tDailyTask end date: " + endDate + "\tDaily task end time: " + endTime + "\n");

            for(let i = 0; i < numDays; i++) {
                let newDailyEvent = arrayObjUtils.arrayObjsDeepCopy(dailyParentEvent);
                let dailyEventStartTime = startDateSpan + (datedJS.dated.inMilliSeconds.day * i)
                newDailyEvent.startISO = datedJS.dated.getLocalISODateTime(dailyEventStartTime);
                
                // console.log("newDailyvent.start = " + newDailyEvent.start);
                
                newDailyEvent.endISO = datedJS.dated.getLocalISODateTime(dailyEventStartTime + duration);
                
                // console.log("newDailyvent.end = " + newDailyEvent.end);
                // console.log("\nnewDailyvent: " + JSON.stringify(newDailyEvent, null, 2));
                
                newDailyEvent.start_unix_timestamp = new Date(newDailyEvent.startISO).getTime();
                newDailyEvent.end_unix_timestamp = new Date(newDailyEvent.endISO).getTime();

                dailyEvents.push(newDailyEvent);
               
            }

        });
        return dailyEvents;
    },
    getEmptyTaskObj: state => state.emptyTaskObj,
    getNewCalendarEventObj: state => state.emptyCalendarEventObj,
    // getTaskPropertiesArrObj: state => state.taskPropertiesArrObj,
    getTaskPriorityLevels: state => state.taskPropertiesArrObj.taskPriorityTypes,
    getTaskTypes: state => state.taskPropertiesArrObj.taskTypes,
    getTaskStatusTypes:  state => state.taskPropertiesArrObj.taskStatusTypes,
    getTaskInportanceTypes: state => state.taskPropertiesArrObj.taskImportanceTypes,
    getWantVsNeedOptions: state => state.wantOrNeedOptions,
    getCalendarEvents2Do: state => {
        const nonrecurringCalendarEvents = state.tasks2Do.filter(todo => (state.taskPropertiesArrObj.taskTypes[todo.type_id].typeName !== 'Daily'));
        return nonrecurringCalendarEvents.map(arrayObjUtils.reduceObjectProperties('id', 'index', 'name', 'created_by_id', 'owner_screen_name', 'start', 'start_unix_timestamp', 'end', 'end_unix_timestamp', 'duration', 'progress_percent', 'type_id', 'status_id', 'color', 'priority_lvl_id', 'is_finished', 'want_or_need', 'timed'));
    }
    
};

const actions = {

    async setupTodaysTaskList( {dispatch, commit, getters}) {
        let rightNow = datedJS.dated.getLocalISODate();
        
        let tempTodaysTask = [];
        getters.allTasks2Do.forEach(task => {
            let taskDate = datedJS.dated.getLocalISODate(task.start);
            if (taskDate === rightNow) {
                console.log("Found task for today: " + task.name);
                tempTodaysTask.push(task);
            }
            // task.start_unix_timestamp = parseInt(task.start_unix_timestamp);
            // task.end_unix_timestamp = parseInt(task.end_unix_timestamp);

            // task.start = new Date(task.start).getTime();
            // task.end = new Date(task.end).getTime();
        });
        commit('SETUP_TODAYS_TASKS', tempTodaysTask);
    },
    async selectTaskObjById( { dispatch, commit, getters, rootGetters}, taskId ) {
        console.log('selectTaskObjById action called, commiting id: ' + taskId + ' along with the following taskObj: \n');
        commit('SET_SELECTED_TASK_ID', taskId);
        if (taskId === null) {
            commit('SET_SELECTED_TASK_OBJ', {} );
        } else {
            let taskObjArray = rootGetters['tasks/allTasks2Do'].filter(task => (task.id === taskId));
            // let taskObjArray = rootGetters['tasks/getCalendarEvents2Do'].filter(task => (task.id === taskId));
           
            console.log(JSON.stringify(taskObjArray[0], null, 1));
            commit('SET_SELECTED_TASK_OBJ', taskObjArray[0]);
        }
        console.log(JSON.stringify(rootGetters['tasks/getSelectedTask'], null, 1));

    },
    async copyToSelectedTaskObj( { commit }, taskObj) {
        commit('COPY_TO_SELECTED_TASK_OBJ', taskObj);
    },
    async resetSelectedTaskObj( { commit } ) {
        commit('RESET_SELECTED_TASK_OBJ');
    },
    async fetchTasks2Do( { dispatch, commit, getters, rootGetters}) {
        return await new Promise(function(resolve, reject) {
            Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            // axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            Vue.prototype.$http({
                url: 'https://ts-api.t2do.io/t2do-api/tasks/query/created_by_id/' + rootGetters['auth/getCurrentUserId'],
                method: "GET"
                })
                .then(function(response) {
                    let typeCastedResponse = typecastTasks2DoResponse(response.data.task_data);
                   

                    if(!!typeCastedResponse) {
                        commit('SET_TASKS_2_DO', typeCastedResponse);
                    } else {
                        console.log('Empty repsonse from API');
                    }
                    // console.log("fetchTasks2Do state.tasks2Do after type casting: " +  JSON.stringify(state.tasks2Do, null, 2) );
                    resolve(response);
                })
                .catch(function(err) {
                    console.log("Error fetching tasks: " + err);
                    let statusObj =  { statusCode: err, status: 'failed-to-fetch-task-data-from-api', name: err.name, message: err.message};
                    dispatch('auth/authError', statusObj, { root: true } );
                    // commit("auth_error", statusObj);
                    console.log("'auth_error' has been called, but action is in the parent vuex module (index.js)");
                    reject(statusObj);
                });
            });
    },
    async fetchTaskPropertyTypes ({ dispatch, commit, rootGetters }) {
          
        let camelCasedResponse = {};
        
        camelCasedResponse['taskImportanceTypes'] = state.wantOrNeedOptions;
        camelCasedResponse['taskPriorityTypes'] = [];
        camelCasedResponse['taskTypes'] = [];
        camelCasedResponse['taskStatusTypes'] = [];

        // Sort taskStatusTypes by statusIndex property
        camelCasedResponse['taskImportanceTypes'].sort(function(a, b) {
            return a.wantNeedIndex - b.wantNeedIndex;
        });
        // camelCasedResponse['taskStatusTypes'][0] = 'unset';

        return await new Promise((resolve,reject) => {
            Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            // axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            Vue.prototype.$http({
                url: 'https://ts-api.t2do.io/t2do-api/tasks/related-data/all',
                method: 'GET'
            })
            .then(response => {
                let typeCastedResponse = typecastPriorityLevelsResponse(response.data.related_data);
                
/******************************************************************************
* // TODO: These variable names should be dynamically generated using camel case code
* 
*******************************************************************************/
                typeCastedResponse['task_types'] = typecastTaskTypesResponse(response.data.related_data);
                // console.log(`typeCastedResponse:  ${ JSON.stringify(typeCastedResponse, null, 2) }\n`);

                typeCastedResponse['task_priority_types'].forEach(priorityLevel => {
                    let priorityLevelItem = {};
                    priorityLevelItem.labelText = priorityLevel.priority_level_name;
                    priorityLevelItem.levelNum = parseInt(priorityLevel.priority_level_num);
                    priorityLevelItem.levelColor = priorityLevel.priority_level_color;
                    console.log('PriorityLevelColor before adjustment: ' + priorityLevelItem.levelColor);
                    priorityLevelItem.levelColor = adjustLevelRGB( priorityLevelItem.levelColor, .35 );
                    console.log('PriorityLevelColor after adjustment: ' + priorityLevelItem.levelColor);

                    priorityLevelItem.levelId = priorityLevel.priority_level_id;
    
                    // console.log("PriorityLevelItem: " +  JSON.stringify(priorityLevelItem));
                    camelCasedResponse['taskPriorityTypes'].push(priorityLevelItem);
                }); 

                // console.log(`camelCasedResponse['taskPriorityTypes']:  ${ JSON.stringify(camelCasedResponse['taskPriorityTypes'], null, 2) }\n`);

                typeCastedResponse['task_types'].forEach(taskType => {
                    let taskTypeItem = {};
                    taskTypeItem.typeId = taskType.type_id;
                    taskTypeItem.typeName = taskType.type_name;
                    taskTypeItem.typeDesc = taskType.type_desc;
                    taskTypeItem.typeColor = taskType.type_color;
                    
                    camelCasedResponse['taskTypes'].push(taskTypeItem);
                });

                typeCastedResponse['task_status_types'].forEach(taskStatusType => {
                    let taskStatusTypeItem = {};
                    taskStatusTypeItem.statusId = parseInt(taskStatusType.status_id);
                    taskStatusTypeItem.statusIndex = parseInt(taskStatusType.status_order);
                    taskStatusTypeItem.statusName = taskStatusType.status_name;
                    taskStatusTypeItem.statusDesc = taskStatusType.status_desc;
                    
                    camelCasedResponse['taskStatusTypes'].push(taskStatusTypeItem);
                });

                // Sort taskStatusTypes by statusIndex property
                camelCasedResponse['taskStatusTypes'].sort(function(a, b) {
                    return a.statusIndex - b.statusIndex;
                });
                
                console.log(`camelCasedResponse: ${ JSON.stringify(camelCasedResponse, null, 2) }\n`);
                commit('SET_TASK_PROPERTIES_ARR_OBJ', camelCasedResponse);
                resolve(response);
            })
            .catch(err => {

                console.log("Error fetching task properties: " + err);
                let statusObj =  { statusCode: err, status: 'failed-to-fetch-preferences-from-api', name: err.name, message: err.message};
                dispatch("auth/authError", statusObj, { root: true } );
                reject(statusObj);
            });
        });
    },
    async fetchTasks({ dispatch, commit, rootGetters }) {
        // const response = axios({url: "https://ts-api.t2do.io/t2do-api/tasks/query/all", method: 'GET' });
        // axios.defaults.headers.common["Authorization"] = token
        
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            console.log("State of token: " + rootGetters['auth/getCurrentJwt']);
            // axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
           
            // const config = {
            //     headers: { Authorization: `Bearer ${store.getters.currentJwt}` }
            // };
            // const bodyParameters = {
            //     key: "value"
        // };

        // const response = axios.get('https://ts-api.t2do.io/fileorg/note/search/note_creator/Devteam', bodyParameters, config).then(console.log).catch(console.log);

        // const response = axios({url: "https://ts-api.t2do.io/t2do-api/tasks/query/all", method: 'GET' });
        // axios({url: "https://ts-api.t2do.io/fileorg/note/search/note_creator/Devteam", method: 'GET' })
        // axios({
        //   url: "https://ts-api.t2do.io/t2do-api/tasks/query/all",
        //   method: "GET"axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/currentJwt'];
        // })

        Vue.prototype.$http({
            // url: "https://ts-api.t2do.io/t2do-api/tasks/query/owner_screen_name/" + store.getters.currentUser,
            url: 'https://ts-api.t2do.io/t2do-api/tasks/query/created_by_id/' + rootGetters['auth/currentUserId'],
            method: "GET"
            })
            .then(response => {
                const typeCastedResponse = typecastTasks2DoResponse(response.data.task_data);
                commit("SET_TASKS_2_DO", typeCastedResponse);
                resolve(response);
               
            })
            .catch(err => {
                let statusObj =  { statusCode: err, status: 'Failed to fetch tasks', name: err.name, message: err.message};
                dispatch("auth/authError", statusObj, { root: true } );
                reject(statusObj);
         
            });
        });
    },
    async createTask2Do({ dispatch, commit, rootGetters }, task2DoObj) {
        // task2DoObj.start = task2DoObj.startISO;
        // task2DoObj.end = task2DoObj.endISO;
        // let postTask2DoObj = arrayObjUtils.arrayObjsDeepCopy(task2DoObj);
        let postTask2DoObj = arrayObjUtils.arrayObjsDeepCopy(task2DoObj);
        postTask2DoObj.time_data.times[0] = { start: task2DoObj.start, end: task2DoObj.start, start_unix_timestamp: task2DoObj.start_unix_timestamp, end_unix_timestamp: task2DoObj.end_unix_timestamp , prep_time: task2DoObj.time_data.times[0].prep_time };
        
        // delete object properties that are only used locally and properties that are computed on the server (startISO, start_unit_timestamp, etc...)
        delete postTask2DoObj.index;
        delete postTask2DoObj.startISO;
        delete postTask2DoObj.endISO;
        delete postTask2DoObj.start_unix_timestamp;
        delete postTask2DoObj.end_unix_timestamp;
        delete postTask2DoObj.tempObj;

        // these are the required database fields, if these aren't set, you will get some obscur network issues like CORS header not being set, when in 
        // reality, its the database back end freaking out and not reporting the correct error
        /**
         * id
         * name
         * created_by_id
         * type_id
         *  */
         
        postTask2DoObj.is_finished = postTask2DoObj.is_finished ? 1 : 0;
        postTask2DoObj.is_archived = postTask2DoObj.is_archived ? 1 : 0;
        postTask2DoObj.has_alert = postTask2DoObj.has_alert ? 1 : 0;
        postTask2DoObj.has_push_notification = postTask2DoObj.has_push_notification ? 1 : 0;
        
        if (postTask2DoObj.hasOwnProperty('data')) {
            postTask2DoObj.data = JSON.stringify(postTask2DoObj.data, null, 2);
        }

        if (postTask2DoObj.hasOwnProperty('time_data')) {
            postTask2DoObj.time_data = JSON.stringify(postTask2DoObj.time_data, null, 2);
        }

        if (postTask2DoObj.hasOwnProperty('location_data')) {
            postTask2DoObj.location_data = JSON.stringify(postTask2DoObj.location_data, null, 2);
        }
        
        console.log("POSTING THE FOLLOWING postTask2DoObj: " + JSON.stringify(postTask2DoObj, null, 2));
        console.log("start time in postTask2DoObj create action: " + postTask2DoObj.start);
        // console.log("postTask2DoObj createTask2Do action: " + JSON.stringify(postTask2DoObj, null, 2));

        /* 

        TODO: write this function
        Data from POSTMAN:
        https://ts-api.t2do.io/t2do-api/tasks/add

        */
      
        // store.dispatch('fetchTasks2Do');
    
       return await new Promise((resolve, reject) => {
            commit('UPDATE_LOAD_PROGRESS', { progress: 1, message: 'Axios POST request sent the following object: postTask2DoObj' }, { root: true } );   
            Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            // axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            Vue.prototype.$http({
                url: 'https://ts-api.t2do.io/t2do-api/tasks/add',
                method: 'POST',
                data: postTask2DoObj
            }).then(response => {

                commit('UPDATE_LOAD_PROGRESS', { progress: 40, message: 'Data sent to server' }, { root: true } );   
                console.log(`*********************************\nAdd Task2Do API response ${ JSON.stringify(response, null, 2)}\n`);
                commit('NEW_TASK_2_DO', postTask2DoObj);
                resolve(response);
            }).then(async fetchResponse => {
                // resolve(fetchResponse);
                commit('UPDATE_LOAD_PROGRESS', { progress: 45, message: 'Dispatching fetchTasks2Do' }, { root: true } );   
                await dispatch('fetchTasks2Do');
                console.log(`\tAdd Task2Do fetchResponse ${ JSON.stringify(fetchResponse, null, 2)}\n`);               
            }).then(fetchResponse => {
               
                commit('UPDATE_LOAD_PROGRESS', { progress: 100, message: 'Finished fetching Tasks2Do' }, { root: true } );  
                resolve(fetchResponse); 
            }).catch(err => {
                let statusObj =  { statusCode: err, status: 'failed-to-submit-data-to-api', name: err.name, message: err.message};
                commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: 'Failed to submit data to API' }, { root: true } );
                dispatch("auth/authError", statusObj, { root: true } );
                reject(statusObj);
            });
        });

    },
    async updateTask2Do({ dispatch, commit, rootGetters}, task2DoObj) {
        
        const task2DoObjIndex = task2DoObj.index;
        task2DoObj.start = task2DoObj.startISO;
        task2DoObj.end = task2DoObj.endISO;
        let putTask2DoObj = arrayObjUtils.arrayObjsDeepCopy(task2DoObj);
        putTask2DoObj.time_data.times[0] = { start: task2DoObj.start, end: task2DoObj.start, start_unix_timestamp: task2DoObj.start_unix_timestamp, end_unix_timestamp: task2DoObj.end_unix_timestamp , prep_time: task2DoObj.time_data.times[0].prep_time };
    
        // delete object properties that are only used locally and properties that are computed on the server (startISO, start_unit_timestamp, etc...)
        delete putTask2DoObj.index;
        delete putTask2DoObj.startISO;
        delete putTask2DoObj.endISO;
        delete putTask2DoObj.start_unix_timestamp;
        delete putTask2DoObj.end_unix_timestamp;
        delete putTask2DoObj.tempObj;

        putTask2DoObj.is_finished = putTask2DoObj.is_finished ? 1 : 0;
        putTask2DoObj.is_archived = putTask2DoObj.is_archived ? 1 : 0;
        putTask2DoObj.has_alert = putTask2DoObj.has_alert ? 1 : 0;
        putTask2DoObj.has_push_notification = putTask2DoObj.has_push_notification ? 1 : 0;
        
        if (putTask2DoObj.data) {
            putTask2DoObj.data = JSON.stringify(putTask2DoObj.data, null, 2);
        }   
        
        if (putTask2DoObj.time_data) {
            putTask2DoObj.time_data = JSON.stringify(putTask2DoObj.time_data, null, 2);
        } 

        if (putTask2DoObj.location_data) {
            putTask2DoObj.location_data = JSON.stringify(putTask2DoObj.location_data, null, 2);
        }
        
        console.log("putTask2DoObj update action: " + JSON.stringify(putTask2DoObj, null, 2));

        console.log("task2DoObj index in vuex tasks.js: " + task2DoObjIndex);

        return await new Promise((resolve, reject) => {
            
            commit('UPDATE_LOAD_PROGRESS', { progress: 1, message: 'Putting data to API' }, { root: true } );
            // axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            Vue.prototype.$http({
                url: 'https://ts-api.t2do.io/t2do-api/tasks/edit/' + task2DoObj.id,
                method: 'PUT',
                data: putTask2DoObj
            }).then(response => {
                resolve(response);
                console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^\nAPI update response  ${ JSON.stringify(response.data, null, 2) }\n`);
                commit('PUT_TASK_2_DO', task2DoObj, task2DoObjIndex);
                commit('UPDATE_LOAD_PROGRESS', { progress: 50, message: `API Response: ${JSON.stringify(response.data, null, 2)}` }, { root: true } );
                
            }).then(async fetchResponse => {
                
                console.log(`\tUpdate Task2Do fetchResponse ${ JSON.stringify(fetchResponse, null, 2)}\n`);     
                commit('UPDATE_LOAD_PROGRESS', { progress: 55, message: 'Dispatching fetchTasks2Do' }, { root: true } );   
                await dispatch('fetchTasks2Do');
                resolve(fetchResponse);
            }).then(fetchResponse => {
               
                commit('UPDATE_LOAD_PROGRESS', { progress: 100, message: 'Finished fetching Tasks2Do' }, { root: true } );  
                resolve(fetchResponse); 
            }).catch(err => {
                let statusObj =  { statusCode: err, status: 'failed-to-update-data-to-api', name: err.name, message: err.message};
                commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: 'Failed to update data to API' }, { root: true } );
                dispatch("auth/authError", statusObj, { root: true } );
                // commit("auth_error", statusObj);
           
                reject(statusObj);

            });
        });
    }

};

const mutations = {
    //   setTasks2Do: (state, tasks2Do) => {state.tasks2Do = []; state.tasks2Do = [...state.hardcoded_tasks2Do, ...tasks2Do]; },
    SET_TASKS_2_DO: (state, tasks2Do) => {
        state.tasks2Do = [];
        state.tasks2Do = [...tasks2Do];
    },
    SET_TASK_PROPERTIES_ARR_OBJ: function (state, taskPropertiesObj) { 
        state.taskPropertiesArrObj = [];
        state.taskPropertiesArrObj = arrayObjUtils.arrayObjsDeepCopy(taskPropertiesObj);
    },
    NEW_TASK_2_DO: (state, task2DoObj) => state.tasks2Do.push(task2DoObj),
    PUT_TASK_2_DO: (state, task2DoObj, objIndex) => state.tasks2Do[objIndex] = task2DoObj,
    SETUP_TODAYS_TASKS: function (state, todaysTasksObj) { state.todaysTasks = arrayObjUtils.arrayObjsDeepCopy(todaysTasksObj); },
    SET_SELECTED_TASK_ID: (state, taskId) => state.selectedTaskId = taskId,
    SET_SELECTED_TASK_OBJ: (state, taskObj) => state.selectedTask = taskObj,
    RESET_SELECTED_TASK_OBJ: (state) => { state.selectedTask = arrayObjUtils.arrayObjsDeepCopy(state.emptyTaskObj); },
    COPY_TO_SELECTED_TASK_OBJ: (state, taskObj) => state.selectedTask = taskObj

    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};