<template>
  <v-system-bar v-if="!fixedWindow" class="widget-window-bar" style="height: var(--widget-window-bar--height);" >
    <!-- <v-system-bar class="widget-title-bar" window dark> -->
      <!-- TODO:
            add prop for colored notifications in windowbar
            Right now this just defaults to red, which should be used only when showing error or important message. Utilize green, blue, yellow and orange for notification messages
            
       -->
        <!-- <span v-if="(notifications.length !== undefined) && (notifications.length > 0)" class="widget-window-bar-notifications"> -->
        <span v-if="(notifications !== undefined) && (notifications.length > 0)" class="widget-window-bar-notifications">
            <v-icon>mdi-message</v-icon>
            <span> {{ notificationMessage }}</span>
        </span>
        <span v-else class="widget-window-bar-no-notifications-spacer"></span>

        <span class="widget-window-bar-label">
            <h3 class="ml-4">{{ widgetLabel }}</h3>
        </span>
        <v-spacer></v-spacer>
         <div class="inline-window-button-span">
            <v-icon dark outlined 
                class="mr-2 window-button TM__blue-glowing-outline"
                @click="minimizeWidget()">
                    {{ minimizeState ? "mdi-window-restore" : "mdi-window-minimize" }}
            </v-icon>
            
            <v-icon dark outlined
                class="mr-2 window-button TM__green-glowing-outline"
                @click="maximizeWidget()">
                    {{ maximizeState ? "mdi-window-restore" : "mdi-window-maximize" }}
            </v-icon>
            <v-icon dark outlined
                class="mr-2 window-button TM__red-glowing-outline"
                @click="dialog = false">
                    mdi-window-close
            </v-icon>
            
        </div>             
                
    </v-system-bar>
  <!-- </div> -->

</template>

<script>
export default {
  name: "WindowBar",
  props: ["widgetLabel", "minimizeState", "maximizeState", "notifications", "notificationType", "fixedWindow"],
  computed: {
      notificationMessage: function() {
            console.log("notifications length: " + this.notifications.length);
          console.log("notifications: " + this.notifications);
        //   let notificationString = this.notifications.length + ' ' + (this.notifications.length > 1) ? this.notificationType + 's' : this.notificationType;
        //   return notificationString;
        return `${this.notifications.length} ${(this.notifications.length > 1) ? this.notificationType + 's' : this.notificationType}`;
        }
  },
  methods: {
    maximizeWidget() {
      this.$emit("maximize");
    },
    minimizeWidget() {
      this.$emit("minimize");
    }
  }
};
</script>

<style>




#task2do-app-id .widget > .widget-window-outer-container > .widget-window-bar {
	background: rgba(0, 0, 200, .5);
	width: 100%;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	height: var(--widget-window-bar--height);
	position: relative;
}

#task2do-app-id .widget-window-bar span.widget-window-bar-notifications {
    overflow: hidden;
    text-align: left;
    padding: 0.35rem .25rem 0.125rem;
    height: 100%;
    margin-left: -0.5rem;
    width: 25%;
    background: rgba(175, 0, 0, .85);
}


#task2do-app-id .widget-window-bar span.widget-window-bar-no-notifications-spacer {
	width: calc(50% - 35rem);
	/* background: rgba(0, 0, 200, .5); */
}

#task2do-app-id .minimize-widget .widget-window-bar span.widget-window-bar-no-notifications-spacer,
#task2do-app-id .minimize-widget .widget-window-bar span.widget-window-bar-notifications {
    width: 60%;
    margin-left: -0.25rem;
    /* background: rgba(0, 0, 200, .5); */
}

#task2do-app-id .widget-window-bar .widget-window-bar-notifications .v-icon {
    font-size: 1.25rem;
    margin: .05rem .25rem;
    padding: .125rem;
}

#task2do-app-id .widget-window-bar .widget-window-bar-notifications span {
    font-size: 0.95rem;
    line-height: 0.9rem;
    /* vertical-align: text-top; */
}

#task2do-app-id .widget-window-bar .inline-window-button-span .v-icon {
   padding-right: .225rem;
}

#task2do-app-id .widget-window-bar span.widget-window-bar-label {
    display: inline-flex;
    white-space: nowrap;
    width: 66%;
  
}

#task2do-app-id .minimize-widget .widget-window-bar span.widget-window-bar-label {
    display: none;
}

#task2do-app-id .widget-window-bar h3.ml-4 {
    width: 66%;
    /* background: rgba(0, 200, 0, .5); */
    text-align: center;
    padding: .125rem .25rem;
}


#task2do-app-id .widget-window-bar .inline-button-span {
  right: 10%;
  position: absolute;
  height: 100%;
}



#task2do .widget-window-bar .inline-window-button-span .window-button i.v-icon.notranslate.mdi {
    /* The widget titlebar window button icon sizes */
  font-size: 250%;
  color: rgb(235, 245, 255);
/*       filter: drop-shadow(.225rem .225rem .0875rem rgba(6, 6, 6, .99)); */
  text-shadow: .15rem .15rem .15rem rgba(6, 6, 6, .75);
  
}

.v-system-bar.widget-window-bar.v-system-bar--window.theme--dark {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0 .5rem;

}


/* #task2do-app-id .widget .widget-window-bar .inline-window-button-span button{
  
    margin: .0875rem;#task2do-app-id .widget-window-bar .widget-window-bar-notifications .v-icon {
    font-size: 1.25rem;
    margin: .05rem .25rem;
}

#task2do-app-id .widget-window-bar .widget-window-bar-notifications span {
    font-size: 0.95rem;
    line-height: 0.9rem;
    vertical-align: text-top;
}
    text-align: center;
    display: inline-block;
    border-radius: 0.125rem;
    padding: .0875rem .125rem .0875rem 0.5rem;
    margin-right: 0.75rem !important;
}


#task2do-app-id .widget .widget-window-bar .inline-window-button-span button {
    margin: .125rem .25rem .125rem 0.75rem;
    text-align: center;
    display: inline-block;
    height: 90%;
    width: auto;
    border: .125rem outset rgba(175, 200, 250, .95);
    border-radius: 0.125rem;
    padding: .0875rem 0.25rem .0875rem 0.5rem;
} */


#task2do-app-id .widget .widget-window-bar .inline-window-button-span {
    position: fixed;
    display: inline-block;
    height: 2rem;
    right: 0.125rem;
    overflow: hidden;
}


#task2do-app-id .widget .widget-window-bar .inline-window-button-span button {
    margin: .125rem .25rem .125rem 0.25rem;
    text-align: center;
    display: inline-flex;
    position: relative;
    height: 90%;
    width: 2.75rem;
    border: .125rem outset rgba(175, 200, 250, .95);
    border-radius: 0.125rem;
    padding: .0875rem 0.25rem .0875rem 0.5rem;
}


#task2do-app-id .widget .widget-window-bar .inline-window-button-span button:nth-child(1) {
  
   background: rgba(0, 50, 250, .25);
}

/* 
#task2do-app-id .widget .widget-window-bar .inline-window-button-span button:nth-child(1):hover,
#task2do-app-id .widget .widget-window-bar .inline-window-button-span button:hover.TM__blue-glowing-outline {
    border-color: rgba(100, 150, 250, .95);
    box-shadow: 0px 0px 4px 2px rgba(0, 100, 250, .75) !important;
    transform: scale(1.025);
    height: 87.5%;
    filter: drop-shadow(0px 0px 0.25rem rgba(0, 100, 250, 0.75));
    transition: .25s transform ease-in-out !important;
} */


#task2do-app-id .widget .widget-window-bar .inline-window-button-span button:nth-child(1):hover, #task2do-app-id .widget .widget-window-bar .inline-window-button-span button:hover.TM__blue-glowing-outline {
    border-color: rgba(200, 225, 250, .995) !important;
    box-shadow: 0px 0px 0.0875rem .125rem rgba(50, 150, 250, .95) !important;
    transform: scale(1.025);
    filter: drop-shadow(0px 0px 0.25rem rgba(100, 175, 250, 0.995));
    transition: .15s transform ease-in-out !important;
}

#task2do-app-id .widget .widget-window-bar .inline-window-button-span button:nth-child(2) {
    background: rgba(50, 250, 0, .25);
}




#task2do-app-id .widget .widget-window-bar .inline-window-button-span button:nth-child(2):hover, #task2do-app-id .widget .widget-window-bar .inline-window-button-span button:hover.TM__green-glowing-outline {
    border-color: rgba(175, 225, 200, .995) !important;
    box-shadow: 0px 0px 4px 2px rgba(0, 225, 50, .95) !important;
    transform: scale(1.025);
    filter: drop-shadow(0px 0px 0.25rem rgba(0, 225, 50, 0.5));
    transition: .15s transform ease-in-out !important;
}


#task2do-app-id .widget .widget-window-bar .inline-window-button-span button:nth-child(3) {
  background: rgba(250, 50, 0, .25);
}




#task2do-app-id .widget .widget-window-bar .inline-window-button-span button:nth-child(3):hover.TM__red-glowing-outline, #task2do-app-id .widget .widget-window-bar .inline-window-button-span button:hover.TM__red-glowing-outline {
    border-color: rgba(250, 150, 150, .995);
    box-shadow: 0px 0px 4px 2px rgba(250, 0, 0, .75) !important;
    transform: scale(1.025);
    filter: drop-shadow(0px 0px 0.25rem rgba(250, 0, 0, 0.75));
    transition: .15s transform ease-in-out !important;
}

.v-system-bar.widget-window-bar.v-system-bar--window.theme--dark {
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0 .5rem;

}


</style>
