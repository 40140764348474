<template>
  <v-dialog
    class="text-property-dialog"
    v-model="displayDialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title>{{ textPropAction }} Category</v-card-title>
      <span
        >Original value:
        <strong class=".blue-highlighted-text">
          {{ originalValue }}
        </strong></span
      >
      <v-card-text>
        <v-text-field
          v-model="textField"
          :autofocus="autofocus"
          v-on:keyup.enter="commitEdit"
          v-on:keyup.esc="cancelEdit"
          v-on:keyup="updateText"
        ></v-text-field>
        <!-- :label="label"
                                :hint="hint"
                                :placeholder="placeholder"
                                :shaped="shaped"
                                :outlined="outlined"
                                :rounded="rounded"
                                :solo="solo"
                                :single-line="singleLine"
                                :filled="filled"
                                :clearable="clearable"
                                :persistent-hint="persistentHint"
                                :dense="dense" -->

        <!-- <v-btn color="primary" dark @click="$emit('closeDialog')">Close Dialog</v-btn> -->
        <!-- <v-select
              :items="select"
              label="A Select List"
              item-value="text"
            ></v-select> -->
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="commitEdit">Submit</v-btn>
        <v-btn color="primary" text @click="cancelEdit">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TextPropertyDialog",
  props: [
    "displayDialog",
    "objToEdit",
    // 'domElementHandle',
    "objPropertyToEdit",
    "textPropAction",
    "displayTextPropChange",
    "updateMethod",
    "parentMethod"
  ],
  data: () => ({
    originalValue: "",
    textField: "",
    model: "",
    label: "Enter the new value",
    hint: "",
    placeholder: "",
    shaped: false,
    outlined: false,
    rounded: false,
    solo: false,
    singleLine: false,
    filled: false,
    clearable: false,
    persistentHint: false,
    dense: false,
    autofocus: true
  }),
  created: function() {
    this.hint = this.objProperyToEdit;
    this.placeholder = this.objToEdit.name;
    this.originalValue = this.objToEdit.name;
    console.log("Dialog object propert name: " + this.objToEdit.name);
    console.log("Object property action: " + this.textPropAction);
  },
  methods: {
    updateText() {
      //   this.objToEdit.name = this.textField;
      //  this.updateMethod(this.textField);
      this.displayChangedTextField(this.objToEdit, this.textField);

      // item.name = this.textField;

      //  this.$emit('updateTextProp', this.objToEdit, this.textField);
    },
    commitEdit() {
      this.textField =
        this.textField !== "" ? this.textField : this.originalValue;
      this.parentMethod(this.objToEdit, true);
      //this.$emit('closeTextPropDialog', this.objToEdit, true);
    },
    cancelEdit() {
      this.textField = this.originalValue;
      // this.parentMethod(this.objToEdit, this.originalValue);

      this.$emit("closeTextPropDialog", this.objToEdit, false);
    }
  }
};
</script>
