<template>
    <v-sheet>
        <v-btn @click="addRootCat(task2DoCategories)"
        >Add Root Category</v-btn
        >
        <!-- <v-treeview activatable :load-children="loadTasks" :items="combinedCatsAndTasks"  :expand-icon="'\u{1F5C2}'" class="task2Do-cats"> -->

        <!-- :expand-icon="F0798"  -->
        <!-- <v-treeview
        v-model="tree"
        :open="open"
        :items="combinedCatsAndTasks"
        :item-key="combinedCatsAndTasks.id"
        activatable
        selectable
        open-on-click
        :load-children="loadTasks"
        class="task2Do-cats"
        > -->
        <template v-slot:prepend="{ item, open }" class="task2Do-item">
            <v-icon v-if="item.taskType === 'category'">
            {{ open ? "mdi-arrow-expand-right" : "mdi-arrow-collapse-right" }}
            </v-icon>
            <v-tooltip bottom style="z-index: 10000;">
                <template v-slot:activator="{ on }">

                    <v-icon v-on="on" v-model="item.name" v-if="(item.type === 'task') || (item.type === 'todo')">
                        <!-- {{ "\u{1F4DD}" }} -->
                        mdi-format-list-checks
                    </v-icon>

                    <v-icon v-on="on" v-model="item.name" v-else-if="item.type === 'note'">
                        <!-- {{ "\u{1F4D3}" }} -->
                        mdi-note-text
                    </v-icon>

                    <v-icon v-on="on" v-model="item.name" v-else-if="item.type === 'event'">
                        <!--{{ "\u{1F5D3}" }} -->
                        <!-- {{ "\u{1F4C5}" }} -->
                        mdi-calendar-text
                    </v-icon>

                    <v-icon v-on="on" v-model="item.name" v-else-if="item.type === 'daily'">
                        <!-- {{ "\u{1F4C6}" }} -->
                        mdi-calendar-today
                    </v-icon>

                    <v-icon v-on="on" v-model="item.name" v-else-if="item.type === 'want2Do'">
                        mdi-emoticon-poop
                        <!-- {{ "\u{1F4A9}" }} -->
                    </v-icon>
                </template>
                <span>{{  $jsUtils.capitalizeString(item.type) }}</span>
            </v-tooltip>
        
            <!-- <v-icon v-model="item.name" v-if="item.type === 'category'">mdi-arrow-collapse-right</v-icon> -->
            <!-- <v-text-field  v-model="item.name" v-else>{{ '\u{1F4DD}' }} {{ item.name }} </v-text-field> -->
        </template>
        <!-- <template
            v-slot:label="{ item }"
            class="task2Do-item"
            @dblclick="editTaskCat(item)"
        >
            <span class="task2Do-item" style="display: inline;">
            <span
                v-if="item.priorityLevel !== undefined"
                style="display: inline-block; width: calc(50% - 2rem);"
                :style="{
                backgroundColor: getTaskPriorityLevels[item.priorityLevel].levelColor
                }"
            >
                <a
                @dblclick="editTaskCat(item)"
                style="color: rgba(13, 13, 13, .995);"
                >
                {{ item.name }}
                </a>
                <v-icon
                small
                dark
                style="margin-bottom: .125rem;"
                @click="editTaskCat(item)"
                >mdi-pencil</v-icon
                >
            </span>
            <span v-else style="display: inline-block; width: calc(75% - 2rem);"
                ><a @dblclick="editTaskCat(item)">{{ item.name }} </a>
            </span>
            <v-checkbox
                v-if="item.type !== 'category'"
                v-model="item.isFinished"
            >
                <v-icon small dark style="margin-bottom: .125rem;">{{
                "\u{1F5D1}"
                }}</v-icon>
            </v-checkbox>
            </span>
        </template> -->
        <template style="display: inline;" v-slot:append="{ item }">
            <span v-if="item.taskType === 'category'">
            <!-- <v-btn
                style="display: inline;"
                :data-keypath="item.keyPath"
                @click="addTask(item)"
                >Add task in {{ item.name }}
            </v-btn> -->
            <v-btn
                style="display: inline;"
                :data-keypath="item.keyPath"
                @click="addSubCat(item)"
                >{{ item.keyPath }} - Add Sub Category</v-btn
            >
            </span>
        </template>
        </v-treeview>
        <!-- <v-dialog v-model="nameDialog" persistent max-width="20rem">
                <v-card>
                    <v-card-title>
                        <span class="headline">Rename Category</span>
                    </v-card-title>
                
                </v-card>
            </v-dialog> -->
        <!-- <v-dialog v-model="renameDialog" max-width="500px">
                    <v-card>
                        <v-card-title>Rename Category</v-card-title>
                        <span> {{ selectedCategory.keyPath }} </span>
                        <v-card-text>
                            <v-text-field style="display: inline;"
                                    v-model="selectedCategory.name"></v-text-field>
                                
                            <v-btn color="primary" dark @click="renameDialog = !renameDialog">Close Dialog</v-btn>
            
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                            color="primary"
                            text
                            @click="renameDialog = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog> -->
    </v-sheet>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    // import { arrayObjsDeepCopy } from 'https://git.trinix.io/Trinix-Media/JS-Utils-n-Helpers/raw/branch/master/obj-array-utils.js';
    export default {
    name: "Categories",
    props: [
        "task2DoCats",
        "addTask",
        "editTaskCat",
    ],
    data: () => ({
        task2DoCategories: [
            {
                id: '87cc546b-d42c-4960-8022-3fbedad31ad6',
                name: 'Uncategorized',
                desc: 'Uncategorized data',
                taskType: 'category',
                keyPath: '',
                nodeLevel: 0,
                nodeIndex: 0,
                
            },
            {
                id: '4e20aec5-bd60-4d31-8d75-e6cfb1145db4',
                name: 'Personal',
                desc: 'Personal tasks or events',
                taskType: 'category',
                keyPath: '',
                nodeLevel: 0,
                nodeIndex: 1,
                children: [
                    {
                        id: '38b9b84c-0180-4493-96f2-74f4e3513e5b',
                        name: 'Practice guitar',
                        taskType: 'category',
                        keyPath: '',
                        nodeLevel: 1,
                        nodeIndex: 0,
                        children: [
                            {
                                id: 'ebcd889a-040d-409b-8075-d61b3e0d43ab',
                                name: 'Exercises',
                                taskType: 'category',
                                keyPath: '',
                                nodeLevel: 2,
                                nodeIndex: 0,
                                
                            },
                            { 
                                id: 'eb8e8298-9c16-43b2-ab9e-283ed4f8a3e5',
                                name: 'Songs',
                                taskType: 'category',                        
                                keyPath: '',
                                nodeLevel: 2,
                                nodeIndex: 1,
                                
                            }
                        ]
                    },
                    {
                        id: '59e61339-6f1e-4d35-b141-d0741505b805',
                        name: 'Health',
                        taskType: 'category',                   
                        keyPath: '',
                        nodeLevel: 1,
                        nodeIndex: 1,   
                        children: [
                            {
                                id: '940a521b-93df-4903-962d-d4c2fe0f4acb',
                                name: 'Exercises todo',
                                taskType: 'category',
                                keyPath: '',
                                nodeLevel: 2,
                                nodeIndex: 0,
                                
                            },
                        ],
                    },
                ],
            },
            {
                id: 'b7bc4bb6-ac58-453e-9e5e-1cdbf88128b7',
                name: 'Business',
                desc: 'Business related tasks or events',
                taskType: 'category',
                keyPath: '',
                nodeLevel: 1,
                nodeIndex: 2,
                children: [
                    {
                        id: '087043fa-2e05-445c-b839-b32d80aee43f',
                        name: 'Career',
                        taskType: 'category',
                        keyPath: '',
                        nodeLevel: 2,
                        nodeIndex: 0,
                        
                    }
                ]
            },
            {
                id: '289f485d-a993-4c4b-a635-4f9f7447a295',
                name: 'Projects',
                desc: 'Projects that you are involved with',
                taskType: 'category',
                keyPath: '',
                nodeLevel: 1,
                nodeIndex: 2,
                children: [
                    {
                        id: '396b44ab-f177-4fb7-8073-e79a11304f0a',
                        name: 'Task2Do Project',
                        desc: 'our beloved-hated Task2Do! project',
                        taskType: 'category',
                        keyPath: '',
                        nodeLevel: 2,
                        nodeIndex: 0,
                        
                    }
                ]
            },
            {
                id: '2894febb-6b24-4bfd-88cc-9f5c270d3bc6',
                name: 'Shared',
                desc: 'Shared task data',
                taskType: 'category',
                keyPath: '',
                nodeLevel: 1,
                nodeIndex: 3
            }
        ],
        task2Dos: [
                {
                    id: 'b74f72d1-9527-45a6-b25f-6c7ea43d6056',
                    categoryId: '2894febb-6b24-4bfd-88cc-9f5c270d3bc6',
                    createdBy: 'Mr. Ass Hat',
                    createdFor: 'Him self',
                    type: 'task',
                    name: 'make list of crap designs',
                    summary: 'no summary for crap',
                    body: 'Make a list, do you really need to know more than that',
                    categoryKeyPath: '3c0',
                    isFinished: false,
                    start: '2020-02-09 09:00:00',
                    end: '2020-02-09 10:00:00',
                },
                {
                    id: '3e329a72-dbd9-4a02-bca7-26771bb71237',
                    categoryId: '2894febb-6b24-4bfd-88cc-9f5c270d3bc6',
                    createdBy: 'Mr. Ass Hat',
                    createdFor: 'Him self',
                    type: 'task',
                    name: 'make list of your awesome designs',
                    body: 'Make a list, do you really need to know more than that',
                    categoryKeyPath: '3c0',
                    isFinished: false,
                    start: '2020-02-09 10:00:00',
                    end: '2020-02-09 11:00:00',
                },
                {
                    id: '9c071ead-e204-44a7-bba2-b1d892feb6a2',
                    name: 'Get skills',
                    categoryId: '087043fa-2e05-445c-b839-b32d80aee43f',
                    createdBy: 'Mr. Ass Hat',
                    createdFor: 'Him self',
                    type: 'todo',
                    body: 'get skills, do you really need to know more than that',
                    categoryKeyPath: '2c1c0',
                    isFinished: false,
                    taskCategoryOrder: 1,
                    priorityLevel: 7,
                    taskTimeFrame: '2 months',
                    start: '2020-02-13',
                    end: '2020-02-13',
                },
                {
                    id: '228eb459-1276-4333-b6f3-88ce9c46c851',
                    name: 'Get Job',
                    categoryId: '087043fa-2e05-445c-b839-b32d80aee43f',
                    createdBy: 'Mr. Ass Hat',
                    createdFor: 'Him self',
                    type: 'todo',
                    body: 'Get Job, do you really need to know more than that',
                    categoryKeyPath: '2c1c0',
                    isFinished: false,
                    taskCategoryOrder: 2,
                        priorityLevel: 8,
                    taskTimeFrame: '1 month',
                    start: 'after_3-1',
                    end: 'duration_1 month',
                },
                {
                    id: '4bdb37e2-ea8e-4fb2-839a-51ccf9d8fe09',
                    name: 'Get Loot',
                    categoryId: '087043fa-2e05-445c-b839-b32d80aee43f',
                    createdBy: 'Mr. Ass Hat',
                    createdFor: 'Him self',
                    type: 'todo',
                    body: 'Get Job, do you really need to know more than that',
                    categoryKeyPath: '2c1c0',
                    isFinished: false,
                    taskCategoryOrder: 3,
                    taskTimeFrame: 'immediately',
                    priorityLevel: 9,
                    start: 'after_2',
                    // if start refers to a todo that doesn't have an explicit end, then the start for this will equal the start of the task its refering to
                    end: 'unset',
                },
                {
                    id: '1540b854-82ba-433f-aa75-ff23c6bd1372',
                    name: 'Sex',
                    categoryId: '87cc546b-d42c-4960-8022-3fbedad31ad6',
                    createdBy: 'Brando',
                    createdFor: 'Me and my best good friend',
                    type: 'event',
                    categoryKeyPath: '0c0',
                    isFinished: false,
                    taskCategoryOrder: 1,
                    priorityLevel: 9,
                    start: '2020-02-10 10:00:00',
                    end: '2020-02-10 11:00:00',
                },
                {
                    id: 'ee1526e2-c368-4c4d-be22-81d950c4ecff',
                    categoryId: '87cc546b-d42c-4960-8022-3fbedad31ad6',
                    createdBy: 'Me',
                    createdFor: 'Me',
                    type: 'event',
                    name: 'Sleep',
                    categoryKeyPath: '0c0',
                    isFinished: true,
                    taskCategoryOrder: 2,
                    priorityLevel: 8,
                    start: '2020-02-10 11:00:00',
                    end: '2020-02-10 12:00:00',
                },
                {
                    id: '540c6736-d405-4292-84f0-44c9bd32b827',
                    categoryId: '87cc546b-d42c-4960-8022-3fbedad31ad6',
                    createdBy: 'Me',
                    createdFor: 'Me',
                    type: 'event',
                    name: 'Eat',
                    categoryKeyPath: '0c0',
                    isFinished: false,
                    taskCategoryOrder: 3,
                    priorityLevel: 6,
                    start: '2020-02-10 12:00:00',
                    end: '2020-02-10 13:00:00',
                },
                {
                    id: 'c3c5ad06-df5f-4115-9ad5-f9e4f8a58bbb',
                    categoryId: '87cc546b-d42c-4960-8022-3fbedad31ad6',
                    createdBy: 'Me',
                    createdFor: 'Me',
                    type: 'event',
                    name: 'Drink',
                    categoryKeyPath: '0c0',
                    isFinished: true,
                    taskCategoryOrder: 4,
                    priorityLevel: 4,
                    start: '2020-02-10 15:00:00',
                    end: '2020-02-10 17:00:00',
                },
                {
                    id: '6e5317c7-6b51-4cbe-8644-0c57c639350b',
                    categoryId: '87cc546b-d42c-4960-8022-3fbedad31ad6',
                    createdBy: 'Me',
                    createdFor: 'Me',
                    type: 'event',
                    name: 'Dream',
                    categoryKeyPath: '0c0',
                    isFinished: true,
                    taskCategoryOrder: 5,
                    priorityLevel: 0,
                    start: '2020-02-10 17:00:00',
                    end: '2020-02-10 20:00:00',
                },
                { 
                    id: '1b7dcf0d-6e62-4559-ae24-17e0b3d39af2',
                    categoryId: 'ebcd889a-040d-409b-8075-d61b3e0d43ab',
                    createdBy: 'Me',
                    createdFor: 'Me',
                    type: 'daily',
                    name: 'Do picking exercises',
                    categoryKeyPath: '1c1c1c0',
                    isFinished: false,
                    taskCategoryOrder: 1,
                    
                    start: '17:00:00',
                    end: '18:00:00',
                },
                {
                    id: '00b248d6-e707-42b0-abb0-53d13e3e9a84',
                    categoryId: 'ebcd889a-040d-409b-8075-d61b3e0d43ab',
                    createdBy: 'Me',
                    createdFor: 'Me',
                    type: 'daily',
                    name: 'Build up hand strength with barr chords',
                    categoryKeyPath: '1c1c1c0',
                    isFinished: false,
                    taskCategoryOrder: 2,
                    
                    start: '18:00:00',
                    end: '19:00:00',
                },
                {
                    id: '7706ce11-675b-4cf6-9a9e-717827f460cb',
                    categoryId: 'eb8e8298-9c16-43b2-ab9e-283ed4f8a3e5',
                    createdBy: 'Me',
                    createdFor: 'Me',
                    type: 'wantToDo',
                    name: 'Master of Puppets',
                    categoryKeyPath: '1c1c2c0',
                    isFinished: false,
                    taskCategoryOrder: 1,
                    
                    start: 'noTimeFrame',
                    end: 'noTimeFrame',
                },
                {
                    id: 'f932964a-b63e-47f6-94d4-de7ba6439103',
                    categoryId: 'eb8e8298-9c16-43b2-ab9e-283ed4f8a3e5',
                    createdBy: 'Me',
                    createdFor: 'Me',
                    type: 'goal',
                    name: 'Call of the Kutulu',
                    categoryKeyPath: '1c1c2c0',
                    isFinished: false,
                    taskCategoryOrder: 2,
                    
                    start: 'noTimeFrame',
                    end: 'noTimeFrame',

                },
                {
                    id: '59724f5f-df85-4f8c-b584-f51bde1b986f',
                    categoryId: '2894febb-6b24-4bfd-88cc-9f5c270d3bc6',
                    createdBy: 'Mr. Ass Hat',
                    createdFor: 'Him self',
                    type: 'task',
                    name: 'How to design shit right',
                    categoryKeyPath: '3c1c0',
                    isFinished: false,
                    taskCategoryOrder: 1,
                    
                    start: 'noTimeFrame',
                    end: 'noTimeFrame'

                },
                {
                    id: 'dcf8eae3-0614-4674-8aec-a870866f3588',
                    categoryId: '0a10dfc4-0e1a-4062-aa90-1aaf0fd53ae0',
                    createdBy: 'Mr. Ass Hat',
                    createdFor: 'Him self',
                    type: 'task',
                    name: 'Advanced techniques',
                    categoryKeyPath: '3c1c0',
                    isFinished: false,
                    taskCategoryOrder: 2,
                    start: 'noTimeFrame',
                    end: 'noTimeFrame'
                },
                {
                    id: 'ef46984a-1e0f-43c3-8674-bffbd98dd216',
                    categoryId: '0a10dfc4-0e1a-4062-aa90-1aaf0fd53ae0',
                    createdBy: 'Mr. Ass Hat',
                    createdFor: 'Him self',
                    type: 'task',
                    name: 'All about app',
                    categoryKeyPath: '3c1c0',
                    isFinished: false,
                    taskCategoryOrder: 3,
                    start: 'noTimeFrame',
                    end: 'noTimeFrame'
                }
        ],
        tree: [],
        open: ['Shit 2 Do'],
        subCat: [],
        tempCategoryTree: [],
        combinedCatsAndTasksArray: [],
        newCategoryTemplate: {},
        categoryRename: false,
        renameDialog: false,
        selectedCategory: ""

    }),
    beforeCreate() {
        console.log("Nothing gets called before me!");
    },
    created: function() {
        console.log("CREATED function called");
        // this.$arrayObjUtils.buildNestedArrayPaths(this.taskCategoriesNoKeyPath);
        // console.log(
        //   "\tTASK2Dos in categories component: " +
        //     JSON.stringify(this.task2Dos, null, '\t') +
        //     "\n"
        // );
    },
    mounted: function() {
        console.log("MOUNTED function called");
        this.buildNestedArrayPaths(this.task2DoCategories);
        // this.categorizeTasks(this.items);
        // console.log("\t this.allTasks2Do: " +  JSON.stringify(this.allTasks2Do, null, 2) );
    },
    updated: function() {
        console.log("UPDATED function called in Categories.vue");
    },
    computed: {
        ...mapGetters(["allTasks2Do"]),
        ...mapGetters( 'tasks', {
            allTasks2Do: 'allTasks2Do',
            calendarTasks2Do: 'calendarTasks2Do',
            getTaskPriorityLevels: 'getTaskPriorityLevels',
            getTaskTypes: 'getTaskTypes',
            getTaskStatusTypes: 'getTaskStatusTypes',
            emptyTaskObj: 'emptyTaskObj',
            newCalendarEventObj: 'newCalendarEventObj',
            wantVsNeedOptions: 'wantVsNeedOptions',
            
        }),
        ...mapGetters(["t2doAppState"]), 
        combinedCatsAndTasks() {
            console.log("COMPUTED function called");
            // this.resetCombinedCatsAndTasks();
            // this.categorizeTasks(this.combinedCatsAndTasksArray);
            let tempCombinedCatsAndTasksArray = [];
            tempCombinedCatsAndTasksArray = this.$arrayObjUtils.arrayObjsDeepCopy(this.task2DoCategories);
            // this.combinedCatsAndTasksArray = this.$arrayObjUtils.arrayObjsDeepCopy(this.task2DoCats);
           
           /**
            * This doesn't make any sense to call this...it's not returning or modifying
            * in any way the tempCombinedCatsAndTasksArray
            * //TODO: This whole component needs to be refactored!
            * //FIX:
            * //ALL: 
            * //THIS:
            * //SHIT:            
            */
           this.categorizeTasks(tempCombinedCatsAndTasksArray);

            // console.log(
            //     "\t\ttempTasksAndCats from computed combinedCatsAndTasks: " +
            //     JSON.stringify(tempCombinedCatsAndTasksArray, null, 2) +
            //     "\n"
            // );
            return tempCombinedCatsAndTasksArray;
            }
        },
    // watch: {
    //      combinedCatsAndTasks: {
    //          deep: true,
    //          handler: function
    //      }
    //     this.combinedCatsAndTasksArray = this.$arrayObjUtils.arrayObjsDeepCopy(this.task2DoCats);
    methods: {
        ...mapActions( 'tasks', {
            createTask2Do: 'createTask2Do',
            updateTask2Do: 'updateTask2Do',
        }),
        openDialog(categoryToEdit) {
        this.selectedCategory = categoryToEdit;
        this.renameDialog = true;
        },
        resetCombinedCatsAndTasks() {
            this.combinedCatsAndTasksArray = [];
            // this.combinedCatsAndTasksArray.push(JSON.parse(JSON.stringify(this.taskCategories)));
            // this.combinedCatsAndTasksArray = JSON.parse(JSON.stringify(this.taskCategories));
            //this.combinedCatsAndTasksArray = this.arrayObjsDeepCopy(this.taskCategories);

            this.combinedCatsAndTasksArray = this.$arrayObjUtils.arrayObjsDeepCopy(this.t2doAppState.task2DoCategories);
            // this.categorizeTasks(this.taskCategories);
        },
        addRootCat(taskCategory) {
            console.log("taskCategories length: " + this.taskCategories.length);
             setTimeout(() => {
                this.resetTaskAndCats();
             }, 50);
            let newIndex = this.taskCategories.length;
            let newId = newIndex + 'c0';
            let newCategory = {
                id: newId,
                level: 1,
                name: 'New Category',
                children: [
                    {
                        id: 'addTask' + newId,
                        name: 'Add Task'
                    }
                ]
            };
            // this.$set(taskCategory, newIndex, newCategory);
            this.taskCategories.push(newCategory);
            console.log("taskCategory: " + taskCategory);

        },
        addSubCat(item) {
            console.log("taskCategories length: " + this.task2DoCategories.length);
            setTimeout(() => {
                this.resetCombinedCatsAndTasks();
            }, 50);
            console.log("subTask Parent: " + JSON.stringify(item) + "\n\tsubTask keyPath: " + item.keyPath + "\n");

            this.tempCategoryTree = this.$arrayObjUtils.arrayObjsDeepCopy(this.task2DoCategories);
            console.log("this.taskAndCats before subcat: " + JSON.stringify(this.task2DoCategories) + "\n");
            this.subCat = this.getNestedArrayObjectsByIndexKeyPath(this.task2DoCategories, item.keyPath);
            let newNodeLevel = this.subCat.nodeLevel + 1;
            let newNodeIndex;
            console.log("subCat before pushing new category: " + JSON.stringify(this.subCat));
            setTimeout(() => {
                this.resetCombinedCatsAndTasks();
            }, 50);
            let newIndex = this.subCat.length;
            let newId = newIndex + 'c0';

            let categoryUUIDv4 = this.genUUIDv4();
            let newCategory = {
                id: categoryUUIDv4,
                name: 'New Sub Category',
                nodeLevel: item.nodeLevel + 1,
                nodeIndex: item.nodeIndex + 1,
                type: 'category',
                children: [
                    {
                        id: 'addTask' + categoryUUIDv4,
                        name: 'Add Task',
                        index: 0,
                        type: 'addCatButton',
                    }
                ]
            };

            console.log("newCategory template object: " + JSON.stringify(this.newCategoryTemplate) + "\n");

            if (!this.subCat.children) {
                this.subCat.children = [];
                newNodeIndex = 0;
            } else {
                newNodeIndex = this.subCat.children.length;
            }
            // this.newCategoryTemplate = this.$arrayObjUtils.getNewCategoryTemplateObject('New Category', item.keyPath + 'c', newNodeLevel, newNodeIndex);
            this.subCat.children.push(this.newCategoryTemplate);
            console.log("subCat after pushing new category: " + JSON.stringify(this.subCat));

            this.$set(this.subCat, newIndex, newCategory);
            this.subCat.push(newCategory);
            // this.taskCategories.push(newCategory);
            console.log("item: " + JSON.stringify(item));

            if (!item.children) {
                this.$set(item, "children", []);
            }

            const name = `${item.name} (${item.children.length})`;
            const id = this.nextId++;

            item.children.push(newCategory);

        },
        generateTaskTreeIds() {
            console.log("generateTaskTreeIds called");
            // id = task.category.substr(0, 2) + task.taskCategoryIndex;
        },
        loadTasks() {
            //this.categorizeTasks(this.items);
            console.log("loadTasks Called");
        },
        createNewCategoryTemplateObject(
            categoryName = "New Sub-Category",
            keyPath = "0",
            nodeLevel = 0,
            nodeIndex = 0
        ) {
        this.newCategoryTemplate = {
            id: this.genUUIDv4(),
            name: categoryName,
            type: "category",
            keyPath: keyPath + nodeIndex,
            nodeLevel: nodeLevel,
            nodeIndex: nodeIndex,
            children: [
            {
                id: "addTask" + this.genUUIDv4(),
                name: "Add Task"
            }
            ]
        };
        },
        buildCategoryKeyPaths(
            categoryArray,
            catetgoryTotalIndex = 0,
            nestDepth = 0
            ) {
            catetgoryTotalIndex += 1;
            console.log(
                "catetgoryTotalIndex: " +
                catetgoryTotalIndex +
                "\n\tcategoryArray: " +
                Object.getOwnPropertyNames(categoryArray[0])
            );
            console.log("\n\tcategoryArray length: " + categoryArray.length);
            for (let curLevelIndex = 0; curLevelIndex < categoryArray.length; curLevelIndex++) {
                if (Array.isArray(categoryArray[curLevelIndex])) {
                    nestDepth += 1;
                    this.buildCategoryKeyPaths(categoryArray[curLevelIndex].children, nestDepth);
                }
                categoryArray.keyPath = "";
            }
        },
        categorizeTasks(combinedCatsAndTasks, catIndex = 0) {
            console.log(
                "combinedCatsAndTasks length: " + combinedCatsAndTasks.length
            );
            for (let index = 0; index < combinedCatsAndTasks.length; index++) {
                console.log("Category: " + typeof combinedCatsAndTasks[index]);
                if (Array.isArray(combinedCatsAndTasks[index].children)) {
                    this.categorizeTasks(combinedCatsAndTasks[index].children, catIndex + 1);
                }

                for (let taskIndex = 0; taskIndex < this.allTasks2Do.length; taskIndex++) {
                    if (this.allTasks2Do[taskIndex].category_id === combinedCatsAndTasks[index].id) {
                        if (!combinedCatsAndTasks[index].children) {
                            combinedCatsAndTasks[index].children = [];
                        }

                        combinedCatsAndTasks[index].children.push({
                            id: this.allTasks2Do[taskIndex].id,
                            categoryId: this.allTasks2Do[taskIndex].category_id,
                            name: this.allTasks2Do[taskIndex].name,
                            createdBy: this.allTasks2Do[taskIndex].creator,
                            createdFor: this.allTasks2Do[taskIndex].creator,
                            type: 'task',
                            priorityLevel: 0,
                            categoryKeyPath: this.allTasks2Do[taskIndex].category_keypath,
                            isFinished: null,
                            start: null,
                            end: null,
                            createdOn: this.allTasks2Do[taskIndex].datetime_stamp,
                            editedOn: this.allTasks2Do[taskIndex].datetime_stamp,
                            deadlineOn: null
                        });
                    }
                }

            }
        },
        arrayObjsDeepCopy(sourceObj) {
            let destObj;
            let objValue;
            let objKey;

            if (typeof sourceObj !== "object" || sourceObj === null) {
                return sourceObj; // Return the value of sourceObj if sourceObj is not an array or object
            }

            destObj = Array.isArray(sourceObj) ? [] : {};

            for (objKey in sourceObj) {
                // if (objKey === 'id') {
                //     console.log("objectId: " + sourceObj[objKey] + "\n");
                //     sourceObj[objKey] = this.genUUIDv4();
                //     console.log("objectId assigned UUIDv4: \n\t" + sourceObj['name'] + ": " + sourceObj[objKey] + "\n");
                // }
                objValue = sourceObj[objKey];

                destObj[objKey] =
                typeof objValue === "object" && objValue !== null
                    ? this.arrayObjsDeepCopy(objValue)
                    : objValue;
            }

            return destObj;
        },
        buildNestedArrayPaths(nestedArray, parentNodePath = "", nodeLevel = 0, nodeIndex = 0, nestedNodeKeyName = "children") {
            let numElements = nestedArray.length;
            // nodeIndex will be used to target if needed..take out if it's determined to not be needed.
            for (let keyIndex = nodeIndex; keyIndex < numElements; ++keyIndex) {
                nestedArray[keyIndex].keyPath = parentNodePath + keyIndex;
                nestedArray[keyIndex].nodeLevel = nodeLevel;
                nestedArray[keyIndex].nodeIndex = nodeIndex;

                console.log(`Assigned path: ${nestedArray[keyIndex].keyPath} \n Current element stats:\n\t\t name: ${nestedArray[keyIndex].name} \n\t\t nodeLevel: ${(nestedArray[keyIndex].nodeLevel = nodeLevel)} \n\t\t nodeIndex: ${(nestedArray[keyIndex].nodeIndex = nodeIndex)}\n`);

                if (nestedArray[keyIndex][nestedNodeKeyName] !== undefined && Array.isArray(nestedArray[keyIndex][nestedNodeKeyName])) {
                    let elmPathToNextNode = nestedArray[keyIndex].keyPath + "c";
                    this.buildNestedArrayPaths(nestedArray[keyIndex][nestedNodeKeyName], elmPathToNextNode, nodeLevel + 1);
                }
                nodeIndex += 1;
            }
        },
        getNestedArrayObjectsByIndexKeyPath(nestedArray, pathString) {
            pathString = pathString.replace(/\[(\w+)\]/g, ".$1");
            pathString = pathString.replace(/^\./, "");
            let keyIndexes = [...pathString];

            let numKeyIndexes = keyIndexes.length;
            for (let i = 0; i < numKeyIndexes; ++i) {
                let keyIndex = keyIndexes[i];
                if (keyIndex === "c") {
                    keyIndex = "children";
                }
                if (keyIndex in nestedArray) {
                    nestedArray = nestedArray[keyIndex];
                } else {
                    return;
                }
            }
            return nestedArray;
        },

        // function buildObjPaths(objData, nodeLevel=0, leafNum=0, parentObjPath='') {
        //     for(key in objData) {
        //         if((typeof(objData[key]) == "object") && (objData[key] != null)) {
        //             objData[key]["parentObjPath"] = parentObjPath;
        //             objData[key]["idTag"] = key + objData[key]["objName"] + "-id-" + nodeLevel + "-" + leafNum;
        //             curObjPath = parentObjPath + '[' + key + ']';
        //             if ((parentObjPath === '') && (nodeLevel === 0)) {
        //                 //this is the root node
        //                 objData[key]["fullObjPath"] = curObjPath;
        //                 buildObjPaths(objData[key], ++nodeLevel, leafNum, curObjPath);
        //             } else if ((typeof(objData[key]["children"]) == "object") && (Array.isArray(objData[key]["children"]) != true) && (((objData[key]["children"]) != undefined) || ((objData[key]["children"]) != null))) {
        //                 //looks like we have a subtree object
        //                 objData[key]["fullObjPath"] = curObjPath;
        //                 buildObjPaths(objData[key]["children"], ++nodeLevel, leafNum, curObjPath + '[children]');
        //             } else if (objData[key]["children"] === undefined) {
        //                 //looks like we've reached a leaf
        //                 ++leafNum;
        //                 objData[key]["fullObjPath"] = curObjPath;
        //             }
        //         } // any thing else would be a key:value property and will ignore
        //     }
        //     return;
        // } */
        genUUIDv4() {
            let uuidV4 = "";
            let random;

            for (let i = 0; i < 32; i++) {
                random = (Math.random() * 16) | 0;

                if (i === 8 || i === 12 || i === 16 || i === 20) {
                uuidV4 += "-";
                }

                uuidV4 += (i === 12
                ? 4
                : i === 16
                ? (random & 3) | 8
                : random
                ).toString(16);
            }

            return uuidV4;
        }
    }
};
</script>