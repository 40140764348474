

<template>
    <v-container class="widget" :class="[
        minimizeState ? minimizeClassName : ' ',
        maximizeState ? maximizeClassName : ' ',
        focusedWidget === 'BlogsWidget' ? ' focusedWidget ' : ' backgroundWidget ',
        fixedWidget ? ' fixedWidget ' : '' 
    ]" >

        <div style="position: absolute; display: block; background: unset; opacity: 0; left: 0; top:0; width: 100%; height: 100%; z-index: 1500;" 
            v-if="focusedWidget !== 'BlogsWidget'"
            @click.stop="$emit('bring-to-front', 'BlogsWidget')">
        </div>

        <v-card class="widget-window-outer-container" :class="[ fixedWidget ? ' fixed-widget ' : '']" style="background: rgba(26, 26, 26, .95);">

            <SystemBar :widgetLabel="widgetLabel"
                        :minimizeState="minimizeState"
                        v-on:minimize="minimize"
                        :maximizeState="maximizeState"
                        v-on:maximize="maximize"
                        v-on:closeWidget="closeWidget"
                        :notifications="notificationsList"
                        :notificationType="notificationString"
                        :fixedWindow="fixedWidget" />

            <v-container  class="widget-inner-container" style="max-height: calc(90vh - 1rem); height: 100%; margin: -0.125rem auto .5rem; padding: .125rem;">
            <!-- <v-container class="widget-inner-container" fluid style="max-height: calc(100% - 3rem); height: 100%; overflow-y: auto; margin: -0.125rem auto .25rem; padding: .125rem;"> -->
            <!-- <v-container class="widget-inner-container" style="max-height: calc(90vh - 1rem); height: 100%; margin: -0.125rem auto .5rem; padding: .125rem;"> -->
    
                <v-row dense>
                    <v-col cols="12">
                        <v-card>
                            <v-list
                                subheader
                                two-line
                                >
                                <v-subheader inset>Journals</v-subheader>

                                <v-list-item
                                    v-for="journal in getJournals"
                                    :key="journal.id"
                                    @click="selectJournal(journal)"
                                >
                                    <v-list-item-avatar>
                                    <v-icon
                                        class="grey lighten-1"
                                        dark
                                    >
                                        mdi-folder
                                    </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                    <v-list-item-title v-text="journal.name"></v-list-item-title>

                                    <v-list-item-subtitle v-text="journal.summary"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                    <v-btn icon>
                                        <v-icon color="grey lighten-1">mdi-information</v-icon>
                                    </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
               
                        <v-data-table
                            :headers="headers"
                            :items="selectedJournal.data.journalEntries"

                            sort-by="entryDate"
                            class="elevation-1"
                            @click:row="showJournalEntry"
                            >

                            <template v-slot:top style="overflow-y: auto;">
                                <v-toolbar flat>
                                    <v-toolbar-title>Current Journal</v-toolbar-title>
                                    <v-divider
                                        class="mx-4"
                                        inset
                                        vertical
                                    ></v-divider>
                                    <v-spacer></v-spacer>
                                    
                                    <v-dialog
                                        v-model="showJournalDialog"
                                        max-width="500px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                dark
                                                class="mb-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >New Journal Entry</v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5">{{ formTitle }}</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="4"
                                                        >
                                                            <v-text-field
                                                                v-model="editedItem.entryDate"
                                                                label="Journal Entry Date"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="4"
                                                        >
                                                            <v-text-field
                                                                v-model="editedItem.name"
                                                                label="Journal Entry Title"
                                                            ></v-text-field>
                                                        </v-col>

                                                        <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="4"
                                                        >
                                                            <v-text-field
                                                                v-model="editedItem.body"
                                                                label="Journal Entry Body"
                                                            ></v-text-field>
                                                        </v-col>

                                                        <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="4"
                                                        >
                                                            <v-text-field
                                                                v-model="editedItem.category"
                                                                label="Journal Entry category"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="closeJournalForm"
                                                >Cancel</v-btn>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="saveJournalForm"
                                                >Save</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                    <v-dialog v-model="showDeleteDialog" max-width="500px">
                                        <v-card>
                                            <v-card-title class="text-h5">Are you sure you want to delete this journal entry?</v-card-title>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Yes</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <!-- <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="editItem(item)">
                                    mdi-pencil
                                    mdi-pencil
                                </v-icon>
                                <v-icon small @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template> -->

                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    small
                                    @click="deleteItem(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <template v-slot:no-data>
                                <v-btn color="primary" @click="initialize">
                                    Reset
                                </v-btn>
                            </template>
                        </v-data-table>    
                        <InformationDialog
                            v-if="showInformationDialog"
                            :showInformationDialog="showInformationDialog"
                            :informationMessage="informationDialogMessage"
                            :informationTitle="informationDialogTitle"
                            :informationTimeStamp="informationDialogTimestamp"
                            @confirmInfoMessage="confirmInfoMessage"
                        />    
                             
                    </v-col>
                </v-row>
              
                   
            </v-container>
       
          </v-card>
       
    </v-container>
    
</template>

<script>
// import TitleBar from "@/components/widgets/components/TitleBar.vue";
import WindowBar from "@/components/widgets/components/WindowBar.vue";
import { mapGetters, mapActions } from "vuex";
import SystemBar from "@/components/widgets/components/SystemBar.vue";
import InformationDialog from "@/components/dialogs/InformationDialog";

export default {
    name: "BlogsWidget",
    components: {
        // TitleBar,
        WindowBar,
        SystemBar,
        InformationDialog
    },
    props: ["focusedWidget", "fixedWidget"],
    data: () => ({
        componentName: 'BlogsWidget',
        widgetLabel: "Blogs - Journals",
        componentName: "BlogsWidget",
        minimizeInBackground: true,
        minimizeInForeground: false,
        minimizeClassName: "minimize-widget",
        maximizeClassName: "maximize-widget",
        maximizeState: false,
        notificationsList: [],
        notificationString: "",
        apiBlogs: [],
        showJournalDialog: false,
        showDeleteDialog: false,
        showInformationDialog: false,
        informationDialogTitle: '',
        informationDialogTimestamp: null,
        informationDialogMessage: '',
        selectedJournal: {
            data: {
                journalEntries: []
            }
        },
        selectedJournalIndex: 0,
        headers: [
            {
                text: 'Date',
                align: 'start',
                value: 'entryDate'
            },
            {
                text: 'Title',
                // align: 'start',
                sortable: false,
                value: 'name'
            },
            // {
            //     text: 'Body',
            //     sortable: false,
            //     value: 'body'
            // },
            // {
            //     text: 'Category',
            //     value: 'category'
            // },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            }
        ],
        journalEntries: [],
        editedIndex: -1,
        editedItem: {
            id: '',
            entryDate: '',
            name: '',
            body: '',
            category: '',

        },
        tempJournalEntryObj: {
            alert_id: null,
            body: null,
            categories: null,
            color: '',
            created_by_id: null,
            created_for_id: null,
            created_on: null,
            data: {},
            duration: null,
            edited_on: null,
            end: null,
            end_unix_timestamp: 0,
            endISO: '',
            finished_on: null,
            has_alert: null,
            has_push_notification: null,
            id: '',
            index: null,
            in_reference_to: null,
            is_archieved: null,
            is_finished: null,
            name: null,
            nested_level: null,
            note: null,
            owner_screen_name: null,
            parent_id: null,
            priority_lvl_id: 1,
            progress_percent: null,
            start: null,
            start_unix_timestamp: 0,
            startISO: '',
            status: null,
            subject: null,
            summary: null,
            timed: null,
            type_id: 1,
            tempObj: true
        },
    }),
    computed: {
       
        ...mapGetters( 'tasks', {
            allTasks2Do: 'allTasks2Do', 
            getJournals: 'getJournals',
            taskTypes: 'taskTypes',
            emptyTaskObj: 'emptyTaskObj'
        }),
        minimizeState() {
            // basically apply foreground state when focused, otherwise apply background state

            // return (this.t2doDisplaySettings.componentsDisplayState.focusedWidget  === this.componentName) ? this.minimizeInForeground : this.minimizeInBackground; 
             return (this.focusedWidget === this.componentName) ? (this.minimizeInForeground === true ? true : false) : (this.minimizeInBackground === true) ? true : false; 
        },
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
            
        },
        defaultItem() {
            return {
            id: this.$jsUtils.genUUIDv4(),
            entryDate: this.$datedJS.dated.getLocalISODateTime(new Date()),
            name: '',
            body: '',
            category: ''
            }

     
        }

    },
    watch: {
        showJournalDialog (val) {
            val || this.closeJournalForm();
        },
        showDeleteDialog(val) {
            val || this.closeDeleteConfirm();
        }
    },
    created() {
        this.initialize();
    },
    mounted() {
        // this.getBlogs();
    },
    updated: function() {
        if (this.focusedWidget !== this.componentName) {
        // if (this.t2doDisplaySettings.componentsDisplayState.focusedWidget !== this.componentName) {
        //     // This resets the foreground state to ensure widget isn't minimized when regaining focus
            this.minimizeInForeground = false;
        }
    },
    methods: {
         ...mapActions( 'uiState', {
            setFocusedWidget: 'setFocusedWidget',
            toggleShowWidget: 'toggleShowWidget'
         }),
        ...mapActions( 'tasks', {
            createTask2Do: 'createTask2Do',
            updateTask2Do: 'updateTask2Do'
        }),
        minimize() {
            this.minimizeInForeground = !this.minimizeInForeground;
            this.minimizeInBackground = this.minimizeInForeground;

            if (this.maximizeState === true) {
                this.maximizeState = false;
            }
        },
        maximize() {
            if (this.maximizeState === false) {
                this.maximizeState = true;
                this.minimizeInForeground = false;
                this.minimizeInBackground = this.minimizeInForeground;
            } else {
                this.maximizeState = false;
            }
        },
        closeWidget() {
            this.toggleShowWidget(this.componentName);
            // if (this.t2doDisplaySettings.componentsDisplayState.focusedWidget === this.name) {
                /******************************************************************
                 * // TODO: the open widgets should be stored in an array instead of
                 * keeping track of a xcrap tone a variables. This will also have the 
                 * benefit of moving focus to the next widget.
                 * *****************************************************************/
            //     this.setFocusedWidget(this.name);
            // }

        },
        initialize() {
            // this.journalEntries = this.$arrayObjUtils.arrayObjsDeepCopy(this.getJournals[this.selectedJournalIndex].data.journalEntries);
            this.selectedJournal =  this.$arrayObjUtils.arrayObjsDeepCopy(this.getJournals[this.selectedJournalIndex]);
            

            // this.journalEntries = [
            //     {
            //         id: '8944c50a-3129-45f3-a24e-747d0b81f726',
            //         entryDate: '2021-08-09 08:00',
            //         entryTime: '8:00',
            //         name: 'My First journal entry',
            //         body: 'This is my first journal entry',
            //         category: 'Personal',
                    
            //     },
            //     {
            //         id: 'ea42acd7-8d8f-4935-8768-2e41ad146b32',
            //         entryDate: '2021-08-08 18:59',
            //         entryTime: '18:59',
            //         name: 'Riders without helmets on 4 wheeler',
            //         body: 'riding behind shop',
            //         category: 'Chronicles Of Fu',
                    
            //     },
            //     {
            //         id: '7da83965-555c-4f6d-a10d-0612190a9816',
            //         entryDate: '2021-08-08 09:10',
            //         entryTime: '9:10',
            //         name: 'Kids driving ATV with no helmets',
            //         body: 'riding behind shop',
            //         category: 'Chronicles Of Fu',
                    
            //     },
            //     {
            //         id: '4fb4ec79-bef8-4216-8d1c-ec0886df23a4',
            //         entryDate: '2021-08-08 10:43',
            //         entryTime: '10:43',
            //         name: '2 young Kids on ATV with no helmets, unsupervised',
            //         body: 'riding behind shop',
            //         category: 'Chronicles Of Fu',
                    
            //     },
            // ]
        },
        selectJournal(journal) {
            this.selectedJournalIndex = this.getJournals.indexOf(journal);
            this.selectedJournal =  this.$arrayObjUtils.arrayObjsDeepCopy(this.getJournals[this.selectedJournalIndex]);

            // this.journalEntries = this.selectedJournal.data.journalEntries;
        },
        showJournalEntry(journalItem) {
            this.informationDialogTitle = journalItem.name;
            this.informationDialogTimestamp = "Date of entry: " + journalItem.entryDate;
            this.informationDialogMessage = journalItem.body;
            this.showInformationDialog = true;
        },
        confirmInfoMessage() {
            this.showInformationDialog = !this.showInformationDialog;
        },
        editItem(item) {
            // this.editedIndex = this.journalEntries.indexOf(item);
            this.editedIndex = this.selectedJournal.data.journalEntries.indexOf(item);
            
            this.editedItem = this.$arrayObjUtils.arrayObjsDeepCopy(item);
            this.showJournalDialog = true;
        },
        deleteItem(item) {
            // this.editedIndex = this.journalEntries.indexOf(item);
            this.editedIndex = this.selectedJournal.data.journalEntries.indexOf(item);

            this.editedItem = this.$arrayObjUtils.arrayObjsDeepCopy(item);
            this.showDeleteDialog = true;
        },
        deleteItemConfirm() {
            // this.journalEntries.splice(this.editedIndex, 1);
            this.selectedJournal.data.journalEntries.splice(this.editedIndex, 1);
           
            this.updateTask2Do(this.selectedJournal);
            this.closeDelete();
        },
        closeJournalForm() {
            this.showJournalDialog = false;
            this.$nextTick(() => {
                this.editedItem = this.$arrayObjUtils.arrayObjsDeepCopy(this.defaultItem);
                this.editedIndex = -1;
               

            });
        },
        closeDeleteConfirm() {
            this.showDeleteDialog = false;
            this.$nextTick(() => {
                this.editedItem = this.$arrayObjUtils.arrayObjsDeepCopy(this.defaultItem);
                this.editedIndex = -1;
            })
        },
        saveJournalForm() {
            if (this.editedIndex > -1) {
                // this.journalEntries[this.editedIndex] = this.$arrayObjUtils.arrayObjsDeepCopy(this.editedItem);
                
                this.selectedJournal.data.journalEntries[this.editedIndex] = this.$arrayObjUtils.arrayObjsDeepCopy(this.editedItem);
                this.updateTask2Do(this.selectedJournal);
            } else {
                // this.journalEntries.push(this.editedItem);
                this.editedItem.id = this.$jsUtils.genUUIDv4();
                this.selectedJournal.data.journalEntries.push(this.editedItem);
                this.updateTask2Do(this.selectedJournal);
                this.selectedJournal =  this.$arrayObjUtils.arrayObjsDeepCopy(this.getJournals[this.selectedJournalIndex]);
            }
            this.closeJournalForm();
        },
        async getBlogs() {
            console.log("Blogs currentJwt: " + this.$store.getters.currenJwt);
            let fetchParams = {
                method: "GET",
                headers: new Headers({
                Authorization: "Bearer " + this.$store.getters.currenJwt
                })
            };

            let response = await fetch(
                `https://ts-api.t2do.io/fileorg/note/search/note_creator/Brando`,
                fetchParams
            );
            let apiBlogData = await response.json();
            this.apiBlogs = [];
            window.console.log(
                "Data from Trinix API: " + JSON.stringify(apiBlogData)
            );
            apiBlogData.forEach(note => {
                let noteData = note;
                noteData.title = note.note_name;
                noteData.timestamp = note.note_datetime_stamp;
                noteData.subject = note.note_subject;
                noteData.details = note.note_msg;

                this.apiBlogs.push(noteData);
            });
            this.apiBlogs = this.apiBlogs.reverse();
            this.apiBlogs.length = 13;
            //this.apiNotes = apiNoteData
        }
    }
};
</script>

<style scoped>
    .is-complete {
    text-decoration: line-through;
    }
</style>
