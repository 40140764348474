<template>
    <v-dialog v-model="displayDaySummary" overlay-opacity="1" overlay-color="rgba(13, 13, 13, .75)" width="98%" persistent max-width="98%"> 
        <v-card class="daily-summary">
                      <v-icon>mdi-dots-vertical</v-icon>
            <v-toolbar dense dark style="height: 3.5rem; position: relative; display: block; top: 0px; width: 100%;">
                <v-btn icon>
                </v-btn>
                <v-toolbar-title>
                    Summary of Events for {{ formattedDate }}
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn icon>
                    <!-- <v-icon>mdi-pencil</v-icon> -->
                    <v-icon  @click="onCloseButton()">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <!-- <v-card-text style="display: block; margin: 0.75rem 0.5rem; text-align: left; width: 70vw; max-height: 75%; height: 71vh; overflow-y: auto;top: 0rem;position: relative;left: 1rem;bottom: 5rem; border: .0875rem solid rgba(150, 175, 200, .95);"> -->

            <v-card-text  class="daily-summary-flexible-container flex-direction">
                <div class="daily-summary-inner-container days-events-flexbox">
                    <!-- <form v-if="tempEventObj.id !== selectedEvent.id"> -->
                    <div v-for="event in daysEvents " :key="event.id" >
                        <!-- <span> Task #: {{ event.id }} </span> <br class=""> -->

                        <span class="clickable-task-date" @click="openTaskSummary(event.id)" > 
                            <span style="margin: .5rem .25rem .25rem .5rem; letter-spacing: .05rem;">
                                <strong style="margin-left: -1rem;">{{ $datedJS.dated.getTwelveHourTime(event.start, false) }}</strong> - <strong>{{ $datedJS.dated.getTwelveHourTime(event.end, false) }}</strong>
                            </span>                    
                            <span class="clickable-task-type" :style="{ backgroundColor: getTaskTypes[event.type_id].typeColor }">[{{ getTaskTypes[event.type_id].typeName }}]</span> 
                                <br class="">
                        </span>
                        <div class="event-name-details-line" :style="{ borderBottom: '.35rem solid ' + getTaskTypes[event.type_id].typeColor }" style="margin-left: 2.5rem;" >
                            <span class="clickable-task-name" @click="openTaskSummary(event.id)">
                                <span> 
                                    <strong style="max-width: 20rem;" class="task-event-name"> {{ event.name }}</strong>
                                </span>
                            </span>
                            <span  v-if="event.body" @click="expandDetails(event.id)" class="task-event-details">- Details: </span><br class="">
                        </div>
                        <div :class="[ (expandedTaskId === event.id) ? 'details-expanded' : 'details-collapsed' ]" > <span v-if="expandedTaskId === event.id" v-html="taskBodyHTML(event.body)"></span> </div>   
                    </div>
                </div>
                
                <div  class="daily-summary-inner-container unfinished-todos-flexbox">
                    <span  style="backgroundColor: rgba(13, 13, 64, .85);" > <strong style="font-size: 125%;"> Misc Todos</strong> </span><br class="">
                    <ol style="padding: 0;">
                        <li class="clickable-task-name" v-for="todo in getUnFinishedTodos" :key="todo.id" style="margin: .25rem 0; line-height: 1.15; cursor: pointer;" :style="{ backgroundColor: getEventColor(todo.priority_lvl_id) }" @click="openTaskSummary(todo.id)">
                            <span style="margin-left: 1rem; font-family: 'Anonymous Pro';  font-size: 100%; font-weight: 700; color: rgba(245, 75, 25, .95);">{{ $datedJS.dated.getLocalISODate(todo.end) }} </span>
                            <span style="font-family: 'Anonymous Pro'; font-size: 1rem; color: rgba(245, 175, 75, .95); display: inline-block; margin-left: -.25rem; width: 3.95rem; text-align: right;">{{ $datedJS.dated.getTwelveHourTime(todo.end, false) }}</span>
                            <span> - <strong class="task-event-name"> {{ todo.name }} </strong></span> - <span style="color: rgba(235, 125, 50, .95);"> {{todo.progress_percent }}% done </span>
                        </li>

                    </ol>
                </div>
            </v-card-text>
            <v-card-actions style="height: 3.5rem; background: rgba(13, 13, 64, .5);">
                <v-btn text style="background: rgba(13, 64, 13, .95); color: rgb(200, 225, 225); margin: 0 auto;" @click="onCloseButton()">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    name: "DailySummary",
    // props: [
    //     "daySummaryDate"
    // ],
    data: () => ({
        componentName: "DailySummary",
        shaped: false,
        outlined: false,
        rounded: false,
        // dense: false,
        autofocus: true,
        expandedTaskId: null,
    }),
    computed: {
        ...mapGetters('tasks', {
            allTasks2Do: 'allTasks2Do',
            getTaskPriorityLevels: 'getTaskPriorityLevels',
            getTaskTypes: 'getTaskTypes',
            getEventsFromDate: 'getEventsFromDate',
            getTodaysTasks: 'getTodaysTasks',
            getUnFinishedTodos: 'getUnFinishedTodos'
        }),
        ...mapGetters('uiState', {
            getDaySummaryState: 'getDaySummaryState',
            getTaskSummaryState: 'getTaskSummaryState'

        }),
        daysEvents() {
            let daysEvents = this.getEventsFromDate(this.daySummaryDate);
            daysEvents.sort(function(a, b) {
                return a.start_unix_timestamp - b.start_unix_timestamp;
            });
            return daysEvents;
        },
        displayDaySummary() {
            return this.getDaySummaryState.show;
        },
        daySummaryDate() {
            console.log('this.getDaySummaryState.date: ' + this.getDaySummaryState.date);
            return this.getDaySummaryState.date;
        },
        formattedDate() {
            return this.$datedJS.dated.getLocalISODate(this.getDaySummaryState.date);
        }
      
    },
    created() {
    
        console.log("Display DailySummary created function called");
    },
    mounted() {
        console.log('daySummaryDate: ' + this.daySummaryDate);
    },
    methods: {
        ...mapActions( 'uiState', {
            toggleDaySummary: 'toggleDaySummary',
            openTaskSummary: 'openTaskSummary',
            closeTaskSummary: 'closeTaskSummary'
        }),
        getEventColor(taskPriorityLevelId) {
            if (taskPriorityLevelId > 1) {
                return this.getTaskPriorityLevels[parseInt(taskPriorityLevelId) - 1].levelColor;
            } else {
                return this.getTaskPriorityLevels[1].levelColor;
            }
          
        },
        onCloseButton() {
            console.log(`calling: toggleDaySummary`);
            // let today = new Date;
            this.toggleDaySummary( this.getDaySummaryState.date );
           
        },
        expandDetails(taskId) {
            if ((this.expandedTaskId === null) || (this.expandedTaskId !== taskId)){
                this.expandedTaskId = taskId;
            } else {
                this.expandedTaskId = null;
            }
            
        },
        taskBodyHTML(eventBody = '') {
            return eventBody.replaceAll('\n', '<br class=\"\">');
        }
        
    }
};
</script>

<style>

#task2do-app-id  .daily-summary-flexible-container {
    display: inline-flex;
    flex-wrap: wrap;
    max-height: calc(96% - 7rem);

}

#task2do-app-id .daily-summary-flexible-container .flex-direction {
    flex-direction: column;
}

#task2do-app-id .daily-summary-flexible-container .daily-summary-inner-container  {
    position: relative;
    display: inline-block;
    /* left: 0;
    top: 0; */
    margin: 0.25rem 0.25rem;
    padding: 0.25rem 0.25rem;
    min-width: 24rem;
    max-width: 100%;
    width: 100%;
    /* max-height: 35vh; */
    max-height: 31vh;
    
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    background: rgba(13, 13, 26, 0.5) none repeat scroll 0% 0%;
    border: 0.0875rem inset rgba(150, 175, 200, 0.975);
    border-radius: .5rem;
    
}

#task2do-app-id .daily-summary .task-event-name {
    font-family: Prompt;
    font-size: 1.15rem;
    font-weight: 500;
    color: rgb(225, 225, 250);

}

#task2do-app-id .daily-summary-flexible-container .clickable-task-date {
    margin: .25rem .25rem .25rem .5rem;
    padding: .25rem .75rem .15rem .25rem;
    border-radius: .25rem;
    line-height: 1;
    cursor: pointer;
    font-family: 'Tomorrow';
    font-size: 1rem;
    font-weight: 500;
    color: rgb(175, 100, 0);
    vertical-align: middle;
    transition: 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

}

#task2do-app-id .daily-summary-flexible-container  .clickable-task-date:hover {
 
  /* transform: translate(-50%, -50%) scale(1.05); */
 
  color: rgb(225, 125, 0);
  transform: scale(1.05);
  transition: 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
    
}

#task2do-app-id .daily-summary-flexible-container .clickable-task-type {
  padding: 0.2rem .235rem .25rem;
  color: rgb(225, 235, 250);
  font-size: .9rem;
  letter-spacing: .05rem;
  font-variant: all-small-caps;
  vertical-align: text-top;
}

#task2do-app-id .daily-summary-flexible-container .event-name-details-line {
  
  border-bottom: 0.35rem solid rgba(100, 50, 0, 0.95);
  top: 0.15rem;
  display: inline-block;
  position: relative;
  height: 1.6rem;
  height: auto;
  margin: 0.125rem 0 0.75rem 2.5rem;
  width: 100%;
}

#task2do-app-id .details-expanded span {
    user-select: text;
}

#task2do-app-id .daily-summary-flexible-container .clickable-task-name {
  margin: -0.15rem .25rem 0.5rem 0.25rem;
  padding: 0.05rem .25rem 0.15rem .25rem;
  border-radius: .25rem;
  line-height: 1.35;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  transition: 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
  color: rgb(220, 200, 235);
  vertical-align: initial;
  display: inline-block;
}

#task2do-app-id .daily-summary-flexible-container .clickable-task-name:hover {
 
  /* transform: translate(-50%, -50%) scale(1.05); */
  color: rgb(240, 240, 255);
  transform: scale(1.05);
  transition: 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
    
}

#task2do-app-id .daily-summary-flexible-container .daily-summary-inner-container .task-event-details {
  margin: 0.125rem 0 0.125rem 0.125rem;
  line-height: 1.35;
  font-size: 115%;
  font-weight: 700;
  color: rgb(225, 225, 245);
  cursor: pointer;
  padding: 0;
  display: inline-block;
  font-family: Prompt;
  letter-spacing: .025rem;
  transition: 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
  user-select: text !important;

}

#task2do-app-id .daily-summary-flexible-container .daily-summary-inner-container .task-event-details:hover {
    color: rgb(240, 245, 255);
    transition: 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

}

#task2do-app-id .daily-summary-flexible-container .daily-summary-inner-container div {
    display: block;
    margin: .125rem .5rem .125rem .25rem;
    /* transition: .20s height cubic-bezier(0.25, 0.8, 0.5, 1); */
    /* transition: .15s all ease-in-out; */
    /* position: relative; */
}
 

#task2do-app-id .daily-summary-flexible-container .daily-summary-inner-container.days-events-flexbox div.details-expanded {
  /* height: auto; */
  height: 100%;
  margin-bottom: .5rem;
  /* overflow: visible; */
  opacity: 1;
  
  font-family: Tomorrow;
  font-size: 1.1rem;
  font-weight: 400;
  margin: .5rem .25rem .75rem 2.5rem;
  color: rgb(245, 245, 255);
  letter-spacing: .05rem;
  line-height: 1;
  /* transition:.15s all cubic-bezier(0.25, 0.8, 0.5, 1); */
  transition: .25s height cubic-bezier(0.5, 1.25, 0.75, 1), .3s opacity ease-in-out;
  /* transition: .15s height cubic-bezier(0.5, 1.25, 0.75, 1);  */
  /* transition: .15s all ease-in-out; */
}

#task2do-app-id .daily-summary-flexible-container .daily-summary-inner-container.days-events-flexbox div.details-collapsed {
    height: 0;
    opacity: 0;
    /* min-height: 0;
    max-height: 0;
    top: 0;
    bottom: 0; */
    transition:  .25s opacity ease-in-out, .5s height cubic-bezier(0.5, 1.25, 0.75, 1);
    /* transition: .3s height cubic-bezier(0.5, 1.25, 0.75, 1);  */
    /* transition: .3s height cubic-bezier(0.25, 0.8, 0.5, 1); */
    /* transition: .3s all ease-in-out; */
}

@media screen and (min-width: 800px) {
    #task2do-app-id  .daily-summary-flexible-container .daily-summary-inner-container  {
        min-width: 20rem;
        max-width: 48%;
        font-size: 95%;
        max-height: 55vh;
    }

    #task2do-app-id  .daily-summary-flexible-container .flex-direction {
        flex-direction: row;
    }
}



/* #task2do-app-id  .daily-summary-flexible-container .unfinished-todos-flexbox {


   


} */
</style>