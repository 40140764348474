<template>
    <div class="auto-fill-container">
            <!-- <input
                v-model="inputString"
                @input="onInput"
                @focus="openDropDown"
                type="text"
             /> -->
             <!-- 
                  @blue="loseFocus"
                  @blur="loseFocus"
              -->
            <v-text-field class="mt-1"
                v-model="inputString"
                @input="onInput"
                @click="openDropDown"
                @focus="[  openDropDown(), $event.target.select() ];"
               
                type="text"
                dense
                outlined
                :label="inputLabel"
            ></v-text-field> 
            <ul 
            v-show="showSuggestions"
            class="auto-fill-suggestions">
                <li 
                    v-for="(suggestion, i) in suggestionsObjects"
                    :key="i"
                    @click="selectSuggestion(suggestion, suggestion['index'])"
                    class="auto-fill-suggestion"
                >
                   <span v-if="typeof(suggestion) === 'string'"> {{ suggestion }} </span>
                   <span v-else> {{ suggestion['name'] }} </span>
                </li>
            </ul>  
    </div>
</template>

<script>
    export default {
        name: 'AutoFillFormFields',
        props: [ 'inputValue', 'suggestionsPoolObjects', 'inputVariableName', 'inputLabel' ],
        //  {
        //     SuggestionsPool: {
        //         type: Array,
        //         required: false,
        //         default: () => [],
        //     },
        //     inputVariableName: {
        //         type: String,
        //         required: true,
        //         default: ''  
        //     }
        // },
        data: () => ({
            inputString: '',
            showSuggestions: false,
            suggestionsObjects: [{}],
        }),
        created() {
            // this.suggestions = this.suggestionsPool;
             console.log('AutoFillFormFields.vue created method called');
            this.inputString = this.inputValue;
            
        },
        mounted() {
            console.log('mounted called in AutoFillFormFields.vue');
            
             this.filterSuggestions();
            document.addEventListener('click', this.handleClickOutside);
            console.log('typeof(suggestionsPoolObjects): ' + typeof(this.suggestionsPoolObjects));

            // this.fetchNotes();
        },
        updated() {
            console.log('updated method called in AutoFillFormFields.vue');
            console.log('this.inputString: ' + this.inputString);
            console.log('this.inputValue: ' + this.inputValue);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
        methods: {
            filterSuggestions() {
                console.log('typeof(suggestionsPoolObjects): ' + typeof(this.suggestionsPoolObjects));
                console.log('typeof(suggestionsPoolObjects[0]): ' + typeof(this.suggestionsPoolObjects[0]));
        
                // if (typeof(this.suggestionsPoolObjects) === 'array') {

                this.suggestionsObjects = this.suggestionsPoolObjects.filter(suggestionObj => suggestionObj['name'].toLowerCase().indexOf(this.inputString.toLowerCase()) > -1);

                // Sort taskStatusTypes by occurrences property
                this.suggestionsObjects.sort(function(a, b) {
                    return b.occurrences - a.occurrences;
                });

                console.log('this.suggestionsObjects after sort: ' + JSON.stringify(this.suggestionsObjects, null, 1));
                // } 
            },
            onInput() {
                this.filterSuggestions();
                this.showSuggestions = true;
                this.$emit( 'updateTextInput', { 'inputVariableName': this.inputVariableName, 'inputString': this.inputString } );
            },
            openDropDown() {
                this.showSuggestions = true;
            },
            loseFocus() {
                this.showSuggestions = false;
                console.log('Lost focus on auto fill input field');
                console.log('emitting updateTextInput with the following data: \n\tthis.inputVariableName: ' + this.inputVariableName + '\n\tthis.inputString: ' + this.inputString);
                // this.$emit( 'updateTextInput', { this.inputVariableName, this.inputString } );
                this.$emit( 'updateTextInput', { 'inputVariableName': this.inputVariableName, 'inputString': this.inputString } );
                this.$emit( 'loseFocus' );
            },
            selectSuggestion(suggestion, index) {
                
                // if (typeof(suggestion) === 'string') {
                console.log('selectSuggestion suggestion: ' + suggestion + 'with index of: ' + index);
                //     this.inputString = suggestion;
                    
                // } else {
                    // console.log('selectSuggestion suggestion[this.suggestionsPoolValue]: ' + suggestion[this.suggestionsPoolValue] + 'with index of: ' + index);
                    // this.inputString = suggestion[this.suggestionsPoolValue];
                // }

                this.inputString = this.suggestionsPoolObjects[index]['name'];
                this.showSuggestions = false;
                this.$emit( 'updateFormFields', this.suggestionsPoolObjects[index] );
               
                
            },
            handleClickOutside(event) {
                if (!this.$el.contains(event.target)) {
                    this.showSuggestions = false;
                }
            }
        }
    };
</script>

<style>
    /* #app {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        margin-top: 60px;
    } */
    :root {
        --left-offset: .5rem;
    }

    .auto-fill-container {
        position: relative;
        /* width: 5.75rem; */
        width: calc(100% - var(--left-offset));
    }

    .auto-fill-container input {
        width: 100%;
        /* width :6rem; */
    
    }

    .auto-fill-suggestions {
        /* top: -1rem; */
        padding: 0;
        position: fixed;

        padding-top: .125rem;
        margin: 0;
        margin-left: var(--left-offset);
        margin-top: -1.5rem;
        border: 1px solid #eeeeee;
        max-height: 33vh;
        height: auto;
        overflow: auto;
        z-index: 1;
        width: calc(90% - calc(var(--left-offset) * 2));
        background: rgba(130, 130, 130, .5);
        border: .25rem inset rgba(13, 220, 250, .995);
        border-bottom-left-radius: .35rem;
        border-bottom-right-radius: .35rem;
        box-shadow: 1.125rem 0.125rem 0.5rem rgba(6, 6, 6, .95);
        border-top: none;
    }

    .auto-fill-suggestion {
        list-style: none;
        margin: 0;
        text-align: left;
        line-height: 1.1;
        padding: .25rem 0;
        margin-left: -1.5rem;
        padding-left: 1rem;
        font-size: 1.1rem;
        color: rgb(240, 245, 250);
        font-family: Tomorrow;
        /* font-family: Prompt; */
        font-weight: 400;
        letter-spacing: .035rem;
        background-color: rgba(26, 26, 26, .65);
    }

    .auto-fill-suggestion:nth-child(2n+1) {
        background-color: rgba(6, 6, 6, .65);
    }

    .auto-fill-suggestions > .auto-fill-suggestion.is-active,
    .auto-fill-suggestions > .auto-fill-suggestion:hover {
        background-color: rgba(25, 50, 175, .85);
        color: rgb(225, 225, 250);
    }

</style>

