<template>
    <v-dialog v-model="showErrorDialog" persistent max-width="500"> 
        <v-card class="error-dialog">
            <v-card-title>
                <span class="mdi mdi-alert orange-alert-icon">{{ errorCode }} Error: {{ errorTitle }}</span>
            </v-card-title>
            <v-card-text>  {{ errorMessage }}</v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="onConfirmError" class="full-width" style="margin: auto;">OK</v-btn>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
    name: "DisplayErrorDialog",
    props: [
        "showErrorDialog",
        "errorTitle",
        "errorMessage",
        "errorCode", // seletedItemType used to distinguish between tasks, notes, etc so this component can easily be used with other components that modify various record types.
    ],
    data: () => ({
        shaped: false,
        outlined: false,
        rounded: false,
        // dense: false,
        autofocus: true
    }),
    created: function() {
    
        console.log("Display error message code: " + this.errorCode);
    },
    computed: {         
        ...mapGetters('auth', {
            authStatus: 'authStatus',
            authStatusCode: 'authStatusCode',
            authStatusDetail: 'authStatusDetail',
            isLoggedIn: 'isLoggedIn',
            justLoggedIn: 'justLoggedIn',
        }),
        ...mapGetters('uiState', {
            isErrorDialogVisible: 'isErrorDialogVisible'        
        }),
        showErrorDialog() {
            return this.isErrorDialogVisible;

            // // let authStatusString = '';
            // // authStatusString = this.authStatus;
            // console.log("this.authStatusCode: " + this.authStatusCode);
            // if (((this.authStatusCode !== 200) || (this.authStatusCode !== 0)) || (this.authStatus !== 'logging-in')) {
            //     return true;
            // } else {
            //     return false;
            // }
        }
    },
    methods: {
        ...mapActions({
            onConfirmError: 'uiState/onConfirmError'
        })

        // onCancelDelete() {
        //     this.$emit(`cancel${this.selectedItemType}Delete`);
        // },
        // onConfirmError() {
         
        //     console.log(`calling: set_error_dialog_mutation`);

        //     this.confirm_error();

        //     // this.$emit(`confirmErrorMessage`);
        //     // this.$emit(`confirm${this.selectedItemType}Delete('${this.selectedItem.id}')`);
        // }
    }
};
</script>

<style>


</style>