<template>
  <!-- <v-flex class="centered-container"> -->
    <div class="landing-page-home centered-container">
      <HelloDoer
        :isLoggedIn="isLoggedIn"
        :login="login"
        :register="register"
        :logout="logout"
        msg="<span class='t2do catch-phrase'><span style='color: rgba(250, 125, 0, .995);'>Turn Your </span>OCD ADHD <span style='color: rgba(250, 125, 0, .995);'>Too Many</span> Task2DOs OMG!!!</span><br><span class='t2do slogan'><span style='color: rgba(250, 125, 0, .995);'>Into </span>Task2Donez ASAP!!!</span>"
      />
    </div>
  <!-- </v-flex> -->
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import HelloDoer from "@/components/HelloDoers.vue";

    export default {
    name: "home",
    components: {
        HelloDoer
    },
    computed: {
        ...mapGetters( 'auth', {
            authStatus: 'authStatus',
            authStatusCode: 'authStatusCode',
            authStatusDetail: 'authStatusDetail',
            isLoggedIn: 'isLoggedIn',
            justLoggedIn: 'justLoggedIn'
        }),
        isLoggedIn: function() {
            return this.$store.getters.isLoggedIn;
        },
        currentUser: function() {
            return this.$store.getters.currentUser;
        }
    },
    created() {
       
        if (this.isLoggedIn) {
            //  this.router.push({ name: '/restricted'});
            this.$router.push("/restricted");
        }
    },
    mounted() {
        
        this.updateMonthlyBackgroundImage( { curMonth: 0, xPosition: 'center', yPosition: '10%',  backgroundColor: 'rgba(6, 6, 6, .975)', backgroundSize: 'contain' } );
    },
    methods: {
        ...mapActions( 'auth', {
            initLoginProcessesFinished: 'initLoginProcessesFinished',
            checkLocalStorage: 'checkLocalStorage',
            checkTokenStatus: 'checkTokenStatus',
            authError: 'authError',
            showLoginForm: 'showLoginForm',
            logoutUser: 'logoutUser',
        }),
        ...mapActions( 'userSettings', {
            getUserPreferences: 'getUserPreferences',
            toggleMakeItDark: 'toggleMakeItDark',
            updateMonthlyBackgroundImage: 'updateMonthlyBackgroundImage',
        }),
        ...mapMutations('auth', {
            REAUTH_REQUEST: 'REAUTH_REQUEST'
        }),
        
        login() {
            this.$router.push("/login");
             
            // this.$emit("login");
        },
        register() {
            this.$emit("register");
        },
        logout() {
            this.$emit("logout");
        }
    }
};
</script>
