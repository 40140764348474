import Vue from "vue";
import router from "@/router"
import { dispatch } from "rxjs/internal/observable/pairs";
import * as arrayObjUtils from "../../lib/arrayObjUtils.js";
import axios from "axios";


const state = {
    status: 'init',

    statusCode: 0,
    statusDetail: '',
    // userInfoObj: JSON.parse(localStorage.getItem("userInfoObj")),
    userInfoObj: {
        id: localStorage.getItem("userId") || null,
        screenName: localStorage.getItem("user") || null,
        jwt: localStorage.getItem("jwt") || null,
        jwtIssuedAt: null,
        jwtExpireAt: null,
        jwtStatus: null,
        loggedIn: false,
        justLoggedIn: false,

    },
    // jwt: localStorage.getItem("jwt") || null,
    // // token: localStorage.getItem('jwt') || null,
    // user: localStorage.getItem("user") || null,
    // userId: localStorage.getItem("userId") || null,
    tokenIssuedAt: 0,
    tokenExpireAt: 0,
    tokenStatus: null,

};

const getters = {
    getCurrentUserId: state => state.userInfoObj.userId,
    getCurrentUserName: state => state.userInfoObj.userName,
    getCurrentJwt: state => state.userInfoObj.jwt,
    isLoggedIn: state => state.userInfoObj.loggedIn, //!!state.userInfoObj.jwt,
    hasJustLoggedIn: state => state.userInfoObj.justLoggedIn,
    getAuthStatus: state => state.status,
    getAuthStatusCode: state => state.statusCode,
    getAuthStatusDetail: state => state.statusDetail,
    getUserStateObj: state => state.userInfoObj,
    getUserIdObj: state => {
        return { 'id': state.userInfoObj.userId, 'screen_name':  state.userInfoObj.userName };
    }
}

const actions = {

    showLoginForm( { commit }){
        commit('uiState/SET_OK_TO_LOAD_WIDGETS', false, { root: true } );
        commit("SHOW_LOGIN_FORM");
    },
    initLoginProcessesFinished({ commit}) {
        commit('uiState/SET_OK_TO_LOAD_WIDGETS', true, { root: true } );
        commit('INIT_LOGIN_PROCESS_FINISHED');
    },

    authError( { dispatch, commit, getters, rootGetters }, statusObj = ( { statusCode: 401, status: 'auth-error', name: 'auth-error', message: 'unspecified authentication error'} )) {
        // let error = ({ data: 'Auth error was thrown', status: 402 });
        console.log("\n\tcatch error: " + JSON.stringify(statusObj, null, 2));

        
        if(statusObj.statusCode < 400) {
            statusObj.statusCode = 666;
        }
        
        commit('UPDATE_APP_STATE', { 'statusCode': statusObj.statusCode, 'status': statusObj.status, 'statusInfo': statusObj.message }, { root: true } );
        commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: rootGetters.getAppStateObj.statusInfo}, { root: true } );
        commit('uiState/SET_OK_TO_LOAD_WIDGETS', false, { root: true } );
        commit('uiState/SET_ERROR_DIALOG_VISIBLE', true, { root: true });
        commit("AUTH_ERROR",  statusObj);

        const route = router.currentRoute;
        // console.log('\n\n$$$$$$$$$$$$$$$$$$$$$$\n\tcurrent route: ' + JSON.stringify(route, jsUtils.jsonCircularReplacer(), 2) + '$$$$$$$$$$$$$$$$$4\n');

        if (route.name !== 'Login' ) {
            router.push("/login");
        }

    },
    checkLocalStorage( { state, dispatch, commit, getters, rootGetters }) {

        // let localStorageUserInfoObj = localStorage.getItem("userInfoObj");
        const initialUserDataFromLocalStorage = JSON.parse(localStorage.getItem("userInfoObj")) || null;
        console.log('initialUserDataFromLocalStorage: ' + JSON.stringify(initialUserDataFromLocalStorage, null, 2));

        if (initialUserDataFromLocalStorage === null) {
            dispatch('logoutUser');
            const route = router.currentRoute;    
            if (route.name !== 'Login' ) {
                router.push("/login");
            }              
            return false;
            // throw ({ data: "no-token-saved", status: 401 });
        } else {
            // commit user initial state based on local storage data
            commit("UPDATE_USER_STATE", initialUserDataFromLocalStorage);
            // set authorization header with jwt retreived from local storage

           
            commit("LOCAL_TOKEN_SAVED");
            return true;
        }

    },
    async loginUser({ dispatch, commit }, user) {
            commit("AUTH_REQUEST");
        // return new Promise((resolve, reject) =>  {
            
        return await new Promise((resolve,reject) => {
            Vue.prototype.$http({
                url: "https://ts-api.t2do.io/t2do-api/users/login",
                data: user,
                method: "POST"
            })
            .then(function(response) {
                const token = response.data.token_value;
                // const user = response.data.user;
                const tokenSegmentsArray = response.data.token_value.split(".");
                
                // const userDataObj = JSON.parse(atob(tokenSegmentsArray[1]));
                let responseDataObj = response.data;
                let userName = responseDataObj.data.screen_name;
                let userId = responseDataObj.data.id;
                let issuedAt = responseDataObj.data.issued_at;
                let expireAt = responseDataObj.data.expire_at;
                let tokenStatus = responseDataObj.data.status;

                const userInfoObj = {
                    userId: userId,
                    userName: userName,
                    jwt: token,
                    jwtIssuedAt: issuedAt,
                    jwtExpireAt: expireAt,
                    jwtStatus: tokenStatus,
                    loggedIn: true,
                    justLoggedIn: true
                };

                console.log("Response: " + JSON.stringify(response.data, null, 2) + "\n");
                console.log("Token: " + token + "\n");
                console.log("user data: " + JSON.stringify(userInfoObj, null, 2) + "\n");

                localStorage.setItem("userInfoObj", JSON.stringify(userInfoObj));
                // localStorage.setItem("jwt", token);
                // localStorage.setItem("userId", userId);
                // localStorage.setItem("user", userName);
                // localStorage.setItem("tokenIssuedAt", issuedAt);
                // localStorage.setItem("tokenExpireAt", expireAt);
                // localStorage.setItem("tokenStatus", tokenStatus);
                
                
                // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + token;
                commit("AUTH_SUCCESS", { userDataObjArg: userInfoObj, tokenArg: token });

                dispatch('initAppState', null, { root: true });
                // commit("auth_success",  token, userData);
                resolve(response);
            })
            .catch(err => {
                console.log("Error logging in: " + err);
                let statusObj = {};
                if (err.response) {       
                    statusObj = { statusCode: err.response.status, status: err.response.statusText, name: err.response.data, message: 'Error logging in: ' + JSON.stringify(err.response.data, null, 2)};
                } else {
                    statusObj = { statusCode: 499, status: err, name: err, message: 'Error: ' + JSON.stringify(err, null, 2)};
                }
                dispatch("authError", statusObj);            
                reject(statusObj);
            });

            // .catch(function(err) {
            //     console.log("\n=========================================\n\tcatch err: " + JSON.stringify(err, null, 2));
            //     // let errorObj = { statusCode: }
            //     commit("auth_error",  err );
            //     // localStorage.removeItem("jwt");
            //     reject(err);
            // });
        });
        
    },
    async checkTokenStatus( { dispatch, commit, getters } ) {
        // const userIdObj = { 'id': getters.getCurrentUserId, 'screen_name': getters.getCurrentUserName };
        // return new Promise(function(resolve, reject) {

            // if (rootGetters.authStatus === 'no-token-saved') {
                
            //     throw ({ data: "no-token-saved", status: 401 });
                
            // } else {
        
            // }
            // resolve(response);
        
        // }).then(
            
        return await new Promise((resolve,reject) => {
            // axios.defaults.headers.common["Authorization"] = "Bearer " + getters.getCurrentJwt;
            // the axios header  should have been set when checking local storage
            Vue.prototype.$http({
                url: "https://ts-api.t2do.io/t2do-api/users/auth",
                data: getters.getUserIdObj,
                method: "POST"
        }).then(response => {
            
        // .then(function(response) {
                const token = response.data.token_value;
                          
                let responseDataObj = response.data;
                let userName = responseDataObj.data.screen_name;
                let userId = responseDataObj.data.id;
                let issuedAt = responseDataObj.data.issued_at;
                let expireAt = responseDataObj.data.expire_at;
                let tokenStatus = responseDataObj.data.status;
                let tokenUserIdMatch = responseDataObj.data.id_match;

                const userInfoObj = {
                    userId: userId,
                    userName: userName,
                    jwt: token,
                    jwtIssuedAt: issuedAt,
                    jwtExpireAt: expireAt,
                    jwtStatus: tokenStatus,
                    loggedIn: true,
                    justLoggedIn: true,
                    idMatch: tokenUserIdMatch
                };

                

                if (tokenUserIdMatch === 'false') {
                    let statusObj =  { statusCode: 401, status: 'invalid-token', name: 'Invalid token', message: 'User data does not correspond with token' };
                    dispatch("authError", statusObj);
                    throw ( { 'data': 'User data does not correspond with token', 'status': 401 } );

                } else {
                    localStorage.setItem("userInfoObj", JSON.stringify(userInfoObj));
                }

                console.log("\n\t------------------------------------------------------------------\n\tResponse: " + JSON.stringify(response.data, null, 2) + "\n");
                console.log("user data: " + JSON.stringify(userInfoObj, null, 2) + "\n");

                if (tokenStatus === 'valid') {
                    console.log('\n\tWe got a valid token');
                    Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + token;
                    commit("UPDATE_USER_STATE", userInfoObj);
                    // commit("AUTH_SUCCESS", {userDataObjArg: userInfoObj, tokenArg: token});

                    const route = router.currentRoute;    
                    if (route.name !== 'Restricted' ) {
                        router.push("/restricted");
                    }
                } else {
                    // throw ({ data: 'You need to login. The current token is not valid.', status: 403});
                    let statusObj =  { statusCode: 401, status: 'token isn\'t valid', name: 'invalid-token', message: JSON.stringify(userDataObj, null, 2)};
                    dispatch("authError", statusObj);
                }                    
                // commit("auth_success",  token, userData);
                resolve(response);
            // }).catch(function(err) {
            }).catch(err => {
                console.log("catch err: " + JSON.stringify(err, null, 2));
                let statusObj = {};
                if (err.response) {       
                    statusObj = { statusCode: err.response.status, status: err.response.statusText, name: err.response.data, message: 'Error logging in: ' + JSON.stringify(err.response.data, null, 2)};
                } else {
                    statusObj = { statusCode: 499, status: err, name: err, message: 'Error: ' + JSON.stringify(err, null, 2)};
                }
                dispatch("authError", statusObj);
                
                reject(err);
            });

        });
        
        // }))
        
        // .catch(function(err) {
        //         console.log("catch err: " + JSON.stringify(err, null, 2));
        //         commit("auth_error",  err);
        //         // localStorage.removeItem("jwt");
        //     reject(err);
        //     });

    },
    async renewToken({ state, dispatch, commit, getters, rootGetters }) {

        // let requestBody = {
        //     id: "bdf69d2c-43d3-4a8f-97b0-b21969e0ce93", 
        //     screen_name: "brandon"
        // }
        
        return new Promise((resolve, reject) => {
            commit("AUTH_REQUEST");
            Vue.prototype.$http({
            url: "https://ts-api.t2do.io/t2do-api/user/renewToken",
            data: getters.getUserIdObj,
            method: "POST"
            })
            .then(function(response) {
                               
                const token = response.data.token_value;
                const tokenSegmentsArray = response.data.token_value.split(".");
                const userDataObj = JSON.parse(atob(tokenSegmentsArray[1]));
                   
                console.log('\n---------------------------------\nrenew token response: ' + response.data);

                let responseDataObj = response.data;
                let userName = responseDataObj.data.screen_name;
                let userId = responseDataObj.data.id;
                let issuedAt = responseDataObj.data.issued_at;
                let expireAt = responseDataObj.data.expire_at;
                let tokenStatus = responseDataObj.data.status;
                let tokenUserIdMatch = responseDataObj.data.id_match;

                const userInfoObj = {
                    userId: userId,
                    userName: userName,
                    jwt: token,
                    jwtIssuedAt: issuedAt,
                    jwtExpireAt: expireAt,
                    jwtStatus: tokenStatus,
                    loggedIn: true,
                    justLoggedIn: true,
                    idMatch: tokenUserIdMatch
                };

                localStorage.setItem("userInfoObj", JSON.stringify(userInfoObj));
                
                // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + token;

                commit("UPDATE_USER_STATE", userInfoObj);
                // commit("AUTH_SUCCESS", {userDataObjArg: userInfoObj, tokenArg: token});

                const route = router.currentRoute;    
                if (route.name !== 'Restricted' ) {
                    router.push("/restricted");
                }
                // commit("auth_success",  token, userData);
                resolve(response);
            })
            .catch(function(err) {
                console.log("catch err: " + JSON.stringify(err.response, null, 2));
                let statusObj = { statusCode: 403, status: 'logged-out', name: 'renew-token-error', message: 'Error renewing token \n ' + JSON.stringify(err.response, null, 2)};
                dispatch("authError", statusObj);
                    
                reject(statusObj);
            });
        });

    },
    registerUser({ commit }, user) {
        return new Promise((resolve, reject) => {
            this.commit("AUTH_REQUEST");
            Vue.prototype.$http({
            url: "https://ts-api.t2do.io/users/register",
            data: user,
            method: "POST"
            })
            .then(response => {
                // This isn't how my API works, use can't just sign up that in straight away, posible confirmation email,
                //  requiring them to actually login through the login form will do for now. So I need a redirect here
                
                // const token = response.data.token;
                // const userData = response.data.user;
                const token = response.data.token_value;
                const tokenSegmentsArray = response.data.token_value.split(".");
                
                const userDataObj = JSON.parse(atob(tokenSegmentsArray[1]));
                let userName= userDataObj.data.user_screen_name;
                let userId = userDataObj.data.user_id;

                localStorage.setItem("userInfoObj", JSON.stringify(userDataObj.data));
                localStorage.setItem("jwt", token);
                localStorage.setItem("userId", userId);
                localStorage.setItem("user", userName);
                Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + token;
                commit("AUTH_SUCCESS", { userDataObjArg: userDataObj['data'], tokenArg: token });
                // commit("auth_success", token, userData);
                resolve(response);
            })
            .catch(function(err) {
                this.commit("AUTH_ERROR", err.response );
                // localStorage.removeItem("jwt");
                reject(err);
            });
        });
    },
    logoutUser({ dispatch, commit, getters }) {
        // return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {

            dispatch('time/killRealtimeClockInterval', null, { root: true } );
            commit('uiState/SET_OK_TO_LOAD_WIDGETS', false, { root: true } );
            commit('FINISHED_LOADING_API_DATA', false, { root: true } );
            commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: 'Logged out' }, { root: true } );

            // while removing the jwt from the client will require the user to fetch another token when they use the app,
            // it would also be a good idea to let the server know that the user is bailing so it can set the jwt to invalid

            // localStorage.setItem("jwt", token);
            // localStorage.setItem("userId", userId);
            // localStorage.setItem("user", userName);
            // delete axios.defaults.headers.common["Authorization"];
            commit("LOGOUT");
            resolve();
        });
    }

};

const mutations = {
    SET_AUTH_STATUS(state, { statusObj } ) {
        state.status = statusObj.status;
        state.statusCode = statusObj.statusCode;
        state.statusDetail = statusObj.statusDetail;

    },
    SHOW_LOGIN_FORM(state) {
        state.statusCode = 0;
        state.status = 'logging-in';
    },
    AUTH_REQUEST(state) {
        state.status = "loading";
    },
    CHECKING_TOKEN_STATUS(state) {
        state.status = 'checking-token';
    },
    AUTH_SUCCESS(state, { userDataObjArg, tokenArg }) {
        console.log('AUTH_SUCCESS stateObj: ' + JSON.stringify(userDataObjArg, null, 2));
        state.userInfoObj = arrayObjUtils.arrayObjsDeepCopy(userDataObjArg);
        state.status = "success";
        state.statusCode = 200;
        // state.userJustLoggedIn = true;
        // state.jwt = tokenArg;
        // state.user = userDataObjArg.screen_name;
        // state.userId = userDataObjArg.id;
        // state.tokenIssuedAt = userDataObjArg.issued_at;
        // state.tokenExpireAt = userDataObjArg.expire_at;
        // state.tokenStatus = userDataObjArg.status;
    },
    UPDATE_USER_STATE(state, userInfoObjArgument) {
        state.userInfoObj = arrayObjUtils.arrayObjsDeepCopy(userInfoObjArgument);
        state.statusCode = 200;
        state.status = "ok";
        
    },
    // token_valid(state, stateObj) {
    //     token_valid
    // },
    REAUTH_REQUEST(state, userInfoObjArgument) {

        state.userJustLoggedIn = true;
    },
    // auth_success(state, token, userData) {
    //   state.status = 'success';
    //   state.jwt = token;
    //   state.user = userData;
    // },
    INIT_LOGIN_PROCESS_FINISHED(state) {
        console.log("init_login_process_finished commit called");
        state.userJustLoggedIn = false;
      
     
    },
    TOKEN_EMPTY(state) {
        state.status = 'no-token-saved';
        state.jwt = "";
        state.user = "";
        state.userJustLoggedIn = false;
        delete Vue.prototype.$http.defaults.headers.common["Authorization"];
        localStorage.removeItem("jwt");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");

    },
    LOCAL_TOKEN_SAVED(state) {
        state.status = 'local-token-saved';
        state.statusCode = 200;
    },
    AUTH_ERROR(state, statusObj) {
        console.log("auth_error mutation called: STATEOBJ.ERR: " +  JSON.stringify(statusObj, null, 2));
        
        
        if ((typeof(statusObj) === 'object') &&
            (statusObj.hasOwnProperty('name')) &&
            (statusObj.hasOwnProperty('message')) &&
            (statusObj.hasOwnProperty('statusCode')) &&
            (statusObj.hasOwnProperty('status')))
        {
                
            state.status = statusObj.status;
            // state.status = statusObj.statusText.toString();
            state.statusCode = statusObj.statusCode;
            state.statusDetail = statusObj.message;

        } else {
            if(statusObj) {
                state.status = statusObj;
                state.statusCode = 513;
                if (statusObj.hasOwnProperty('message')) {
                    state.statusDetail = statusObj.message;
                } else {
                    state.statusDetail = 'details not provided';
                }

            } else {
                state.statusCode = 999;
                state.status = 'unknown';
                state.statusDetail = 'the statusObj was not set when calling auth error';
            }
            
        }
    },
    LOGOUT(state) {
        state.status = "logged-out";
        state.statusCode = 0;
        state.statusDetail = '';
        // state.jwt = null;
        // state.userId = null;
        // state.user = null;
        state.userInfoObj = {
            id: null,
            screenName: '',
            jwt: null,
            jwtIssuedAt: null,
            jwtExpireAt: null,
            jwtStatus: null,
            loggedIn: false,
            justLoggedIn: false

        };
        // state.userJustLoggedIn = false;
        // state.tokenStatus = null;
        // state.tokenIssuedAt = 0;
        // state.tokenExpireAt = 0;
        
        
        delete Vue.prototype.$http.defaults.headers.common["Authorization"];

        localStorage.removeItem("jwt");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");
        localStorage.removeItem("tokenStatus");
        localStorage.removeItem("tokenIssuedAt");
        localStorage.removeItem("tokenExpireAt");
        localStorage.removeItem("userInfoObj");
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };