<template>
  <!-- <footer id="app-footer"> -->
  <v-footer id="task2Do-app-footer-id" app align="center">
    <span class="trinix-copyright"
      ><h3>{{ getCopyright("Trinix Computer Systems", "2002") }}</h3>
      <p>Awe <v-icon class="nice-icons">&#x1F4A9;</v-icon> SHIT!</p></span
    ><span class="trinix-fine-print"
      ><p>
        Notice: This App is currently in development and should UNDER NO
        CIRCUMSTANCES be distributed or used by anyone other than the Developers
        and Beta Testers until a production version becomes available. <br />
        All rights reserved. No part of this publication may be reproduced,
        distributed, or transmitted in any form or by any means, including
        photocopying, recording, or other electronic or mechanical methods,
        without the prior written permission of the publisher, except in the
        case of brief quotations embodied in critical reviews and certain other
        noncommercial uses permitted by copyright law. For permission requests,
        write to the publisher, addressed “Attention: Digital Asset
        Permissions,” at the address below.
      </p></span>

    <!-- </footer> -->
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data: () => ({
    copyrightYear: new Date().toISOString().substr(0, 4)
  }),
  methods: {
    getCopyright(entityName = "", yearEst = null) {
      return this.$jsUtils.printCopyright(entityName, yearEst);
    }
  }
};
</script>
