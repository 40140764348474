<template>
    <v-container class="widget" :class="[ minimizeState ? minimizeClassName : '',
            maximizeState ? maximizeClassName : ''  ]" >
       
        <div style="position: absolute; display: block;  background: unset; opacity: 0; left: 0; top:0; width: 100%; height: 100%; z-index: 1500;"
        v-if="focusedWidget !== 'WeatherViewWidget'" @click.stop="$emit('bring-to-front', 'WeatherViewWidget')"></div>

        <v-card class="widget-window-outer-container">
            
            <WindowBar :widgetLabel="widgetLabel"
                        :minimizeState="minimizeState"
                        v-on:minimize="minimize"
                        :maximizeState="maximizeState"
                        v-on:maximize="maximize"
                        :notifications="weatherAlertData['features']"
                        :notificationType="weatherAlertNotificationString"
                        />

           <v-container class="widget-inner-container" fluid style="max-height: calc(100% - 3rem); height: 100%; overflow-y: auto; margin: 1.0rem auto 1rem; padding: .125rem .25rem;">
                <v-card class="mx-auto" style="max-width: 100%;">
                    <v-card-title align="center">Fredonia</v-card-title>
                    <v-card-subtitle align="right" style="margin-top:-4.25rem !important;"
                        >Mon, December 28,2019 9:30 PM</v-card-subtitle
                    >
                    <v-card-subtitle align="right">Mostly Clear</v-card-subtitle>
                    <v-row>
                        <v-col class="display-3" cols="6"> 37&deg;F</v-col>
                    </v-row>
                    <v-card-text>
                        <v-row>
                        <v-col cols="3" align="right">
                            <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/sun.png"
                            alt="Sunny image"
                            width="92"
                            ></v-img>
                        </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card>
                        <v-sparkline
                        :value="temp"
                        :gradient="gradient[1]"
                        :smooth="radius || false"
                        :padding="padding"
                        :line-width="width"
                        :stroke-linecap="lineCap"
                        :gradient-direction="gradientDirection"
                        :fill="fill"
                        :type="type"
                        :auto-line-width="autoLineWidth"
                        auto-draw
                        >
                        </v-sparkline>
                        <v-overlay absolute>
                        <v-sparkline
                            :labels="hourly"
                            color="white"
                            :value="wind"
                            :gradient="gradient[0]"
                            :smooth="radius || false"
                            :padding="padding"
                            :line-width="width"
                            :stroke-linecap="lineCap"
                            :gradient-direction="gradientDirection"
                            :fill="fill"
                            :type="type"
                            :auto-line-width="autoLineWidth"
                            auto-draw
                        >
                        </v-sparkline>
                        </v-overlay>
                    </v-card>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn text>Full Report</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="show = !show">
                        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="show">
                            <v-divider></v-divider>

                            <v-list class="transparent">
                                <v-list-item v-for="item in forecast" :key="item.day">
                                <v-list-item-title>{{ item.day }}</v-list-item-title>
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-subtitle class="text-right">
                                    {{ item.temp }}
                                </v-list-item-subtitle>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-expand-transition>
                </v-card>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import WindowBar from "@/components/widgets/components/WindowBar.vue";

const gradients = [
  ["rgba(245,240,250,.25)", "rgba(75, 75, 75, .75)", "rgba(0,0,0,.90)"],
  [
    "rgba(190,180,255,1)",
    "rgba(60, 40, 255,1)",
    "rgba(120, 200, 235, 1)",
    "rgba(120,255,160,1)",
    "rgba(245, 160, 15,1)",
    "rgba(255, 10, 15,1)"
  ]
];
export default {
    name: "WeatherViewWidget",
    components: {
        WindowBar
    },
    props: ["focusedWidget"],
    data: () => ({
        widgetLabel: "WeatherView",
        componentName: "WeatherViewWidget",
        minimizeInBackground: true,
        minimizeInForeground: false,
        minimizeClassName: "minimize-widget",
        maximizeClassName: "maximize-widget",
        maximizeState: false,
        weatherAlertData: {
            'features': ''
        },
        weatherAlertNotificationString: 'Alert',
        show: true,
        forecast: [
            {
            day: "Tuesday",
            icon: "mdi-white-balance-sunny",
            temp: "24\xB0/12\xB0"
            },
            {
            day: "Wednesday",
            icon: "mdi-white-balance-sunny",
            temp: "22\xB0/14\xB0"
            },
            { day: "Thursday", icon: "mdi-cloud", temp: "25\xB0/15\xB0" }
        ],
        width: 2,
        radius: 10,
        padding: 8,
        lineCap: "round",
        gradient: gradients,
        hourly: [12, 2, 4, 6, 8, 10, 12],
        temp: [51, 49, 48, 47, 48, 50, 52, 52],
        wind: [15, 16, 15, 15, 15, 15, 15, 16],
        gradientDirection: "bottom",
        fill: true,
        type: "trend",
        autoLineWidth: false
    }),
    computed: {
        minimizeState() {
            // basically apply foreground state when focused, otherwise apply background state
            return (this.focusedWidget === this.componentName) ? this.minimizeInForeground : this.minimizeInBackground; 
        }

    },
    updated: function() {
        if (this.focusedWidget !== this.componentName) {
            // This resets the foreground state to ensure widget isn't minimized when regaining focus
            this.minimizeInForeground = false;
        }

        console.log("\tthis.name:" + this.componentName);
        console.log("\tthis.focusedWidget: " + this.focusedWidget);
        console.log("\tInitialFocus: " + this.initialFocus);
        console.log("\tminimize state: " + this.minimizeState);
    },
    methods: {
        minimize() {
            this.minimizeInForeground = !this.minimizeInForeground;
            this.minimizeInBackground = this.minimizeInForeground;

            if (this.maximizeState === true) {
                this.maximizeState = false;
            }
        },
        maximize() {
            if (this.maximizeState === false) {
                this.maximizeState = true;
                this.minimizeInForeground = false;
                this.minimizeInBackground = this.minimizeInForeground;
            } else {
                this.maximizeState = false;
            }
        }
    }
};
</script>

<style>
/* .v-card__title {
  font-size: 1.75rem !important;
  font-weight: 400;
  line-height: 2rem;
  text-align: center !important;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 1.75rem !important;
}

.v-application .display-3 {
  font-size: 2.75rem !important;
  font-weight: 300;
  line-height: 2.75rem;
  letter-spacing: -0.0083333333em !important;
  font-family: "Roboto", sans-serif !important;
  margin-top: -1.95rem !important;
  margin-left: 30px !important;
  padding-bottom: 1.5rem;
}

.v-card__subtitle,
.v-card__text {
  font-size: 0.9rem !important;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  margin-top: -2rem !important;
}

.v-sparkline {
  top: 5px;
}

.primary--text {
  position: relative;
  top: -98px;
}

.v-overlay__scrim {
  opacity: 0.46;
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(33, 33, 33);
} */
</style>
