import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";
import vuetify from "./plugins/vuetify";
import CONSTANTS from "@/plugins/Constants";
// import vuetify from 'vuetify/lib';
// import firebase from "firebase/app";
import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
 
// Vue.use(Vuetify);
// const opts = {};
// export default new Vuetify(opts);

Vue.use(DatetimePicker);

Vue.use(CONSTANTS);

// import "firebase/firestore";
Vue.prototype.$http = axios;
import * as arrayObjUtils from "./lib/arrayObjUtils.js";
Object.defineProperty(Vue.prototype, "$arrayObjUtils", {
  value: arrayObjUtils
});

import * as jsUtils from "./lib/jsUtils.js";
Object.defineProperty(Vue.prototype, "$jsUtils", { value: jsUtils });
import * as datedJS from "./lib/dated.js";
Object.defineProperty(Vue.prototype, "$datedJS", { value: datedJS });
// export const jsUtils = require("./lib/jsUtils.js");

//  import marked from './lib/marked.js';
// export const marked = require("./lib/marked.js");
export const marked = require("./lib/marked/marked.min.js");

const jwt = localStorage.getItem("jwt");

if (jwt) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + jwt;
}

Vue.config.productionTip = false;

/************************************************************
 * TODO: THE FOLLOWING CODE IS NOT A FIX, ITS JUST A TEMP. HACK
 * FIX PROPERLY ONCE CALENDAR FUNCTIONING OR CREATE A NEW CALENDAR
 * FROM SCRATCH THAT WILL PROVIDE DESIRED FUNCTIONALITY
 * 
 * Code Source: https://github.com/werner/event_calendar_front/commit/accf600f9b6ed9aaab12a45c1a7d111bbb2de464#diff-27653c212e1cfe533e4eb2f7d0d3f89604c9de48a09583b4cbbbcbd08a07da79
 * 
 ***********************************************************/

// To avoid some annoying warning
// https://github.com/vuetifyjs/vuetify/issues/9999
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = function (msg, vm, trace) {
  trace
  if (msg === ignoreWarnMessage) {
    msg = null
    vm = null
    trace = null
  }
}

// firebase.initializeApp({
//   apiKey: "AIzaSyDWlN2tVVB7JVyl_fHVXhmIjuMXNG6h4TM",
//   authDomain: "vue-calendarr.firebaseapp.com",
//   databaseURL: "https://vue-calendarr.firebaseio.com",
//   projectId: "vue-calendarr",
//   storageBucket: "vue-calendarr.appspot.com",
//   messagingSenderId: "2775757136",
//   appId: "1:2775757136:web:80c61223666bd2c9273c55"
// });

// export const db = firebase.firestore();

const vm = new Vue({
  router,
  store,
  data() {
    return {
      initComplete: false
    }
  },
  async beforeCreate() {
    console.log('app.vue beforeCreate() method, dispatching initAppState');
    await this.$store.dispatch('initAppState', null, { root: true });
    this.initComplete = true;
  },
  vuetify,
  render(h) {
    // if (this.initComplete) {
      return h(App);
    // }
  }
}).$mount("#app");
