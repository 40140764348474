<template>
  <div class="about">
    <h1>Task2DO!</h1>
    <p>
      Task2DO! is created by the totally awesome and spectacular DevTeam Duo Kt
      &amp; Brando
    </p>
  </div>
</template>

<script>
export default {
  name: "about"
};
</script>
