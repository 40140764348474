<template>
    <v-content id="widget-spinner-id" class="widget-spinner-container" >
         <v-sheet fullscreen> 
            <v-toolbar class="navigator-controls" flat color="rgba(13, 13, 13, .9)">
                <v-row>
                    <v-btn outlined style="z-index: 50;" class="mr-4 TM__blue-glowing-outline" @click="moveNavigatorPositionLeft()">
                        Shift Left</v-btn>
                    <v-btn outlined style="z-index: 50;" class="mr-4 TM__blue-glowing-outline" @click="moveNavigatorPositionRight()">
                        Shift Right</v-btn>
                </v-row>
            </v-toolbar>
        </v-sheet>

        <v-container class="widgets-container" style="top: 3rem;">
    
            <BlogsWidget
                class="semi-trans--tinted__background blogs-widget-component"
                :focusedWidget="navigatorItems[navigatorFocusedWidgetIndex]"
                :fixedWidget=false
                @bring-to-front="bringWidgetToFront"
                v-bind:class="navigatorArray[navigatorItems.indexOf('BlogsWidget')]"/>

            <NotesWidget class="semi-trans--tinted__background notes-widget-component"
                :focusedWidget="navigatorItems[navigatorFocusedWidgetIndex]" 
                fixedWidget=false
                @bring-to-front="bringWidgetToFront"  v-bind:class="navigatorArray[navigatorItems.indexOf('NotesWidget')]" />
            
            <TasksWidget
                class="semi-trans--tinted__background tasks-widget-component"
                :focusedWidget="navigatorItems[navigatorFocusedWidgetIndex]"
                fixedWidget=false
                :priorityLevelLookup="priorityLevelLookup"
                :task2doCats="task2doCats"
                :addSubCat="addSubCat"
                :addTask="addTask"
                :editTaskCat="editTaskCat"
                @bring-to-front="bringWidgetToFront"
                v-bind:class="navigatorArray[navigatorItems.indexOf('TasksWidget')]"/>
           
            <CalendarWidget
                class="semi-trans--tinted__background calendar-widget-component "
                :focusedWidget="navigatorItems[navigatorFocusedWidgetIndex]"
                :fixedWidget=false
                :priorityLevelLookup="priorityLevelLookup"
                @bring-to-front="bringWidgetToFront"
                @updateCurMonth="updateCurrentMonth"
                v-bind:class="navigatorArray[navigatorItems.indexOf('CalendarWidget')]"/>
                

            <MarkdownWidget
                class="semi-trans--tinted__background markdown-widget-component"
                :focusedWidget="navigatorItems[navigatorFocusedWidgetIndex]"
                fixedWidget=false
                @bring-to-front="bringWidgetToFront"
                v-bind:class="navigatorArray[navigatorItems.indexOf('MarkdownWidget')]"
            />

            <WeatherWidget
                class="semi-trans--tinted__background weather-widget-component"
                :focusedWidget="navigatorItems[navigatorFocusedWidgetIndex]"
                fixedWidget=false
                @bring-to-front="bringWidgetToFront"
                v-bind:class="navigatorArray[navigatorItems.indexOf('WeatherWidget')]"
            />
     
            <WeatherViewWidget
                class="semi-trans--tinted__background weather-view-widget-component"
                :focusedWidget="navigatorItems[navigatorFocusedWidgetIndex]"
                :fixedWidget="false"
                @bring-to-front="bringWidgetToFront"
                v-bind:class="navigatorArray[navigatorItems.indexOf('WeatherViewWidget')]"
            /> 

        </v-container>
      
    </v-content>
  
</template>

<script>
// import marked from '@/main';
import BlogsWidget from "../components/widgets/Blogs";
import TasksWidget from "../components/widgets/Tasks";
import CalendarWidget from "../components/widgets/Calendar";
import NotesWidget from "../components/widgets/Notes";
import WeatherWidget from "../components/widgets/Weather";
import WeatherViewWidget from "../components/widgets/WeatherView";
import MarkdownWidget from "../components/widgets/MarkdownNotes";
// import Login from '../components/Login';
// import Register from '../components/Register';

export default {
  name: "WidgetSpinner",
  components: {
    // marked,
    /* eslint-disable no-unused-components */
    BlogsWidget,
    TasksWidget,
    CalendarWidget,
    NotesWidget,
    WeatherWidget,
    WeatherViewWidget,
    MarkdownWidget
  },
  props: [
        "task2doCats",
        "addSubCat",
        "addTask",
        "editTaskCat",
        "priorityLevelLookup"
  ],
  data: () => ({
    RegisterUserDialogOpen: false,
    LoginDialogOpen: false,
    navigatorArray: [
        "navigator-position left3",
        "navigator-position left2",
        "navigator-position left1",
        "navigator-position center",
        "navigator-position right1",
        "navigator-position right2",
        "navigator-position right3"
    ],
    navigatorItems: [
        "TasksWidget",
        "WeatherViewWidget",
        "NotesWidget",
        "CalendarWidget",
        "MarkdownWidget",
        "WeatherWidget",
        "BlogsWidget"
    ],
    navigatorFocusedWidgetIndex: 3,

  }),
  mounted: function() {

      this.updateCurrentMonth();
      console.log("mounted date getMonth: " + this.$datedJS.dated.getMonthNum());
    //  this.$emit("updateBackgroundImageEvent");
     
  },
  methods: {
    arrayRotateLeft(arr) {
        return arr.push(arr.shift());
    },
    arrayRotateRight(arr) {
        return arr.unshift(arr.pop());
    },
    moveNavigatorPositionLeft() {
        this.arrayRotateLeft(this.navigatorItems);
        // for(let i=0; i < this.navigatorItems.length; i++) {
        //   window.console.log("INDEX: " + i + "Navigator Items after rotate: " + this.navigatorItems[i] + "\n");
        //   window.console.log("Navigator array element" + i + "value: " + this.navigatorArray[i] + "\n");
        // }
        // window.console.log("-----------------------------------");
    },
    moveNavigatorPositionRight() {
      this.arrayRotateRight(this.navigatorItems);
      // for(let i=0; i < this.navigatorItems.length; i++) {
      //   window.console.log("INDEX: " + i + "Navigator Items after rotate: " + this.navigatorItems[i] + "\n");
      //   window.console.log("Navigator array element" + i + "value: " + this.navigatorArray[i] + "\n");
      // }
      // window.console.log("------------SHIFTED -----------------------");
    },
    updateCurrentMonth(curMonth = this.$datedJS.dated.getMonthNum()) {
            console.log("\t###########curMonth: " + curMonth + "\n\n");
        this.$emit("updateBackgroundImageEvent", curMonth);
        
    },
    bringWidgetToFront(widgetName) {
        let clickedOnIndexValue = this.navigatorItems.indexOf(widgetName);
        console.log("ClickedOnIndexValue: " + clickedOnIndexValue);
        if (clickedOnIndexValue == 0) {
            setTimeout(() => {
                this.arrayRotateRight(this.navigatorItems);
                setTimeout(() => {
                    this.arrayRotateRight(this.navigatorItems);
                    setTimeout(() => {
                        this.arrayRotateRight(this.navigatorItems);
                    }, 500);
                }, 500);
            }, 500);
        }
        if (clickedOnIndexValue == 1) {
            setTimeout(() => {
                this.arrayRotateRight(this.navigatorItems);
                setTimeout(() => {
                    this.arrayRotateRight(this.navigatorItems);
                }, 250);
            }, 250);
        }
        if (clickedOnIndexValue == 2) {
            this.arrayRotateRight(this.navigatorItems);
        }
        if (clickedOnIndexValue == 4) {
            this.arrayRotateLeft(this.navigatorItems);
        }
        if (clickedOnIndexValue == 5) {
            this.arrayRotateLeft(this.navigatorItems);
            setTimeout(() => {
                this.arrayRotateLeft(this.navigatorItems);
            }, 250);
        }
        if (clickedOnIndexValue == 6) {
            this.arrayRotateLeft(this.navigatorItems);
            setTimeout(() => {
                this.arrayRotateLeft(this.navigatorItems);
                setTimeout(() => {
                    this.arrayRotateLeft(this.navigatorItems);
                }, 250);
            }, 250);
        }
    },
    // toggleFullscreenWidgetSpinnerEvent() {
    //     th
    //     console.log("toggleFullscreenEdit called");
    //     // let widgetRootElementName = this.componentName+'Id';
    //     let widgetRootElementName = 'widget-spinner-id';
    //     let widgetRootElement = document.getElementById(widgetRootElementName);

    //     console.log("widgetRootElementName: " + widgetRootElementName);
        
       
    //     if (document.fullscreenEnabled === true) {
    //         this.fullscreenEditMode = !this.fullscreenEditMode;
    //         if (this.fullscreenEditMode === true) {
    //             this.browserFullscreenPrefix.call(widgetRootElement);
    //         }
    //     } else {
    //         this.fullscreenEditMode = false;
    //     }

    // }
  }
};
</script>
