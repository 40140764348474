/**
 * The following computed constants work,
 * it just doesn't make sence to use this
 * pattern here.
 */

// const CONSTANTS = {
//     ONE_MILISECOND: 1,
//     ONE_SECOND: 1000,
//     get ONE_MINUTE() {
//         return this.ONE_SECOND * 60;
//     },
//     get ONE_HOUR() {
//         return this.ONE_MINUTE * 60;
//     },
//     get ONE_DAY() {
//         return this.ONE_HOUR * 24;
//     }
// };

const CONSTANTS = {
    install(Vue, options) {
        Vue.CONSTANTS = {
            ONE_MILISECOND: 1,
            ONE_SECOND: 1000,
            ONE_MINUTE: 60000,
            NINTY_SECONDS: 90000,
            FIVE_MINUTES: 300000,
            TEN_MINUTES: 600000,
            FIFTEEN_MINUTES: 900000,
            THIRTY_MINUTES: 1800000,
            FOURTY_FIVE_MINUTES: 2700000,
            ONE_HOUR: 3600000,
            ONE_DAY: 86400000,
        }
    }
};

export default CONSTANTS;
