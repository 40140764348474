import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Notes from "../components/widgets/Notes";
import Tasks from "../components/widgets/Tasks";
import Register from "../components/Register";
import Login from "../components/Login";
import Restricted from "../components/Restricted";
// import WidgetCarousel from "../views/WidgetCarousel";
import Settings from "../components/Settings";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/restricted",
    name: "Restricted",
    component: Restricted,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/notes",
    name: "Notes",
    component: Notes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: "/widgetwheel",
  //   name: "WidgetCarousel",
  //   component: WidgetCarousel,
  //   props: { default: true },
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true
    }
  }

  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// let isLoggedIn = store.getters['auth/isLoggedIn'];
// console.log('router index, isLoggedIn value: ' + isLoggedIn);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isLoggedIn']) {
      next('/login');
    } else {
      // route requires auth, but user is alreadt loggedIn
      next();
      
    }
  } else {
    // route doesn't require auth
    next();
  }
});

export default router;
