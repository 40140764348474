<template>
  <v-toolbar class="widget-toolbar"  height="unset">
    <!-- <div class="widget-toolbar">
      <h3 class="ml-4">{{ widgetLabel }}</h3>
    </div> -->
    <v-toolbar-items class="inline-toolbar-icons" style="height: 3rem;" height="unset">
        <v-spacer></v-spacer>
       
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="editCategories()">
                    <v-icon>mdi-file-tree</v-icon>
                </v-btn>
            </template>
            <span>{{ toolbarButtons["editCategories"]["description"] }}</span>
            </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="saveEdit()">
                    <v-icon>mdi-floppy</v-icon>
                </v-btn>
            </template>
            <span>{{ toolbarButtons["saveEdit"]["description"] }}</span>
            </v-tooltip>
       
        <!-- <v-btn icon dark outlined class="mr-2 toolbar-icon TM__blue-glowing-outline" @click="toggleEditMode()">
            Edit Mode
            <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
 <v-spacer></v-spacer>
         <v-switch :v-model="{ getEditMode }"  @click="toggleEditModeEvent" :label="`${ editModeProp ? 'Edit Mode': 'Preview Mode'}`" ></v-switch>


   

        <!-- <span id="noteEditToolbar-tagMode-status-id" data-toolbar-status="ok" data-toolbar-status-msg="null" class="TCS toolbar-widget-status-indicator"></span>
        <div id="noteEditToolbar-titlebar">
            Tag Inserter Toolbar
            <button id="noteEditToolbar-CloseButton">X</button>
        </div>
        <div class="noteEditToolbar-buttons">
            <button class="noteEditToolbar-InsertHTML" data-button-function="template-insert" value="insert-note">Note Template</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="template-insert" value="insert-todo">Todo Template</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="template-insert" value="insert-blog">Blog Template</button>
            <br class="">
            
            <button class="noteEditToolbar-InsertHTML" data-button-function="tag-mode-toggle" value="off">Tag-Mode</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="h3" value="<h3>">&lt;h3&gt;</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="h4" value="<h4>">&lt;h4&gt;</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="ol-li" value="<ol>">&lt;ol&gt;</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="ul-li" value="<ul>">&lt;ul&gt;</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="p" value="<p>">&lt;p&gt;</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="br" value="<br><br>">&lt;br&gt;</button>
            <button class="noteEditToolbar-InsertHTML" data-button-function="CLI" value="<span class='cli-inline'>">&lt;span class="cli-inline"&gt;</button>
        </div> -->
                    

    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  name: "AppToolbar",
  props: ["editModeProp"],
  data:() => ({
    toolbarButtons: {
        newTask: {
            name: "New Task",
            description: "Create a new task",
            emitFunctionName: "newTask"
        },
        newList: {
            name: "New List",
            description: "Create a new list",
            emitFunctionName: "newList"
        },
        newTalliedList: {
            name: "New Tallied List",
            description: "Create a new tallied list, e.g, a shopping list",
            emitFunctionName: "newTalliedList"
        },
        newAppt: {
            name: "New Appointment",
            description: "Add a new appointment to your schedule",
            emitFunctionName: "newTalliedList"
        },
        editCategories: {
            name: "Edit Categories",
            description: "Edit your primary categories",
            emitFunctionName: "editCategories"
        },
        saveEdit: {
            name: "Save Edits",
            description: "Save changes made to the current selected item",
            emitFunctionName: "saveEdit"
        },
        toggleFullscreenEdit: {
            name: "Toggle Fullscreen Edit Mode",
            description: "Toggle fullscreen edit mode",
            emitFunctionName: "toggleFullscreen"
        },
        

      }
  }),
  computed: {
      getEditMode() {
          return this.editModeProp;
      }
  },
  methods: {
    toggleEditModeEvent() {
        this.$emit("toggleEditModeEvent");
        },
    newNote() {
        this.$emit("newNote");
    },
    newTask() {
        this.$emit("newTask");
    },
    newList() {
        this.$emit("newList");
    },
    newTalliedList() {
        this.$emit("newTalliedList");
    },
    newAppt() {
        this.$emit("newAppt");
    },
    editCategories() {
        this.$emit("editCategories");
    },
    saveEdit() {
        this.$emit("saveEdit");
    },
    toggleFullscreen() {
        console.log("toggleFullscreenEdit called");
        this.$emit("toggleFullscreenEvent");
    }
  }
};
</script>

<style>
#task2do-app-id .widget-titlebar .inline-button-span {
  right: 10%;
  position: absolute;
}

#task2do-appid .widget-toolbar {
    height: 3rem;
    display: block;
    /* position: absolute; */
}

.widget-toolbar .v-toolbar__content {
    height: 3rem;
}

header.widget-toolbar .inline-toolbar-icons button.mr-2.toolbar-icon.TM__blue-glowing-outline.v-btn.v-btn--depressed.v-btn--flat.v-btn--icon.v-btn--outlined.v-btn--round.theme--dark.v-size--default {
    color: rgb(13, 13, 100);
}



.widget-toolbar .toolbar-icon i.v-icon {
color: rgb(13, 13, 100);
/* border: .0875rem solid rgba(13, 13, 75, .95); */

}

.widget-toolbar .toolbar-icon button.mr-2.toolbar-icon.v-btn.v-btn--depressed.v-btn--flat.v-btn--icon.v-btn--outlined {
    color:  rgb(13, 13, 75);
}


header.widget-toolbar.v-sheet.v-sheet--tile.theme--dark.v-toolbar {
    height:  3.5rem;
    margin: 1.2rem .25rem .125rem;
}

#task2do-app-id header.widget-toolbar.v-toolbar {
    height: 3.35rem;
    margin: 1.35rem .25rem 0;
    padding: .125rem;
    background: rgba(130, 130, 150, .95);
    color: rgb(13, 13, 100);
}

</style>
