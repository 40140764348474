<template>
    <span :class="componentName">
        <v-dialog v-model="showProgressDialog" persistent width="500" max-width="640"> 
            <v-card>
                <v-card-title style="padding: 0.5rem 1rem; margin: 0 auto;"><span class="mdi mdi-autorenew rotating"></span>                
                <!-- <span>{{ progressDialogTitle }} {{ progress }}% Complete</span> -->

                <span style="
                    margin: 1rem .5rem 0.5rem 4rem;
                    font-size: 1.5rem;
                    font-weight: 700;
                    width:  90%;
                    display: inline-block;
                ">{{ progressDialogTitle }} {{ progress }}% Complete</span>

                </v-card-title>
                <!-- <v-card-text>
            
                </v-card-text>
                <v-divider></v-divider>
                 -->
                <v-card-text>  {{ progressDialogMessage }}</v-card-text>
                
                <v-divider></v-divider>
                <div style="display: inline-block; width: 90%;"> <span class="prog-bar_full-width"></span> </div>
                <!-- <v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="onConfirmError" class="full-width" style="margin: auto;">OK</v-btn>
                    </v-card-actions>
                </v-card-text> -->
            
            </v-card>
        </v-dialog>
    </span>
</template>

<script>


export default {
    name: "ProgressDialog",
    props: [
        "showProgressDialog",
        "progressDialogTitle",
        "progressDialogMessage",
        "progress"
    ],
    data: () => ({
        componentName: "ProgressDialog",
        shaped: false,
        outlined: false,
        rounded: false,
        // dense: false,
        autofocus: true,
        progressWidth: 1,
        progressRed: 0,
        progressGreen: 0,
        progressHue: 0
    }),
    created: function() {
    
        console.log("Showing progress dialog thats: " + this.progress);
    },
    updated: function() {
        this.updateProgressBar(this.progress);
    },
    computed: {
        displayDialog() {
            return this.showProgressDialog;
            
        }
    },
    methods: {

        // onCancelDelete() {
        //     this.$emit(`cancel${this.selectedItemType}Delete`);
        // },
        onConfirmError() {
         
            console.log(`calling: set_error_dialog_mutation`);

            
            this.confirm_error();

            // this.$emit(`confirmErrorMessage`);
            // this.$emit(`confirm${this.selectedItemType}Delete('${this.selectedItem.id}')`);
        },
        updateProgressBar(progressPercent) {
            progressPercent = progressPercent < 0 ? 0 : progressPercent > 100 ? 100 : progressPercent;
            let tempRange = progressPercent * 2.55,//Convert range of (0-100%) to range of (0-255),
                tempDubVal,
                tempHalfVal,
                tempThird;

            //tempRed = tempThird <= 255 ? tempThird: 255;
            // tempGreen =  (255-tempHalfVal) >=0 ? (255-tempHalfVal) : 0;
            //document.getElementById("tempText").value =  "halfval:" + tempHalfVal + "green= :" + tempGreen + "\n ThirdVal: " +  tempThird+ " Red:" + tempRed;   
            
            
            this.progressRed = progressPercent;
            tempRange = (progressPercent * 2.55).toFixed(0);
            tempDubVal = (tempRange * 2).toFixed(0),
            tempHalfVal = (tempRange / 2).toFixed(0),
            tempThird = (tempRange * 0.95).toFixed(0),

            this.progressWidth = progressPercent;
            //tempRed = tempThird <= 255 ? tempThird: 255;
            //tempGreen =  (255-tempHalfVal) >=0 ? (255-tempHalfVal) : 0;
            //    tempRed = (255+(tempThird *-1)) >= 0 ? (255+(tempThird * -1)): 0;

            this.progressRed = ((255-tempDubVal)+255) >= 0 ? ((255-tempDubVal)+255) : 0;
            this.progressRed = this.progressRed <= 255 ? this.progressRed : 255;
            this.progressGreen = ((255-tempHalfVal)+255) >= 0 ? ((255-tempHalfVal)+255) : 0;
            // tempGreen =  (tempGreen <= 255) ? tempRange: 255;
            this.progressGreen =  (this.progressGreen <= 255) ? this.progressWidth: 255;


            this.progressRed = (255+(tempThird *-1)) >= 0 ? (255+(tempThird * -1)): 0;
            this.progressGreen = ((255-tempDubVal)+128) >= 0 ? ((255-tempDubVal)+128) : 0;
            this.progressRed = this.progressRed <= 255 ? this.progressRed : 255;
            this.progressGreen =  (tempRange <= 255) ? tempRange: 255;
            
            //x[i].style.backgroundColor = "red";
            document.documentElement.style.setProperty('--prog-bar__width', this.progressWidth + '%');
            document.documentElement.style.setProperty('--prog-bar__green', this.progressGreen);
            document.documentElement.style.setProperty('--prog-bar__red', this.progressRed);

        
            }
    }
};
</script>

<style>

:root {

    --prog-bar__hue: 0;
    --prog-bar__green: 0;
    --prog-bar__red:255;
    --prog-bar__foreground-color: rgba(13,13,13,1);
    --prog-bar__width: 1%;
    --prog-bar__width-unit: 1%;
    --prog-bar__height: .5;
    --prog-bar__height-unit: 1rem;

}

.prog-bar_full-width
{

    display: inline-block;
    padding: 0px;
    margin: 0px;
    vertical-align: top;
    text-align:center;
    height:calc(var(--prog-bar__height) * var(--prog-bar__height-unit)) !important;;
    /*width:100%;*/
    width: var(--prog-bar__width);  /*  var(--prog-bar__width-unit)); */
    background-color: rgba(var(--prog-bar__red), var(--prog-bar__green), 0, 1);
    /* background: hsla(var(--prog-bar__hue), 50, 50, .9); */
    color:var(--prog-bar__foreground-color);
    transition: width 1.75s ease-in-out,
                background-color 1.75s ease-in-out,
                color 1.5s ease;

    

}

.prog-bar {
    transition: all 1.0s ease-in-out;
}



</style>