import * as arrayObjUtils from "../../lib/arrayObjUtils.js";


const state = {
    settingsWindow: {
        Tooltips: {
            EnableAnimationNotice: 'To enable Animated Backgrounds must acknowledge the risks and performance requirements before you can select this feature/trap',
            StaticBackgroundTip: 'A static image will be used for the background'
        }
    },
    widgets: {
        task2Do: {
            name: 'Task2DO! Hieharchy',
            description:
            'See your nested categorized tasks layed out in an intuitive Treeview display. Also very easy to filter tasks by category, type, priority level and deadline for even quicker access to what you need to get done.',
            primaryIconType: 'unicode',
            primaryIconUnicode: '\u{1F5C2}',
            secondaryIconUnicode: '\u{1F4CB}',
            primaryIconHtmlEnt: '&#xF0645;',
            primaryIcon: '\u{1F5C2}',
            isOpen: true,
            isMinimized: false,
            isMaximized: false,
            widgetSpinnerPosition: 1,
            dynamicGridPosition: 1,
            stricklyBusinessPosition: 1
        },
        noteTaker: {
            name: 'The Note Taker',
            description:
            'A simple note taking widget that\'s designed to be a bare-bones-no-frills note taker. The idea is to jot down your\'re note, idea, thought, opinion or anything you want / need to jot down. With shared data across the app, you can use other widgets like the Task2DO! Hieharchy and apply the note filter to see how all your notes are aranged in various categories. But for getting an idea recorded as quickly as possible so you can move on with your life...this is your GO-TO.',
            primaryIconType: 'unicode',
            primaryIconHtmlEnt: '&#x1F4DD;',
            secondaryIconHtmlEnt: '&#x1F5D2;',
            primaryIconUnicode: '\u{1F4DD}',
            primaryIcon: '\u{1F4DD}',
            isOpen: true,
            isMinimized: false,
            isMaximized: false,
            widgetSpinnerPosition: 2,
            dynamicGridPosition: 2,
            stricklyBusinessPosition: 2
        },
        // even2Do: {
        //     name: 'Even2Do',
        //     description: 'The Eventer, or maybe: Even2do <--yeah, I like Even2DO. It fits in with the whole 2DO app theme and it works phonetically.',
        //     descriptionCont: 'This is same widget as apt2DO!, i just wasn\'t sure what I liked better, so leaving this here until I make up my mind',
        //     isOpen: false,
        //     isMinimized: false,
        //     isMaximized: false,
        //     widgetSpinnerPosition: null,
        //     dynamicGridPosition: null,
        //     stricklyBusinessPosition: null

        // },
        apt2Do: {
            name: 'apt2DO! or Even2DO!',
            description:
            'Besides just managing a list of task and 2DOs, your also have appointments, events and other tasks that involve a schedual. That\'s where apt2DO! comes in. Manage you appoinments and still view your timed tasks and todos within the calendar. Like all the widgets, you can easily apply display filters to get to exactly what 2DO you need to view.',
            primaryIconType: 'unicode',
            primaryIconHtmlEnt: '&#x1F4C5;',
            secondaryIconHtmlEnt: '&#x1F5D3;',
            primaryIconUnicode: '\u{1F5D3}',
            secondaryIconUnicode: '\u{1F4C6}',
            primaryIcon: '\u{1F5D3}',
            isOpen: true,
            isMinimized: false,
            isMaximized: false,
            widgetSpinnerPosition: 3,
            dynamicGridPosition: 3,
            stricklyBusinessPosition: 3
        },
        whatsWeather2Do: {
            name: 'Whats-The-Weather-Going-2DO',
            description:
            'Good question. Lets find out with this handy little gem! Do you need to know more than that?',
            primaryIconType: 'unicode',
            primaryIcon: '\u{1F324}',
            isOpen: true,
            isMinimized: false,
            isMaximized: false,
            widgetSpinnerPosition: 4,
            dynamicGridPosition: 4,
            stricklyBusinessPosition: 4
        },
        blogs2Log: {
            name: 'Blogs-2-Log',
            description:
            'Got some blogs your writing,  maybe you just want to keep a journal of your days or log some sort of event. This widget is for those more polished works and includes formatting options with a Totally-Spectacular-WYIWYG editor to make formatting your literary master pieces to really make them shine.',
            primaryIconType: 'unicode',
            primaryIconHtmlEnt: '&#x1F4D2;',
            secondaryIconHtmlEnt: '&#x1F4D3;',
            primaryIconUnicode: '\u{1F4D2}',
            secondaryIconUnicode: '\u{1F4D3}',
            primaryIcon: '\u{1F4D2}',
            isOpen: true,
            isMinimized: false,
            isMaximized: false,
            widgetSpinnerPosition: 5,
            dynamicGridPosition: 5,
            stricklyBusinessPosition: 5
        },
        lists2Tally: {
            name: 'List2Tally',
            description:
            'List2Tally is your one stop shop for those everyday list you create, with the added feature of calculating totals for each item. Use it for a shopping list. With quantitys and estimated costs per item, you can get an idea of your cost well before check out. Use it as a home office inventory list or a list of fools that owe you loot or merch. Is your cousin of yours ever going to return that game you let them borrow? What ever the list may be, List2Tally will allow you quickly see whats-what on the status of that game finding it\'s way back to you. ',
            primaryIconType: 'unicode',
            primaryIconUnicode: '\u{1F4CB}',
            secondaryIconUnicode: '\u{1F5F3}',
            primaryIcon: '\u{1F5F3}',
            isOpen: false,
            isMinimized: false,
            isMaximized: false,
            widgetSpinnerPosition: null,
            dynamicGridPosition: null,
            stricklyBusinessPosition: null
        }
    },
    reminders: {
        numNotificationInQue: 0,
        remindersQue: [
            {
                id: 0,
                name: 'Notification Template',
                timestamp: null,
                title: '',
                lable: '',
                message: '',
                icon: '',
                badge: '',
                sound: null,
                action: '',
                referenceId: '', 
                /**
                 * Some values for reference ID that can be parsed according to prefix. 
                 * 
                 * Examples:
                 * 
                 * A notification referencing a task would look like:
                 * 'task-(followed by task's DB id)'
                 * 
                 * a weather alert might look something like this:
                 * 
                 * 'weatherAlert-(timestamp)' 
                 * (weather.gov probably already has a id for alerts that are sent out, so maybe use that)
                 */
                                        
            }
            
        ],
        nags: [
            {
                id: 0,
                label: 'How is your progress?',
                message: 'You should be done with ',
                sound: 'audio/samples/ding-a-ling.mp3'
            },
            {
                id: 1,
                label: 'Are you done yet?',
                message: 'Are you still working on ',
                sound: 'audio/samples/ding-a-ling-ding.mp3'
            },
            {
                id: 2,
                label: 'Whats taking sooo long?!',
                message: 'Seriously!? You need to finish ',
                sound: 'audio/samples/ding-a-ling-ding-ding-dong.mp3'
            },
            {
                id: 3,
                label: 'What\'s the status of your current task?',
                message: 'Have you completed ',
                sound: 'audio/samples/ding-a-ling-ding-ding-dong_deeper.mp3'
            },
            {
                id: 4,
                label: 'ding-a-ling',
                message: 'ding-a-ling',
                sound: 'audio/samples/ding-a-ling_softer.mp3'
            },
            {
                id: 5,
                label: 'ding-dong.mp3',
                message: 'ding-dong.mp3',
                sound: 'audio/samples/ding-dong.mp3'
            },
            {
                id: 6,
                label: 'heres-a-notification-for-ya-ding-a-ling-ding_compressed.mp3',
                message: 'heres-a-notification-for-ya-ding-a-ling-ding_compressed.mp3',
                sound: 'audio/samples/heres-a-notification-for-ya-ding-a-ling-ding_compressed.mp3'
            },
            {
                id: 7,
                label: 'Hey-Heres-a-reminder-for-ya.mp3',
                message: 'Hey-Heres-a-reminder-for-ya.mp3',
                sound: 'audio/samples/Hey-Heres-a-reminder-for-ya.mp3'
            },
            {
                id: 8,
                label: 'hey-you-got-something-coming-up.mp3',
                message: 'hey-you-got-something-coming-up.mp3',
                sound: 'audio/samples/hey-you-got-something-coming-up.mp3'
            },
            {
                id: 9,
                label: 'Have you finished your current task?',
                message: 'Have you finished ',
                sound: 'audio/samples/you-should-be-finishing-your-current-task-in.mp3'
            },
            {
                id: 10,
                label: 'yeah-I-hate-to-be-that-guy-but--ya-done-yet.mp3',
                message: 'yeah-I-hate-to-be-that-guy-but--ya-done-yet.mp3',
                sound: 'audio/samples/yeah-I-hate-to-be-that-guy-but--ya-done-yet.mp3'
            },
            {
                id: 11,
                label: 'you-should-be-finishing-your-current-task-in.mp3',
                message: 'you-should-be-finishing-your-current-task-in.mp3',
                sound: 'audio/samples/you-should-be-finishing-your-current-task-in.mp3'
            },
            {
                id: 12,
                label: 'i-see-productivity-isnt-ur-strong-point_compressed.mp3',
                message: 'i-see-productivity-isnt-ur-strong-point_compressed.mp3',
                sound: 'audio/samples/i-see-productivity-isnt-ur-strong-point_compressed.mp3'
            },
            {
                id: 13,
                label: 'yoo-hoo-whistle.mp3',
                message: 'yoo-hoo-whistle.mp3',
                sound: 'audio/samples/yoo-hoo-whistle.mp3'
            },
            {
                id: 14,
                label: 'you-have-an-appt-comming-up.mp3',
                message: 'you-have-an-appt-comming-up.mp3',
                sound: 'audio/samples/you-have-an-appt-comming-up.mp3'
            },
            {
                id: 15,
                label: 'you-need-to-prepare-for-your-next-task.mp3',
                message: 'you-need-to-prepare-for-your-next-task.mp3',
                sound: 'audio/samples/you-need-to-prepare-for-your-next-task.mp3'
            },
            {
                id: 16,
                label: 'you-need-to-get-ready-for-your-next-appointment.mp3',
                message: 'you-need-to-get-ready-for-your-next-appointment.mp3',
                sound: 'audio/samples/you-need-to-get-ready-for-your-next-appointment.mp3'
            },
            {
                id: 17,
                label: 'you-should-really-work-on-your-productivity-a-little-bit_compressed.mp3',
                message: 'you-should-really-work-on-your-productivity-a-little-bit_compressed.mp3',
                sound: 'audio/samples/you-should-really-work-on-your-productivity-a-little-bit_compressed.mp3'
            }                
        ]
    },
    audioData: {
        soundEnabled: true,
        soundVolume: 50,
        isPlayCurrentTimeAudioEnabled: true,
        audioTimeHourArray: [],
        audioTimeMinArray: [],
        audioTimeBufer: {
            hours: [],
            minutes: [],
            AM: null,
            PM: null
        }
    }
};

const getters = {
    getAppData: state => state,
    getReminderData: state => state.reminders,
    getAudioData: state => state.audioData
};

const actions = {
    toggleNotificationSounds({ commit }) {
        console.log("Sound toggle called");
        commit('TOGGLE_NOTIFICATION_SOUND_MUTATION');
    },
    async loadAudioIntoBufferArray({ commit }) {
        // audioTimeBufer
        let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
       
        let source;
        let audioMinArray = [];
        let audioHourArray = [];

        console.log('initializing audio time buffer array...');

        /**************************************************************************
         * //TODO: You need a more efficent way to load the audio files
         *         instead of making 72+ https requests!!!
         * 
         *************************************************************************/
        for (let i = 0; i <= 12; i++) {
           
            // audioHourArray[i] = 'audio/time-audio/' + i + '.mp3';
            var url = 'http://192.168.0.165:8080/' + state.appDataSettings.audioTimeHourArray[i];
            console.log('Request URL: ' + url);
            const request = new XMLHttpRequest();
            await ( request.open('GET', url));
            request.responseType = 'arraybuffer';
            request.onreadystatechange = function() {
                if(request.readyState === XMLHttpRequest.DONE && request.status === 200) {
                    console.log('loading file ' + i + ' with data: ' + request.response);
                    audioHourArray[i] = request.response;

                    // var data = JSON.parse(request.responseText);
                    // console.log('-->' + data._id);
                }
            }
            // request.onload = async function() {
            //     // let audioData = request.response;
            //     console.log('loading file ' + i + ' with data: ' + request.response);
            //     audioHourArray[i] = await request.response;
            //     // audioCtx.decodeAudioData(audioData, function(buffer) {
            //     //     audioHourArray[i] = buffer;
            //     // },
            //     // function(e) { 
            //     //     console.log("Error with decoding hour audio data" + e.err);
            //     // });
            // }
            request.send;
        }

        for (let i = 0; i <= 60; i++) {
            
            // if (i < 10) {
            //     request.open('GET', 'audio/time-audio/0' + i + '.mp3', true);      
            // } else { 
            let url = 'http://192.168.0.165:8080/' + state.appDataSettings.audioTimeMinArray[i];
            let request = new XMLHttpRequest();
            console.log('Request URL: ' + url);
            await request.open('GET', url);
            // }
            request.responseType = 'arraybuffer';
            module
            request.onreadystatechange = function() {
                if(request.readyState === XMLHttpRequest.DONE && request.status === 200) {
                    console.log('loading file ' + i + ' with data: ' + request.response);
                    audioMinArray[i] = request.response;

                    // var data = JSON.parse(request.responseText);
                    // console.log('-->' + data._id);
                }
            }
            
            // request.onload = async function() {
            //     // let audioData = request.response;
            //     audioMinArray[i] = await request.response;
            //     // audioCtx.decodeAudioData(audioData, function(buffer) {
            //     //     audioMinArray[i] = buffer;
            //     // },
            //     // function(e) { 
            //     //     console.log("Error with decoding minutes audio data" + e.err);
            //     // });
            // }
            await request.send;
        }


        commit('STORE_AUDIO_BUFFER', { audioHourArray, audioMinArray });
    },
    initAudioTimeArray({ commit })  {
        let audioMinArray = [];
        let audioHourArray = [];
        console.log('initializing audio time array...');
        for (let i = 0; i <= 60; i++) {
            if (i < 10) {
                audioMinArray[i] = 'audio/time-audio/0' + i + '.mp3';
            } else {
                audioMinArray[i] = 'audio/time-audio/' + i + '.mp3';
            }
        }

        for (let i = 0; i <= 12; i++) {
            audioHourArray[i] = 'audio/time-audio/' + i + '.mp3';
        }

        commit('INIT_AUDIO_TIME_ARRAY_MUTATION', { audioHourArray, audioMinArray });
    }

};

const mutations = {
    TOGGLE_NOTIFICATION_SOUND_MUTATION: (state) => state.audioData.soundEnabled = !state.audioData.soundEnabled,
    INIT_AUDIO_TIME_ARRAY_MUTATION(state, { audioHourArray, audioMinArray }) {
        state.audioData.audioTimeHourArray =  arrayObjUtils.arrayObjsDeepCopy(audioHourArray);
        state.audioData.audioTimeMinArray =  arrayObjUtils.arrayObjsDeepCopy(audioMinArray);
    },
    STORE_AUDIO_BUFFER(state, { audioHourArray, audioMinArray }) {
        state.audioData.audioTimeBufer.hours = arrayObjUtils.arrayObjsDeepCopy(audioHourArray);
        state.audioData.audioTimeBufer.minutes = arrayObjUtils.arrayObjsDeepCopy(audioMinArray);

    }

};

export default  {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};