
const state = {
    task2DoCategories: [
        {
        id: "87cc546b-d42c-4960-8022-3fbedad31ad6",
        name: "Shit 2 Do",
        taskType: "category",
        keyPath: "",
        nodeLevel: 0,
        nodeIndex: 0
        },
        {
        id: "4e20aec5-bd60-4d31-8d75-e6cfb1145db4",
        name: "Personal",
        taskType: "category",
        keyPath: "",
        nodeLevel: 0,
        nodeIndex: 1,
        children: [
            {
            id: "38b9b84c-0180-4493-96f2-74f4e3513e5b",
            name: "Practice guitar",
            taskType: "category",
            keyPath: "",
            nodeLevel: 1,
            nodeIndex: 0,
            children: [
                {
                id: "ebcd889a-040d-409b-8075-d61b3e0d43ab",
                name: "Exercises",
                taskType: "category",
                keyPath: "",
                nodeLevel: 2,
                nodeIndex: 0
                },
                {
                id: "eb8e8298-9c16-43b2-ab9e-283ed4f8a3e5",
                name: "Songs",
                taskType: "category",
                keyPath: "",
                nodeLevel: 2,
                nodeIndex: 1
                }
            ]
            },
            {
            id: "59e61339-6f1e-4d35-b141-d0741505b805",
            name: "Health",
            taskType: "category",
            keyPath: "",
            nodeLevel: 1,
            nodeIndex: 1,
            children: [
                {
                id: "940a521b-93df-4903-962d-d4c2fe0f4acb",
                name: "Exercises 2 Do",
                taskType: "category",
                keyPath: "",
                nodeLevel: 2,
                nodeIndex: 0
                }
            ]
            }
        ]
        },
        {
        id: "b7bc4bb6-ac58-453e-9e5e-1cdbf88128b7",
        name: "Business",
        taskType: "category",
        keyPath: "",
        nodeLevel: 1,
        nodeIndex: 2,
        children: [
            {
            id: "087043fa-2e05-445c-b839-b32d80aee43f",
            name: "Career",
            taskType: "category",
            keyPath: "",
            nodeLevel: 2,
            nodeIndex: 0
            }
        ]
        },
        {
        id: "2894febb-6b24-4bfd-88cc-9f5c270d3bc6",
        name: "Shared",
        taskType: "category",
        keyPath: "",
        nodeLevel: 1,
        nodeIndex: 3,
        children: [
            {
            id: "396b44ab-f177-4fb7-8073-e79a11304f0a",
            name: "Task2Do Project",
            taskType: "category",
            keyPath: "",
            nodeLevel: 2,
            nodeIndex: 0
            }
        ]
        }
    ],
    frequentInputFields: [
        {
            "name": "Work on Task2Do",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 10,
            "priorityLevelId": 5
        },
        {
            "name": "Activities",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 3,
            "priorityLevelId": 5
        },
        {
            "name": "Work on Task2Do glitches",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Katie turns 40",
            "taskTypeId": 3,
            "wantOrNeed": "n/a",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Shoveled around shop",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Shower",
            "taskTypeId": 14,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Post office",
            "taskTypeId": 7,
            "wantOrNeed": "NEED",
            "occurrences": 4,
            "priorityLevelId": 5
        },
        {
            "name": "Worked on Task2Do touch events",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Sleep",
            "taskTypeId": 12,
            "wantOrNeed": "NEED",
            "occurrences": 10,
            "priorityLevelId": 5
        },
        {
            "name": "Nap",
            "taskTypeId": 12,
            "wantOrNeed": "NEED",
            "occurrences": 2,
            "priorityLevelId": 5
        },
        {
            "name": "Katie birthday",
            "taskTypeId": 3,
            "wantOrNeed": "want",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Worked on task2do scroll functionality",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Txt/call dad back",
            "taskTypeId": 11,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Shovel deck, brush off car",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Looked Into finding court records",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Call court to confirm adjournment till Feb. 24th",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Made e-card for Katie",
            "taskTypeId": 6,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Chautauqua Mental Health appointment",
            "taskTypeId": 9,
            "wantOrNeed": "NEED",
            "occurrences": 8,
            "priorityLevelId": 5
        },
        {
            "name": "Worked on Task2Do styling glitches",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Fulvio Jr. harassment court date",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Worked on Task2do performance  & crashing issues",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Task2do still running like a turd on mobile",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Hung out @ Scots",
            "taskTypeId": 11,
            "wantOrNeed": "want",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Pickup Katie from work",
            "taskTypeId": 10,
            "wantOrNeed": "NEED",
            "occurrences": 8,
            "priorityLevelId": 5
        },
        {
            "name": "Finished shoveling driveway",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Worked on Task2do async/await functions",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Eat",
            "taskTypeId": 18,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Work on more Task2do glitches",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Drive to PA",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "PA to see Katies's family",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Shovel driveway",
            "taskTypeId": 8,
            "wantOrNeed": "NEED",
            "occurrences": 3,
            "priorityLevelId": 5
        },
        {
            "name": "Drive Katie to work",
            "taskTypeId": 10,
            "wantOrNeed": "NEED",
            "occurrences": 8,
            "priorityLevelId": 5
        },
        {
            "name": "Cleared parh in driveway so I could get car out",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Pickup Jeep @ mom & dads",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Worked on Task2Do refactoring",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Outside work",
            "taskTypeId": 8,
            "wantOrNeed": "NEED",
            "occurrences": 3,
            "priorityLevelId": 5
        },
        {
            "name": "Burn garbage",
            "taskTypeId": 8,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Drop Jeep off to mom & dads",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Send emails to various people",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Fix Task2Do loading glitches",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Eat dinner",
            "taskTypeId": 18,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Pomfret Court - Small Claims",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Take air tank out of Camry put salt down in driveway",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Practice guitar",
            "taskTypeId": 16,
            "wantOrNeed": "NEED",
            "occurrences": 4,
            "priorityLevelId": 5
        },
        {
            "name": "Lunch & nap",
            "taskTypeId": 18,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Researched vue reactivity & input autofill",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Clear snow on walkway/driveway",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Dinner",
            "taskTypeId": 18,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Implement auto complete component in task2do",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Help Katie take down tree",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Send emails",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Worked on Task2Do",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Mail out rods package",
            "taskTypeId": 7,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "List connecting rods on Craigslist",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Watch Superbowl @ Chris's",
            "taskTypeId": 11,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Work on Task2Do bugs",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "More Task2Do bugs",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        },
        {
            "name": "Finish clearing ditches, secure tarp on 740il",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5
        }
    ],
    frequentInputFieldsObj: [
        {
            "name": "Work on Task2Do",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 10,
            "priorityLevelId": 5,
            "index": 0
        },
        {
            "name": "Activities",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 3,
            "priorityLevelId": 5,
            "index": 1
        },
        {
            "name": "Work on Task2Do glitches",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 2
        },
        {
            "name": "Katie turns 40",
            "taskTypeId": 3,
            "wantOrNeed": "n/a",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 3
        },
        {
            "name": "Shoveled around shop",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 4
        },
        {
            "name": "Shower",
            "taskTypeId": 14,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 5
        },
        {
            "name": "Post office",
            "taskTypeId": 7,
            "wantOrNeed": "NEED",
            "occurrences": 4,
            "priorityLevelId": 5,
            "index": 6
        },
        {
            "name": "Worked on Task2Do touch events",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 7
        },
        {
            "name": "Sleep",
            "taskTypeId": 12,
            "wantOrNeed": "NEED",
            "occurrences": 10,
            "priorityLevelId": 5,
            "index": 8
        },
        {
            "name": "Nap",
            "taskTypeId": 12,
            "wantOrNeed": "NEED",
            "occurrences": 2,
            "priorityLevelId": 5,
            "index": 9
        },
        {
            "name": "Katie birthday",
            "taskTypeId": 3,
            "wantOrNeed": "want",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 10
        },
        {
            "name": "Worked on task2do scroll functionality",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 11
        },
        {
            "name": "Txt/call dad back",
            "taskTypeId": 11,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 12
        },
        {
            "name": "Shovel deck, brush off car",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 13
        },
        {
            "name": "Looked Into finding court records",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 14
        },
        {
            "name": "Call court to confirm adjournment till Feb. 24th",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 15
        },
        {
            "name": "Made e-card for Katie",
            "taskTypeId": 6,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 16
        },
        {
            "name": "Chautauqua Mental Health appointment",
            "taskTypeId": 9,
            "wantOrNeed": "NEED",
            "occurrences": 8,
            "priorityLevelId": 5,
            "index": 17
        },
        {
            "name": "Worked on Task2Do styling glitches",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 18
        },
        {
            "name": "Fulvio Jr. harassment court date",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 19
        },
        {
            "name": "Worked on Task2do performance  & crashing issues",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 20
        },
        {
            "name": "Task2do still running like a turd on mobile",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 21
        },
        {
            "name": "Hung out @ Scots",
            "taskTypeId": 11,
            "wantOrNeed": "want",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 22
        },
        {
            "name": "Pickup Katie from work",
            "taskTypeId": 10,
            "wantOrNeed": "NEED",
            "occurrences": 8,
            "priorityLevelId": 5,
            "index": 23
        },
        {
            "name": "Finished shoveling driveway",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 24
        },
        {
            "name": "Worked on Task2do async/await functions",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 25
        },
        {
            "name": "Eat",
            "taskTypeId": 18,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 26
        },
        {
            "name": "Work on more Task2do glitches",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 27
        },
        {
            "name": "Drive to PA",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 28
        },
        {
            "name": "PA to see Katies's family",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 29
        },
        {
            "name": "Shovel driveway",
            "taskTypeId": 8,
            "wantOrNeed": "NEED",
            "occurrences": 3,
            "priorityLevelId": 5,
            "index": 30
        },
        {
            "name": "Drive Katie to work",
            "taskTypeId": 10,
            "wantOrNeed": "NEED",
            "occurrences": 8,
            "priorityLevelId": 5,
            "index": 31
        },
        {
            "name": "shovel driveway",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 32
        },
        {
            "name": "Cleared parh in driveway so I could get car out",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 33
        },
        {
            "name": "Pickup Jeep @ mom & dads",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 34
        },
        {
            "name": "Worked on Task2Do refactoring",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 35
        },
        {
            "name": "Outside work",
            "taskTypeId": 8,
            "wantOrNeed": "NEED",
            "occurrences": 3,
            "priorityLevelId": 5,
            "index": 36
        },
        {
            "name": "Burn garbage",
            "taskTypeId": 8,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 37
        },
        {
            "name": "Drop Jeep off to mom & dads",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 38
        },
        {
            "name": "Send emails to various people",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 39
        },
        {
            "name": "Fix Task2Do loading glitches",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 40
        },
        {
            "name": "Eat dinner",
            "taskTypeId": 18,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 41
        },
        {
            "name": "Pomfret Court - Small Claims",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 42
        },
        {
            "name": "Take air tank out of Camry put salt down in driveway",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 43
        },
        {
            "name": "Practice guitar",
            "taskTypeId": 16,
            "wantOrNeed": "NEED",
            "occurrences": 4,
            "priorityLevelId": 5,
            "index": 44
        },
        {
            "name": "Lunch & nap",
            "taskTypeId": 18,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 45
        },
        {
            "name": "Researched vue reactivity & input autofill",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 46
        },
        {
            "name": "Clear snow on walkway/driveway",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 47
        },
        {
            "name": "Dinner",
            "taskTypeId": 18,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 48
        },
        {
            "name": "Implement auto complete component in task2do",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 49
        },
        {
            "name": "Help Katie take down tree",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 50
        },
        {
            "name": "Send emails",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 51
        },
        {
            "name": "Worked on Task2Do",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 52
        },
        {
            "name": "Mail out rods package",
            "taskTypeId": 7,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 53
        },
        {
            "name": "List connecting rods on Craigslist",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 54
        },
        {
            "name": "Watch Superbowl @ Chris's",
            "taskTypeId": 11,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 55
        },
        {
            "name": "Work on Task2Do bugs",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 56
        },
        {
            "name": "More Task2Do bugs",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 57
        },
        {
            "name": "Finish clearing ditches, secure tarp on 740il",
            "taskTypeId": 3,
            "wantOrNeed": "NEED",
            "occurrences": 1,
            "priorityLevelId": 5,
            "index": 58
        }
    ],
    avatar: {
        animated: true,
        basePath: 'https://t2do.io/img/Avatars/Updated-2020/',
        normalMood: {
            frames: [
                {
                    name: 'Default',
                    url: 'Brando-big-mouth-normal_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Default avatar frame'
                },
                {
                    name: 'Talking',
                    url: 'Brando-big-mouth-talking_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Talking avatar frame'
                },
                {
                    name: 'blink',
                    url: 'Brando-big-mouth-blink_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Blink'
                },
                {
                    name: 'winkLeft',
                    url: 'Brando-big-mouth-left-wink_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Wink Left'
                },
                {
                    name: 'winkRight',
                    url: 'Brando-big-mouth-right-wink_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Wink Right'
                },
                {
                    name: 'lookDown',
                    url: 'Brando-big-mouth-look-down_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Look Down'
                },
                {
                    name: 'lookUp',
                    url: 'Brando-big-mouth-look-up_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Look Up'
                },
                {
                    name: 'lookLeft',
                    url: 'Brando-big-mouth-look-left_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Look Left'
                },
                {
                    name: 'lookDownLeft',
                    url: 'Brando-big-mouth-look-down-left_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Look Down Right'
                },
                {
                    name: 'lookUpLeft',
                    url: 'Brando-big-mouth-look-up-left_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Look Up Right'
                },
                {
                    name: 'lookRight',
                    url: 'Brando-big-mouth-look-right_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Look Right'
                },
                {
                    name: 'lookDownRight',
                    url: 'Brando-big-mouth-look-down-right_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Look Down Right'
                },
                {
                    name: 'lookUpRight',
                    url: 'Brando-big-mouth-look-up-right_spikey-hair_thin-rectangle-glasses.png',
                    desc: 'Look Up Right'
                }
                

            ],
            description: 'A content state of mind'
        },
        angryMood: {
            frames: [

            ],
            description: 'Don\'t mess with me!'
        }
    },
};


const getters = {
    getFrequentInputFields: state => state.frequentInputFieldsObj,
    getUserAvatars: state => state.avatar

};


const actions = {
    updateFrequentInputFields( { dispatch, commit, getters, rootGetters} , frequentFieldsObj) {
        // this.getNotes.filter(note => note.name.toLowerCase().includes(this.notesFilterString.toLowerCase()));
        
        let frequentFieldObj = rootGetters['userData/getFrequentInputFields'].filter(frequentInputFieldObj => frequentInputFieldObj['name'].toLowerCase().indexOf(frequentFieldsObj.name.toLowerCase()) > -1);

        let matchIndex = rootGetters['userData/getFrequentInputFields'].indexOf(frequentFieldObj);

        if (matchIndex !== -1) {
            console.log('match found with index of: ' + matchIndex + '\n\tmatching object: ' + JSON.stringify(frequentFieldObj, null, 1));

            frequentFieldObj.occurrences += 1;
            frequentFieldObj.wantOrNeed = frequentFieldsObj.wantOrNeed;
            frequentFieldObj.taskTypeId = frequentFieldsObj.taskTypeId;
            frequentFieldObj.priorityLevelId = frequentFieldsObj.priorityLevelId;
        } else {
            let newFrequentFieldObjIndex = rootGetters['userData/getFrequentInputFields'].length;
            frequentFieldObj = {   
                "name": frequentFieldsObj.name,
                "taskTypeId": frequentFieldsObj.taskTypeId,
                "wantOrNeed": frequentFieldsObj.wantOrNeed,
                "occurrences": 1,
                "priorityLevelId": frequentFieldsObj.priorityLevelId,
                "index": newFrequentFieldObjIndex
            }
        }

        console.log('\n\tmatching object after update: ' + JSON.stringify(frequentFieldObj, null, 1));


        // if((!state.preferences.frequentInputFields.includes(textValue)) && ( textValue !== '')) {
            commit("PUSH_TEXT_VALUE_TO_FREQUENT_INPUT_FIELDS", { fieldIndex: matchIndex, fieldObject: frequentFieldObj });
        //     this.dispatch('putUserPreferences');
        // } else {
        //     console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!\ndidnt update frequentInputFields data')
        // }
    },

};


const mutations = {
    PUSH_TEXT_VALUE_TO_FREQUENT_INPUT_FIELDS(state, {fieldIndex, fieldObject }) {
        state.frequentInputFieldsObj[fieldIndex] = fieldObject;
        // state.preferences.frequentInputFields.push(textValue);
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

