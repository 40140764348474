<template>
<!-- eslint-disable no-unused-components -->
    <v-container class="widget" :class="[ minimizeState ? minimizeClassName : '',
            maximizeState ? maximizeClassName : ''  ]" >

    <div style="position: absolute; display: block; background: unset; opacity: 0; left: 0; top:0; width: 100%; height: 100%; z-index: 1500;"
            v-if="focusedWidget !== 'MarkdownWidget'"
            @click.stop="$emit('bring-to-front', 'MarkdownWidget')">
    </div>
        <v-card class="widget-window-outer-container">
      
        <WindowBar :widgetLabel="widgetLabel"
                    :minimizeState="minimizeState"
                    v-on:minimize="minimize"
                    :maximizeState="maximizeState"
                    v-on:maximize="maximize"
                    :notifications="notificationsList"
                    :notificationType="notificationString">
        </WindowBar>

        <v-container id="markdownContainerId" class="widget-inner-container" fluid >
            <v-row dense class="markdown-container">
                <v-col align="left" cols="12">
                    <!-- <div id="app-wall" v-bind:class="{ full: isFull }" v-bind:style="{ backgroundColor: actualSkin.wall }"> -->
                        <!-- <div id="app"> -->
                            <!-- <header>just-a-file.md<div class="buttons"></div></header> -->
                        <div class="editor">
                            <textarea class="code" v-model="code" v-bind:style="{ color: actualSkin.color }"></textarea>

                            <!-- <div class="result" v-html="code | marked"></div> -->

                            <div class="result markdown-render" v-html="compiledOutput"></div>

                            <div class="settings" v-bind:class="{ active: isSettings }">
                                <h2>Settings
                                    <i class="fa fa-window-close-o" @click="isSettings = false"></i>
                                </h2>
                                <h3>Editor Theme</h3>
                                    <div class="skin" v-for="(skin, i) in skins" :key="i" 
                                        v-bind:class="{ active: skin.isActive }"
                                        v-bind:style="{ background: skin.color }"
                                        @click="setSkin(skin)"
                                    ></div>
                                <h3>Screen</h3>
                                <span class="toggleView" v-show="!isFull" @click="isFull = true">
                                    <i class="fa fa-window-maximize"></i>
                                    Full Screen
                                </span>
                                <span class="toggleView" v-show="isFull" @click="isFull = false">
                                    <i class="fa fa-window-restore"></i>
                                    Compact
                                </span>
                                <h3>Update</h3>
                                <span class="search" @click="updateMe"><i class="fa fa-refresh"></i>
                                    Search for updates
                                </span>
                                <div class="alert" v-bind:class="{ updating: isUpdating }">
                                    <div class="text"> Hehehe, serious?? You know it's not gonna happen, right?</div>
                                </div>
                            </div>
                        </div>
                        <!-- <footer v-bind:style="{ background: actualSkin.background }">{{ appTitle }}
                            <i class="fa fa-cog" v-bind:class="{ lookme: isSeen }" @click="showSettings"></i>
                        </footer> -->
                        <!-- </div> -->
                    <!-- </div> -->
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import { marked } from "@/main";
//  const marked = require('@/lib/marked.js');

import { mapGetters, mapActions } from "vuex";

import WindowBar from "@/components/widgets/components/WindowBar.vue";


export default {
    name: "MarkdownWidget",
    // eslint-disable no-unused-components
    components: {
        // TitleBar
        WindowBar
    },
    props: ["focusedWidget"],
    data: () => ({
        componentName: "MarkdownWidget",
        appTitle: "VueDown Editor",
        code: "# Primary Header\n---\n\n## _Secondary Header_\n\nGot some mutha-friggin' markdown...\n\n### Hey look at me!!!  I'm another header!!!\n\n First Table Column Header | Table Header\n------------ | -------------\nContent from cell 1 | Content from cell 2\nContent in the first column | Content in the second column \n\n- [ ] foo\n- [x] bar\n\n- [x] foo\n  - [ ] bar\n  - [x] baz\n- [ ] bim\n\n\n```bash\nsudo systemctl status apache2\n```\n",
        isSeen: true,
        isSettings: false,
        isFull: false,
        isUpdating: false,
        actualSkin: {
        color: "#0cc",
        background: "rgba(0, 204, 204, .4)",
        wall: "rgba(0, 204, 204, .15)"
        },
        skins: [
            {
                color: "#0cc",
                background: "rgba(0, 204, 204, .4)",
                wall: "rgba(0, 204, 204, .15",
                isActive: true
            },
            {
                color: "#0c0",
                background: "rgba(0, 204, 0, .4)",
                wall: "rgba(0, 204, 0, .15",
                isActive: false
            },
            {
                color: "#f60",
                background: "rgba(255, 102, 0, .4)",
                wall: "rgba(255, 102, 0, .15)",
                isActive: false
            },
            {
                color: "#f0f",
                background: "rgba(255, 0, 255, .4)",
                wall: "rgba(255, 0, 255, .15)",
                isActive: false
            },
            {
                color: "#e00",
                background: "rgba(204, 0, 0, .2)",
                wall: "rgba(204, 0, 0, .15)"
            }
        ],
        minimizeInBackground: true,
        minimizeInForeground: false,
        minimizeClassName: "minimize-widget",
        maximizeClassName: "maximize-widget",
        maximizeState: false,
        notificationsList: [ 'Warning: unsaved changes'],
        notificationString: "Unsaved Edit",
    }),
    //   filters: {
    //     marked: marked
    //   },
    computed: {
        compiledOutput() {
            return marked(this.code, { sanitize: true });
        },
        minimizeState() {
            // basically apply foreground state when focused, otherwise apply background state
            return (this.focusedWidget === this.componentName) ? this.minimizeInForeground : this.minimizeInBackground; 
        }
    },
    created() {
        this.minimizeState = true;
    },
    updated: function() {
        if (this.focusedWidget !== this.componentName) {
            // This resets the foreground state to ensure widget isn't minimized when regaining focus
            this.minimizeInForeground = false;
        }

        console.log("\tthis.name:" + this.componentName);
        console.log("\tthis.focusedWidget: " + this.focusedWidget);
        console.log("\tInitialFocus: " + this.initialFocus);
        console.log("\tminimize state: " + this.minimizeState);
    },
    methods: {
        ...mapActions(["fetchTodos"]),
        minimize() {
            this.minimizeInForeground = !this.minimizeInForeground;
            this.minimizeInBackground = this.minimizeInForeground;

            if (this.maximizeState === true) {
                this.maximizeState = false;
            }
        },
        maximize() {
            if (this.maximizeState === false) {
                this.maximizeState = true;
                this.minimizeInForeground = false;
                this.minimizeInBackground = this.minimizeInForeground;
            } else {
                this.maximizeState = false;
            }
        },
        showSettings() {
            this.isSettings = !this.isSettings;
            this.isSeen = false;
        },
        setSkin(skin) {
            this.actualSkin.color = skin.color;
            this.actualSkin.background = skin.background;
            this.actualSkin.wall = skin.wall;
            // Toggle the actual active skin
            this.skins.map(function(obj) {
                if (obj === skin) {
                obj.isActive = true;
                } else {
                obj.isActive = false;
                }
            });
        },
        updateMe() {
            var ctx = this;
            ctx.isUpdating = true;
            setTimeout(function() {
                ctx.isUpdating = false;
            }, 4000);
        }
    }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700|Source+Code+Pro");
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* body {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
} */

/* h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
} */

div#markdownContainerId {
    max-height: calc(100% - 2.5rem);
    height: 100%;
    width: calc(100% - 0.2rem);
    overflow-y: auto;
    margin: 1.2rem auto -0.25rem;
    padding: 0 0.25rem;
}


#app-wall {
    min-height: 100vh;
    padding: 5rem 0;
    -webkit-transition: background 0.4s;
    transition: background 0.4s;
}
#app-wall.full {
    padding: 0;
}
#app-wall.full #app {
    width: 100%;
    height: calc(100vh - 40px);
    padding: 0;
    font-size: 100%;
}



header {
    position: relative;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.8);
    background: lightgray;
    border-radius: 5px 5px 0 0;
}

header .buttons {
    position: absolute;
    top: 6px;
    left: 22px;
    width: 8px;
    height: 8px;
    background: rgba(255, 153, 0, 0.5);
    border-radius: 50%;
    box-shadow: -12px 0 0 rgba(204, 0, 0, 0.6), 12px 0 0 rgba(0, 204, 0, 0.4);
}

/* .editor {
    position: relative;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: flex;
    overflow: hidden;
} */

.editor .code,
.editor .result,
.editor .settings {
    width: 50%;
    padding: 1rem;
}

.editor .code {
    resize: none;
    border: none;
    font-family: "Source Code Pro", monospace;
    background: rgba(0, 0, 0, 0.8);
    outline: none;
    -webkit-transition: color 0.4s;
    transition: color 0.4s;
}

.editor .markdown-render {
    background: #fff;
}
.editor .markdown-render hr {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.editor .markdown-render img {
    max-width: 100%;
    height: auto;
}

.editor .settings {
    position: absolute;
    top: 0;
    right: 0;
    width: 51%;
    height: 100%;
    color: #fff;
    background: #000;
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
}
.editor .settings.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.editor .settings h2 {
    position: relative;
}
.editor .settings h2 .fa {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    font-size: 0.85em;
    cursor: pointer;
}
.editor .settings h2 .fa:hover {
    -webkit-transform: translateY(-50%) rotate(-180deg);
    transform: translateY(-50%) rotate(-180deg);
}
.editor .settings h3 {
    margin-top: 2rem;
    padding: 0.25rem 0.2rem 0.2rem;
    font-weight: normal;
    background: rgba(255, 255, 255, 0.2);
}
.editor .settings .skin {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: middle;
    opacity: 0.5;
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
.editor .settings .skin:hover,
.editor .settings .skin.active {
    opacity: 1;
}

.editor .settings .search,
.editor .settings .toggleView {
    cursor: pointer;
    font-size: 0.85em;
}

.editor .settings .search .fa,
.editor .settings .toggleView .fa {
    margin-right: 0.5rem;
}
.editor .settings .toggleView:hover .fa-window-maximize {
    -webkit-animation: pulse 1s linear infinite;
    animation: pulse 1s linear infinite;
}
.editor .settings .toggleView:hover .fa-window-restore {
    animation: pulse 1s linear infinite reverse;
}
.editor .settings .search:hover .fa {
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
}

.editor .settings .alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.85);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
}

.editor .settings .alert.updating {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.editor .settings .alert .text {
    padding: 1rem 1rem 1.1rem;
    text-align: center;
    background: #000;
    line-height: 1.4;
}


/*********************** T A S K 2 D O   C U S T O M   S T Y L E S ***********************/

/* #task2do-app-id .widget > .widget-window-outer-container .widget-inner-container > .row.row--dense.markdown-container */
#task2do-app-id .widget > .widget-window-outer-container .widget-inner-container > .markdown-container {
	min-height: 100%;
	max-height: 100%;
	position: absolute;
	background: rgba(13, 13, 13, .9);
	height: 100%;
	max-width: 100%;
	margin: 0;
	/* padding: .125rem; */
	display: flex;
	top: 0;
	padding: 0;
	position: relative;
}

#task2do-app-id .widget > .widget-window-outer-container .widget-inner-container > .markdown-container .editor {
	position: relative;
	min-height: 100%;
	background: rgba(13, 13, 13, 0.5);
	display: flex;
	overflow: unset;
	margin: 0;
	padding: .125rem;
	border: .25rem inset rgba(75, 75, 75, .975);
}


#task2do-app-id .widget > .widget-window-outer-container .widget-inner-container > .markdown-container .editor textarea.code {
	resize: none;
	border: none;
	font-family: "Source Code Pro", monospace;
	background: rgba(13, 13, 13, 0.666);
	outline: none;
	transition: color 0.4s;
	font-size: .9rem;
	max-height: 100%;
	position: absolute;
	display: block;
	margin: 0;
	height: 100%;
	overflow-y: auto;
	width: 50%;
	color: rgb(0, 225, 250);
}


#task2do-app-id .widget > .widget-window-outer-container .widget-inner-container > .markdown-container .editor .markdown-render {
	background: #fff;
	overflow-y: auto;
	position: absolute;
	left: 50%;
	width: 50%;
	height: 100%;
	border-left: .25rem inset rgba(13, 13, 13, .975);
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render h1 {
    color: rgba(13, 13, 13, .975) !important;
    font-size:  3.5rem !important;
    font-family: Gruppo;
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render h2 {
    color: rgba(13, 13, 13, .975) !important;
    font-size:  2.5rem !important;
    font-family: Raleway;
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render h3 {
    color: rgba(13, 13, 13, .975) !important;
    font-size:  1.75rem !important;
    font-family: Tomorrow;
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render p {
    color: rgba(13, 13, 13, .975) !important;
    border: none;
    outline: none;
    font-size: .9rem;
}
/* 
#task2do-app-id .markdown-container > div > .editor > .markdown-render ol,
#task2do-app-id .markdown-container > div > .editor > .markdown-render ul,
#task2do-app-id .markdown-container > div > .editor > .markdown-render li {
  color: rgba(13, 13, 13, 1) !important;
  font-size: .9rem;
} */

.editor .markdown-render ul,
.editor .markdown-render ol {

    margin-left: 0;
    padding-left: 20px;
    color: rgb(13, 13, 13);
}
.editor .markdown-render ul li,
.editor .markdown-render ol li {
    margin-left: 0;
    color: rgb(13, 13, 13);
    list-style: none;
}


#task2do-app-id .markdown-container > div > .editor > .markdown-render table {
    font-size: 75%;
    display: flex;
    z-index: 100000;
    position: relative;
    background: rgba(100, 100, 100, .5);
    color: rgb(13, 13, 13);
    
        
    border: .0875rem solid rgba(26, 26, 26, .66);
    border-collapse: collapse;
    box-sizing: border-box;
}


#task2do-app-id .markdown-container > div > .editor > .markdown-render table {
	font-size: 75%;
	display: inline-table;
	background: rgba(100, 100, 100, .5);
	color: rgb(13, 13, 13);
	border: .0875rem solid rgba(26, 26, 26, .66);
	border-collapse: collapse;
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render table thead tr {
    border-bottom: .0875rem solid rgba(26, 26, 26, .66);
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render table thead tr th {
    padding: .125rem .25rem;
    border-left: .0875rem solid rgba(26, 26, 26, .66);
    border-bottom: .0875rem solid rgba(26, 26, 26, .66);
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render table tbody tr {
    border-left: .0875rem solid rgba(26, 26, 26, .66);
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render table tbody tr td {
    padding: .125rem .5rem;
    border-left: .0875rem solid rgba(26, 26, 26, .66);
    border-bottom: .0875rem solid rgba(26, 26, 26, .66);
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render table tbody tr td:last-child {
    border-right: .0875rem solid rgba(26, 26, 26, .66);
}

#task2do-app-id .markdown-container > div > .editor > .markdown-render table tbody tr:last-child td {
    border-left: .0875rem solid rgba(26, 26, 26, .66);
    border-bottom: none;
}

#task2do-app-id .markdown-render code {
 
	--code-block-nested-level: 0;
	--code-block-nested-offset: 2.5rem;

	display: block;
	display: grid;
	max-width: 95%;
	/*
	 * text-indent: var(--code-block-nested-offsett);
	 */

	margin: .25rem auto 1.25rem;
	width: auto;
	min-width: 15rem;
	padding: .5rem .75rem .5rem .15rem;
	text-indent: 0;
	padding-left: .5rem;
	border-radius: .33rem;
	box-shadow: 8px 10px 16px -2px rgba(13, 13, 13, .33);
		font-family: 'Roboto Mono', monospace;
	font-size: calc(var(--trinixcs-base-font-size) * .75);
	font-weight: 300;

	background: rgba(15, 15, 15, .95) !important;
	color: rgb(235, 245, 255);
	/*	border: .175rem solid rgba(200, 225, 255, .90); */
	border-top: .175rem groove rgba(225, 225, 250, .85);
	border-left: .175rem groove rgba(200, 200, 215, .85);
	border-bottom: .175rem ridge rgba(155, 165, 175, .85);
	border-right: .175rem ridge rgba(175, 175, 175, 85);

}

footer {
  position: relative;
  height: 20px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0 0 5px 5px;
  box-shadow: 0 12px 15px -8px rgba(0, 0, 0, 0.9);
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
footer .fa {
  position: absolute;
  right: 10px;
  top: 3px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}
footer .fa:hover {
  color: rgba(0, 0, 0, 0.8);
}
footer .fa.lookme {
  -webkit-animation: blink 1s linear infinite alternate;
  animation: blink 1s linear infinite alternate;
}

@-webkit-keyframes blink {
  from {
    color: rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 10px 7px rgba(255, 255, 0, 0.2);
  }
  to {
    color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 10px 7px rgba(255, 255, 0, 0.8);
  }
}

@keyframes blink {
  from {
    color: rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 10px 7px rgba(255, 255, 0, 0.2);
  }
  to {
    color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 10px 7px rgba(255, 255, 0, 0.8);
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
</style>
