import Vue from "vue";
import Vuex from "vuex";
// import VueRouter from "../router";
// import VueRouter from "vue-router";

import auth from "./modules/auth";
import appData from "./modules/appData";
import time from "./modules/time";
import tasks from "./modules/tasks";
import notes from "./modules/notes";
import userData from "./modules/userData";
import uiState from "./modules/uiState";
import userSettings from "./modules/userSettings";

import * as jsUtils from "../lib/jsUtils";

Vue.use(Vuex);

        
    // Vue.use(VueRouter);

export default new Vuex.Store({
    namespaced: true,
    state: {
        appStateObj: {
            status: 'init',
            statusCode: 0,
            statusInfo: 'Initializing app...',
            isDataLoaded: false
        },
        status: "init",
        finishedLoadingDataFromAPI: false,
        apiDataRefreshStatus: 'stopped',
        loadDataProgressObj: {
            progress: 0,
            title: 'Loading API Data ',
            message: '',
        }
    },
    getters: {
        getLoadDataProgressObj: state => state.loadDataProgressObj,
        isDataLoadedFromAPI: state => state.appStateObj.isDataLoaded,
        getAppStateObj: state => state.appStateObj,
        getRefreshStatusOfAPI: state =>state.apiDataRefreshStatus

    },
    actions: {
        async initAppState( { dispatch, commit, getters, rootGetters } ) {
            commit('tasks/RESET_SELECTED_TASK_OBJ', null, { root: true } );
            dispatch('userSettings/initUserSettings', null, { root: true } );
            commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: 'Data not loaded from API' }, { root: true } );
            commit('uiState/SET_OK_TO_LOAD_WIDGETS', false, { root: true } );
            commit('FINISHED_LOADING_API_DATA', false, { root: true } );

            commit('UPDATE_APP_STATE', { 'statusCode': 1, 'status': 'loading-initial-data', 'statusInfo': 'Loading initial app data...' }, { root: true } );
            commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: getters.getAppStateObj.statusInfo}, { root: true } );
            let isLocalStorageSet = await dispatch('auth/checkLocalStorage', null, { root: true } );
            
            console.log('OK, we\'ve checked localStorage,  isLocalStorageSet: ' + isLocalStorageSet);
            if (!isLocalStorageSet) {
                console.log('isLocalStorageSet returned false');
                return false;
            } else {
                Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            }

            commit('UPDATE_APP_STATE', { 'statusCode': 50, 'status': 'checking-token-status', 'statusInfo': 'Local data found...checking stored token...' }, { root: true } );
            commit('UPDATE_LOAD_PROGRESS', { progress: 50, message: getters.getAppStateObj.statusInfo}, { root: true } );
            let isTokenValid = await dispatch('auth/checkTokenStatus', null, { root: true } );
            
            if (isTokenValid) {
                commit('UPDATE_APP_STATE', { 'statusCode': 99, 'status': 'checking-token-status', 'statusInfo': 'Token is valid...loading data into API' }, { root: true } );
                commit('UPDATE_LOAD_PROGRESS', { progress: 99, message: getters.getAppStateObj.statusInfo}, { root: true } );
            } else {
                return false;
            }
            
            commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: getters.getAppStateObj.statusInfo}, { root: true } );

            await dispatch('fetchAllFromAPI', null, { root: true } );


            // here we'll check local data first, and also initiall get the most recently edited task event and compare it
            // with the newest task event locally, if they are the same, no need to make expensive remote API call

        },

        // reAuthUser({ commit , rootGetters}) {
        //     this.dispatch('getUserPreferences', null, { root: true});           

        //     commit("reauth_request");
        // },
        async fetchAllFromAPI( { dispatch, commit , getters, rootGetters}) {
           

            console.log("After commiting false to FINISHED_LOADING_API_DATA: " + getters.isDataLoadedFromAPI + "************************************\n");
            
            if ((getters.isDataLoadedFromAPI === false) && (getters.getLoadDataProgressObj.progress === 0)) {
             
                commit('UPDATE_APP_STATE', { 'statusCode': 5, 'status': 'getting-user-preferences', 'statusInfo': 'Getting User Preferences' }, { root: true } );
                commit('UPDATE_LOAD_PROGRESS', { progress: 5, message: 'getting user preferences' }, { root: true } );
                await dispatch('userSettings/fetchUserPreferences', null, { root: true } );

                commit('UPDATE_APP_STATE', { 'statusCode': 15, 'status': 'dispatching-fetch-task-property-types', 'statusInfo': 'Dispatching: fetchTaskPropertyTypes' }, { root: true } );
                commit('UPDATE_LOAD_PROGRESS', { progress: 15, message: 'dispatching: fetchTaskPropertyTypes' }, { root: true } );
                await dispatch('tasks/fetchTaskPropertyTypes', null, { root: true } );

                commit('UPDATE_APP_STATE', { 'statusCode': 40, 'status': 'dispatching-fetch-tasks-2-do', 'statusInfo': 'Dispatching: fetchTasks2Do' }, { root: true } );
                commit('UPDATE_LOAD_PROGRESS', { progress: 40, message: 'dispatching: fetchTasks2Do' }, { root: true } );
                await dispatch('tasks/fetchTasks2Do', null, { root: true } );

                commit('UPDATE_APP_STATE', { 'statusCode': 60, 'status': 'dispatching-tasks-setup-todays-task-list', 'statusInfo': 'Dispatching:  tasks/setupTodaysTaskList' }, { root: true } );
                commit('UPDATE_LOAD_PROGRESS', { progress: 60, message: 'dispatching: tasks/setupTodaysTaskList' }, { root: true } );
                await dispatch('tasks/setupTodaysTaskList', null, { root: true } );

                commit('UPDATE_APP_STATE', { 'statusCode': 65, 'status': 'dispatching-notes-fetch-notes', 'statusInfo': 'Dispatching:   notes/fetchNotes' }, { root: true } );
                commit('UPDATE_LOAD_PROGRESS', { progress: 65, message: 'dispatching: notes/fetchNotes' }, { root: true } );
                await dispatch('notes/fetchNotes', null, { root: true } );

                   
                commit('UPDATE_LOAD_PROGRESS', { progress: 90, message: 'Finished getting notes...preparing data...' }, { root: true } );
                   
                commit('UPDATE_LOAD_PROGRESS', { progress: 95, message: 'initializing audio time array...' }, { root: true } );
                
                
                await dispatch('appData/initAudioTimeArray', null, { root: true } );
                   
                    /************************************************************************
                     *
                     * // TODO: Don't enable next line until you figure out a better way to
                     * // TODO: load audio instead of making 72+ individual https requests.
                     * 
                     ************************************************************************/
 
                // await dispatch("loadAudioIntoBufferArray", null, { root: true } );

                commit('UPDATE_LOAD_PROGRESS', { progress: 98, message: 'setting up intervals...' }, { root: true } );
                await dispatch('time/init', null, { root: true } );
                await dispatch('time/initRealtimeClockInterval', null, { root: true } );
               

                commit('FINISHED_LOADING_API_DATA', true);
                commit('UPDATE_LOAD_PROGRESS', { progress: 100, message: 'setting up intervals...' }, { root: true } );
                commit('UPDATE_APP_STATE', { 'statusCode': 100, 'status': 'all-systems-go', 'statusInfo': 'App is ready 2DO what you need it 2DO!' , 'isDataLoaded': true  }, { root: true } );
            

                commit('uiState/SET_OK_TO_LOAD_WIDGETS', true, { root: true } );
                
                console.log("***********************************\nAfter commiting true to FINISHED_LOADING_API_DATA: " + getters.isDataLoadedFromAPI);
                
                // }).catch(function(err) {
                //     let statusObj =  { statusCode: 500, status: 'failed-to-fetch-all-data-from-api', name: err.name, message: JSON.stringify(err.response, null, 2)};
                //     commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: 'Failed to load API Data' });
                //     dispatch("authError", statusObj);
    
                // });
            }
        },
        async refreshDataFromAPI( { dispatch, commit, getters, rootGetters }) {
            console.log("After commiting false to finishedRefreshApiData: " + rootGetters.getRefreshStatusOfAPI + "************************************\n");
            
            if ((getters.getLoadDataProgressObj.progress === 100) && ((rootGetters.getRefreshStatusOfAPI === 'stopped') || (rootGetters.getRefreshStatusOfAPI === 'finished'))) {
                commit('UPDATE_API_DATA_REFRESH_STATUS', 'started');
                commit('FINISHED_LOADING_API_DATA', false);
                commit('UPDATE_LOAD_PROGRESS', { progress: 1, message: 'dispatching: fetchTasks2Do' }, { root: true } );
                
                await dispatch('tasks/fetchTasks2Do', null, { root: true } ).then(async () => {
                    await commit('UPDATE_LOAD_PROGRESS', { progress: 40, message: 'dispatching: tasks/setupTodaysTaskList' }, { root: true } );
                    return await dispatch('tasks/setupTodaysTaskList', null, { root: true } );
                    // return await setTimeout(async () => (await dispatch("tasks/setupTodaysTaskList", null, { root: true } )), 1500);
                }).then(async () => {
                    await commit('UPDATE_LOAD_PROGRESS', { progress: 45, message: 'dispatching: notes/fetchNotes' }, { root: true } );
                    return await dispatch("notes/fetchNotes", null, { root: true } );
                   
                }).then(async() => {
                    commit('UPDATE_LOAD_PROGRESS', { progress: 90, message: 'Finished getting notes...preparing data...' }, { root: true } );
                 
                    commit('UPDATE_LOAD_PROGRESS', { progress: 95, message: 'Finished loading data from API' }, { root: true } );
                    commit('UPDATE_API_DATA_REFRESH_STATUS', 'finished', { root: true } );
                    commit('UPDATE_LOAD_PROGRESS', { progress: 100, message: 'Finished loading data from API' }, { root: true } );
                    
                    commit('auth/INIT_LOGIN_PROCESS_FINISHED', null, { root: true } );
                    commit('FINISHED_LOADING_API_DATA', true);
                    
                   
                    console.log("***********************************\nAfter commiting true to finishedRefreshApiData: " + getters.isDataLoadedFromAPI);
                    return true;
    
                }).catch(function(err) {
                    let statusObj =  { statusCode: 500, status: 'failed-to-fetch-all-data-from-api', name: err.name, message: JSON.stringify(err.response, null, 2)};
                    commit('UPDATE_LOAD_PROGRESS', { progress: 0, message: 'Failed to load API Data' }, { root: true } );
                    dispatch("auth/authError", statusObj, { root: true } );
    
                });
            }
        },
        loadDataFromAPI( { commit }) {

        },
        // getUserPreferences({ commit, rootGetters }) {
        //     return new Promise((resolve, reject) => {
        //         console.log("State of token: " + rootGetters.getters.currentJwt);
        //         axios.defaults.headers.common["Authorization"] = "Bearer " + rootGetters.currentJwt;

        //         axios({
                   
        //             url: 'https://ts-api.t2do.io/t2do-api/user/preferences/' + this.$store.getters.currentUserId,
        //             method: "GET"
        //             })
        //             .then(response => {
        //                 console.log("user preferences Response: " + response.data);
        //                 const typecastedResponse = typecastTasks2DoResponse(response.data.user_data);
        
        //                 commit("setUserPreferences", typecastedResponse);
        
        //                 resolve(response);
                       
        //             })
        //             .catch(err => {
        //                 commit("auth_error", err.response);
        //                 // localStorage.removeItem("jwt");
        //                 reject(err);
        //             });
        //         });     
        // },
    },
    mutations: {
        UPDATE_APP_STATE(state, { status = '', statusCode = 0, statusInfo = '', isDataLoaded = false } ) {
            // state.appStateObj = this._vm.$arrayObjUtils.arrayObjsDeepCopy(appStateObjParam);
            state.appStateObj.status = status;
            state.appStateObj.statusCode = statusCode;
            state.appStateObj.statusInfo = statusInfo;
            state.appStateObj.isDataLoaded = isDataLoaded;

            
        },
        FINISHED_LOADING_API_DATA: (state, isDataLoaded) => state.finishedLoadingDataFromAPI = isDataLoaded,
        UPDATE_API_DATA_REFRESH_STATUS: (state, refreshStatus) => state.apiDataRefreshStatus = refreshStatus,
        UPDATE_LOAD_PROGRESS(state, { progress, message }) { 
            state.status = 'loading-data';
            state.loadDataProgressObj.progress = progress;
            state.loadDataProgressObj.message = message;
         }  
    },
    modules: {
        auth,
        appData,
        time,
        tasks,
        notes,
        userData,
        uiState,
        userSettings
    }
});

    // https://ts-api.t2do.io/users/register

    // ts-api.t2do.io/t2do-api/tasks/query/all