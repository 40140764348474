<template>
 <v-container class="widget" :class="[ minimizeState ? minimizeClassName : '',
        maximizeState ? maximizeClassName : ''  ]" >

        <div style="position: absolute; display: block;  background: unset; opacity: 0; left: 0; top:0; width: 100%; height: 100%; z-index: 1500;"
        v-if="focusedWidget !== 'WeatherWidget'" @click.stop="$emit('bring-to-front', 'WeatherWidget')"></div>

        <v-card class="widget-window-outer-container">
            
        <WindowBar :widgetLabel="widgetLabel"
                    :minimizeState="minimizeState"
                    v-on:minimize="minimize"
                    :maximizeState="maximizeState"
                    v-on:maximize="maximize"
                    :notifications="apiWeatherAlertData['features']"
                    :notificationType="alertNotificationString" />

        <!-- <div style="position: absolute; display: block; left: 0; top:0; width: 100%; height: 100%; z-index: 1500;"
        v-if="focusedWidget !== 'WeatherWidget'" @click.stop="$emit('bring-to-front', 'WeatherWidget')"></div> -->
            <v-container class="widget-inner-container" fluid style="max-height: calc(100% - 3rem); height: 100%; overflow-y: auto; margin: 1.0rem auto 1rem; padding: .125rem .25rem;">
                <v-row dense>
                    <v-col cols="12">
                        <div class="weather-radar-container">
                            <!--    <img
                            src="https://radar.weather.gov/lite/N0Z/BUF_loop.gif" -->
                            <img src="https://radar.weather.gov/ridge/lite/KBUF_loop.gif"
                            class="weather-radar-image" @click="radarZoomed = !radarZoomed" :class="[ radarZoomed ? 'zoomed' : '' ]" />
                        </div>
                        <v-card-text style="width: calc(99% - 15rem);margin-left: 15rem;text-align: justify;" :apiWeatherAlerts="apiWeatherAlerts" v-bind:key="alert.id" v-for="alert in apiWeatherAlerts">
                            <h4><span class="weather-alert"> {{ alert.headline }} </span></h4>
                            <div>{{ alert.description }}</div>
                        </v-card-text>
                        <v-card-text>
                            <div class="tm-weather_container">
                                <h3>Daily Forecast</h3>
                                <div id="dailyForecastTableContainerId" class="tm-forecast_container ">
                                    <p>Loading weather data from api.weather.gov...</p>
                                <!--  <div v-html="displayData(periodsArrayDaily, 'dailyForecastTableContainerId')"></div> -->
                                            
                                </div>
                            </div>
                        </v-card-text>
                        <!-- <v-card-text>
                            <div class="tm-weather_container">
                                <h3>12 Hour Forecast</h3>
                                    <div id="hourlyForecastTableContainerId" class="tm-forecast_container ">     
                                        <p>Loading weather data from api.weather.gov...</p>
                                        <div v-html="displayData(periodsArrayHourly, 'hourlyForecastTableContainerId')"></div>
                                </div>-
                            </div>
                        </v-card-text> -->
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import WindowBar from "@/components/widgets/components/WindowBar.vue";

export default {
    name: "WeatherWidget",
    components: {
        WindowBar
    },
    props: ["focusedWidget"],
    data: () => ({
        widgetLabel: "Weather",
        componentName: "WeatherWidget",
        minimizeInBackground: true,
        minimizeInForeground: false,
        minimizeClassName: "minimize-widget",
        maximizeClassName: "maximize-widget",
        maximizeState: false,
        radarZoomed: false,
        alertNotificationString: 'Alert',
        apiWeatherAlertData: {},
        apiWeather: [],
        apiWeatherAlerts: [],
        forecastURL: "https://api.weather.gov/gridpoints/BUF/21%2C22/forecast",
        forecastData: {},
        periodsArrayDaily: [],
        periodsArrayHourly: []
    }),
    computed: {
        minimizeState() {
            // basically apply foreground state when focused, otherwise apply background state
            return (this.focusedWidget === this.componentName) ? this.minimizeInForeground : this.minimizeInBackground; 
        }
    },
    mounted() {
        this.getWeatherAlerts();
        this.getDailyWeatherData();

    },
    created() {
    },
    updated: function() {
        if (this.focusedWidget !== this.componentName) {
            // This resets the foreground state to ensure widget isn't minimized when regaining focus
            this.minimizeInForeground = false;
        }
    },
// computed: {
   
// },
methods: {
    minimize() {
        this.minimizeInForeground = !this.minimizeInForeground;
        this.minimizeInBackground = this.minimizeInForeground;

        if (this.maximizeState === true) {
            this.maximizeState = false;
        }
    },
    maximize() {
        if (this.maximizeState === false) {
            this.maximizeState = true;
            this.minimizeInForeground = false;
            this.minimizeInBackground = this.minimizeInForeground;
        } else {
            this.maximizeState = false;
        }
    },
    toggleWeatherFocus() {
        this.notesIsMinimized = true; // !this.notesIsMinimized;
        this.calendarIsMinimized = true; //!this.calendarIsMinimized;
        this.todosIsMinimized = true;
        this.blogsIsMinimized = true;
        this.weatherIsMinimized = false;

        // if(this.calendarIsMinimized) {
        //   this.calendarIsMinimized = false;
        // } else {
        //   this.calendarIsMinimized = true;
            // }
    },
    async getWeatherAlerts() {
        let response = await fetch(
            `https://api.weather.gov/alerts/active/zone/NYZ019`
        );
        this.apiWeatherAlertData = await response.json();
        this.apiWeatherAlerts = [];
        window.console.log(
            "Data from weather API: " + JSON.stringify(this.apiWeatherAlertData)
        );
        if (this.apiWeatherAlertData["features"].length > 0) {
            for (let i = 0; i < this.apiWeatherAlertData["features"].length; i++) {
            this.apiWeatherAlerts[i] = [];
            this.apiWeatherAlerts[i]["id"] = i;

            this.apiWeatherAlerts[i]["headline"] =
                this.apiWeatherAlertData["features"][i]["properties"]["headline"];
            this.apiWeatherAlerts[i]["description"] =
                this.apiWeatherAlertData["features"][i]["properties"]["description"];
            }
        }
    },
    async getDailyWeatherData() {
        // TODO: This function fails if there is a 500 server error, fix
        //////////////////////////////////////////////////////////////////
        let response = await fetch(this.forecastURL);
        this.periodsArrayDaily = [];
        let forecastResponseData = await response.json();
        for( let i = 0; i < forecastResponseData["properties"]["periods"].length; i++) {
            this.periodsArrayDaily[i] = {};
            for (let key in forecastResponseData["properties"]["periods"][i]) {
                this.periodsArrayDaily[i][key] = forecastResponseData["properties"]["periods"][i][key];
            }
        }
        document.getElementById("dailyForecastTableContainerId").innerHTML = this.displayData(this.periodsArrayDaily, 'dailyForecastTableContainerId');

    },
    async getHourlyWeatherData() {
        let response = await fetch(this.forecastURL+"/hourly");
        let forecastResponseData = await response.json();
        for( let i = 0; i < forecastResponseData["properties"]["periods"].length; i++) {
            this.periodsArrayHourly[i] = {};
            for (let key in forecastResponseData["properties"]["periods"][i]) {
                this.periodsArrayHourly[i][key] = forecastResponseData["properties"]["periods"][i][key];
            }
        }

    },
     displayData(periods, forecastContainerId, numPeriods = 0) { 
        let periodLength = numPeriods === 0 ? periods.length: numPeriods;
        let htmlString = "<table id=\"" +  forecastContainerId + "\" class=\"forecast-table\">"; 
        // <thead><tr><th>Name</th><th style=\"width:90% !important; margin: 0 auto;\">Forcast Details</th></tr></thead><tbody>";
        for (let i=0; i < periodLength; i++ ) {
            //fill the columns of the table
            htmlString += `<tr style="background: transparent url(${periods[i]["icon"]}) no-repeat center; background-size: 210% 125%; background-position-x: 0%; background-position-y: 0%; height: 100%;"><td style="background-size: 50% 100%; background-position-x: 50%; width: 13rem;"><h3>`;

            // htmlString += `<tr style="background: transparent url(${periods[i]["icon"]}); background-size: 210% 150%; background-position-x: 0%; background-position-y: 0%; height: 100%;"><h3>`;

            //<img style="position:absolute; width: 100$; height;100%;" src="${periods[i]["icon"]}">`;
            // 1st column: Period name / time, ontop of temp, which is ontop of wind speed
        
            if (periods[i]["name"]) {
                htmlString += periods[i]["name"];
            } else {
                let forcastedHour = dated.getCurrHour() + parseInt(periods[i]["number"]);
                htmlString += dated.toTwelveHour(forcastedHour);
            }
           
            htmlString += " " + periods[i]["temperature"] + "&deg;</h3><h4> " + periods[i]["windSpeed"] + `</h4></td><td style="width: calc(100% - 13rem) !important;">`;
            
            if (periods[i]["detailedForecast"] === "") {
                htmlString += "<p>" + periods[i]["shortForecast"] + "</p></td></tr>";
            } else  {
                htmlString += "<p>" + periods[i]["detailedForecast"] + "</p></td></tr>";
            }
            
        }
        htmlString += "</tbody></table>";
        return htmlString;

    }
    

}
};
</script>

<style>
.is-complete {
  text-decoration: line-through;
}

.weather-alert {
  color: rgba(225, 225, 225, 1);
  background: rgba(150, 0, 0, 0.9);
}

.weather-radar-container {
  width: 100%;
  display: inline-block;
  height: auto;
  margin: auto;
  text-align: center;
}

.weather-radar-image {
    display: block;
    position: absolute;
    margin: .5rem 1rem;
    width: 14.5rem;
    transform: scale(1);
    height: 14.5rem;
    transition: all .5s ease-in-out;
}

.weather-radar-image.zoomed {
    width: 100%;
    height: 100%;
    transform: scale(1.25);
}

#task2do-app-id  table#dailyForecastTableContainerId.forecast-table {
    width: calc(95% - 15rem);
    margin-left: 17.5rem;
    border-collapse: collapse;
    color: rgba(13, 13, 13, 1);
    border: 1px solid rgba(13, 13, 13, .4);
}



#task2do-app-id table#dailyForecastTableContainerId.forecast-table tr {
	border: 1px solid rgba(13, 13, 13, .4);
	
}

#task2do-app-id table#dailyForecastTableContainerId.forecast-table tr td {
	border-left: 1px solid rgba(13, 13, 13, .4);
	padding-left:1rem;
	background: rgba(13, 13, 13, .05);
}
#task2do-app-id table#dailyForecastTableContainerId.forecast-table tr:nth-child(even) {
    background: rgba(13, 13, 13, .1);

}


#task2do-app-id #dailyForecastTableContainerId {
    display: grid;
    padding: 0.25rem;
    
}

#task2do-app-id #dailyForecastTableContainerId tbody {
    border-collapse: collapse;
}


#task2do-app-id #dailyForecastTableContainerId tr {
    min-height: 8.5rem;
    height: 100%;
    max-height: 100%;
    display: flex;
    border-bottom: .125rem solid rgba(0, 175, 245, .75);
}

#task2do-app-id #dailyForecastTableContainerId tr th,
#task2do-app-id #dailyForecastTableContainerId tr td {
    /* padding: 0 .125rem 0.75rem; */
    /* margin: .25rem; */
    /* max-height: 3rem; */

    padding: .25rem;
    display: grid;
    align-content: center;
    justify-content: normal;
}


  
/* 
#task2do-app-id #dailyForecastTableContainerId tr td:first-child {
    width: 24rem !important;
}

#task2do-app-id #dailyForecastTableContainerId tr td:last-child {
    width: 66% !important;
} */



/* 
#task2do-app-id #dailyForecastTableContainerId tr th:first-child,
#task2do-app-id #dailyForecastTableContainerId tr td:first-child {
    min-width: 10rem;
    width: 25%;
    padding-left: 9%;
} */

#task2do-app-id #dailyForecastTableContainerId tr td:last-child
{
   
    height: 100%;
    background-position-y: calc(100% / 14);
    width: 100%;
    background-size: 100%;
}


#task2do-app-id #dailyForecastTableContainerId tr td h3 {
    padding: 0.25rem;
    background: rgba(13, 13, 13, .275);
    color: rgb(220, 220, 225);
    font-size: 1.2rem;
    text-shadow: 0.0875rem 0.0875rem 0.125rem rgba(13, 13, 13, .975);
    /* margin-top: -50%; */
    /* margin: .25rem; */
    display: block;
}

#task2do-app-id #dailyForecastTableContainerId tr td h4{
    padding: 0.25rem;
    background: rgba(13, 13, 13, .275);
    color: rgb(220, 220, 225);
    font-size: 1.1rem;
    text-shadow: .0875rem .0875rem .125rem rgba(13, 13, 13, .975);
    
    /* margin: .25rem; */
}

#task2do-app-id #dailyForecastTableContainerId tr td p {
    padding: .0875rem .25rem;
    text-shadow: 0.0875rem 0.0875rem 0.125rem rgba(13, 13, 13, .975);
    font-weight: 500;
    font-family: Tomorrow;
    height: 150%;
    margin: auto;
    letter-spacing: .0875rem;
    font-size: 115%;
    color: rgb(245, 250, 255);
    text-align: left;
    background: rgba(13, 13, 13, 0.85);
}

@media (max-width: 900px) { 
    #task2do-app-id #dailyForecastTableContainerId tr td p {
        font-size: 90%;
    }
	
}
</style>
