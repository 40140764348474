<template>
    <v-toolbar class="widget-edit-toolbar"  height="var(--widget-toolbar-height)">
        <!-- <div class="widget-toolbar">
        <h3 class="ml-4">{{ widgetLabel }}</h3>
        </div> -->
        <v-toolbar-items class="inline-toolbar-icons" style="margin: 0;" height="unset">
            <!-- <v-spacer></v-spacer> -->
        
            <span v-for="editToolbarItem in editToolbarItems" v-bind:key="editToolbarItem.id"> 
                <v-tooltip v-if="editToolbarItem.visible && (editToolbarItem.type === 'button')" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="!editToolbarItem.enabled" icon dark outlined class="mr-1 edit-toolbar-icon" v-on="on" @click="$emit(editToolbarItem.functionToCall)">
                            <v-html v-if="editToolbarItem['iconType'] === 'htmlEnt'">{{ editToolbarItem["icon"] }}</v-html>
                            <v-icon v-else>{{ editToolbarItem["icon"] }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ editToolbarItem["tooltip"] }}</span>
                </v-tooltip>
                <v-tooltip v-if="editToolbarItem.visible && (editToolbarItem.type === 'buttonToggle')" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="!editToolbarItem.enabled" icon dark outlined class="mr-1 edit-toolbar-icon" v-on="on" @click="$emit(editToolbarItem.functionToCall)">
                            <v-icon :style="editToolbarItem['states'][editToolbarItem['curStateIndex']]['iconStyle']">{{ editToolbarItem["states"][editToolbarItem["curStateIndex"]]["icon"] }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ editToolbarItem["tooltip"] }}</span>
                </v-tooltip>
                <!-- Drop Down Select toolbar menu -->
                <v-tooltip v-if="editToolbarItem.visible && (editToolbarItem.type === 'dropDownSelect')" bottom>
                    <template  v-slot:activator="{ on }">   
                        <v-menu v-on="on" v-if="editToolbarItem.visible && (editToolbarItem.type === 'dropDownSelect')" mr-2 bottom right>
                            <template  v-slot:activator="{ on }">     
                            <v-btn outlined v-on="on">
                                <span>{{ dropDownSelection }}</span>
                                <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                            </template>
                                <v-list>
                                    <v-list-item v-for="selectionOption in editToolbarItem.selectionOptions" v-bind:key="selectionOption.id" @click="$emit(selectionOption.functionToCall, selectionOption.functionArgument)">
                                        <v-list-item-title>{{ selectionOption.label }}</v-list-item-title>
                                    </v-list-item>

                                    <!-- <v-list-item @click="type = 'day'">
                                        <v-list-item-title>Day</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = 'week'">
                                        <v-list-item-title>Week</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = 'month'">
                                        <v-list-item-title>Month</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="type = 'custom-daily'">
                                        <v-list-item-title>5 days</v-list-item-title>
                                    </v-list-item> -->
                                </v-list>
                        
                        </v-menu>
                    </template>
                    <span>{{ editToolbarItem["tooltip"] }}</span>
                </v-tooltip>

                <v-tooltip v-else-if="editToolbarItem.visible && (editToolbarItem.type === 'switch')" bottom>
                    <template v-slot:activator="{ on }">
                        <v-switch  v-on="on" :disabled="!editToolbarItem.enabled" :v-model="editToolbarItem.curStateIndex"  @click="$emit(editToolbarItem.functionToCall)" :label="editToolbarItem['states'][editToolbarItem['curStateIndex']]['label']" ></v-switch>
                    </template>
                    <span>{{ editToolbarItem["states"][editToolbarItem["curStateIndex"]]["tooltip"] }}</span>
                </v-tooltip>
                <v-tooltip :id="editToolbarItem.name" :class="toolbarAlertContainerClass" v-if="editToolbarItem.visible && (editToolbarItem.type === 'alert')" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="!editToolbarItem.enabled" icon dark outlined class="mr-2 edit-toolbar-icon" style="overflow: hidden;" v-on="on" @click="$emit(editToolbarItem.functionToCall)">
                            <v-alert dense :type="editToolbarItem.icon">
                                <v-icon>mdi-{{ editToolbarItem.icon }}</v-icon>
                            </v-alert>
                        </v-btn>
                    </template>
                    <span class="tooltip-alert" v-html="editToolbarItem['tooltip']"></span>
                </v-tooltip>
                <v-tooltip :id="editToolbarItem.name" :class="toolbarAlertContainerClass" v-if="editToolbarItem.visible && (editToolbarItem.type === 'infoDisplay')" bottom>
                    <template v-slot:activator="{ on }">
                        <span class="info-display-bar--container">
                            <v-btn :disabled="!editToolbarItem.enabled" icon dark outlined class="mr-1 edit-toolbar-icon" style="overflow: hidden;position: absolute;z-index: 10000; width: 100%; background: transparent; border: none;" v-on="on" @click="$emit(editToolbarItem.functionToCall)">   </v-btn>
                            <div id="status-info" class="info-display-bar">
                                <span v-if="editToolbarItem.outputFormat === 'oneByOne'" class="info-display-bar--data single-line-output">
                                    <p>{{ getinfoDisplayBarData }} </p>
                                </span>
                                <span v-else-if="editToolbarItem.outputFormat === 'twoByTwo'" class="info-display-bar--data dual-line-output">
                                    <span><span>{{ editToolbarItem.dataVariables[0].name }} = </span><span class="data-value-field"> {{ (editToolbarItem.dataVariables[0].value !== '' ) ? editToolbarItem.dataVariables[0].value : 'not set'  }} </span> |<span> {{ editToolbarItem.dataVariables[1].name }} = </span> <span class="data-value-field"> {{ editToolbarItem.dataVariables[1].value  }} </span> </span>
                                    <span><span>{{ editToolbarItem.dataVariables[2].name }} = </span> <span class="data-value-field"> {{ editToolbarItem.dataVariables[2].value  }} </span> | <span>{{ editToolbarItem.dataVariables[3].name }} = </span><span class="data-value-field"> {{ editToolbarItem.dataVariables[3].value  }} </span> </span>
                                </span>
                                <span v-else-if="editToolbarItem.outputFormat === 'threeByTwo'" class="info-display-bar--data dual-line-output">
                                    <span><span>{{ editToolbarItem.dataVariables[0].name }} = </span><span class="data-value-field"> {{ (editToolbarItem.dataVariables[0].value !== '' ) ? editToolbarItem.dataVariables[0].value : 'not set'  }} </span> | <span> {{ editToolbarItem.dataVariables[1].name }} = </span> <span class="data-value-field"> {{ editToolbarItem.dataVariables[1].value  }} </span> | <span> {{ editToolbarItem.dataVariables[2].name }} = </span> <span class="data-value-field"> {{ editToolbarItem.dataVariables[2].value  }} </span> </span>
                                    <span><span>{{ editToolbarItem.dataVariables[3].name }} = </span> <span class="data-value-field"> {{ editToolbarItem.dataVariables[3].value  }} </span> | <span>{{ editToolbarItem.dataVariables[4].name }} = </span><span class="data-value-field"> {{ editToolbarItem.dataVariables[4].value  }} </span> | <span> {{ editToolbarItem.dataVariables[5].name }} = </span> <span class="data-value-field"> {{ editToolbarItem.dataVariables[5].value  }} </span></span>
                                </span>
                            </div>
                        </span>
                    </template>
                    <span class="tooltip-alert" v-html="editToolbarItem['tooltip']"></span>
                </v-tooltip>
                <v-tooltip v-if="editToolbarItem.visible && (editToolbarItem.type === 'spacer')" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon dark outlined style="opacity: 0;">
                            <v-icon>mdi-blank</v-icon>
                        </v-btn>
                    </template>
                    <span></span>
                </v-tooltip>
                    
            </span>

            <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="editCategories()">
                        <v-icon>mdi-file-tree</v-icon>
                    </v-btn>
                </template>
                <span>{{ toolbarButtons["editCategories"]["description"] }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="revertEdits()">
                        <v-icon>mdi-undo</v-icon>
                    </v-btn>
                </template>
                <span>{{ toolbarButtons["revertEdits"]["description"] }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="saveEdit()">
                        <v-icon>mdi-floppy</v-icon>
                    </v-btn>
                </template>
                <span>{{ toolbarButtons["saveEdit"]["description"] }}</span>
            </v-tooltip> -->

            <!-- 
                    <button id="noteModalButtonPrevEdit" href="#0">Edit</button>
                            <button id="noteModalButtonDismiss" href="#0">Dismiss</button> -->
        
            <!-- <v-btn icon dark outlined class="mr-2 toolbar-icon TM__blue-glowing-outline" @click="toggleEditMode()">
                Edit Mode
                <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
            <v-spacer></v-spacer>

            <!-- <span id="noteEditToolbar-tagMode-status-id" data-toolbar-status="ok" data-toolbar-status-msg="null" class="TCS toolbar-widget-status-indicator"></span>
            <div id="noteEditToolbar-titlebar">
                Tag Inserter Toolbar
                <button id="noteEditToolbar-CloseButton">X</button>
            </div>
            <div class="noteEditToolbar-buttons">
                <button class="noteEditToolbar-InsertHTML" data-button-function="template-insert" value="insert-note">Note Template</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="template-insert" value="insert-todo">Todo Template</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="template-insert" value="insert-blog">Blog Template</button>
                <br class="">
                
                <button class="noteEditToolbar-InsertHTML" data-button-function="tag-mode-toggle" value="off">Tag-Mode</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="h3" value="<h3>">&lt;h3&gt;</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="h4" value="<h4>">&lt;h4&gt;</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="ol-li" value="<ol>">&lt;ol&gt;</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="ul-li" value="<ul>">&lt;ul&gt;</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="p" value="<p>">&lt;p&gt;</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="br" value="<br><br>">&lt;br&gt;</button>
                <button class="noteEditToolbar-InsertHTML" data-button-function="CLI" value="<span class='cli-inline'>">&lt;span class="cli-inline"&gt;</button>
            </div> -->
                        
        </v-toolbar-items>
    </v-toolbar>
</template>

<script>
export default {
    name: "EditToolbar",
    props: ["editModeProp",
    "editToolbarItems",
    "dropDownSelection",
    "infoDisplayBarData"],
    data:() => ({
        editModeSwitch: 'off',
        toolbarAlertContainerClass: 'toolbar-alert-container',
        toolbarButtons: {
            createNewNote: {
                name: "Create New Note",
                description: "Create and Edit a New Note ",
                functionToCall: "createNewNote"
            },
            createAppointment: {
                name: "Create New Appointment",
                description: "Create a New Appointment in the calendar",
                functionToCall: "createAppointment"
            },
            newList: {
                name: "New List",
                description: "Create a new list",
                functionToCall: "newList"
            },
            newTalliedList: {
                name: "New Tallied List",
                description: "Create a new tallied list, e.g, a shopping list",
                functionToCall: "newTalliedList"
            },
            revertEdits: {
                name: "Revert Edits",
                description: "Restore Data to Time of Last Save",
                functionToCall: "revertEdits"
            },
            editCategories: {
                name: "Edit Categories",
                description: "Edit your primary categories",
                functionToCall: "editCategories"
            },
            saveEdit: {
                name: "Save Edits",
                description: "Save changes made to the current selected item",
                functionToCall: "saveEdit"
            },
            toggleFullscreenEdit: {
                name: "Toggle Fullscreen Edit Mode",
                description: "Toggle fullscreen edit mode",
                functionToCall: "toggleFullscreen"
            },

        }
    }),
    computed: {
        curEditMode() {
            return (this.editModeProp === 'on') ? 'Edit Mode' : 'Preview Mode';
        },
        isEditModeOn() {
            return (this.editModeProp === 'on') ? true : false;
        },
        getEditMode() {
            return this.editModeProp;
        },
        getinfoDisplayBarData() {
            let infoDisplayBarDataString = '';
            let dataValues = [];
            this.infoDisplayBarData.forEach(element => dataValues.push(element));

            this.infoDisplayBarData.forEach(function(item) {
                infoDisplayBarDataString += 'Variable [' + item.name + '] = ' + item.value + '  \n';
                
            });
            
    console.log("INFO DISPLAY BAR STRING DATA: " + infoDisplayBarDataString + "\n");

            return infoDisplayBarDataString;
        }
    },
    methods: {
        toggleEditModeSwitch() {
            this.$emit("toggleEditModeEvent");
        },
        createNewNote() {
            this.$emit("newNoteEvent");
        },
        newTask() {
            this.$emit("newTask");
        },
        newList() {
            this.$emit("newList");
        },
        newTalliedList() {
            this.$emit("newTalliedList");
        },
        newAppt() {
            this.$emit("newAppt");
        },
        editCategories() {
            this.$emit("editCategories");
        },
        saveEdit() {
            this.$emit("saveEdit");
        },
        toggleFullscreen() {
            console.log("toggleFullscreenEdit called");
            this.$emit("toggleFullscreenEvent");
        }
    }
    };
    </script>

<style>


:root {
    --widget-header--height: 3.35rem;
}

#task2do-app-id .widget-edit-toolbar .inline-button-span {
  right: 10%;
  position: absolute;
}

#task2do-appid .widget-edit-toolbar {
    height: 3rem;
    display: block;
    /* position: absolute; */
}

.widget-edit-toolbar .v-toolbar__content {
    height: 3rem;
}

header.widget-edit-toolbar .inline-toolbar-icons button.mr-2.toolbar-icon.TM__blue-glowing-outline.v-btn.v-btn--depressed.v-btn--flat.v-btn--icon.v-btn--outlined.v-btn--round.theme--dark.v-size--default {
    color: rgb(13, 13, 100);
}


#task2do-app-id .edit-toolbar-icon .v-icon.mdi {
    color: rgb(13, 13, 100);
    text-shadow: 0.0875rem 0.0875rem 0.0875rem rgb(6, 6, 6);
    font-size: 200%;
    padding: 0 .125rem .125rem;
    font-weight: 100;
    text-shadow: 0.0875rem 0.0875rem 0.05rem rgb(6, 6, 6);
    text-shadow: none;
    text-shadow: 0.115rem 0.115rem 0.05rem rgba(6, 6, 6, .35);
}

#task2do-app-id .edit-toolbar-icon .v-icon.mdi.mdi-lock-open, 
#task2do-app-id .edit-toolbar-icon .v-icon.mdi.mdi-lock {
    font-size: 150%;
}

#task2do-app-id .inline-toolbar-icons button.edit-toolbar-icon.v-btn.v-btn--outlined {
    width: calc(100% - .35rem);
    height: calc(100% + 0.5rem);
    border: .125rem outset rgba(225, 225, 235, .75);
    border-radius: .25rem;
    border-block-start-color: rgba(200, 200, 200, .85);
    border-inline-start-color: rgba(200, 200, 200, .85);
    border-block-end-color: rgba(100, 100, 100, .66);
    border-inline-end-color: rgba(100, 100, 100, .5);
}

#task2do-app-id .edit-toolbar-icon .v-icon.notranslate.mdi.mdi-content-save {
    color: rgba(225, 0, 0, 0.85);
    background: rgba(225, 215, 220, 0.65);
}


.widget-edit-toolbar .toolbar-icon button.mr-2.toolbar-icon.v-btn.v-btn--depressed.v-btn--flat.v-btn--icon.v-btn--outlined {
    color:  rgb(13, 13, 75);
}

#task2do-app-id .v-toolbar__items.inline-toolbar-icons {
    height: 2.5rem;
    margin: 0.35rem .25rem 0.15rem;
    padding: 0.125rem;
    background: rgba(130, 130, 150, .95);
    color: rgb(13, 13, 100);
}

#task2do-app-id .v-toolbar__items.inline-toolbar-icons span {
  margin: 0 .35rem;
  height: calc(100% - .25rem);
}

header.widget-edit-toolbar.v-sheet.v-sheet--tile.theme--dark.v-toolbar {
    height:  3.5rem;
    margin: 1.2rem .25rem .125rem;
}


#task2do-app-id .widget header.widget-edit-toolbar.v-toolbar {
	height: var(--widget-header--height);
    margin: 0;
	padding: 0 .5rem .125rem;
	background: rgba(130, 130, 150, .95);
    color: rgb(13, 13, 100);

}

#task2do-app-id i.v-icon.notranslate.v-alert__icon.mdi.mdi-exclamation.theme--dark {
    margin-top: 0.4rem;
    margin-left: .5rem;
    display: inline-block;
    width: 1.45rem;
    height: 1.45rem;
    font-size: 2.0rem;
    line-height: 0;
    overflow: visible;
}

#task2do-app-id i.v-icon.notranslate.v-alert__icon.mdi.mdi-exclamation.theme--dark {
    font-size: 2.0rem;
    line-height: 0.75;
    margin-top: -0.65rem;
}

#task2do-app-id button.v-btn .v-alert:hover {
    text-shadow: .125rem .125rem .0975rem rgba(13, 13, 13, .9);
    background-color: rgba(255, 75, 1, 0.95);
}


#task2do-app-id .v-tooltip .v-tooltip__content{
    background: rgba(37, 37, 37, 0.9);
    color: #FFFFFF;
    border-radius: 4px;
    font-size: .95rem;
    margin: .05rem auto;
    line-height: 2.3;
    display: inline-block;
    padding: .125rem .5rem;
    position: absolute;
    text-transform: initial;
    width: auto;
    height: 2.75rem;
    opacity: 1;
    pointer-events: none;
}

#task2do-app-id .tooltip-alert > span.mdi.mdi-alert-outline {
    position: relative;
    font-size: 2.0rem;
    color: rgb(225, 75, 0);
    font-weight: 100;
    top: 0.35rem;
    padding: .125rem .25rem;
    line-height: 0.6;
    margin: 0 auto;
}

#task2do-app-id .v-tooltip .v-tooltip__content.v-tooltip__content--fixed {
    border: inset .15rem rgba(250, 25, 50, .985) !important;
    background-color: rgba(13, 13, 13, .95);
}

/* 
    Info Display Bar
*/

#task2do-app-id .info-display-bar--container {
	position: absolute;
	margin: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 2.95rem;
	/* z-index: 12345; */
	width: 50rem;
	/* width: 100%; */
	/* right: -0.5rem; */
	/* left: 40%; */
	overflow: hidden;
	white-space: pre-line;
	padding: 0.075rem;
	margin-top: -.3rem;
	color: rgba(13, 16, 26, 0.95);
	background: rgba(75, 85, 95, .9875);
	border: .15rem inset rgba(56, 66, 66, .85);
	border-radius: 0.115rem;
	/* margin-left: 3rem; */
    right: .1rem;
    transform: translateX(5%) scale(0.9);
}

#task2do-app-id .info-display-bar {
	position: relative;
	z-index: 1234;
	display: inline-block;
	/* right: 1.5%; */
	width: 100%;
	height: 2.75rem;
	/* padding: .05rem .15rem .05rem; */
	/* margin: -.125rem .25rem .5rem; */
	margin: 0;
	color: rgba(13, 16, 26, 0.95);
    background: rgba(85, 95, 215, .9875);
    background: rgba(50, 125, 250, .9875);
	/* border: .215rem inset rgba(56, 66, 66, .85); */
	border: none;
	overflow: hidden;
	white-space: nowrap;
}

#task2do-app-id .info-display-bar > span.info-display-bar--data {
	position: absolute;
	display: block;
	font-family: 'Roboto', monospace;
	/* font-family: 'VT323', monospace; */
	/* font-family: 'Roboto', sans-serif; */
	/* font-family: 'Oxygen Mono', monospace; */
	/* font-family: 'Anonymous Pro', monospace; */
	/* line-height: unset; */
	font-variant: small-caps;
	font-size: 1.25rem;
	font-weight: 300;
	letter-spacing: 0.055rem;
	text-shadow: 0.025rem 0.025rem 0.05rem rgba(6, 6, 6, 0.5);
}

/* 
#task2do-app-id .info-display-bar > span.info-display-bar--data > span {
	position: relative;
	display: grid;
	width: 100%;
	height: 1.25rem;
	padding: 0;
	margin: -0.05rem .05rem .05rem;
	padding: .01rem .25rem;
	text-align: left;
	letter-spacing: .055rem;
	white-space: nowrap;
	overflow: hidden;
    text-shadow: 0.1rem 0.1rem 0.2rem rgba(6, 6, 6, 0.5); 
    grid-template-columns: minmax(8rem, 10rem) minmax(5rem, 8rem) minmax(.5rem, 2rem) minmax(9rem, 11rem) minmax(3rem, 4rem);
	grid-template-columns: minmax(7rem, 10rem) minmax(4rem, 7rem) minmax(.5rem, .75rem) minmax(7rem, 9rem) minmax(3rem, 5rem) minmax(.5rem, .75rem) minmax(6rem, 11rem) minmax(5rem, 13rem);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	max-width: 100%;
	line-height: 1.1;
} */

#task2do-app-id .info-display-bar > span.info-display-bar--data > span {
	position: relative;
	display: grid;
	width: 100%;
	height: 1.4rem;
	padding: 0;
	margin: -0.05rem .05rem .05rem;
	padding: .01rem .25rem;
	text-align: left;
	letter-spacing: .055rem;
	white-space: nowrap;
	overflow: hidden;
    text-shadow: 0.1rem 0.1rem 0.2rem rgba(6, 6, 6, 0.5);
    /* twoByTwo format */
    grid-template-columns: minmax(8rem, 9rem) minmax(5rem, 8rem) minmax(.5rem, 2rem) minmax(9rem, 11rem) minmax(3rem, 4rem);
    
     /* threeByThree format */
    /* grid-template-columns: minmax(4rem, 12rem) minmax(2rem, 4rem) minmax(.5rem, .75rem) minmax(5rem, 10rem) minmax(2rem, 5rem) minmax(.5rem, .75rem) minmax(5rem, 17rem) minmax(2rem, 4rem); */
    grid-template-columns: minmax(4rem, 10rem) minmax(2rem, 8rem) minmax(.5rem, .75rem) minmax(5rem, 10rem) minmax(2rem, 7rem) minmax(.5rem, .75rem) minmax(5rem, 17rem) minmax(2rem, 10rem);

	justify-content: flex-start;
	max-width: 100%;
    line-height: 1.1;
    font-size: .9rem;
}

.info-display-bar--data.dual-line-output > span > span:not(.data-value-field) {
	overflow: hidden;
	/* max-width: 10rem; */
	/* overflow-x: scroll; */
	/* scrollbar-width: none; */
}

#task2do-app-id .info-display-bar > span.info-display-bar--data > span .data-value-field {
    font-weight: 700;
}
#task2do-app-id .info-display-bar > span.info-display-bar--data.single-line-output {

    line-height: 1.1;
    font-variant: small-caps;
/*     font-size: 1.5rem; */
    font-weight: 300;
    height: 2.5rem;
    padding: .1rem .025rem .25rem;
/*     margin: .1rem;  */
}

#task2do-app-id .info-display-bar > span.info-display-bar--data.dual-line-output {
	/* background: rgba(10, 150, 50, .9); */
	/* height: 2.5rem; */
	margin: .025rem;
	padding: .05rem;
	width: 100%;
	font-size: 0.9rem;
	font-weight: 300;
	color: rgb(10, 0, 0);
}

#task2do-app-id .info-display-bar > span.info-display-bar--data.dual-line-output * {
   /* background: rgba(10, 150, 50, .9); */
    line-height: 1;
    font-variant: small-caps;
    /*! font-weight: 300; */
}

#task2do-app-id .info-display-bar > span.info-display-bar--data.dual-line-output > span.second-row  {
  /* background: rgba(10, 150, 50, .9); */
    font-size: .8rem;
/*     margin-top: .125rem; */
}

</style>
