<!-- eslint-disable -->
<template>
<v-content>
    <v-sheet  v-if="getAuthStatus === 'logged-out'" class="user-account-container">
        <v-toolbar flat color="rgba(13, 13, 13, .9)" style="height: 2.5rem;" class="container-titlebar">
            <h3 class="container-titlebar-header" align="center">User Login</h3>
            <v-btn  @click="$router.push({ name: 'Home'})" outlined class="container-close-btn  TM__red-glowing-outline">X</v-btn>  
            <!-- <router-link :to="{ name: 'Home' }">   <v-btn outlined class="container-close-btn  TM__red-glowing-outline">X</v-btn>  </router-link> -->
        </v-toolbar>
        <v-card>
            <v-form @submit.prevent="submitUserLogin"> <!--{{ backendSelection }}"> -->
                <fieldset>
                    <legend>Please enter your credentials</legend>
                    <div class="mr-4 theme--dark centered">
                        <div class="TS L-form-group">
                            <!-- <input type="text" placeholder="Email" email="" class="ts-c-form-control"> -->
                            <v-text-field v-model="screen_name" type="text" label="Screen Name (required)"></v-text-field>
                            <span ><i class="fas fa-envelope"></i></span>
                        </div>
                        <div  class="TS L-form-group">
                            <!-- <input type="password" placeholder="Password" email="" class="ts-c-form-control"> -->
                            <v-text-field v-model="password" type="password" label="Password (required)" v-on:keyup.enter='submitUserLogin'></v-text-field>
                            <span ><i class="fas fa-lock"></i></span>
                        </div>
                    </div>

                </fieldset>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions class="form-button-group centered">
                                
                <v-btn type="submit" class="mr-4 form-button-group-button TM__blue-glowing-outline form-button centered" @click.stop='submitUserLogin'>Login</v-btn>
                
                <!-- <v-btn type="submit" outlined class="TM__blue-glowing-outline theme--dark form-button">Login</v-btn> -->
                
            </v-card-actions>              
               
        </v-card>
  </v-sheet>
    <DisplayErrorDialog
            v-if="showErrorDialog"
            :errorMessage="getAuthStatusDetail"
            :errorTitle="getAuthStatus"
            :errorCode="getAuthStatusCode"
            @confirmErrorMessage="confirmErrorMessage"
            />
</v-content>

</template>

<script>
/* eslint-disable */
    import { mapGetters, mapState, mapActions } from "vuex";
    import DisplayErrorDialog from "@/components/dialogs/DisplayErrorDialog";
    export default {
        data:  () => ({
           
            screen_name: "",
            password: ""
            // email1: null,
            
        }),
        components: {
            DisplayErrorDialog
        },
        computed: {
            ...mapGetters('auth', {
                getAuthStatus: 'getAuthStatus',
                getAuthStatusCode: 'getAuthStatusCode',
                getAuthStatusDetail: 'getAuthStatusDetail',
                isLoggedIn: 'isLoggedIn',
                justLoggedIn: 'justLoggedIn'
            })
        },
        created() {
            this.updateMonthlyBackgroundImage( { curMonth: 'var(--task2do-logo-svg)', yPosition: '10%', backgroundColor: 'transparent' } );
        },
    // export default {
    //     data: () => ({
    //       first_name: "",
    //       last_name: "",
    //       screen_name: "",
    //       password: "",
    //       email1: "",
    //       email2: "",
    //       avatar_url: "",
    //       about: ""
    //     }),
        methods: {
        ...mapActions( 'auth', {
            initLoginProcessesFinished: 'initLoginProcessesFinished',
            showLoginForm: 'showLoginForm',
            loginUser: 'loginUser'
        }),
        ...mapActions( 'userSettings', {
            updateMonthlyBackgroundImage: 'updateMonthlyBackgroundImage',
        }),
        closeForm: function() {
            this.$emit('toggle-data-prop', 'LoginDialogOpen');
        },
        submitUserLogin: async function () {
            let screen_name = this.screen_name;
            let password = this.password;
            // let email1 = this.email1;
            await this.$store.dispatch('auth/loginUser', { "screen_name": screen_name, "password": password }); //.then(() => {

           
            // this.loginUser({ "screen_name": screen_name, "password": password })
            // .then(() => {
                // console.log("Pushing router to /restricted");
            this.$router.push("/restricted").catch(e => { console.log('e: ' + e); } );
                //debugger;
            // })
            // .catch(err => console.log("Caught error: " + JSON.stringify(err, null, 2) + "\n"));

            }
        }
    }        
//         async loginUser() {
//             if(this.screen_name && this.password) {
//                 let serializedData = JSON.stringify({
//                     screen_name: this.screen_name,
//                     password: this.password
//                 });
//                 let fetchParams = {
//                     method: 'POST',
//                     headers:{
//                     "Content-Type":"application/json; charset=UTF-8"
//                     },
//                     body: serializedData
//                     };
//                 let response = await fetch(`https://ts-api.t2do.io/t2do-api/users/login`, fetchParams);
//                 window.console.log("Response from API" + response.json());
//                         this.first_name = "";
//                         this.last_name = "";
//                         this.screen_name = "";
//                         this.email1 = "";
//                         this.password = "";
//             } else {
//                 alert('Please fill in the required fields.');
//             }       
//         }
//         }
//  }
</script>