<template>
  <v-dialog class="task-field-form" v-model="displayForm">
    <v-card>
      <v-card-title>{{ formCreatOrEditAction }} Task</v-card-title>

      <v-card-text>
        <!-- <v-form @submit.prevent='registerUser'> {{ backendSelection }}"> -->
        <!-- <fieldset class="ts-c-user-forms">
                    <legend>User Register</legend>
                    <div class="TS L-form-group"> -->
        <v-text-field
          v-model="taskFormObj.name"
          type="text"
          required
          autofocus
          :label="taskFormObj.name"
        ></v-text-field>
        <!-- <span><i class="fas fa-user-tag"></i></span> -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="taskFormObj.createdBy"
              type="text"
              disabled
              label="Can't edit because we're keeping you from being a poser"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="taskFormObj.createdFor"
              type="text"
              label="...well this should be you, but if you want to throw your pile onto someone else...here you go. I hope you feel good about yourself."
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="task-form-dropdown">
            <!-- <v-text-field v-model="taskFormObj.type" type="text" required label="Task Type - this should really be a drop down"></v-text-field> -->
            <v-select
              v-model="selectedTaskType"
              :menu-props="{
                offSetY: true,
                bottom: true,
                closeOnContentClick: true,
                contentClass: 'task-form-dropdown'
              }"
              :items="taskTypeLookup"
              item-text="label"
              item-value="index"
              max-height="30rem"
              return-object
              label="Task Type"
              dense
              outlined
            >
              <template v-slot:selection="data">
                <v-list>
                  <v-tooltip top open-delay="10">
                    <template v-slot:activator="{ on }">
                      <v-list-item
                        :style="{ background: 'rgba(125, 125, 225, .5)' }"
                        v-on="on"
                      >
                        <!-- <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn outlined dark v-on="on"> -->
                        <v-list-item-title
                          >{{ data.item.index }} -
                          {{ data.item.label }}</v-list-item-title
                        >
                        <!-- </v-btn>
                                                            </template>
                                                            <span>{{ data.item.description }}</span>
                                                        </v-tooltip> -->
                      </v-list-item>
                    </template>
                    <span>{{ data.item.description }}</span>
                  </v-tooltip>
                </v-list>
              </template>
              <template v-slot:item="data">
                <v-tooltip top open-delay="10">
                  <template v-slot:activator="{ on }">
                    <v-list-item
                      :style="{ background: 'rgba(125, 125, 125, .5)' }"
                      :selected="data.selected"
                      @click="selectedTaskType = data.item.index"
                      v-on="on"
                    >
                      <!-- <v-btn outlined dark v-on="on"> -->
                      <!-- <v-list-item-action> -->
                      <v-list-item-title
                        >{{ data.item.index }} -
                        {{ data.item.label }}</v-list-item-title
                      >
                      <!-- </v-list-item-action> -->
                      <!-- </v-btn> -->
                    </v-list-item>
                  </template>
                  <span>{{ data.item.description }}</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" class="task-form-dropdown">
            <v-select
              v-model="selectedPriorityLevel"
              :menu-props="{
                offSetY: true,
                bottom: true,
                closeOnContentClick: true,
                contentClass: 'task-form-dropdown'
              }"
              :items="priorityLevels"
              item-text="label"
              item-value="level"
              max-height="auto"
              return-object
              label="Priority Levels"
              dense
              outlined
            >
              <!--  content-class="['task-form-dropdown']" -->
              <!-- <template v-slot:selection="item"> -->
              <!-- <v-list-item  :selected="data.selected" :key="JSON.stringify(data.item)" :close-on-content-click="closeOnContentClick" @click="selectedPriorityLevel=data.parent.selectItem(data.item)"> -->
              <!-- <v-list-item> -->
              <!-- :style="{ 'color': 'rgba(200, 10, 10, .995)', 'background': 'rgba(50, 50, 150, .75)'}"> -->
              <!--                                             
                                                    <v-list-item-title> {{ item.level }} - {{ item.label }}</v-list-item-title>
                                             
                                            </v-list-item>
                                        </template> -->
              <template v-slot:selection="data">
                <v-list>
                  <v-list-item :style="{ background: data.item.color }">
                    <v-list-item-title style="text-align: center;"
                      >{{ data.item.level }} -
                      {{ data.item.label }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </template>
              <template v-slot:item="data">
                <v-list-item
                  :style="{ background: data.item.color }"
                  :selected="data.selected"
                  @click="selectedPriorityLevel = data.item.level"
                >
                  <v-list-item-title
                    >{{ data.item.level }} -
                    {{ data.item.label }}</v-list-item-title
                  >
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!-- <span><i class="fas fa-user-edit"></i></span><br class=""> -->
        <v-text-field
          v-model="taskFormObj.summary"
          type="text"
          label="Task summary"
        >
        </v-text-field>
        <v-text-field v-model="taskFormObj.body" type="text" label="Task body">
        </v-text-field>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="taskFormObj.start"
              type="text"
              required
              label="Task Start - Change to date picker"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="taskFormObj.end"
              type="text"
              label="Task End - Change to date picker"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="taskFormObj.createdOn"
              type="text"
              disabled
              label="Task Start - Change to date picker"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="taskFormObj.deadlineOn"
              type="text"
              label="Task End - Change to date picker"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6"> </v-col>
          <v-col cols="12" sm="6"> </v-col>
        </v-row>

        <!-- <span><i class="fas fa-envelope"></i></span><br class=""> -->

        <!-- <span><i class="fas fa-envelope"></i></span><br class="">                   -->
        <!-- </div> -->
        <!-- <div class="TS L-form-group">
                        <v-text-field v-model="avatar_url" type="text" label="Enter the path to your avatar image (optional)"> </v-text-field>  <span><i class="fas fa-user-cog"></i></span> <br class="">
                        <v-text-field v-model="about" type="text" label="Write a little about yourself"> </v-text-field>  <span><i class="fas fa-user-cog"></i></span> <br class="">
                        </div> -->
        <!-- 
                    </fieldset> -->
        <!-- </v-form> -->
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="commitEdit">Submit</v-btn>
        <v-btn color="primary" text @click="cancelEdit">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TaskForm",
  props: [
    "displayForm",
    "formCreatOrEditAction",
    "task2DoFormProp",
    "taskTypeLookup",
    "priorityLevelLookup",
    "task2DoPath",
    "parentMethod"
  ],
  data: () => ({
    taskFormObj: {},
    closeOnClick: true,
    selectedTaskType: 1,
    selectedPriorityLevel: 3
  }),
  computed: {
    priorityLevels() {
      return this.priorityLevelLookup;
    }
    // priorityLevelList() {
    //   let priorityLevelList = priorityLevels.map(obj => {
    //     return ( [obj.level] = obj.label);
    //   });
    //   return priorityLevelList;
    //   // return this.priorityLevelLookup.map(({ label }) => label);
    // },
    // priorityLevelColor() {
    //   let priorityLevelList = priorityLevels.map(obj => {
    //     return { [obj.level]: obj.color };
    //   });
    //   return priorityLevelList;
    // }
  },
  created() {
    this.taskFormObj = {
      id: "",
      categoryId: "",
      categoryKeyPath: "",
      name: "",
      createdBy: this.task2DoFormProp.createdBy,
      type: "",
      priorityLevel: "",
      summary: "",
      body: "",
      createdOn: "",
      editedOn: "",
      deadlineOn: "",
      finishedOn: "",
      start: "",
      end: "",
      status: "",
      progressPercent: 0
    };

    console.log(
      "task2DoFormProp (TaskForm.vue): " +
        JSON.stringify(this.task2DoFormProp, null, 2)
    );
    console.log(
      "Priority Level lookup check: " +
        JSON.stringify(this.priorityLevelLookup, null, 2)
    );
    console.log(
      "TaskType lookup check: " + JSON.stringify(this.taskTypeLookup, null, 2)
    );
  },
  mounted() {
    this.taskFormObj.id = this.task2DoFormProp.id;
    this.taskFormObj.categoryId = this.task2DoFormProp.categoryId;
    this.taskFormObj.categoryKeyPath = this.task2DoFormProp.categoryKeyPath;
    this.taskFormObj.name = this.task2DoFormProp.name;
    this.taskFormObj.type = this.task2DoFormProp.type;
    // this.taskFormObj.createdBy = this.task2DoFormProp.createdBy;
    this.taskFormObj.createdFor = this.task2DoFormProp.createdFor;
    this.taskFormObj.priorityLevel = this.task2DoFormProp.priorityLevel;
    this.taskFormObj.createdOn = this.task2DoFormProp.createdOn;
    this.taskFormObj.editedOn = this.task2DoFormProp.editedOn;
    this.taskFormObj.deadlineOn = this.task2DoFormProp.deadlineOn;
    this.taskFormObj.start = this.task2DoFormProp.start;
    this.taskFormObj.end = this.task2DoFormProp.end;
    this.taskFormObj.status = this.task2DoFormProp.status;

    this.taskFormObj.progressPercent = this.task2DoFormProp.progressPercent;
  },
  methods: {
    // setupPriorityLevelDropDown() {

    // },
    updateText() {
      //  this.objToEdit.name = this.textField;
      console.log(
        "Current values stored in the task2DoFormProp: " +
          JSON.stringify(this.task2DoFormProp, null, 2)
      );
      // this.parentMethod(this.task2DoFormProp, this.textField);
    },
    commitEdit() {
      // this.textField = this.textField !== '' ? this.textField : this.originalValue;
      // this.parentMethod(this.objToEdit, this.textField);
      console.log(
        "Current values stored in the task2DoFormProp: " +
          JSON.stringify(this.taskFormObj, null, 2)
      );
      this.$emit("closeTaskForm", this.taskFormObj, true);
    },
    cancelEdit() {
      // this.textField = this.originalValue;
      // this.parentMethod(this.objToEdit, this.originalValue);

      this.$emit("closeTaskForm", this.task2DoFormProp, false);
    }
  }
};
</script>
