<template>
    <div id="app" :class="[
      fullscreenMode ? 'fullscreenEnabled' : ''
      ]">
    <v-app id="task2do-app-id">
        <div class="background-container">
            <!-- <div v-if="task2doUser.displaySettings.background.animatedBackground===false" -->
            <div :class="[
                getAppState.componentsDisplayState.openWidgets.length > 0 ? `${t2doDisplaySettings.background.backgroundSelection}-background-image` : 'task2do-logo',
                t2doDisplaySettings.background.blurrBackground ? 'blurred-background' : '',
                t2doDisplaySettings.background.makeItDark ? 'night-time--filter' : ''
            ]">
                <video v-if="t2doDisplaySettings.background.animatedBackground===true"
                    id="animated-background-media-id"
                    autoplay=""
                    loop=""
                    muted=""
                    playsinline="">
                    <source type="video/mp4" :src="t2doDisplaySettings.background.animatedMedia.animatedMediaSrc"/>
                </video>
            </div>
        </div>
        <v-navigation-drawer
            v-if="isLoggedIn"
            v-model="getAppState.navDrawer.open"
            fixed
            clipped
            floating
            :width="'25rem'"
        >
            <v-list dense>
                <v-list-item @click="toggleNavDrawer()"> <!-- toggleShowWidget('navDrawer')"> -->
                    <v-list-item-content class="user-avatar-header"> <h2> {{ currentUser }} </h2>
                    <!-- <img
                        class="header-image"
                        src="http://task2do.co/img/Task2Do_Logo_48px.png"
                    /> -->
                    <img 
                    class="header-image" :src="getUserAvatars.basePath + getUserAvatars.normalMood.frames[0].url" />
                    
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="toggleMakeItDark()">
                    <v-list-item-action>
                        <!--  {{ '\u{2600}' }} <==  lame sun...no color! -->
                        <!-- <v-icon v-if="task2doUser.displaySettings.makeItDark"> {{ '\u{1F319}' }} mdi-moon-waxing-crescent</v-icon> -->
                        <!-- <v-icon v-if="task2doUser.displaySettings.makeItDark"
                            >{{ "\u{1F31E}" }}
                        </v-icon> -->

                        <!-- 
                        <v-icon v-if="t2doDisplaySettings.background.makeItDark" class="sunny-icon">mdi-weather-sunny</v-icon> 
                        <v-icon v-else class="moonlit-night-icon">mdi-weather-night</v-icon> 
                        -->

                        <v-icon v-if="t2doDisplaySettings.background.makeItDark" class="sunny-icon"> {{ "\u{1F307}" }} </v-icon>
                        
                        <v-icon v-else class="moonlit-night-icon"> {{ "\u{1F303}" }} </v-icon>
                        

                        <!-- <v-icon v-else> {{ "\u{1F319}" }} </v-icon> -->
                    </v-list-item-action>

                    <v-list-item-content v-if="t2doDisplaySettings.background.makeItDark"><!--{{ '\u{1F31E}' }}-->
                        <v-list-item-title>Let there be Light!</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                        <!--{{ '\u{1F319}' }} -->
                        <v-list-item-title>Make it Dark!</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item-group>
                    <v-tooltip top open-delay="10">
                        <template v-slot:activator="{ on }">
                            <v-list-item>
                            <v-list-item-title id="navigation-drawer-realtime-clock-id" :disabled="true" v-on="on"
                                ><h2 style="margin: .5rem .25rem .25rem;">{{ $datedJS.dated.getDateShort() }}</h2> <h3>{{ getCurrentTimeObj.twelveHourTimeWithSeconds }}</h3> </v-list-item-title
                            >
                            </v-list-item>
                        </template>
                        <span
                            >Quick access to your most used widgets and stuff
                            <strong> 2DO 2DAY!</strong>
                        </span>
                    </v-tooltip>
                    <v-list-item @click="toggleWidget('CalendarWidget')">
                        <v-list-item-action>
                            <v-icon class="sunrise-icon">mdi-weather-sunset-up</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                            <!-- {{ "\u{1F324}" }} -->
                            <h3>2DO 2DAY!</h3></v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="toggleWidget('BlogsWidget')">
                        <v-list-item-action>
                            <v-icon >{{ "\u{1F4D2}" }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                            <!-- {{ "\u{1F4D2}" }} -->
                            <h3>Journal Log</h3></v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="toggleWidget('NotesWidget')">
                        <v-list-item-action>
                            <v-icon >{{ "\u{1F4DD}" }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                            <!-- {{ "\u{1F4D2}" }} -->
                            <h3>Notes</h3></v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="toggleWidget('TasksWidget')">
                        <v-list-item-action>
                            <v-icon >{{ "\u{1F5C2}" }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                            <!-- {{ "\u{1F4D2}" }} -->
                            <h3>Tasks</h3></v-list-item-title>
                    </v-list-item>

                </v-list-item-group>

                <v-list-item @click="() => {}">
                    <v-list-item-action>
                        <!-- <v-icon>mdi-view-dashboard</v-icon> -->
                        <v-icon>mdi-developer-board</v-icon>
                        <!-- <icon style="font-size:200%;"> {{ "\u{1F5C3}" }} </icon> -->
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="openWidgets">
                    <v-list-item-action>
                        <!-- <v-icon>{{ "\u{1F4AB}" }}</v-icon> -->
                        <v-icon>mdi-spin mdi-widgets</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                        <!-- <router-link to="/widgetwheel">Widget Wheel</router-link> -->
                        Widget Spinner
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group sub-group @click="toggleNavDrawerWidgetSubmenu()"  class="nav-drawer--widget-group">
                    <template v-slot:activator v-if="getAppState.navDrawer.widgetsSubmenuOpen">
                        <v-list-item-content>
                            <v-list-item-title>Widgets</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item v-for="widget in getAppData.widgets" :key="widget.name" link>
                        <!-- <v-html v-if="widget.primaryIconType === 'htmlEnt'"> {{ widget.primaryIcon }} </v-html> -->
                        <!-- <icon v-if="widget.primaryIconType === 'unicode'"> {{ widget.primaryIcon }} </icon> -->
                        <v-list-item-content>
                            <v-list-item-title
                            v-if="widget.primaryIconType === 'unicode'"
                            style="font-size: 1rem; text-align: left;"
                            ><span
                                style="margin: .0875rem .5rem .0875rem 1rem; font-size: 125%;"
                            >
                                {{ widget.primaryIcon }}
                            </span>
                            {{ widget.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <!-- <v-list-item-title v-if="widget.primaryIconType === 'unicode'" style="font-size: 2rem; height: 2rem;">{{ widget.primaryIcon }}</v-list-item-title> -->
                        <!-- <v-list-item-subtitle> {{ widget.name }} </v-list-item-subtitle> -->
                    </v-list-item>
                </v-list-group>

                <v-divider></v-divider>

                <v-list-item @click="openSettings">
                    <v-list-item-action>
                        <v-icon>mdi-settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            
                <v-list-item @click="toggleFullscreenMode()">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                    
                            <v-list-item-action>
                                <v-icon>mdi-fullscreen</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Toggle Fullscreen</v-list-item-title>
                            </v-list-item-content>
    
                            <!-- <v-btn icon dark outlined class="toolbar-icon" v-on="on">
                                <v-icon>mdi-fullscreen</v-icon>
                            </v-btn> -->
                        </template>
                        <span>{{ toolbarButtons["toggleFullscreenEdit"]["description"] }}</span>
                    </v-tooltip>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item class="logout-button-centered">
                    <span v-if="isLoggedIn">
                        <v-btn class="v-btn--outlined TM__blue-glowing-outline navbar-button" @click="initLogoutUser">Logout</v-btn>
                    </span>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>

        <!-- The Root Landing Page -->
        <v-app-bar v-if="isLoggedIn" app clipped-left dense dark style="height: var(--top-app-bar--height)">  <!-- v-if="$route.name !== 'Home'"   -->
            
            <v-app-bar-nav-icon @click.stop="toggleNavDrawer()" v-if="isLoggedIn"></v-app-bar-nav-icon> 
                
            <!-- <AppToolbar :editModeProp="editMode" @toggleEditModeEvent="editMode = !editMode" @toggleFullscreenEvent="toggleFullscreenMode"></AppToolbar> -->

            <!-- <v-spacer></v-spacer> -->
            <v-toolbar-items id="app-toolbar-id" v-if="isLoggedIn">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click=" openTaskForm(emptyTaskObj, '')">
                            <v-icon>mdi-note-plus-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ toolbarButtons["newTask"]["description"] }}</span>
                </v-tooltip>

                <!-- <v-tooltip bottom style="z-index: 10000;">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="newTask()">
                            <v-icon>mdi-format-list-checks</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ toolbarButtons["newTask"]["description"] }}</span>
                </v-tooltip>
            
                <v-tooltip bottom style="z-index: 10000;">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="newList()">
                            <v-icon>mdi-format-list-bulleted</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ toolbarButtons["newList"]["description"] }}</span>
                </v-tooltip>
            
                <v-tooltip bottom style="z-index: 10000;">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="newTalliedList()">
                            <v-icon>mdi-format-list-numbered</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ toolbarButtons["newTalliedList"]["description"] }}</span>
                    </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark outlined class="mr-2 toolbar-icon" v-on="on" @click="newAppt()">
                            <v-icon>mdi-calendar-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ toolbarButtons["newAppt"]["description"] }}</span>
                </v-tooltip> -->

        
                <!-- <v-spacer></v-spacer> -->

                <v-toolbar-title id="task2do-top-logo-slogan">
                    <span class="app-title">
                        <h1><span>Task</span><span>2</span><span>DO!</span></h1>
                        <p>Don't Stew...DO!</p>
                    </span>
                </v-toolbar-title>
            
                <span v-if="!isLoggedIn" style="font-variant: small-caps; font-weight: 700;">
                    <v-btn v-if="$route.name !== 'Login'" class="v-btn--outlined TM__blue-glowing-outline navbar-button" @click="loginUser">Login</v-btn>

                    <v-btn v-if="$route.name !== 'Register'" class="v-btn--outlined TM__blue-glowing-outline navbar-button" @click="registerUser">Register</v-btn>
                </span>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark outlined class="toolbar-icon" v-on="on" @click="toggleNotificationSounds()">
                            <v-icon v-if="getAudioData.soundEnabled"> {{ "\u{1F50A}" }}</v-icon>
                            <v-icon v-else-if="!getAudioData.soundEnabled"> {{ "\u{1F507}" }}</v-icon>
                        </v-btn>

                    </template>
                    <span>{{ toolbarButtons["toggleNotificationSounds"]["description"] }}</span>
                    
                </v-tooltip>
                <RealtimeClock></RealtimeClock>
                <!-- <span class="appbar-realtime-clock" @click="sayCurrentTime()">{{ getCurrentTimeObj.twelveHourTimeWithSeconds }}<br class="">{{$datedJS.dated.getDateShort()}}</span>  -->
                
                <!-- Hello {{ currentUser }} </p></span> -->
            
                <v-menu v-if="isLoggedIn" left bottom>
                    <template>
                        <!--  v-slot:activator="{ on }"> -->
                        <v-btn icon>
                        <!-- v-on="on"> -->
                        <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="n in 5" :key="n" @click="() => {}">
                        <v-list-item-title>Option {{ n }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>

                <!-- <router-link to="/about">About</router-link>
                    <span v-if="isLoggedIn">| <router-link to="/widgetwheel">Widget Wheel</router-link> 
                                            | <router-link to="/tasks">Tasks</router-link>   
                                            | <router-link to="/notes">Notes</router-link>
                                            | <router-link to="/restricted">Restricted</router-link>
                                            | <a class="navbar-link" @click="logoutUser">Logout</a>
                    </span>
                    <span v-else>   | <a class="navbar-link" @click="loginUser">Login</a>
                                    | <a class="navbar-link" @click="registerUser">Register</a>
                    </span> -->
        </v-app-bar>

        <v-content>  
            <!-- style="padding: 8px 0px 32px 64px;"> -->
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <!-- <v-flex shrink> -->
                    <router-view
                    @login="loginUser"
                    @register="registerUser"
                    @logout="initLogoutUser"
                    />

                    <!-- <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                :href="source"
                                icon
                                large
                                target="_blank"
                                v-on="on"
                                >
                                    <v-icon large>mdi-code-tags</v-icon>
                                </v-btn>
                            </template>
                            <span>Source</span>
                        </v-tooltip>
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-btn icon large href="https://codepen.io/johnjleider/pen/bXNzZL" target="_blank" v-on="on">
                        <v-icon large>mdi-codepen</v-icon>
                        </v-btn>
                    </template>
                    <span>Codepen</span>
                    </v-tooltip> -->
                    <!-- </v-flex> -->
                    <TextPropertyDialog
                        v-if="showTextPropDialog"
                        :textPropAction="textPropDialogAction"
                        :displayDialog="showTextPropDialog"
                        @closeTextPropDialog="closeTextPropDialog"
                        @updateTextProp="updateChangedTextField"
                        @displayTextPropChange="displayChangedTextField"
                        :parentMethod="closeTextPropDialog"
                        :objToEdit="textPropDialogObject"
                        :objPropertyToEdit="'Category Name'"
                    />

                    <DisplayErrorDialog
                        v-if="showErrorDialog"
                        :errorMessage="getAuthStatusDetail"
                        :errorTitle="getAuthStatus"
                        :errorCode="getAuthStatusCode"
                        @confirmErrorMessage="confirmErrorMessage"
                    />

                    <InformationDialog
                        v-if="showInfoDialog"
                        :infoDialogObj="getAppState.informationDialog"
                        @confirmInfoMessage="confirmInfoMessage"
                    />


                    <DailySummary
                        v-if="showDaySummary"
                        @closeDaySummary="closeDaySummary"
                    />
                    
                    <TaskSummary
                        v-if="showTaskSummary"
                        @closeTaskSummary="closeTaskSummary"
                    />

                    <!-- v-if="showLoadingProgressDialog" -->
                    <ProgressDialog 
                        :showProgressDialog="showLoadingProgressDialog"
                        :progressDialogTitle="getLoadDataProgressObj.title"
                        :progressDialogMessage="getLoadDataProgressObj.message"
                        :progress="getLoadDataProgressObj.progress"
                    />

                    <TaskForm
                        v-if="showTaskForm"
                        :displayForm="showTaskForm"
                        :formCreatOrEditAction="taskFormAction"
                        @closeTaskForm="closeTaskForm"
                        :task2DoPath="newTaskPath"
                        :task2DoFormProp="tempTask2DoFormObj"
                        :parentMethod="commitTask2Do"
                        :taskTypeLookup="getTaskTypes"
                        :priorityLevelLookup="getTaskPriorityLevels"
                    />

                    <!-- :task20DoParent="newTaskParentId" /> -->
                    <CalendarWidget
                        v-if="getAppState.componentsDisplayState.openWidgets.includes('CalendarWidget') && isLoggedIn && isOkToLoadWidgets"
                        class="semi-trans--tinted__background calendar-widget-component "
                        @updateCurMonthBackground="updateMonthlyBackgroundImage"
                        @getUpdatedDataFromAPI="getDataFromAPI"
                        fixedWidget=true
                        :focusedWidget="getAppState.componentsDisplayState.focusedWidget"
                    />
                        
                    <BlogsWidget
                        v-if="getAppState.componentsDisplayState.openWidgets.includes('BlogsWidget') && isLoggedIn && isOkToLoadWidgets"
                        class="semi-trans--tinted__background blogs-widget-component "
                        fixedWidget=true
                        :focusedWidget="getAppState.componentsDisplayState.focusedWidget"
                    />

                    <NotesWidget 
                        v-if="getAppState.componentsDisplayState.openWidgets.includes('NotesWidget') && isLoggedIn && isOkToLoadWidgets"
                        class="semi-trans--tinted__background notes-widget-component"
                        :focusedWidget="getAppState.componentsDisplayState.focusedWidget"
                        fixedWidget=true
                    />
                    <TasksWidget 
                        v-if="getAppState.componentsDisplayState.openWidgets.includes('TasksWidget') && isLoggedIn && isOkToLoadWidgets"
                        class="semi-trans--tinted__background tasks-widget-component"
                        :focusedWidget="getAppState.componentsDisplayState.focusedWidget"
                        fixedWidget=true
                    />
            
                    <WidgetSpinner
                        @updateBackgroundImageEvent="updateMonthlyBackgroundImage"
                        :editTaskCat="editTaskCat"
                        :addSubCat="addSubCat"
                        :addTask="addTask"
                        :task2DoCats="task2DoUser.task2DoCategories"
                        :priorityLevelLookup="getTaskPriorityLevels"
                        :taskTypeLookup="getTaskTypes"
                        v-if="showWidgetSpinner && isLoggedIn"
                    />

                    <!-- <AppSettings v-if="showAppSettingsWindow" @closeSettings="showAppSettingsWindow = false" /> -->
                    <AppSettings
                        :showAppSettingsWindow="showAppSettingsWindow" 
                        @closeSettings="showAppSettingsWindow = false"
                        />
                </v-layout>
            </v-container>
        </v-content>

        <!-- <v-footer app> -->
        <!-- <span>&copy; 2019</span> -->
        <!-- <AppFooter /> -->
        <audio id="notificationSound" preload="auto"></audio>
        <!-- <div id="notificationSound"></div> -->
        <!-- </v-footer> -->
    </v-app>

    <!-- 
    <v-app id="app" class="task2do-app" style="overflow: hidden;">
        <div class="wrapper"> -->
    <!--Dahsboard Header-->
    <!-- <header id="header">
                <div class="TS L-header-section-container">
                    <div class="ts-header-row">
                        <div class="ts-left-column">
                            
                        </div>
                        <div class="ts-center-column">
                           
                        </div>
                        <div class="ts-right-column"> -->
    <!--<li>Sign-In</li><li>Register</li></ul>-->

    <!--                             
                           
                        </div>
                    </div>
                </div>
            </header>
        </div> -->
    <!-- <div id="task2do-topnav" class="topnav">
            |
           
        </div> -->
    <!-- <router-view />
        <AppFooter/> -->
    <!-- </v-app> -->
</div>
</template>

<script>
// import AppHeader from "./components/layout/AppHeader";
import { jsUtils } from "@/main";
import store from "./store";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import AppSettings from "@/components/Settings";
import AppFooter from "./components/layout/AppFooter";
// import WidgetCarousel from "@/views/WidgetCarousel";
import WidgetSpinner from "@/views/WidgetSpinner";
import BlogsWidget from "@/components/widgets/Blogs";
import NotesWidget from "@/components/widgets/Notes";
import TasksWidget from "@/components/widgets/Tasks";
import CalendarWidget from "@/components/widgets/Calendar";
import TextPropertyDialog from "@/components/dialogs/TextPropertyDialog";
import DisplayErrorDialog from "@/components/dialogs/DisplayErrorDialog";
import ProgressDialog from "@/components/dialogs/ProgressDialog"
import InformationDialog from "@/components/dialogs/InformationDialog";
import TaskSummary from "@/components/subComponents/TaskSummary";
import DailySummary from "@/components/subComponents/DailySummary";
import TaskForm from "@/components/forms/TaskForm";
import AppToolbar from "@/components/widgets/components/AppToolbar";
import RealtimeClock from "@/components/widgets/components/RealtimeClock"
// export const marked = require("./lib/marked.js");

export default {
    name: "App",
    components: {
        // AppHeader,
        AppToolbar,
        RealtimeClock,
        AppSettings,
        AppFooter,
        // WidgetCarousel,
        BlogsWidget,
        NotesWidget,
        CalendarWidget,
        TasksWidget,
        WidgetSpinner,
        TextPropertyDialog,
        DisplayErrorDialog,
        ProgressDialog,
        InformationDialog,
        TaskSummary,
        DailySummary,
        TaskForm
    },
    data: () => ({
        drawer: null,
        toolbarButtons: {
            newTask: {
                name: 'New Task',
                description: 'Create a new task',
                emitFunctionName: 'newTask'
            },
            newList: {
                name: 'New List',
                description: 'Create a new list',
                emitFunctionName: 'newList'
            },
            newTalliedList: {
                name: 'New Tallied List',
                description: 'Create a new tallied list, e.g, a shopping list',
                emitFunctionName: 'newTalliedList'
            },
            newAppt: {
                name: 'New Appointment',
                description: 'Add a new appointment to your schedule',
                emitFunctionName: 'newTalliedList'
            },
            editCategories: {
                name: 'Edit Categories',
                description: 'Edit your primary categories',
                emitFunctionName: 'editCategories'
            },
            saveEdit: {
                name: 'Save Edits',
                description: 'Save changes made to the current selected item',
                emitFunctionName: 'saveEdit'
            },
            toggleFullscreenEdit: {
                name: 'Toggle Fullscreen Edit Mode',
                description: 'Toggle fullscreen edit mode',
                emitFunctionName: 'toggleFullscreen'
            },
            toggleNotificationSounds: {
                name: 'toggleNotificationSounds',
                description: 'Toggle Notification Sounds',
                emitFunctionName: 'toggleNotificationSounds'
            }
        },
        appId: 'task2Do-app-id',
        task2DoUser: {
            screenName: '',
            appDataSettings: {
                settingsWindow: {
                    Tooltips: {
                        EnableAnimationNotice: 'To enable Animated Backgrounds must acknowledge the risks and performance requirements before you can select this feature/trap',
                        StaticBackgroundTip: 'A static image will be used for the background'
                    }
                },
                navDrawer: {
                    widgetsSubmenuOpen: false
                },
                priorityLevels: [
                    {
                    // remember, this is 0 (hense, the no priority level) but the dbIndex refers to its ID in the DB since 0(zero) is not a valid ID.
                    dbIndex: 1,
                    level: 0,
                    label: "No Priority Level",
                    description:
                        "No Priority Level has been set. Used for general purpose todos.",
                    color: "rgba(166, 166, 166, .66)"
                    },
                    {
                    dbIndex: 2,
                    level: 1,
                    label: "Lowest Priority",
                    description: "The Lowest Priority Level Task",
                    color: "rgba(0, 225, 0, .75)"
                    },
                    {
                    dbIndex: 3,
                    level: 2,
                    label: "Low Priority",
                    description: "A Lower Priority Level Task",
                    color: "rgba(0, 192, 0, .75)"
                    },
                    {
                    dbIndex: 4,
                    level: 3,
                    label: "Low-Medium Priority",
                    description: "A Low-to-Medium Priority Level Task",
                    color: "rgba(64, 128, 0, .75)"
                    },
                    {
                    dbIndex: 5,
                    level: 4,
                    label: "Medium Priority",
                    description: "A Medium Priority Level Task",
                    color: "rgba(96, 128, 0, .75)"
                    },
                    {
                    dbIndex: 6,
                    level: 5,
                    label: "Slightly Elevated Priority",
                    description: "A Slightly Elevated Priority Level Task",
                    color: "rgba(128, 192, 0, .75)"
                    },
                    {
                    dbIndex: 7,
                    level: 6,
                    label: "Medium-High Priority",
                    description: "A Medium-to-High Priority Level Task",
                    color: "rgba(192, 192, 0, .75)"
                    },
                    {
                    dbIndex: 8,
                    level: 7,
                    label: "Moderatly Elevated Priority",
                    description: "A Moderatly Elevated Priority Level Task",
                    color: "rgba(255, 192, 0, .75)"
                    },
                    {
                    dbIndex: 9,
                    level: 8,
                    label: "High Priority",
                    description: "A High Priority Level Task",
                    color: "rgba(255, 128, 0, .75)"
                    },
                    {
                    dbIndex: 10,
                    level: 9,
                    label: "Needs Immediate Attention",
                    description: "A Task That Needs Immediate Attention",
                    color: "rgba(255, 64, 0, .75)"
                    },
                    {
                    dbIndex: 100,
                    level: 10,
                    label: "Holy Shit Batman",
                    description: "It's Life or Death Man!",
                    color: "rgba(255, 0, 0, .75)"
                    }
                ],
                taskTypes: [
                    {
                    id: 1,
                    label: "Unspecified Item",
                    description:
                        "Unspecified note, Used for saving thoughts or ideas that don't necessarily constitute a task or reminder but don't want to forget either."
                    },
                    {
                    id: 2,
                    label: "Task",
                    description: "A Task that needs to be done",
                    icon: "\u{1F4DD}"
                    },
                    {
                    id: 3,
                    label: "Todo",
                    description: "Something that needs to be done"
                    },
                    {
                    id: 4,
                    label: "Reminder",
                    description: "A Reminder todo something",
                    icon: "\u{23F0}"
                    },
                    {
                    id: 5,
                    label: "Note",
                    description: "A note or idea that you had",
                    icon: "\u{1F4DD}"
                    },
                    {
                    id: 6,
                    label: "Want2Do",
                    description:
                        "Something you don't NEED to-do but you would like to do at some point. This is meant to be more general purpose than a Goal."
                    },
                    {
                    id: 7,
                    label: "Goal",
                    description: "A goal you would like to achieve"
                    },
                    {
                    id: 8,
                    label: "Appointment",
                    description: "Meetings or appointments"
                    },
                    {
                    id: 9,
                    label: "Event",
                    description: "A Planned Event"
                    },
                    {
                    id: 10,
                    label: "Daily",
                    description: "A daily activity that you do."
                    },
                    {
                    id: 11,
                    label: "Routine",
                    description:
                        "An activity you routinely do that is a part of your daily, weekly, monthly schedule."
                    },
                    {
                    id: 12,
                    label: "Journal Log",
                    description: "A quick recap or log of something you did."
                    },
                    {
                    id: 13,
                    label: "Idea",
                    description:
                        "A spontaneous idea you had that may or may not be related to what youre currently working on."
                    },
                    {
                    id: 14,
                    label: "TradeSkillNote",
                    description:
                        "A study note or reminder about a specific method or procedure that you want to remind yourself of. This will also require a user-configureable trade/skill category/subject lookup table. Ex. Skill SoftwareDev, Category Javascript Pitfalls"
                    },
                    {
                    id: 15,
                    label: "Study Note",
                    description:
                        "Notes that you will use to study or otherwise act as a resourse that you can easily look up at a later date. (THIS REQUIRES USER DEFINED CATEGORIES)"
                    }
                ],
                nags: [
                    {
                        id: 0,
                        label: 'How is your progress?',
                        message: 'You should be done with ',
                        sound: 'audio/csoundCminPhaseSwell.wav'
                    },
                    {
                        id: 1,
                        label: 'Are you done yet?',
                        message: 'Are you still working on ',
                        sound: 'audio/enotherapy_1.wav'
                    },
                    {
                        id: 2,
                        label: 'Have you finished your current task?',
                        message: 'Have you finished ',
                        sound: 'audio/Motion08_m.WAV'
                    },
                    {
                        id: 3,
                        label: 'Whats taking sooo long?!',
                        message: 'Seriously!? You need to finish ',
                        sound: 'audio/Motion10_s.WAV'
                    },
                    {
                        id: 4,
                        label: 'What\'s the status of your current task?',
                        message: 'Have you completed ',
                        sound: 'audio/Motion11_s.WAV'
                    },
                ]
                    // TODO: priorityLevels and taskTypes should prob be on same level as task2DoCategories and task2Dos
           }
        },
        currentTask: {},
        // userJustLoggedIn: false,
        fullscreenMode: false,
        lastApiDataRefreshTimestamp: 0,
        browserFullscreenPrefix: {},
        cachedCategories: [],
        currentCategory: [],
        newSubCat: {},
        currentlyEditingObj: {},
        catsAndDos: [],
        showAppSettingsWindow: false,
        showWidgetSpinner: false,
        showCalendarWidget: false,
        showTextPropDialog: false,
        showInformationDialog: false,
        informationDialogTitle: '',
        informationDialogTimestamp: null,
        informationDialogMessage: '',
        showTaskForm: false,
        tempTask2DoFormObj: {},
        newTaskPath: '',
        // newTaskParentId: '',
        taskFormAction: '',
        textPropDialogObject: {},
        textPropDialogTextField: '',
        textPropDialogAction: '',
        textPropDialogOriginalValue: '',
        loadDataProgress: 0,
        loadingProgressDialogTitle: '',
        loadingProgressDialogMessage: '',
    }),
    computed: {
        ...mapGetters( [ 'isDataLoadedFromAPI', 'getLoadDataProgressObj']),
        ...mapGetters('notes', {
            allNotes: 'allNotes'
        }),
        ...mapGetters('tasks', {
            allTasks2Do: 'allTasks2Do',
            getTodaysTasks: 'getTodaysTasks',
            getTaskTypes: 'getTaskTypes',
            getTaskPriorityLevels: 'getTaskPriorityLevels',
            getTaskStatusTypes: 'getTaskStatusTypes',
            emptyTaskObj: 'emptyTaskObj'
        }),
        // ...mapState(["finishedLoadingDataFromAPI"]),
        ...mapGetters('auth', {
            getAuthStatus: 'getAuthStatus',
            getAuthStatusCode: 'getAuthStatusCode',
            getAuthStatusDetail: 'getAuthStatusDetail',
            isLoggedIn: 'isLoggedIn',
            hasJustLoggedIn: 'hasJustLoggedIn',
            getUserStateObj: 'getUserStateObj',
            getCurrentUserId: 'getCurrentUserId',
            getCurrentUserName: 'getCurrentUserName'
        }),
        ...mapGetters('userSettings', {
            t2doAllSettings: 't2doAllSettings',
            t2doDisplaySettings: 't2doDisplaySettings',
            getUserPreferences: 'getUserPreferences'
        }),
        ...mapGetters('appData', {
            getAppData: 'getAppData',
            getReminderData: 'getReminderData',
            getAudioData: 'getAudioData'
        }),
        ...mapGetters('userData', {
            getUserAvatars: 'getUserAvatars'
        }),
        ...mapGetters('uiState', {
            getInfoDialogState: 'getInfoDialogState',
            getAppState: 'getAppState',
            getWindowDimensions: 'getWindowDimensions',
            getFocusedInputId: 'getFocusedInputId',
            isOkToLoadWidgets: 'isOkToLoadWidgets',
            isInputFieldFocused: 'isInputFieldFocused',
            isErrorDialogVisible: 'isErrorDialogVisible',
            getDaySummaryState:  'getDaySummaryState',
            getTaskSummaryState: 'getTaskSummaryState'
        }),
        ...mapGetters('time', {
            getTopOfMinuteTimestamp: 'getTopOfMinuteTimestamp',
            getCurrentTimestamp: 'getCurrentTimestamp',
            getCurrentTimeObj: 'getCurrentTimeObj'
        }),

        // currentUser: function() {
              
        //     let currentUserString = this.$store.getters.user;
            
        //     let capitalizedUserName = this.$jsUtils.capitalizeString(currentUserString);
        //     console.log("currentUser: " + capitalizedUserName);
        //     return capitalizedUserName;
            
        // },
        showInfoDialog: function() {
            return this.getInfoDialogState['show'];
        },
        showErrorDialog: function() {
            return this.isErrorDialogVisible;
            
            // if (this.authStatusCode !== 200) {
            //     return true;
            // } else {
            //     return false;
            // }
        },
        showLoadingProgressDialog: function() {
            console.log('this.getAuthStatusCode:' +  this.getAuthStatusCode);
            console.log('this.getLoadDataProgressObj.progress:' + this.getLoadDataProgressObj.progress);
            return (this.getLoadDataProgressObj.progress > 0) && (this.getLoadDataProgressObj.progress < 100) && (this.getAuthStatusCode < 400);
        },
        showDaySummary: function () {
            return this.getDaySummaryState.show;
        },
        showTaskSummary: function() {
            return this.getTaskSummaryState.show;
        }
    },
    async created() {
        // this.initAppState();
        
        //  init non-reactive variables
        
        //  if(!this.currentUser) {
        //         this.authError( { statusCode: 401, status: 'auth-error', name: 'user-not-set', message: 'Username has not been set'} );
        //         return null;
        // } else {
        //     this.screenName = this.currentUser;
        // }
        
        this.$http.interceptors.response.use(undefined, function axiosTimeoutInterceptor(err) {
        // eslint-disable-next-line no-unused-vars
            // return new Promise(function(resolve, reject) {
                console.log('http.interceptor.response err: ' + JSON.stringify(err, null, 2));
                // if (err.status == 401 && err.config && !err.config.__isRetryRequest) {                            
                if ((typeof(err) === 'object') &&
                    (err.hasOwnProperty('name')) &&
                    (err.hasOwnProperty('message')) &&
                    (err.hasOwnProperty('statusCode')) &&
                    (err.hasOwnProperty('status'))) {
            
                    if ((err.status === 401) && err.message) {

                    // if (err.status === 401 || err.response.data.message === '401 Unauthorized') {
                        // vm.logoutUser(); 
                        let statusObj = ( { statusCode: 401, status: err.status, name: 'auth-error', message: err.message} );
                        store.dispatch("auth/authError", statusObj);
                        
                        // $store.logoutUser();
                        console.log('*******************************************\n\tdispatching  authError');
                        console.log('\t!!!!!!error called from App.vue line 1140. Value of err object: ' + err);
                    } else {
                        let jsonErr = JSON.stringify(err, null, 2);
                        let statusObj = ( { 'statusCode': 666, 'status': err, 'name': 'http.interceptor.response err', 'message': jsonErr } );
                        
                        store.dispatch("auth/authError", statusObj);
                    }
                        

                }
                return Promise.reject(err);
            // throw err;
            // });
        });


        this.SET_WINDOW_DIMENSIONS({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });

        

        // this.task2Dos = this.$arrayObjUtils.arrayObjsDeepCopy(this.tempTask2Dos);
        // this.updateTasksAndCats(); <-- these will always be edited seperatly

    },
    async mounted() {
        this.browserFullscreenPrefix = await this.getSuportedFullscreenPrefix();
        console.log('attempting to run action \'initAppState\'');
        

        //  await this.checkTokenStatus({ id: this.currentUserId, screen_name: this.currentUser });

            // await this.checkLocalStorage();
            // if (this.authStatusCode === 200) {
            //     await this.getUserPreferences();
            //     await this.getDataFromAPI();
            // }
           
        // get the correct browser fullscreen API prefix

        if (this.t2doDisplaySettings.background.animatedBackground === true) {
            document.getElementById("animated-background-media-id").playbackRate = 0.3;  
        }
        // await this.initAppData();
        // if (this.isLoggedIn) {
        //     console.log(`User logged in, fetchingNotes and Tasks2Do from App.vue`);
        //     this.fetchNotes();
        //     this.fetchTasks2Do();   
        // }
        
        let catsFromStorage = localStorage.getItem("task2DoCategories");
        let tasksFromStorage = localStorage.getItem("task2Dos");
        console.log("Tasks from local storage: " + tasksFromStorage);

        if (tasksFromStorage) {
            this.task2DoUser.task2Dos = JSON.parse(tasksFromStorage);
        }
        if (catsFromStorage) {
            this.task2DoUser.task2DoCategories = JSON.parse(catsFromStorage);
        }

        // this.$arrayObjUtils.buildNestedArrayPaths(
        // this.task2DoUser.task2DoCategories
        // );

    },
    async updated() {
        console.log("updated method from app.vue");

        // if ((this.getLoadDataProgressObj.progress === 100) && (this.isOkToLoadWidgets === false)) {
        //     this.checkForTimeUpdate();
        //     this.SET_OK_TO_LOAD_WIDGETS(true);

        // }
     
        if (this.isErrorDialogVisible) {
             this.updateMonthlyBackgroundImage( { curMonth: 'var(--task2do-logo-svg)', xPosition: 'center', yPosition: '10%', backgroundColor: 'transparent', backgroundSize: 'contain' } );
        }
       
    },
    beforeDestroy: function() {
        this.killRealtimeClockInterval();
        
        
        
    },
    methods: {
        // ...mapActions(["fetchNotes"]),
        // ...mapActions(["fetchTasks2Do"]),
        ...mapActions([ 'fetchAllFromAPI', 'refreshDataFromAPI', 'initAppState' ]),
        ...mapActions( 'appData', {
            toggleNotificationSounds: 'toggleNotificationSounds'
        }),
        ...mapActions( 'tasks', {
            createTask2Do: 'createTask2Do',
            updateTask2Do: 'updateTask2Do',
            setupTodaysTaskList: 'setupTodaysTaskList',
            fetchTasks2Do: 'fetchTasks2Do',
            fetchTaskPropertiesData: 'fetchTaskPropertiesData'
        }),
        ...mapActions( 'notes', {
            addNote: 'addNote',
            updateNote: 'updateNote',
            fetchNotes: 'fetchNotes'
        }),
        ...mapActions( 'auth', {
            initLoginProcessesFinished: 'initLoginProcessesFinished',
            checkLocalStorage: 'checkLocalStorage',
            checkTokenStatus: 'checkTokenStatus',
            authError: 'authError',
            showLoginForm: 'showLoginForm',
            logoutUser: 'logoutUser',
        }),
        ...mapActions( 'userSettings', {
            getUserPreferences: 'getUserPreferences',
            toggleMakeItDark: 'toggleMakeItDark',
            updateMonthlyBackgroundImage: 'updateMonthlyBackgroundImage',
            initAudioTimeArray: 'initAudioTimeArray',
            loadAudioIntoBufferArray: 'loadAudioIntoBufferArray'
            
        }),
        ...mapActions( 'time', {
            init: 'init',
            killRealtimeClockInterval: 'killRealtimeClockInterval',
            sayCurrentTime: 'sayCurrentTime'
        }),
        ...mapActions( 'uiState', {
            setFocusedWidget: 'setFocusedWidget',
            toggleNavDrawer: 'toggleNavDrawer',
            closeNavDrawer: 'closeNavDrawer',
            closeAppSettings: 'closeAppSettings',
            toggleShowWidget: 'toggleShowWidget',
            toggleNavDrawerWidgetSubmenu: 'toggleNavDrawerWidgetSubmenu',
            
        }),
        ...mapMutations( 'uiState', {
            SET_OK_TO_LOAD_WIDGETS: 'SET_OK_TO_LOAD_WIDGETS',
            SET_WINDOW_DIMENSIONS: 'SET_WINDOW_DIMENSIONS'
        }),
        async initAppData() {

           

            await this.checkTokenStatus();
            if ((this.getCurrentUserId) && (this.getCurrentUserName)) {
                console.log('\n\t!!!!!!!!!!!!Condition met:  currentUserId and currentUser are set');
              

                // if ((this.isLoggedIn === true) && (this.justLoggedIn === true)) {
                    // if(this.authStatus === 'success') {
            console.log('\n\t!!!!!!!!!!!!Are we even trying to get data from API, if not it\'s because of getAuthStatus');
            await this.getDataFromAPI();
                    // }
                    console.log('\n\t!!!!!!!!!!!!authStatus: ' + this.getAuthStatus);
                // }

                console.log("############\nawaiting getDataFromAPI in app.vue created method.\n\tValue of isDataLoadedFromAPI: " + this.isDataLoadedFromAPI);
            }

        },
        async getDataFromAPI(forceRefresh = false) {

            let timeSinceLastDataRefresh =  this.getCurrentTimeStamp - this.lastApiDataRefreshTimestamp;
            console.log('Time since last API data refresh: ' + (timeSinceLastDataRefresh / 1000) + ' seconds');
            
            if (this.isLoggedIn === true) {
                  
                if (this.hasJustLoggedIn === true) { 

                    // this.loadingProgressDialogMessage = 'Calling this.fetchAllFromAPI()';
                    // const APIresult = await this.fetchAllFromAPI();
                    console.log('user has just logged in, calling fetchAllFromAPI...');
                    await this.fetchAllFromAPI()
                        
                    await this.getCurrentTask();
                    

                   
                } else if (((this.getLoadDataProgressObj.progress === 100) && (timeSinceLastDataRefresh > this.getUserPreferences.minuteIntervals.refreshApiData)) || (forceRefresh === true)) { 
                    // just refresh API data if initial data has loaded
                    console.log('Data loaded from API, so only fetching tasks....');
                    if (forceRefresh === true) {
                        console.log(' \n FORCED REFRESH! - Forcing Refresh is true');
                    }
                    await this.refreshDataFromAPI();
                  
                    await this.getCurrentTask();
                 
                  
                }
                this.lastApiDataRefreshTimestamp = this.getCurrentTimeStamp;
                return this.lastApiDataRefreshTimestamp;
            }
          
            return false;

            //  if ((this.isLoggedIn === true) && (this.justLoggedIn === true)) {
            //     // this.userJustLoggedIn = false;
            //     console.log(`User just logged in, so refetching Notes and task2Dos from App.vue getDataFromAPI method`);

            //     const fetchTaskPropertiesResult = await this.fetchTaskPropertiesData();
            //     // if (fetchTaskPropertiesResult) {
            //     await console.log("fetchTaskPropertiesResult: " + fetchTaskPropertiesResult);
            //     // }

            //     const fetchTasksResult = await this.fetchTasks2Do();
            //     if (fetchTasksResult) {
            //         console.log("fetchTasksResult: " + fetchTasksResult);
            //     }

            //     const fetchNotesResult = await this.fetchNotes();
            //     if (fetchNotesResult) {
            //         console.log("fetchNotesResult: " + fetchNotesResult);
            //         this.initLoginProcessesFinished();
            //     }

            // }
        },
        confirmErrorMessage() {

            this.showLoginForm();
            
        },
        confirmInfoMessage() {
            this.showInformationDialog = !this.showInformationDialog;
        },
        // updateBackgroundImage(this.getCurrentBackgroundImage) {
           
            // let monthImgChange = document.documentElement.style.setProperty('--monthly-background-image', 'url(' + this.t2doDisplaySettings.background.staticImages.remoteImageBasePath + this.t2doDisplaySettings.background.staticImages.monthlyImages[curMonth] + ') no-repeat center');

            // let userImageChange = document.documentElement.style.setProperty("--user-background-image", `url(${this.t2doDisplaySettings.background.staticImages.remoteImageBasePath}${this.t2doDisplaySettings.background.staticImages.userImage}) no-repeat center`); 

        // },
        updateRealTimeClock() {
               
            this.realTimeClock = this.$datedJS.dated.getTwelveHourTime(this.getCurrentTimeStamp, true);
            this.getCurrentTimeObj.full12HourTime
            let curSec = this.$datedJS.dated.getCurrSec();
            


        },

        /**
         * // TODO: Only keeping the next block of code in a comment because I need to refactor how to getCurrentTask()
         */
        // checkForTimeUpdate() {            
        //     let curMinute = this.$datedJS.dated.getMinute(this.getTopOfMinuteTimestamp);
        
        //     if (curMinute % this.t2doAppSettings.reminders.playCurrentTimeAudioIntervalMinutes === 0) { //} && ((curSec >= 0) && (curSec <= 5))) {
        //         this.sayCurrentTime();
        //         this.getCurrentTask();
        //     }
        // },

        checkTaskProgress() {

        },
        getCurrentTimeStamp: function() {
            // return new Date().getTime();
            return Date.now();
        },
        getCurrentTask() {
            // let currentTaskObj = this.getTodaysTasks;
            
            this.getTodaysTasks.forEach(currentTask => {
                let taskStartTime = new Date(currentTask.start).getTime();
                let taskEndTime = new Date(currentTask.end).getTime();
                
                let timeLeftForTask = new Date(taskEndTime - this.currentTimeStamp).getTime();
                let timeTillNextTask = new Date(taskStartTime - this.currentTimeStamp).getTime();
                let minTillNextTask = this.$datedJS.dated.milliSec2Minute(timeTillNextTask);
                let minLeftForTask = this.$datedJS.dated.milliSec2Minute(timeLeftForTask);
                let lower15minutebound = this.$datedJS.dated.inMilliSeconds.fifteenMinutes - this.$datedJS.dated.inMilliSeconds.nintySeconds;
                let upper15minutebound = this.$datedJS.dated.inMilliSeconds.fifteenMinutes + this.$datedJS.dated.inMilliSeconds.nintySeconds;

                let lower10minutebound = this.$datedJS.dated.inMilliSeconds.tenMinutes - this.$datedJS.dated.inMilliSeconds.nintySeconds;
                let upper10minutebound = this.$datedJS.dated.inMilliSeconds.tenMinutes + this.$datedJS.dated.inMilliSeconds.nintySeconds;

                let lower5minutebound = this.$datedJS.dated.inMilliSeconds.fiveMinutes - this.$datedJS.dated.inMilliSeconds.nintySeconds;
                let upper5minutebound = this.$datedJS.dated.inMilliSeconds.fiveMinutes + this.$datedJS.dated.inMilliSeconds.nintySeconds;

                let reminderNagIndex = this.$jsUtils.randomNum(0, getReminderData.nags.length - 1);


                // console.log("TaskStartTime: " + taskStartTime + "\n\tTaskEnd Time: " + taskEndTime + "\n\tthis.currentTimeStamp: " + this.currentTimeStamp);
             
                // if ((this.currentTimeStamp >= taskStartTime) && (this.currentTimeStamp <= taskEndTime)) {
                //     console.log("Found CURRENT TASK!!!: " + currentTask.name);
                // }

                // console.log("Time till next task: " + timeTillNextTask + "\n\tlower15minuteBound: " + lower15minutebound);

                // console.log("Time left in current task: " + timeLeftForTask + "\n\tlower15minuteBound: " + (timeLeftForTask - lower15minutebound));

               /** 
                * // TODO: This needs to be moved to a separate function
                * Display notification and play audio 
                */
               
               
                
                // task.start_unix_timestamp = parseInt(task.start_unix_timestamp);
                // task.end_unix_timestamp = parseInt(task.end_unix_timestamp);

                // task.start = new Date(task.start).getTime();
                // task.end = new Date(task.end).getTime();
            });

        },
        registerUser: function() {
            this.$router.push("/register");
        },
        loginUser: function() {
            
            this.SET_OK_TO_LOAD_WIDGETS(false);
            this.showLoginForm();
            // this.updateMonthlyBackgroundImage(0);
            this.updateMonthlyBackgroundImage( { curMonth: 'var(--task2do-logo-svg)', xPosition: 'center', yPosition: 'center', backgroundColor: 'transparent', backgroundSize: 'contain' } );
            this.$router.push("/login");
            console.log("after loginUser route");
            console.log('userInfoObj: ' + JSON.stringify(this.getUserStateObj, null, 2));
            // if (this.isLoggedIn) {
            //     this.userJustLoggedIn = true;
            // }
           
        },
        initLogoutUser: function() {
            console.log('\ninitLogoutUser called in App.vue');

            this.SET_OK_TO_LOAD_WIDGETS(false);
            
            this.updateMonthlyBackgroundImage( { curMonth: 'var(--task2do-logo-svg)', xPosition: 'center', yPosition: 'center', backgroundColor: 'transparent', backgroundSize: 'contain' });
            this.logoutUser().then(() => {
                this.$router.push("/login");
            });
        },
        getSuportedFullscreenPrefix() {
        
            let docRoot = document.documentElement;
            return docRoot.requestFullscreen || docRoot.webkitRequestFullscreen || docRoot.mozRequestFullScreen || docRoot.msRequestFullscreen;
            
        },
        toggleFullscreenMode() {
            console.log("toggleFullscreenEdit called");
            
            let appRootElement = document.getElementById(this.appId);        
            this.closeNavDrawer();
            if (document.fullscreenEnabled === true) {
                this.fullscreenMode = !this.fullscreenMode;
                if (this.fullscreenMode === true) {
                    this.browserFullscreenPrefix.call(appRootElement);
                }
            } else {
                this.fullscreenMode = false;
            }

        },
        toggleWidget: function(widgetName) {
            this.toggleShowWidget(widgetName);
            this.closeNavDrawer();
            this.closeAppSettings();
        },
        // openJournalWidget: function() {
        //     this.toggleShowWidget('showJournalWidget');
        //     this.closeNavDrawer();
        //     this.closeAppSettings();
        //     this.setFocusedWidget('showJournalWidget');
        // },
        openWidgets: function() {
            console.log("Called openWidgets method");
            this.showWidgetSpinner = !this.showWidgetSpinner;
            this.closeNavDrawer();
            this.showAppSettingsWindow = false;
            
            //this.$router.push("/widgetwheel");
        },
        openSettings: function() {
            console.log("Called openSettings method");
            this.showAppSettingsWindow = !this.showAppSettingsWindow;
            this.showWidgetSpinner = false;
            this.closeNavDrawer();
            
            // this.$router.push("/settings");
        },
        categorizeTasks(catArray, catIndex = 0) {
            console.log("CatArray length: " + catArray.length);
            for (let index = 0; index < catArray.length; index++) {

                console.log("Category: " + typeof catArray[index]);

                if (Array.isArray(catArray[index].children)) {
                    this.categorizeTasks(catArray[index].children, catIndex + 1);
                }
                for (let taskIndex = 0; taskIndex < this.tempTask2Dos.length; taskIndex++) {
                    // for (let task in  this.tempTask2Dos) {
                    if (this.tempTask2Dos[taskIndex].categoryId == catArray[index].id) {
                        if (!catArray[index].children) {
                            catArray[index].children = [];
                        }

                        catArray[index].children.push({
                            id: this.tempTask2Dos[taskIndex].id,
                            name: this.tempTask2Dos[taskIndex].name,
                            // keyPath: catArray[index].keyPath + 'c' + taskIndex,
                            nodeLevel: catArray[index].nodeLevel + 1,
                            nodeIndex: taskIndex,
                            isFinished: this.tempTask2Dos[taskIndex].isFinished,
                            priorityLevel: this.tempTask2Dos[taskIndex].priorityLevel
                        });
                        console.log("Task - Category Match! \n\tCatIndex: " + catIndex + "\n\tAdded task " + this.tempTask2Dos[taskIndex].name + " to category " + (catArray[index] + 1).name + "\n");
                    }
                }
            }
        },
        setupNewCategoryTemplateObject(categoryName = "New Category", keyPath = "0", nodeLevel = 0, nodeIndex = 0) {
            // this.newCategoryTemplate = {
            let newCategoryTemplate = {
                id: this.$jsUtils.genUUIDv4(),
                name: categoryName,
                type: "category",
                keyPath: keyPath + nodeIndex,
                nodeLevel: nodeLevel,
                nodeIndex: nodeIndex,
                children: [
                    {
                        id: "addTask" + this.$jsUtils.genUUIDv4(),
                        name: "Add Task"
                    }
                ]
            };
            return newCategoryTemplate;
        },
        setupNewTaskTemplateObject(
            taskName = "New Task",
            categoryId = "",
            keyPath = "0",
            parentId = ""
            ) {
            let newTaskTemplateObject = {
                id: this.$jsUtils.genUUIDv4(),
                name: taskName,
                createdBy: this.currentUser,
                createdFor: this.currentUser,
                type: "task",
                // TODONT: default priority level as setting? 5 seams pretty safe...yup leave it
                priorityLevel: 5,
                categoryId: parentId,
                categoryKeyPath: keyPath,
                summary: "",
                body: "",
                notes: "",
                createdOn: this.$datedJS.localISOTime(),
                editedOn: this.$datedJS.localISOTime(),
                deadlineOn: this.$datedJS.localISOTime(new Date().addHours(6)),
                finishedOn: "",
                isFinished: false,
                start: this.$datedJS.localISOTime(),
                // TODO: add default task end time as setting in...you guessed it: settings
                end: this.$datedJS.localISOTime(new Date().addHours(6)),
                // TODO: status should come from a configurable array...don't hard code this
                status: "unfinished",
                progressPercent: 0
            };
            return newTaskTemplateObject;
        },
        addSubCat(item) {
            // let tempCatObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.tempTask2DoCategoryTree);
            console.log("current Keypath: " + item.keyPath);
            this.cachedCategories = this.$arrayObjUtils.arrayObjsDeepCopy(
                this.task2DoUser.task2DoCategories
            );

            this.currentCategory = this.$arrayObjUtils.getNestedArrayObjectsByIndexKeyPath(
                this.task2DoUser.task2DoCategories,
                item.keyPath
            );
            console.log(
                "currentCategory before new cat pus: " +
                JSON.stringify(this.currentCategory, null, 2) +
                "\n"
            );
            let newNodeLevel = this.currentCategory.nodeLevel + 1;
            let newNodeIndex;

            if (!this.currentCategory.children) {
                this.currentCategory.children = [];
                // item.children = [];
                newNodeIndex = 0;
            } else {
                newNodeIndex = this.currentCategory.children.length;
            }

            let newCategoryTemplate = this.setupNewCategoryTemplateObject(
                "New Category",
                item.keyPath + "c",
                newNodeLevel,
                newNodeIndex
            );
            this.currentCategory.children.push(newCategoryTemplate);

            // if (!item.children) {
            //     item.children = [];
            //     // item.children = [];
            //     newNodeIndex = 0;
            // } else {
            //     newNodeIndex = item.children.length;
            // }
            // item.children.push(newCategoryTemplate);

            // console.log("New Category KeyPath: " + newCategoryTemplate.keyPath);

            // this.updateTasksAndCats();

            // item = this.$arrayObjUtils.arrayObjsDeepCopy(this.newSubCat);

            // set the newSubCat so we can edit the name
            this.currentCategory = this.$arrayObjUtils.getNestedArrayObjectsByIndexKeyPath(
                this.task2DoUser.task2DoCategories,
                newCategoryTemplate.keyPath
            );
            console.log(
                "\n-------------------------\ncurrentCategory after new cat push: " +
                JSON.stringify(this.currentCategory, null, 2) +
                "\n"
            );
            console.log(
                "\n-------------------------\nFull Category Array: " +
                JSON.stringify(this.task2DoUser.task2DoCategories, null, 2) +
                "\n"
            );

            //    this.newSubCat = item.children[newNodeIndex]; //this.$arrayObjUtils.getNestedArrayObjectsByIndexKeyPath(item, newCategoryTemplate.keyPath);

            this.textPropDialogObject = this.currentCategory;
            this.textPropDialogAction = "Create";

            this.openTextPropertyDialog(this.textPropDialogObject);

            //  this.updateTasksAndCats();
        },
        editTaskCat(item) {
            console.log("Item keypath: " + item.keyPath);
            this.currentCategory = this.$arrayObjUtils.getNestedArrayObjectsByIndexKeyPath(
                this.task2DoUser.task2DoCategories,
                item.keyPath
            );
            // this.textPropDialogObject = item;
            this.textPropDialogObject = this.currentCategory;
            this.textPropDialogAction = "Edit";
            // let catToEdit = this.$arrayObjUtils.getNestedArrayObjectsByIndexKeyPath(this.catsAndDos, item.keyPath);
            this.openTextPropertyDialog(item);
        },
        addTask(item) {
            console.log("Add Task method called");
            // let tempTask2Dos = this.$arrayObjUtils.arrayObjsDeepCopy(this.task2Dos);

            let taskTemplateObj = this.setupNewTaskTemplateObject(
                "New Task",
                item.id,
                item.keyPath,
                item.id
            );

            console.log(
                "Parent category id: " +
                item.id +
                "\nParent category KeyPath: " +
                item.keyPath +
                "\n"
            );

            this.taskFormAction = "Create";
            this.openTaskForm(taskTemplateObj, item.keyPath);

            console.log(
                "Current taskTemplateObj (app.vue):" +
                JSON.stringify(taskTemplateObj, null, 2)
            );

            // currentCategoryPath.children.push(taskTemplateObj);
            // item.children = currentCategoryPath;

            // item.children.push(taskTemplateObj);
        },
        updateChangedTextField(objectToEdit, updatedText) {
            // objectToEdit.name = updatedText;
            this.textPropDialogTextField = updatedText;
            // item.name = updatedText;
        },
        displayChangedTextField(objectToEdit, updatedText) {
            objectToEdit.name = updatedText;
        },
        updateName(updatedName) {
            // this.closeTextPropertyDialog();
            this.newSubCat.name = updatedName;

            localStorage.setItem(
                "task2DoCategories",
                JSON.stringify(this.task2DoUser.task2DoCategories, null, 2)
            );
        },
        updateTasksAndCats() {
            this.$arrayObjUtils.buildNestedArrayPaths(this.tempTask2DoCategoryTree);

            const tempCategories = this.$arrayObjUtils.arrayObjsDeepCopy(
                this.tempTask2DoCategoryTree
            );
            this.task2DoUser.task2DoCategories = tempCategories;

            const tempTasks2Do = this.$arrayObjUtils.arrayObjsDeepCopy(
                this.tempTask2Dos
            );
            this.task2Dos = tempTasks2Do;

            const tempCombinedTasksAndCats = this.$arrayObjUtils.arrayObjsDeepCopy(
                this.tempTask2DoCategoryTree
            );
            this.categorizeTasks(tempCombinedTasksAndCats);
            this.catsAndDos = tempCombinedTasksAndCats;
            console.log("catsAndDos: " + JSON.stringify(this.catsAndDos, null, 2));
        },
        commitTask2Do(task2DoObj) {
            console.log("THIS FUNCTION ONLY HAS THIS console.log call \n\tNew task2DO!: " + JSON.stringify(task2DoObj, null, 2));
        },
        openTextPropertyDialog(objectToEdit) {
            this.textPropDialogOriginalValue = objectToEdit.name;
            this.textPropDialogObject = objectToEdit;
            this.showTextPropDialog = true;
        },
        openTaskForm(task2DoEditObj, task2DoPath) {
            this.newTaskPath = task2DoPath;
            this.tempTask2DoFormObj = task2DoEditObj;
            this.showTaskForm = true;
        },
        closeTextPropDialog(objectToEdit, commitChanges) {
            // if (objectToEdit.name === "New Category") {
            //     console.log("Cancel new category - remove this object.");
            // }
            console.log(
                "textPropDialogOriginalValue: " + this.textPropDialogOriginalValue
            );
            if (this.textPropDialogTextField === "" || commitChanges === false) {
                // objectToEdit.name = this.textPropDialogOriginalValue;

                // revert to original obj before push
                this.task2DoUser.task2DoCategories = this.$arrayObjUtils.arrayObjsDeepCopy(
                this.cachedCategories
                );
            } else {
                console.log("textPropField: " + this.textPropDialogTextField);
                objectToEdit.name = this.textPropDialogTextField;
                console.log("objectToEdit " + JSON.stringify(objectToEdit, null, 2));

                localStorage.setItem(
                "task2DoCategories",
                JSON.stringify(this.task2DoUser.task2DoCategories, null, 2)
                );
                console.log("objectToEdit " + JSON.stringify(objectToEdit, null, 2));
                // this.tempTask2DoCategoryTree = this.$arrayObjUtils.arrayObjsDeepCopy(this.task2DoUser.task2DoCategories);
            }
            this.showTextPropDialog = false;
        },
        closeTaskForm(currentTaskObj, commitChanges) {
            console.log("Value of commitChanges: " + commitChanges + "\n");
            if (commitChanges === false) {
                this.showTaskForm = false;
            } else if (commitChanges === true && typeof currentTaskObj === "object") {
                this.task2DoUser.task2Dos.push(currentTaskObj);
                localStorage.setItem(
                "task2Dos",
                JSON.stringify(this.task2DoUser.task2Dos, null, 2)
                );
                /** commit changes to any task properties that have been modified to the task2Dos array (note:
                 * task2Dos is an array of objects. Changes to the task object will be pushed to the task2Dos
                 *  array., hence the need for the object conditional             */

                this.showTaskForm = false; /* <-- if this statement is executed in both conditions, guess what, you don't need it inside the if block ..I will be removeing this once I verify the correct date
                        is being passed back from the task form */
            }
        }
    }
};

    /**
     * The following functions should probably be put into a seperate JS file. The 4 functions are all related to
     * arrays and objects, so they would probably be a good start for a array/object helper library.
     *
     */
    Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000);
    return this;
    };
</script>
