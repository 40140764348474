import Vue from "vue";
import Vuetify, {
    VApp,
    VBtn,
    VCalendar,
    VCard,
    VCheckbox,
    VContent,
    VData,
    VDataTable,
    VDatePicker,
    VDialog,
    VFileInput,
    VForm,
    VIcon,
    VInput,
    VItemGroup,
    VLabel,
    VList,
    VMain,
    VMenu,
    VNavigationDrawer,
    VPicker,
    VRangeSlider,
    VSelect,
    VSheet,
    VSlider,
    VSnackbar,
    VSwitch,
    VSystemBar,
    VTextarea,
    VTextField,
    VTimeline,
    VToolbar,
    VTooltip,
    VTreeview,
    VWindow
} from 'vuetify/lib';

// import Vuetify from 'vuetify/lib';

// Vue.use(Vuetify);

Vue.use(Vuetify, {
    components: {
        VApp,
        VBtn,
        VCalendar,
        VCard,
        VCheckbox,
        VContent,
        VData,
        VDataTable,
        VDatePicker,
        VDialog,
        VFileInput,
        VForm,
        VIcon,
        VInput,
        VItemGroup,
        VLabel,
        VList,
        VMain,
        VMenu,
        VNavigationDrawer,
        VPicker,
        VRangeSlider,
        VSelect,
        VSheet,
        VSlider,
        VSnackbar,
        VSwitch,
        VSystemBar,
        VTextarea,
        VTextField,
        VTimeline,
        VToolbar,
        VTooltip,
        VTreeview,
        VWindow
    }
});

const opts = {
    theme: {
        dark: true
    }
};

export default new Vuetify(opts);
