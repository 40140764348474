import Vue from "vue";

const state = {   
    realtimeClockInterval: null,
    currentTimestamp: 0,
    currentTimestampDateObj: new Date(),
    minuteTimestamp: 0,
    minuteTimestampDateObj: new Date(),
    timeIntervalOptions: [
        {
            timeIntervalId: '0min',
            timeIntervalIndex: 0,
            timeIntervalLabel: '0 minutes',
            timeIntervalMinutesValue: 0
        },
        {
            timeIntervalId: '5min',
            timeIntervalIndex: 1,
            timeIntervalLabel: '5 minutes',
            timeIntervalMinutesValue: 5
        },
        {
            timeIntervalId: '10min',
            timeIntervalIndex: 2,
            timeIntervalLabel: '10 minutes',
            timeIntervalMinutesValue: 10
        },
        {
            timeIntervalId: '15min',
            timeIntervalIndex: 3,
            timeIntervalLabel: '15 minutes',
            timeIntervalMinutesValue: 15
        },
        {
            timeIntervalId: '20min',
            timeIntervalIndex: 4,
            timeIntervalLabel: '20 minutes',
            timeIntervalMinutesValue: 20
        },
        {
            timeIntervalId: '25min',
            timeIntervalIndex: 5,
            timeIntervalLabel: '25 minutes',
            timeIntervalMinutesValue: 25
        },
        {
            timeIntervalId: '30min',
            timeIntervalIndex: 6,
            timeIntervalLabel: '30 minutes',
            timeIntervalMinutesValue: 30
        },
        {
            timeIntervalId: '35min',
            timeIntervalIndex: 7,
            timeIntervalLabel: '35 minutes',
            timeIntervalMinutesValue: 35
        },
        {
            timeIntervalId: '40min',
            timeIntervalIndex: 8,
            timeIntervalLabel: '40 minutes',
            timeIntervalMinutesValue: 40
        },  
        {
            timeIntervalId: '45min',
            timeIntervalIndex: 9,
            timeIntervalLabel: '45 minutes',
            timeIntervalMinutesValue: 45
        },
        {
            timeIntervalId: '60min',
            timeIntervalIndex: 10,
            timeIntervalLabel: '60 minutes',
            timeIntervalMinutesValue: 60
        },
        {
            timeIntervalId: '75min',
            timeIntervalIndex: 11,
            timeIntervalLabel: '75 minutes',
            timeIntervalMinutesValue: 75
        }, 
        {
            timeIntervalId: '90min',
            timeIntervalIndex: 12,
            timeIntervalLabel: '90 minutes',
            timeIntervalMinutesValue: 90
        },
        {
            timeIntervalId: '120min',
            timeIntervalIndex: 13,
            timeIntervalLabel: '120 minutes',
            timeIntervalMinutesValue: 120
        },
    ],
};

const getters = {
/* 
getCurrentTimeObj: state => {
            
        // let timeArray = this.currentTime.split(':');
        // console.log('Current Time Array: ' + JSON.stringify(timeArray, null, 2));
        
        let curHour = new Date(state.currentTimestamp).getHours();
        let curMin = new Date(state.currentTimestamp).getMinutes();
        let curSec =  new Date(state.currentTimestamp).getSeconds();
        let timePeriod = (parseInt(curHour) < 12) ? 'AM' : 'PM';

        // let curHour = parseInt(timeArray[0]);
        // let curMin = parseInt(timeArray[1]);
        return {
            timestamp: state.currentTimestamp,
            hour: curHour,
            twelveHour: (parseInt(curHour) % 12 > 0) ? (parseInt(curHour) % 12) : 12,
            minutes: (parseInt(curMin) < 10) ? '0' + curMin : curMin,
            seconds: curSec,
            timePeriod: timePeriod
        }

    },
    */
    getCurrentTimeObj: state => {
        let curSec =  state.currentTimestampDateObj.getSeconds();
        curSec = (parseInt(curSec) < 10) ? '0' + curSec : curSec;
        let curMin =  state.currentTimestampDateObj.getMinutes();
        curMin = (parseInt(curMin) < 10) ? '0' + curMin : curMin;
        let curHour = state.currentTimestampDateObj.getHours();
        let timePeriod = (parseInt(curHour) < 12) ? 'AM' : 'PM';
        let twelveHour = (parseInt(curHour) % 12 > 0) ? (parseInt(curHour) % 12) : 12;
        
        return {
            'timestamp': state.currentTimestamp,
            'seconds': curSec,
            'minutes': curMin,
            'hour': curHour,
            'twelveHour': twelveHour,
            'timePeriod': timePeriod,
            'twelveHourTime': twelveHour + ':' + curMin + ' ' + timePeriod,
            'twelveHourTimeWithSeconds': twelveHour + ':' + curMin + ':' + curSec + ' ' + timePeriod,

        }
    },
    getCurrentTimestamp: state => state.currentTimestamp,
    getTopOfMinuteTimestamp: state => state.minuteTimestamp,
    getTopOfMinuteTimeObj: state => {
        let curSec =  state.minuteTimestampDateObj.getSeconds();
        curSec = (parseInt(curSec) < 10) ? '0' + curSec : curSec;
        let curMin =  state.minuteTimestampDateObj.getMinutes();
        curMin = (parseInt(curMin) < 10) ? '0' + curMin : curMin;
        let curHour = state.minuteTimestampDateObj.getHours();
        let timePeriod = (parseInt(curHour) < 12) ? 'AM' : 'PM';
        let twelveHour = (parseInt(curHour) % 12 > 0) ? (parseInt(curHour) % 12) : 12;
        
        return {
            'timestamp': state.minuteTimestampDateObj,
            'seconds': curSec,
            'minutes': curMin,
            'hour': curHour,
            'twelveHour': twelveHour,
            'timePeriod': timePeriod,
            'twelveHourTime': twelveHour + ':' + curMin + ' ' + timePeriod,
            'twelveHourTimeWithSeconds': twelveHour + ':' + curMin + ':' + curSec + ' ' + timePeriod,
        };
    },
    getTimeIntervalOptions: state => state.timeIntervalOptions,
};

const actions = {
    init( { dispatch, commit } ) {
        commit('SET_CURRENT_TIMESTAMP', Date.now());
        console.log('CONSTANTS: ' + JSON.stringify(Vue.CONSTANTS, null, 2));
        console.log('Miliseconds in CONSTANTS.ONE_DAY: ' + Vue.CONSTANTS.ONE_DAY);
        dispatch('initRealtimeClockInterval');

    },
    initRealtimeClockInterval( { dispatch, commit } ) {
        let tempIntervalId = null;
        if (!state.realtimeClockInterval) {
            tempIntervalId = setInterval(() => {
                dispatch('onRealtimeClockInterval');
            }, Vue.CONSTANTS.ONE_SECOND);
            commit('SET_REALTIME_CLOCK_INTERVAL', tempIntervalId);
     
        } else {
            dispatch('killRealtimeClockInterval');
            dispatch('initRealtimeClockInterval');

        }
    },
    async onRealtimeClockInterval( { dispatch, commit, getters, rootGetters }) {
        await commit('SET_CURRENT_TIMESTAMP', Date.now());
        // I want to add the time check interval at the top of every minute regaurdless of when the app initially runs
        if (parseInt(getters.getCurrentTimeObj.seconds) === 0) {
            await dispatch('newMinuteChecks');
            
        }
    },
    killRealtimeClockInterval( { commit } ) {
        clearInterval(state.realtimeClockInterval);
        commit('CLEAR_REALTIME_CLOCK_INTERVAL');

    },
    newMinuteChecks( { dispatch, commit, getters, rootGetters }) {
        commit('SET_MINUTE_TIMESTAMP', Date.now()); //getters.getCurrentTimeObj);
        // might put additional functionality here in future, 
        // instead of just doing a commit without an action
        dispatch('time/checkUpCommingEvents', null, { root: true } );
        console.log('rootGetters[\'userSettings/getUserPreferences\'].minuteIntervals.sayCurrentTime: ' + parseInt(rootGetters['userSettings/getUserPreferences'].minuteIntervals.sayCurrentTime));
        console.log('getters.getTopOfMinuteTimeObj.minutes: ' + getters.getTopOfMinuteTimeObj.minutes);
        if((parseInt(getters.getTopOfMinuteTimeObj.minutes) % parseInt(rootGetters['userSettings/getUserPreferences'].minuteIntervals.sayCurrentTime)) === 0) {
            
            dispatch('time/sayCurrentTime', null, { root: true } );
            dispatch('time/checkUpCommingEvents', null, { root: true } );
        }

        let jwtExpireTimeMiliseconds = parseInt(rootGetters['auth/getUserStateObj'].jwtExpireAt) * 1000;
        let milisecondsTillExpire = jwtExpireTimeMiliseconds - parseInt(rootGetters['time/getCurrentTimestamp']);
       
            
            let secondsTillExpire = (milisecondsTillExpire / 1000).toFixed(0);
            let minutesTillExpire = (secondsTillExpire / 60).toFixed(0);
            let timeToExpire = Math.floor(minutesTillExpire) + ' minutes ' + (secondsTillExpire % 60).toFixed(0) + ' seconds';
            console.log('CONSTANTS.TEN_MINUTES: ' + Vue.CONSTANTS.TEN_MINUTES + '\nmilisecondsTillExpire: ' + milisecondsTillExpire);
            console.log('JWT Expires in: ' + timeToExpire + '\n');

        if (milisecondsTillExpire < Vue.CONSTANTS.TEN_MINUTES) {
           
            console.log('!!!!!!! Less than 10 minutes till JWT expires! \n\t Expires in: ' + timeToExpire + '\n');
            console.log('Renewing token\n');
            const informationDialog = {
                'show': true,
                'title': 'JWT about to expire',
                'message': ' Expires in: ' + timeToExpire,
                'timestamp': Date.now(),
                'displayTime': 60000,
                'status': 'waitingForInput'
            };
            dispatch('uiState/updateInfoDialog', informationDialog, { root: true });
            
           
            dispatch('auth/renewToken', null, { root: true } );
            
           
        }

        console.log('TTTTTTTTTTTTTTTTTTTTTTT C h e c k   l o c a l   s t o r a g e   &   c o m p a r e   t i m e s t a m p s TTTTTTTTTTTTTTTTTTTT');
        console.log("\trootGetters['time/getCurrentTimestamp']: " + rootGetters['time/getCurrentTimestamp']);
        console.log("\tstate.userInfoObj.jwtExpireAt: " + jwtExpireTimeMiliseconds);
        
        
        if(parseInt(getters.getTopOfMinuteTimeObj.minutes) % parseInt(rootGetters['userSettings/getUserPreferences'].minuteIntervals.refreshApiData) === 0) {
           console.log('refreshing API data because the current time is divisible by refreshAPI time: ' + parseInt(rootGetters['userSettings/getUserPreferences'].minuteIntervals.refreshApiData));
            let isTokenValid = dispatch('auth/checkTokenStatus', null, { root: true } );
            if (isTokenValid && (!rootGetters['uiState/isDataEntryFormOpened'])) {
                dispatch('refreshDataFromAPI', null, { root: true });
            }
        }
        

    },
    checkUpCommingEvents( { dispatch, commit, getters, rootGetters } ) {
        console.log('checking upcomming events');
        rootGetters['tasks/getTodaysTasks'].forEach(currentTask => {
            let taskStartTime = new Date(currentTask.start).getTime();
            let taskEndTime = new Date(currentTask.end).getTime();
            
            let timeLeftForTask = new Date(taskEndTime - parseInt(rootGetters['time/getCurrentTimestamp'])).getTime();
            let timeTillNextTask = new Date(taskStartTime - parseInt(rootGetters['time/getCurrentTimestamp'])).getTime();
            let minTillNextTask = this._vm.$datedJS.dated.milliSec2Minute(timeTillNextTask);
            let minLeftForTask = this._vm.$datedJS.dated.milliSec2Minute(timeLeftForTask);

            let lower15minutebound = Vue.CONSTANTS.FIFTEEN_MINUTES - Vue.CONSTANTS.NINTY_SECONDS;
            let upper15minutebound = Vue.CONSTANTS.FIFTEEN_MINUTES + Vue.CONSTANTS.NINTY_SECONDS;

            let lower10minutebound = Vue.CONSTANTS.TEN_MINUTES - Vue.CONSTANTS.NINTY_SECONDS;
            let upper10minutebound = Vue.CONSTANTS.TEN_MINUTES + Vue.CONSTANTS.NINTY_SECONDS;

            let lower5minutebound = Vue.CONSTANTS.FIVE_MINUTES - Vue.CONSTANTS.NINTY_SECONDS;
            let upper5minutebound = Vue.CONSTANTS.FIVE_MINUTES + Vue.CONSTANTS.NINTY_SECONDS;

            let reminderNagIndex = this._vm.$jsUtils.randomNum(0, rootGetters['appData/getReminderData'].nags.length - 1);

            if (rootGetters['appData/getAudioData'].soundEnabled === true) {

                let activeElement = document.activeElement;
                
                // console.log("##########\nThe current active element is: " + activeElement.tagName + "\n and the activeElement.class: " + activeElement.className + "\n activeElement.value: " + activeElement.value + "\n"); // jSON HTMLelementObj: " + JSON.stringify(activeElement, null, 2));

                // if ((activeElement.tagName !== 'INPUT') && (activeElement.className !== 'v-dialog__content v-dialog__content--active')) {
                if (((activeElement.tagName === 'BODY') || (activeElement.tagName === 'DIV')) && (rootGetters['uiState/isDataEntryFormOpened'] === false)) {

                    if (((timeTillNextTask >= lower15minutebound) && (timeTillNextTask <= upper15minutebound)) ||
                        ((timeTillNextTask >= lower10minutebound) && (timeTillNextTask <= upper10minutebound)) ||
                        ((timeTillNextTask >= lower5minutebound) && (timeTillNextTask <= upper5minutebound))) {

                            const informationDialog = {
                                'show': true,
                                'title': '<span style="color: rgba(225, 0, 0, 1);">' + minTillNextTask + ' minutes </span> until next task!',
                                'message': '<span style="font-size: 1.25rem;"><strong style="color: rgba(225, 0, 0, 1); font-size: 1.5rem;">' + currentTask.name + ' </strong> is starting at <span style="color: rgba(225, 0, 0, 1); font-size: 1.5rem;">' + this._vm.$datedJS.dated.getTwelveHourTime(taskStartTime, false )  + '</span>.',
                                'timestamp': Date.now(),
                                'displayTime': 120000,
                                'status': 'waitingForInput'
                            };
                            dispatch('time/sayCurrentTime', null, { root: true } );
                            dispatch('uiState/updateInfoDialog', informationDialog, { root: true } );

                        // this.informationDialogTitle = ' <span style="color: rgba(225, 0, 0, 1);">' + minTillNextTask + ' minutes </span> until next task!';
                        // this.informationDialogTimestamp = "It's " + this._vm.$datedJS.dated.getTwelveHourTime(parseInt(rootGetters['time/getCurrentTimestamp']), false );

                        // this.informationDialogMessage = '<span style="font-size: 1.25rem;"><strong style="color: rgba(225, 0, 0, 1); font-size: 1.5rem;">' + currentTask.name + ' </strong> is starting at <span style="color: rgba(225, 0, 0, 1); font-size: 1.5rem;">' + this._vm.$datedJS.dated.getTwelveHourTime(taskStartTime, false );  + '</span>.';

                        // this.showInformationDialog = true;

                        let notificationSoundHTML = '<source src="'  + 'https://task2do.co/' + rootGetters['appData/getReminderData'].nags[reminderNagIndex].sound + '" type="audio/mpeg">';
                        document.getElementById("notificationSound").innerHTML = '<audio autoplay="autoplay">' + notificationSoundHTML + "</audio>";
                        console.log('playing audio: ' + rootGetters['appData/getReminderData'].nags[reminderNagIndex].sound);
                    }

                    if (((timeLeftForTask >= lower15minutebound) && (timeLeftForTask <= upper15minutebound)) ||
                        ((timeLeftForTask >= lower10minutebound) && (timeLeftForTask <= upper10minutebound)) || 
                        ((timeLeftForTask >= lower5minutebound) && (timeLeftForTask <= upper5minutebound))) {

                        // this.informationDialogTitle = currentTask.name + ' is ending in ' + minLeftForTask + ' minutes!';
                        // this.informationDialogTitle = 'You have <strong style="color: rgba(225, 0, 0, 1);">' + minLeftForTask + ' minutes</strong> to finish current task';
                        // this.informationDialogTimestamp = "It's " + this._vm.$datedJS.dated.getTwelveHourTime(parseInt(rootGetters['time/getCurrentTimestamp']), false );
                        // this.informationDialogMessage = '<span style="font-size: 1.25rem;">You should be finishing <strong  style="font-size: 1.5rem; font-weight: 900; color: rgb(250, 250, 250);" >' + currentTask.name + ' </strong> by <span style="font-size: 1.5rem; color: rgba(225, 0, 0, 1);">' + this._vm.$datedJS.dated.getTwelveHourTime(currentTask.end, false )  + ' minutes</span>.';
                        // this.showInformationDialog = true;


                        const notificationMessage = {
                            'show': true,
                            'title': 'You have <strong style="color: rgba(225, 0, 0, 1);">' + minLeftForTask + ' minutes</strong> to finish current task',
                            'message': '<span style="font-size: 1.25rem;">You should be finishing <strong  style="font-size: 1.5rem; font-weight: 900; color: rgb(250, 250, 250);" >' + currentTask.name + ' </strong> by <span style="font-size: 1.5rem; color: rgba(225, 0, 0, 1);">' + this._vm.$datedJS.dated.getTwelveHourTime(currentTask.end, false )  + ' minutes</span>.',
                            'timestamp': Date.now(),
                            'displayTime': 60000,
                            'status': 'waitingForInput'
                        };
                        dispatch('time/sayCurrentTime', null, { root: true } );
                        dispatch('uiState/addNotification', notificationMessage, { root: true } );

                        
                        let notificationSoundHTML = '<source src="' + rootGetters['appData/getReminderData'].nags[reminderNagIndex].sound + '" type="audio/mpeg">';
                        document.getElementById("notificationSound").innerHTML = '<audio autoplay="autoplay">' + notificationSoundHTML + "</audio>";
                        console.log('playing audio: ' + rootGetters['appData/getReminderData'].nags[reminderNagIndex].sound);
                    }
                } else {
                    console.log('An input field has focus, waiting to show notification');
                }
            }
        });
    },
    async getAudioFile(audioContext, filePath) {
        const response = await fetch(filePath);
        const arrayBuffer = await response.arrayBuffer();
        // NOTE: A callback has been added here as a second parameter for Safari only (https://github.com/mdn/webaudio-examples/blob/master/step-sequencer/index.html)
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer, function() { return } );
        return audioBuffer;
    },
    playSample(audioContext, audioBuffer, time) {
        const sampleSource = audioContext.createBufferSource();
        sampleSource.buffer = audioBuffer;
        sampleSource.playbackRate.value = 1;
        sampleSource.connect(audioContext.destination);
        sampleSource.start(time);



    },
    async sayCurrentTime( {dispatch, commit, getters, rootGetters}) {
        if (rootGetters['appData/getAudioData'].soundEnabled === true) {
            let curMin = this._vm.$datedJS.dated.getCurrMin();
            let curHour = this._vm.$datedJS.dated.getCurrHour();
            let AMorPM = '';

            if (curHour < 12) {
                AMorPM = 'audio/time-audio/AM2.mp3'
            } else {
                AMorPM = 'audio/time-audio/PM2.mp3'
                curHour = curHour % 12;
            }

            // let notificationSoundAudioElement = document.getElementById("notificationSound");
            let hourAudioURL = 'http://206.189.187.200:8080/' + rootGetters['appData/getAudioData'].audioTimeHourArray[curHour];
            let minuteAudioURL = 'http://206.189.187.200:8080/' + rootGetters['appData/getAudioData'].audioTimeMinArray[curMin];
            let meridiamAudioURL = 'http://206.189.187.200:8080/' + AMorPM;

            const audioCtx = window.AudioContext ? new AudioContext() : new webkitAudioContext();

            const hourResponse = await fetch(hourAudioURL);
            const hourArrayBuffer = await hourResponse.arrayBuffer();

            // NOTE: A callback has been added here as a second parameter for Safari only (https://github.com/mdn/webaudio-examples/blob/master/step-sequencer/index.html)

            const decodedHourArrayBuffer = await audioCtx.decodeAudioData(hourArrayBuffer, function() { return } );

            console.log('\n\decodedHourArrayBuffer data type: ' + typeof(decodedHourArrayBuffer));
            // Say minutes unless it's the top of the hour
         
            let mergedAudio = null;

            if (curMin !== 0) {
                // audioBuffer = [...decodedHourArrayBuffer, ...decodedMinuteArrayBuffer];
                // audioBuffer = Object.assign({}, decodedHourArrayBuffer, decodedMinuteArrayBuffer);
                const minuteResponse = await fetch(minuteAudioURL);
                const minuteArrayBuffer = await minuteResponse.arrayBuffer();
                const decodedMinuteArrayBuffer =  await audioCtx.decodeAudioData(minuteArrayBuffer, function() { return } );
                mergedAudio = audioCtx.createBuffer(2, decodedHourArrayBuffer.length + decodedMinuteArrayBuffer.length, decodedHourArrayBuffer.sampleRate);

                for (let i=0; i < mergedAudio.numberOfChannels; i++) {
                    let data = mergedAudio.getChannelData(i);
                    data.set(decodedHourArrayBuffer.getChannelData(i));
                    data.set(decodedMinuteArrayBuffer.getChannelData(i), decodedHourArrayBuffer.length);

                }


            } else {
                // audioBuffer = [...decodedHourArrayBuffer, ...decodedMeridianArrayBuffer];
                const meridianResponse = await fetch(meridiamAudioURL);
                const meridianArrayBuffer = await meridianResponse.arrayBuffer();
                const decodedMeridianArrayBuffer = await audioCtx.decodeAudioData(meridianArrayBuffer, function() { return } );

                mergedAudio = audioCtx.createBuffer(2, decodedHourArrayBuffer.length + decodedMeridianArrayBuffer.length, decodedHourArrayBuffer.sampleRate);

                for (let i=0; i < mergedAudio.numberOfChannels; i++) {
                    let data = mergedAudio.getChannelData(i);
                    data.set(decodedHourArrayBuffer.getChannelData(i));
                    data.set(decodedMeridianArrayBuffer.getChannelData(i), decodedHourArrayBuffer.length);

                }
            }
            
            console.log('\n\decodedHourArrayBuffer data type: ' + typeof(decodedHourArrayBuffer));

            if (audioCtx.state === 'suspended') {
                audioCtx.resume();
            }

            const sampleSource = audioCtx.createBufferSource();

            sampleSource.buffer = mergedAudio;

            sampleSource.connect(audioCtx.destination);

            let samplePlayTime = 0.0;

            sampleSource.start(samplePlayTime);
    
            /*
                let notificationSoundHTML = '<source src="' + 'https://task2do.co/' + rootGetters['appData/getAudioData'].audioTimeHourArray[curHour] + '" type="audio/mpeg">';
                
                notificationSoundElement.innerHTML = `<audio autoplay="autoplay">${notificationSoundHTML}</audio>`;
                if (curMin !== 0) {
                    setTimeout(() => {
                        notificationSoundHTML = '<source src="' + 'https://task2do.co/' + rootGetters['appData/getAudioData'].audioTimeMinArray[curMin] + '" type="audio/mpeg">';
                        notificationSoundElement.innerHTML = '<audio autoplay="autoplay">' + notificationSoundHTML + "</audio>";
                    
                        // setTimeout(() => {
                        //     notificationSoundHTML = '<source src="' + AMorPM + '" type="audio/mpeg">';
                        //     notificationSoundElement.innerHTML = '<audio autoplay="autoplay">' + notificationSoundHTML + "</audio>";
                    
                        // }, 925);
                    }, 575);
                } else {
                        setTimeout(() => {
                        notificationSoundHTML = '<source src="'  + 'https://task2do.co/' + AMorPM + '" type="audio/mpeg">';
                        notificationSoundElement.innerHTML = '<audio autoplay="autoplay">' + notificationSoundHTML + "</audio>";
                
                    }, 575);
                }
            */

        }
    }
};

const mutations = {
    /**   setRealtimeClockInterval(state) { //, callback) {
            state.realtimeClockInterval = setInterval(() => {
                // callbacks should not be called within mutation because it 
                // CANNOT be guaranteed that they will be snycronos
                // if (callback) {
                //     callback();
                // }

                state.currentTimestamp = Date.now();
            }, CONSTANTS.ONE_SECOND);
        },
    */ 
    SET_REALTIME_CLOCK_INTERVAL: (state, intervalId) => state.realtimeClockInterval = intervalId,
    SET_CURRENT_TIMESTAMP: (state, timestamp) => {
        state.currentTimestamp = timestamp;
        state.currentTimestampDateObj = new Date(timestamp);

    },
    CLEAR_REALTIME_CLOCK_INTERVAL: (state) => {
        if (state.realtimeClockInterval !== null) {
            state.realtimeClockInterval = null;
        }
    },
    SET_MINUTE_TIMESTAMP: (state, minuteTimestamp) => {
        state.minuteTimestamp = minuteTimestamp;
        state.minuteTimestampDateObj = new Date(minuteTimestamp);
        
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  