<template>
  <v-sheet>
    <!-- <v-layout text-center wrap> -->
    <v-row>
      <v-col align-self="start">
        <!-- <v-flex xs12>
                <v-img
                    :src="require('../assets/Task2Do_Logo_2020-03-09_750x150.png')"
                    class="my-3"
                    contain
                    height="200"
                ></v-img>
            </v-flex> -->

        <v-spacer></v-spacer>
        <v-flex mb-12 xs12>
          <img
            class="logo750px"
            alt="Task2DO! logo"
            src="../assets/Task2Do_Logo_2020-03-09_750x150.png"
          />
          <h3 class="mb-6">
            <span class="roboto-font-family" v-html="msg"> </span>
          </h3>
        </v-flex>
      </v-col>
    </v-row>
    <!-- </v-layout> -->
    <v-row>
      <v-col align-self="center">
        <v-flex mb-12 md12>
          <v-spacer></v-spacer>
          <p>Login or Register to create an account if your New To Task2DO!</p>

          <v-layout justify-center>
            <span v-if="isLoggedIn">
              <v-btn
                class="v-btn--outlined TM__blue-glowing-outline navbar-button"
                @click="logout"
                >Logout</v-btn
              >
            </span>
            <span
              v-if="!isLoggedIn"
              style="font-variant: small-caps; font-weight: 700;"
            >
              <v-btn
                class="v-btn--outlined TM__blue-glowing-outline navbar-button"
                @click="login"
                >Login</v-btn
              >

              <v-btn
                class="v-btn--outlined TM__blue-glowing-outline navbar-button"
                @click="register"
                >Register</v-btn
              >
            </span>

            <v-menu v-if="!isLoggedIn" left bottom>
              <template>
                <!--  v-slot:activator="{ on }"> -->
                <v-btn icon>
                  <!-- v-on="on"> -->
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="n in 5" :key="n" @click="() => {}">
                  <v-list-item-title>Option {{ n }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-layout>
        </v-flex>
      </v-col>
    </v-row>

    <v-row>
      <v-col align-self="end">
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row>
      <v-col align-self="end">
        <v-flex md12 mb-5>
          <v-layout justify-center>
            <a
              v-for="(link, i) in importantLinks"
              :key="i"
              :href="link.href"
              class="subheading mx-3"
              target="_blank"
            >
              {{ link.text }}
            </a>
          </v-layout>
        </v-flex>
      </v-col>
    </v-row>
    <v-row>
      <v-col align-self="end">
        <div class="app-title">
          <h1 class="mb-3"><span>Task</span><span>2</span><span>DO!</span></h1>
          <p>Don't Stew...DO!</p>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
  <!-- </v-container> -->
</template>

<script>
export default {
  name: "HelloDoer",
  props: ["isLoggedIn", "login", "logout", "register", "msg"],
  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader"
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify"
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify"
      }
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com"
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify"
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify"
      }
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer"
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/layout/pre-defined"
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions"
      }
    ]
  })
};
</script>
