<template>
  <div class="text-center">
    <v-snackbar
      v-model="displaySnackbar"
        :bottom="y === 'bottom'"
        :left="x === 'left'"
        :right="x === 'right'"
        :top="y === 'top'"
        :timeout="timeout"
        color="snackbarColor"
          >
    <span v-html="snackbarText"></span>
      <!-- {{ snackbarText }} -->
      <template v-slot:action="{ attrs }">
        <v-btn
          color="snackbarColor"
          text
          v-bind="attrs"
          @click="displaySnackbar(false)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
    props: [ "showSnackbar",
            "snackbarText",
            "snackbarColor" ],
    data: () => ({
        snackbar: false,
        timeout: 10000,
        text: '',
        x: null,
        y: 'top'
    }),
    computed: {
        displaySnackbar: {
            get: function () {
                return this.showSnackbar;
            },
            set: function (valueToSet) {
                return this.$emit("updateSnackbarCondition", valueToSet);

                // return this.snackbar = valueToSet;
            }
        },

            // if(this.showSnackbar !== true) {
            //     return this.snackbar = false;

            // } else {
            //     console.log("showSnackbar = true");
            //     return this.snackbar = true;

            // }
        // },
        getSnackbarText() {
            if(this.snackbarText) {
                return this.snackbarText ;
            } else {
                return 'No Message provided!'
            }
        }
        
    },
    created: function() {
        //this.showSnackbar = this.displaySnackbar;
        //this.text = this.getSnackbarText();
        
    },

}
</script>