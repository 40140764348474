import Vue from "vue";
import axios from "axios";
// import store from "../../store";

import * as arrayObjUtils from "../../lib/arrayObjUtils.js";


const state = {   
    preferences: {
        userInfo: {
            firstName: '',
            lastName: '',
            screenName: '',
            Addresses: [
                {
                    id: 'homeAddress',
                    name: 'home',
                    streetLine1: '',
                    streetLine2: '',
                    city: '',
                    state: '',
                    zip: ''
                }

            ],
            contactInfo: {
                email: [
                    {
                        name: '',
                        address: '',
                        description: ''
                    }
                ],
                phone: [
                    {
                        name: '',
                        number: '',
                        description: ''
                    }
                ],
                social: [
                    {
                        name: '',
                        url: '',
                        description: ''
                    }
                ]
            }

        },
        minuteIntervals: {
            sayCurrentTime: 5,
            checkNotificationQue: 1,
            refreshApiData: 10,
            reminders: []
        }
    },
    screenName: '',
    addressBook: [
        {
            firstName: '',
            middleName: '',
            lastName: '',
            businessName: '',
            sdtreetAddress1: '',
            streetAddress2: '',
            city: '',
            state: '',
            zip: '',
            contactInfo: {
                email: [
                    {
                        name: '',
                        address: '',
                        description: ''
                    }
                ],
                phone: [
                    {
                        name: '',
                        number: '',
                        description: ''
                    }
                ],
                social: [
                    {
                        name: '',
                        url: '',
                        description: ''
                    }
                ]
            },
            notes: ''
        }
    ],
    displaySettings: {
        background: {
            backgroundSelection: 'monthly', //"user", //"monthly", //"user" is other valid option
            currentBackgroundImage: 'var(--task2do-logo-svg)',
            makeItDark: true,
            blurrBackground: false,
            backgroundImageType: 'static',
            animatedBackground: false,
            userAcceptedAnimationRisk: false,
            animatedMedia: {
                animationSpeed: 0.5,
                animatedMediaSrc:
                'http://cdn.trinix.io/media/vid/cccc/TrinixMedia-Conceptual-Cloud-animation_inc-gamma_orig-speed_hqdn3d-4433_2020-01-07_004_768x432.mp4'
            },
            staticImages: {
                imageRotationFrequencies: {
                    second: 1,
                    minute: 60,
                    quarterPast: 900,
                    halfPast: 1800,
                    quarterTo: 2700,
                    hour: 3600,
                    day: 86400
                },
                rotateStaticImageFrequency: {
                    daily: false,
                    weekly: false,
                    monthly: true,
                    custom: false,
                    never: false
                },
                remoteImageBasePath: '../', //'http://task2do.co/img/',
                userImages: [],
                userImage: '../backgrounds/user/TrinixMedia_neon-off_black-back_reflection-fadeout_1080p_2020-04-17-TOP.png', //'backgrounds/user/May-dark.jpeg',
                monthlyImagesPortrait: [
                    // first element in monthImages is just place holder so months correlate with array indicies
                    'backgrounds/user/TrinixMedia_neon-off_black-back_reflection-fadeout_1080p_2020-04-17-TOP.png',
                    'backgrounds/months/January-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/February-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/March-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/April-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/May-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/June-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/July-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/August-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/September-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/October-dark_02_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/November-dark_720x1280_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/December-dark_720x1280_qlty-75_smooth-50.jpeg'
                ],
                monthlyImagesLandscape: [
                    // first element in monthImages is just place holder so months correlate with array indicies
                    'backgrounds/user/TrinixMedia_neon-off_black-back_reflection-fadeout_1080p_2020-04-17-TOP.png',
                    'backgrounds/months/January-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/February-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/March-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/April-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/May-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/June-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/July-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/August-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/September-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/October-dark_02_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/November-dark_1280x720_qlty-75_smooth-50.jpeg',
                    'backgrounds/months/December-dark_1280x720_qlty-75_smooth-50.jpeg'
                ],
                monthlyGradients: [
                    'linear-gradient(180deg, rgba(20, 20, 20, .985) 0%, rgba(125, 125, 125, .985) 25%, rgba(220, 220, 220, 0.995) 50%, rgba(225, 225, 225, 0.95) 75%, rgba(95, 95, 95, .95) 80%, rgba(50, 50, 50, .95) 100%)',
                    'linear-gradient(180deg, rgba(20, 20, 50, .985) 0%, rgba(125, 125, 200, .985) 25%, rgba(200, 235, 250, 0.995) 50%, rgba(200, 225, 250, 0.95) 75%, rgba(75, 95, 105, .95) 80%, rgba(50, 75, 100, .95) 100%)',
                    'linear-gradient(180deg, rgba(50, 20, 20, .985) 0%, rgba(200, 125, 130, .985) 25%, rgba(240, 235, 225, 0.995) 50%, rgba(240, 175, 175, 0.95) 75%, rgba(100, 75, 75, .95) 80%, rgba(100, 50, 55, .95) 100%)',
                    'linear-gradient(180deg, rgba(20, 50, 20, .985) 0%, rgba(125, 200, 120, .985) 25%, rgba(200, 250, 200, 0.995) 50%, rgba(200, 250, 225, 0.95) 75%, rgba(50, 100, 75, .95) 80%, rgba(25, 100, 50, .95) 100%)',
                    'linear-gradient(180deg, rgba(40, 40, 20, .985) 0%, rgba(200, 200, 120, .985) 25%, rgba(250, 235, 200, 0.995) 50%, rgba(250, 225, 200, 0.95) 75%, rgba(115, 100, 75, .95) 80%, rgba(75, 75, 50, .95) 100%)',
                    'linear-gradient(180deg, rgba(20, 20, 90, .985) 0%, rgba(100, 100, 200, .985) 25%, rgba(200, 2200, 250, 0.995) 50%, rgba(200, 200, 250, 0.95) 75%, rgba(75, 75, 105, .95) 80%, rgba(25, 25, 75, .95) 100%)',
                    'linear-gradient(180deg, rgba(20, 20, 50, .985) 0%, rgba(125, 125, 200, .985) 25%, rgba(200, 235, 250, 0.995) 50%, rgba(200, 225, 250, 0.95) 75%, rgba(75, 95, 105, .95) 80%, rgba(50, 75, 100, .95) 100%)',

                ]
            }

        },
        availableDashboardViews: [
        {
            id: 'widgetSpinner',
            name: 'Widget Spinner',
            description: 'Weeeee...Look at the widgets spin!',
            performance: 'High CPU usage'
        },
        {
            id: 'dynamicGrid',
            name: 'Dynamic Grid',
            description:
            'A responsive grid layout that allows dynamic placement of widgets via drag-n-drop',
            performance: 'Moderate CPU usage'
        },
        {
            id: 'strictlyBusiness',
            name: 'Strictly Business',
            description: 'A dashboard that strips away the flashy visuals and focuses stricktly on productivity. Being this is a TODO geared towards the OCD/ADHD crowds, this is probably the best option. Flashy animations, super-sleek and awesome visuals will actually be counter productive given the target demographic is more suceptible to goldfish / little birdy syndrom (ie: we are very easliy distracted). This loss of focus will actually hinder to apps goal...Shit, I did\'t expect to write this, but half way through the first sentence I realized that these neato tranitions and highly customized componets don\'t provide a value add in terms of productivity. Now I have to make a use case for my awesome visuals that I have on my app that...at this preasent time...DOES NOT ACTUALLY FUNCTION. So, yeah I think I made my point. Now quit distracting ME you two! Yes I\'m...err...not I...ME is looking at you...uh...no...actualy the 2 holligans that call them selfs MYSELF and I. OK, now that I confirmed that I\'ve been designing this ASS-BACKWARDS, I need to FOCUS on FUNCTIONALITY...GET IT WORKING...THEN ADD YOUR LIPSTICK...TRY HARDER!!! NOW GET BACK TO WORK!!!',
            performance:
            'GOOD, you\'ll actually have a chace at getting something done. [Light CPU usage]'
        }
        ],
        currDashboard: 'widget-spinner',
        componentsDisplayState: {
            CalendarWidget: true,
            BlogsWidget: false,
            showAppSettingsWindow: false,
            openWidgets: [ 'CalendarWidget' ],
            navDrawer: {
                open: false,
                widgetsSubmenuOpen: false
            },
            focusedWidget: 'CalendarWidget'
        }           
    },
    preferencesSavedToAPI: false
};

const getters = {
    t2doDisplaySettings: state => state.displaySettings,
    getCurrentBackgroundImage: state => state.displaySettings.background.currentBackgroundImage,
    getUserPreferences: state => state.preferences,
    t2doAllSettings: state => state,
};

const actions = {

    initUserSettings( { commit, rootGetters } ) {
        let reminderIntervalsArr = [
            Vue.CONSTANTS.FIVE_MINUTES,
            Vue.CONSTANTS.TEN_MINUTES,
            Vue.CONSTANTS.FIFTEEN_MINUTES,
            Vue.CONSTANTS.THIRTY_MINUTES,
            Vue.CONSTANTS.FOURTY_FIVE_MINUTES,
            Vue.CONSTANTS.ONE_HOUR,
            Vue.CONSTANTS.ONE_DAY
        ];
        commit('userSettings/INIT_REMINDER_INTERVALS', { reminderIntervalsArr }, { root: true } );

       
    },
    async putUserPreferences({ commit, rootGetters }){
        console.log('why are you putting preferences?');
        let stateJSON = {};
        if (state.preferences) {
            // stateJSON['preferences'] = state.preferences;
            stateJSON['preferences'] = JSON.stringify(state.preferences, null, 2);
            console.log("Preferences to be saved to API: " + stateJSON);
        }    
        return await new Promise((resolve, reject) => {
            Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            axios({
                url: 'https://ts-api.t2do.io/t2do-api/user/preferences/' + rootGetters['auth/getCurrentUserId'],
                method: 'PUT',
                data: stateJSON
            }).then(response => {
                resolve(response);
                console.log(`API user preferences update response  ${ JSON.stringify(response.data, null, 2) }\n`);
                commit('SAVED_PREFERENCES_TO_API');
                
            // }).then(fetchResponse => {
            //     resolve(fetchResponse);
            //     console.log(`Update Task2Do fetchResponse ${ JSON.stringify(fetchResponse, null, 2)}\n`);               
            //     this.dispatch('fetchTasks2Do');
            })
            .catch(function(err) {
                console.log("Error putting preferences: " + err.repsonse);
                let statusObj =  { statusCode: err, status: err, name: err.name, message: err.message};

                commit("AUTH_ERROR", statusObj);
                // localStorage.removeItem("jwt");
           
                reject(statusObj);

            });
        });    

    },
    async fetchUserPreferences({ dispatch, commit, getters, rootGetters }) {
        return await new Promise(function(resolve, reject) {
            if(!rootGetters['auth/getCurrentUserId']) {
                // throw ({ data: "Username not defined", status: 402});
                let statusObj =  { statusCode: 401, status: 'failed-to-fetch-user-preferences-data-from-api', name: 'user-not-set', message: 'User has not been set'};
                dispatch('auth/authError', statusObj, { root: true } );
                reject(statusObj);
                // rootGetters.dispatch("logoutUser").then(() => {
                //     this.$router.push("/login");
                //     });
            }
            Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + rootGetters['auth/getCurrentJwt'];
            Vue.prototype.$http({
                url: 'https://ts-api.t2do.io/t2do-api/user/preferences/' + rootGetters['auth/getCurrentUserId'],
                method: "GET"
                })
                .then(function(response) {
                    // let typecastedResponse = typecastTasks2DoResponse(response.data.user_data);
                    let responseData = response['data'];
                    let userPreferences = responseData['user_data']['preferences'];
                    // console.log(" userPreferences: " + JSON.stringify(userPreferences, null, 1));
                    for(let i = 0; i < userPreferences.frequentInputFields.length; i++) {
                        userPreferences.frequentInputFields[i]['index'] = i;
                    }

                    // console.log(" response.data.user_data: " + JSON.stringify(responseData, null, 2));
                            
                    commit('SET_USER_PREFERENCES',  userPreferences);
                    // commit('setTasks2Do', response.data.task_data);
                    // console.log(" response.data.user_data: " + JSON.stringify(state.preferences, null, 2) );
                    resolve(response);
                })
                .catch(function(err) {
                    console.log("Error fetching preferences: " + err.repsonse);
                    let statusObj =  { statusCode: err, status: err, name: err.name, message: JSON.stringify(err, null, 1)};
                    dispatch('auth/authError', statusObj, { root: true } );

                    reject(statusObj);
                });
            });
    },
    toggleMakeItDark({ commit }) {
        commit('TOGGLE_MAKE_IT_DARK');
    },
    async updateMonthlyBackgroundImage( { dispatch, commit, getters, rootGetters }, { curMonth = this._vm.$datedJS.dated.getCurMonth(), xPosition = "center", yPosition = "center", backgroundColor = "transparent", backgroundSize = "cover" } ){
        if (typeof(curMonth) === 'string') {
            
            await commit('SET_CURRENT_BACKGROUND_IMAGE', curMonth);
        
        } else {
            if (rootGetters['uiState/getWindowDimensions'].ratio > 1) {
                commit('SET_CURRENT_BACKGROUND_IMAGE', 'url(' + state.displaySettings.background.staticImages.remoteImageBasePath + state.displaySettings.background.staticImages.monthlyImagesLandscape[curMonth] + ') no-repeat center');
                document.documentElement.style.setProperty('--background-position-y', 'center');
            } else {
                commit('SET_CURRENT_BACKGROUND_IMAGE', 'url(' + state.displaySettings.background.staticImages.remoteImageBasePath + state.displaySettings.background.staticImages.monthlyImagesPortrait[curMonth] + ') no-repeat center');
                document.documentElement.style.setProperty('--background-position-y', 'center');
            }
           
            // await commit('SET_CURRENT_BACKGROUND_IMAGE', state.displaySettings.background.staticImages.monthlyGradients[curMonth]);
            
        }
        
        document.documentElement.style.setProperty('--background-position-x', xPosition);
        document.documentElement.style.setProperty('--background-size', backgroundSize);
        document.documentElement.style.setProperty('--background-color', backgroundColor);
        document.documentElement.style.setProperty('--monthly-background-image', state.displaySettings.background.currentBackgroundImage);
        
        window.console.log('argument passed to updateMonthlyBackgroundImage: ' + curMonth);

        window.console.log('Month Image updated await result: ' + state.displaySettings.background.currentBackgroundImage);

    },


};

const mutations = {
    INIT_REMINDER_INTERVALS: (state, { reminderIntervalsArr }) => state.preferences.minuteIntervals.reminders = reminderIntervalsArr,
    TOGGLE_MAKE_IT_DARK: (state) => state.displaySettings.background.makeItDark = !state.displaySettings.background.makeItDark,
    SAVED_PREFERENCES_TO_API(state) {
        state.preferencesSavedToAPI = true;

    },
    SET_USER_PREFERENCES(state, preferences) {
        // state.preferences = preferences;

        Object.assign(state.preferences, preferences);


    },
    SET_CURRENT_BACKGROUND_IMAGE(state, backgroundImage) {
        state.displaySettings.background.currentBackgroundImage = backgroundImage;
        
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
