/***********************************************************************************************************************
 *                       Trinix Computer Systems - Event Listener and Input Handler
 *                                       2019 (c) Trinix Computer Systems
 *
 *                                           D A T E D . j s
 *                           D A T E   C A L C U L A T I O N S   L I B R A R Y
 *                                         Development Version
 *
 * Created by: Brandon Reilly on 6/1/2019
 * Updated on: 6/1/2019 @ 1:29
 * Updated on: 2020/4/2 @ 4:35AM
 *
 * This JS library will provide various helper functions when dealing with date in JS
 *
 ***********************************************************************************************************************/

import { CodeNode } from "source-list-map";

export const dated = [];

dated.rightNow = new Date();
dated.currentDT = new Date();
dated.fromDate = new Date();
dated.toDate = new Date();


// dated.toDate.US = '01/01/1970';
// dated.fromDate.US = '01/01/1970';
dated.toDateUS = "01/01/1970";
dated.fromDateUS = "01/01/1970";

dated.milsDiff = 0;
dated.secsDiff = 0;
dated.minsDiff = 0;
dated.hMinDiff = 0; //hMin is minute in hour (of hoursDiff), not total number of minutes passed
dated.hoursDiff = -0;
dated.dHourDiff = 0; //dHour is hour in day (of daysDiff), not total number of hours
dated.daysDiff = 0;
dated.monthsDiff = 0;
dated.yearsDiff = 0;

// Current time
dated.currentHour = 0;

/***********************************************************************************************************************
 *                                     dated.js variable length time units
 * These time durations can vary significantly depending on the use case.
 * For example, hours in a work day varies widly. I can literally be any amount of time, but 8 and 12 hour shifts are popular durations.
 * The default values will be the most popular values for that given time frame, but it's up to the user to set these values accordingly
 * since other related variables are calculated based off these base values. E.g: halfWorkDay = fullWorkDay / 2
 ************************************************************************************************************************/
dated.fullWorkDayHours = 8;
dated.workWeekDays = 5;
dated.weekendDays = 7 - dated.workWeekDays;

(dated.halfWorkDaySec = (dated.fullWorkDayHours / 2) * 3600),
  (dated.fullWorkDaySec = dated.fullWorkDayHours * 3600),
  /***********************************************************************************************************************
 *                                          dated Lookup Tables
/***********************************************************************************************************************/

  // Define periods of time in seconds:
  (dated.inSeconds = {
    second: 1,
    minute: 60,
    quarterPast: 900,
    halfPast: 1800,
    quarterTo: 2700,
    hour: 3600,
    day: 86400,
    twoDayWeekEnd: 169200,
    threeDayWeekEnd: 259200,
    fiveDays: 432000,
    week: 604800,
    twoWeeks: 1209600
    // month: 2592000, <-- not correct, months vary in time
    // quarterYear: 10368000,
    // halfYear: 15552000,
    // year: 31104000
    // year: 31536000,
    // thirdQuarter:
  });

  (dated.inMilliSeconds = {
    second: 1000,
    thirtySeconds: 30000,
    minute: 60000,
    nintySeconds: 90000,
    fiveMinutes: 300000,
    tenMinutes: 600000,
    fifteenMinutes: 900000,
    thirtyMinute: 1800000,
    quarterPast: 900000,
    halfPast: 1800000,
    quarterTo: 2700000,
    hour: 3600000,
    day: 86400000,
    twoDayWeekEnd: 169200000,
    threeDayWeekEnd: 259200000,
    fiveDays: 432000000,
    week: 604800000,
    twoWeeks: 1209600000
    // month: 2592000, <-- not correct, months vary in time
    // quarterYear: 10368000,
    // halfYear: 15552000,
    // year: 31104000
    // year: 31536000,
    // thirdQuarter:
  });
// Define 12hr times

dated.hour = [];
dated.hour[0] = "12AM";
dated.hour[1] = "1AM";
dated.hour[2] = "2AM";
dated.hour[3] = "3AM";
dated.hour[4] = "4AM";
dated.hour[5] = "5AM";
dated.hour[6] = "6AM";
dated.hour[7] = "7AM";
dated.hour[8] = "8AM";
dated.hour[9] = "9AM";
dated.hour[10] = "10AM";
dated.hour[11] = "11AM";
dated.hour[12] = "12PM";
dated.hour[13] = "1PM";
dated.hour[14] = "2PM";
dated.hour[15] = "3PM";
dated.hour[16] = "4PM";
dated.hour[17] = "5PM";
dated.hour[18] = "6PM";
dated.hour[19] = "7PM";
dated.hour[20] = "8PM";
dated.hour[21] = "9PM";
dated.hour[22] = "10PM";
dated.hour[23] = "11PM";

// Define Weekdays
dated.weekday = [];
dated.weekday[0] = "Sunday";
dated.weekday[1] = "Monday";
dated.weekday[2] = "Tuesday";
dated.weekday[3] = "Wednesday";
dated.weekday[4] = "Thursday";
dated.weekday[5] = "Friday";
dated.weekday[6] = "Saturday";

// Define Short Weekdays
dated.shortWeekday = [];
dated.shortWeekday[0] = "Sun";
dated.shortWeekday[1] = "Mon";
dated.shortWeekday[2] = "Tues";
dated.shortWeekday[3] = "Wed";
dated.shortWeekday[4] = "Thur";
dated.shortWeekday[5] = "Fri";
dated.shortWeekday[6] = "Sat";

// Define Months
dated.textualMonth = [];
dated.textualMonth[0] = "January";
dated.textualMonth[1] = "February";
dated.textualMonth[2] = "March";
dated.textualMonth[3] = "April";
dated.textualMonth[4] = "May";
dated.textualMonth[5] = "June";
dated.textualMonth[6] = "July";
dated.textualMonth[7] = "August";
dated.textualMonth[8] = "September";
dated.textualMonth[9] = "October";
dated.textualMonth[10] = "November";
dated.textualMonth[11] = "December";


// Define Short Months
dated.shortTextualMonth = [];
dated.shortTextualMonth[0] = "Jan";
dated.shortTextualMonth[1] = "Feb";
dated.shortTextualMonth[2] = "Mar";
dated.shortTextualMonth[3] = "Apr";
dated.shortTextualMonth[4] = "May";
dated.shortTextualMonth[5] = "Jun";
dated.shortTextualMonth[6] = "Jul";
dated.shortTextualMonth[7] = "Aug";
dated.shortTextualMonth[8] = "Sep";
dated.shortTextualMonth[9] = "Oct";
dated.shortTextualMonth[10] = "Nov";
dated.shortTextualMonth[11] = "Dec";

// Define Ordinal Number suffix
dated.ordinal = [];
dated.ordinal[1] = "st";
dated.ordinal[2] = "nd";
dated.ordinal[3] = "rd";
dated.ordinal[4] = "th";

//figure out array from 4-20
//dated.ordinal[4..20] = 'th';
dated.ordinal[5] = "th";
dated.ordinal[6] = "th";
dated.ordinal[7] = "th";
dated.ordinal[8] = "th";
dated.ordinal[9] = "th";
dated.ordinal[10] = "th";
dated.ordinal[11] = "th";
dated.ordinal[12] = "th";
dated.ordinal[13] = "th";
dated.ordinal[14] = "th";
dated.ordinal[15] = "th";
dated.ordinal[16] = "th";
dated.ordinal[17] = "th";
dated.ordinal[18] = "th";
dated.ordinal[19] = "th";
dated.ordinal[20] = "th";
dated.ordinal[21] = "st";
dated.ordinal[22] = "nd";
dated.ordinal[23] = "rd";
dated.ordinal[24] = "th";
dated.ordinal[25] = "th";
dated.ordinal[26] = "th";
dated.ordinal[27] = "th";
dated.ordinal[28] = "th";
dated.ordinal[29] = "th";
dated.ordinal[30] = "th";
dated.ordinal[31] = "st";

// Define Holidays
dated.holiday = [];
dated.holiday["0101"] = "New Years Day";

dated.holiday["1220"] = "Lincoln's Birthday";
dated.holiday["0217"] = "Washington's Birthday";
dated.holiday["1225"] = "Christmas";
dated.holiday["0317"] = "Paddy's Day";
dated.holiday["0704"] = "4th of July";
dated.holiday["0505"] = "Cinco de Mayo";
dated.holiday["1231"] = "New Years Eve";

// Holidays based on particular weekday of month (thus diff day every year)
// This will work for 2020, but need a 'method' for calculating
dated.holiday["0120"] = "Martin Luther King Jr. Day";
dated.holiday["05252020"] = "Memorial Day";
dated.holiday["Thanksgiving"] = "Last Thursday in Novemer";
dated.holiday["20200907"] = "Labor Day";
dated.holiday["20201012"] = "Columbus Day";
dated.holiday["20201111"] = "Veterans Day";

// Define Special Occasions
// dated.special['0504'] = 'May The Fourth By With You...Yak :('

// dated.get methods /////////////////////////////////////////////////////////////////////////////////////////////////

// dated.getISOlocaltime = function(dateToGet) {
//    function localISOTime(d) { if (!d) d = new Date();
//      var tzoffset = d.getTimezoneOffset() * 60000; //offset in milliseconds
//    return (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1); }
// };

dated.refreshTime = function() {
    return dated.rightNow = Date.now();
};

dated.refreshDate = function() {
    return dated.currentDT = new Date();
}


// Dated Timer Methods ////////////////////////////////////////////////////////

    dated.setStartTime = function(startTime = dated.refreshTime()) {
        return dated.startTime = startTime;
    }

    dated.setEndTime = function(endTime = dated.refreshTime()) {
        return dated.startTime = endTime;
    }

    dated.getStartTime = function() {
        return dated.startTime;
    }
    dated.getCurrentTime = function() {
        return dated.currentTime =  dated.refreshTime();
    }
    

    dated.getEndTime = function() {
        return dated.startTime;
    }

    dated.getCurrentElapsedTime = function() {
        return dated.totalElapsedTime = dated.getCurrentTime() - dated.startTime;
    }

    dated.getStartToEndElapsedTime = function() {
        return dated.startToEndElapsedTime = dated.endTime - dated.startTime;
    }
// Dated Timer Methods ////////////////////////////////////////////////////////


// dated.getLocalISOTime = function(d) {
//   if (!d) d = new Date();
//   var tzoffset = d.getTimezoneOffset() * 60000; //offset in milliseconds
//   return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
// }

// export function localISOTime(d) {
//   if (!d) d = new Date();
//   var tzoffset = d.getTimezoneOffset() * 60000; //offset in milliseconds
//   return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
// }

dated.getDaylightSavingsTimeOffset = function (dt) {
  let jan = new Date(dt.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(dt.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != dt.getTimezoneOffset();
}

dated.getLocalISODateTime = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    
    let tzoffset = d.getTimezoneOffset() * 60000; //offset in milliseconds
    // let tzoffset = dated.getDaylightSavingsTimeOffset(dt) * 60000; //offset in milliseconds
    // let tzoffset = dated.getDaylightSavingsTimeOffset(d) * 60000; //offset in milliseconds

    // console.log('dated.getlocalISODateTime tzoffset = ' + tzoffset);

    let localISODateTime = new Date(d.getTime() - tzoffset).toISOString().slice(0, -1);
    //  let timestamp = localISODateTime.substr(11,5) !== '00:00' ? localISODateTime.substr(10,6) : '';
    //  let updatedLocalTime = localISODateTime.substr(0,10) + " " + localISODateTime.substr(11,5);

    let updatedLocalTime = localISODateTime.substr(0, 10) + " " + localISODateTime.substr(11, 5); // !== "00:00"  ? localISODateTime.substr(11, 5) : "");

    // window.console.log("Updated Local Time: " + updatedLocalTime);
    return updatedLocalTime;
}

dated.getLocalISODate = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    let tzoffset = d.getTimezoneOffset() * 60000; //offset in milliseconds
    let localISODateTime = new Date(d.getTime() - tzoffset).toISOString().slice(0, -1);
    

    let updatedLocalTime = localISODateTime.substr(0, 10);
    return updatedLocalTime;
}

dated.getYyyyMmDdDate = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    let YyyyMmDd = d.getFullYear() + '-' + dated.getMonthNum(d.getMonth()) + '-' + d.getDate();
    return YyyyMmDd;
    // let localIsoDateString = dated.getLocalISODate(dt);
    // return localIsoDateString; //.substr(0, 4) + '-' + localIsoDateString.substr(5, 2) + '-' + localIsoDateString.substr(7, 2);

}

dated.getMmDdYyyyDate = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    let MmDdYyyy = dated.getMonthNum(d.getMonth()) + '-' + d.getDate() + '-' + d.getFullYear();
    return MmDdYyyy;
    // let localIsoDateString = dated.getLocalISODate(dt);
    // return localIsoDateString; //.substr(0, 4) + '-' + localIsoDateString.substr(5, 2) + '-' + localIsoDateString.substr(7, 2);

}

dated.getLocalISOTime = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    let tzoffset = d.getTimezoneOffset() * 60000; //offset in milliseconds
    let localISODateTime = new Date(d.getTime() - tzoffset).toISOString().slice(0, -1);
    
    let updatedLocalTime = (localISODateTime.substr(11, 5) !== "00:00"
        ? localISODateTime.substr(11, 5)
        : "00:00");

    return updatedLocalTime;
}

dated.getNoonOnDate = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    console.log('getNoonOnDate:' + d);
    let noonOnDateString = d.getFullYear() + '-' + dated.getMonthNum(d.getMonth()) + '-' + d.getDate() + ' 12:00';
    return noonOnDateString;
    

}

dated.getFirstOfMonthTimestamp = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    
    let firstOfMonthString = d.getFullYear() + '-' + dated.getMonthNum(d.getMonth()) + '-01 12:00';
    console.log('getFirstOfMonthTimestamp: ' + firstOfMonthString);
    let firstOfMonthDate = new Date(firstOfMonthString);
    let firstOfMonthTimestamp = firstOfMonthDate.getTime();
  
    return firstOfMonthTimestamp;

}

dated.getFirstOfPrevMonthTimestamp = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    
    let firstOfPrevMonthString;
    if (d.getMonth() <= 0) {

        firstOfPrevMonthString = parseInt(d.getFullYear() - 1) + '-12-01 12:00';
        console.log('firstOfPrevMonthString: ' + firstOfPrevMonthString);
    } else {
        // lastOfMonthString = d.getFullYear() + '-' + dated.getMonthNum(d.getMonth()) + '-01';
        firstOfPrevMonthString = d.getFullYear() + '-' + dated.getMonthNum(d.getMonth() - 1) + '-01 12:00';
        console.log('firstOfPrevMonthString: ' + firstOfPrevMonthString);
    }
    
    return new Date(firstOfPrevMonthString).getTime();
    
}


dated.getFirstOfNextMonth = function(dt) {
    let d;
   
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    
    let firstOfNextMonthString;
    if (d.getMonth() === 11) {
        firstOfNextMonthString = parseInt(d.getFullYear() + 1) + '-01-01 12:00';
    } else {
        // lastOfMonthString = d.getFullYear() + '-' + dated.getMonthNum(d.getMonth()) + '-01';
        firstOfNextMonthString = d.getFullYear() + '-' + dated.getMonthNum(d.getMonth() + 1) + '-01 12:00';
    }
    
    return new Date(firstOfNextMonthString);
    
}

dated.getLastOfMonthTimestamp = function(dt) {

    let lastOfMonthTimestamp = dated.getFirstOfNextMonth(dt).getTime() - dated.daysToMilliseconds(1);
    // let lastOfMonthDate = new Date(lastOfMonthTimestamp);
    // console.log('getLastOfMonthTimestamp function lastOfMonthTimestamp: ' + lastOfMonthTimestamp);
    // console.log('getLastOfMonthTimestamp lastOfMonthDate: ' + lastOfMonthDate);
    return lastOfMonthTimestamp;

}

dated.getFirstOfNextMonthTimestamp = function(dt) {
    let firstOfMonthTimestamp = dated.getFirstOfNextMonth(dt).getTime();
    return firstOfMonthTimestamp;
}

dated.getNumberOfDaysInMonth = function(dt) {
    return new Date(dated.getLastOfMonthTimestamp(dt)).getDate();
}

dated.getMonthNum = function(monthToGet = dated.refreshDate().getMonth(), zeroPadded = true) {
    if (typeof(monthToGet) === 'string') {
        if (dated.textualMonth.indexOf(monthToGet) !== -1) {
            monthToGet = dated.textualMonth.indexOf(monthToGet);
        } else if (dated.shortTextualMonth.indexOf(monthToGet) !== -1) {
            monthToGet = dated.shortTextualMonth.indexOf(monthToGet);
        } else {
            monthToGet = new Date(monthToGet).getMonth();
        }
    } else if (typeof(monthToGet) === 'object') {
        let monthNum = new Date(monthToGet).getMonth();
        monthToGet = monthNum;
    } else if (typeof(monthToGet) !== 'number') {
        return -1; // dataType not valid
    }

    if (typeof(monthToGet) === 'number') {
        if (monthToGet <= 12) {
            if (zeroPadded === true) {
                return (monthToGet + 1) < 10 ? '0' + parseInt(monthToGet + 1) : monthToGet + 1;
            } else {
                return monthToGet + 1;
            }
        } else {
            // a timestamp was probably sent, 'probably' also need stricter checks for this method
            let monthNum = new Date(monthToGet).getMonth();
            if (zeroPadded === true) {
                return (monthNum + 1) < 10 ? '0' + parseInt(monthNum + 1) : monthNum + 1;
            } else {
                return monthNum + 1;
            }
            
        }
    }

    // return  dated.currentDT.getMonth() + 1 < 10 ? '0' + (dated.currentDT.getMonth() + 1) : dated.currentDT.getMonth() + 1;

}

dated.getTextualMonth = function(monthToGet) {
  if (monthToGet) {
    monthToGet = monthToGet < 0 ? 0 : monthToGet > 11 ? 11 : monthToGet;
    return dated.textualMonth[monthToGet];
  } else {
    dated.refreshDate();
    monthToGet = dated.currentDT.getMonth();
    return dated.textualMonth[monthToGet];
  }
}

dated.getShortTextualMonth = function(monthToGet) {
  if (monthToGet) {
    monthToGet = monthToGet < 0 ? 0 : monthToGet > 11 ? 11 : monthToGet;
    return dated.shortTextualMonth[monthToGet];
  } else {
    monthToGet = dated.currentDT.getMonth();
    return dated.shortTextualMonth[monthToGet];
  }
}


dated.getWeekDay = function(dayToGet) {
  if (dayToGet) {
    dayToGet = dayToGet < 0 ? 0 : dayToGet > 6 ? 6 : dayToGet;
    return dated.weekday[dayToGet];
  } else {
    dated.refreshDate();
    dayToGet = dated.currentDT.getDay();
    return dated.weekday[dayToGet];
  }
}

dated.getShortWeekDay = function(dayToGet) {
  if (dayToGet) {
    dayToGet = dayToGet < 0 ? 0 : dayToGet > 6 ? 6 : dayToGet;
    return dated.shortWeekday[dayToGet];
  } else {
    dated.refreshDate();
    dayToGet = dated.currentDT.getDay();
    return dated.shortWeekday[dayToGet];
  }
}


dated.getOrdinalDay = function(dateToGet) {
  let ordinalDate = "";
  if (dateToGet) {
    dateToGet = dateToGet < 1 ? 1 : dateToGet;
    ordinalDate = dateToGet + dated.ordinal[dateToGet];
    return ordinalDate;
  } else {
    dated.refreshDate();
    let todaysDate = dated.currentDT.getDate();
    ordinalDate = todaysDate + dated.ordinal[todaysDate];
    return ordinalDate;
  }
}


dated.getDayNumber = function(dt) {
    let d;
    if (!dt) {
        d = new Date();
    } else {
        d = new Date(dt);
    }
    return d.getDate();
}

dated.getHourNum = function(dateToGet) {
  return (dated.currentHour = dated.hour[dateToGet.getHours()]);
}

dated.getMinute = function(dt) {
  let d;
  if (!dt) {
    d = new Date();
  } else {
    d = new Date(dt);
  }
  return d.getMinutes();
}

dated.getCurMonth = function() {
  dated.refreshDate();
  return dated.currentDT.getMonth() + 1;
}

dated.getCurrHour = function() {
  dated.refreshDate();
  return dated.currentDT.getHours();
}

dated.getCurrMin = function() {
  dated.refreshDate();
  return dated.currentDT.getMinutes();
}

dated.getCurrSec = function() {
  dated.refreshDate();
  return dated.currentDT.getSeconds();
}

dated.toTwelveHour = function(twentyFourHourNum = dated.getCurrHour()) {
  //return dated.currentHour = dated.hour[dated.currentDT.getHour() % 24];
  return dated.hour[parseInt(twentyFourHourNum % 24)];
}

dated.getTwelveHourTime = function(dt = dated.refreshTime(), displaySeconds = true) {
  let d = new Date(dt);
  //return dated.currentHour = dated.hour[dated.currentDT.getHour() % 24];
  const curHour =  dated.hour[d.getHours() % 24];
  let curMin = d.getMinutes();
  let curSec = d.getSeconds();
  curMin = (curMin < 10) ? '0' + curMin : curMin;
  curSec = (curSec < 10) ? '0' + curSec : curSec;

  let meridiemString = curHour.substring(curHour.length - 2, curHour.length);
  let curHourNum = curHour.substring(0, curHour.length - 2);
  let twelveHourTimeString = `${curHourNum}:${curMin}${displaySeconds ? (':' + curSec) : ''}${meridiemString}`;

  return twelveHourTimeString;

}

dated.getFormattedMsDuration = function(milliseconds = null) {
    if (milliseconds !== null) {
        let formattedDuration = '',
            hours = null,
            minutes = null,
            seconds = null;

        if (milliseconds > dated.inMilliSeconds.hour) {
            hours = Math.floor(milliseconds / dated.inMilliSeconds.hour);
            milliseconds -= (hours * dated.inMilliSeconds.hour);    
        }

        if (milliseconds > dated.inMilliSeconds.minute) {
            minutes = Math.floor(milliseconds / dated.inMilliSeconds.minute);
            milliseconds -= (minutes * dated.inMilliSeconds.minute);  
        }

        if (milliseconds > dated.inMilliSeconds.second) {
            seconds = Math.floor(milliseconds / dated.inMilliSeconds.second);
            milliseconds -= (seconds * dated.inMilliSeconds.second);  
        }

        formattedDuration = ((hours !== null) ? (hours + ' hour' + (hours > 1 ? 's' : '')): '') + ((minutes !== null) ? (' ' + minutes + ' minute' + (minutes > 1 ? 's' : '')) : '') + ((seconds !== null) ? (' ' + seconds + ' second' + (seconds > 1 ? 's ' : ' ')) : '');
        console.log('remainder milliseconds: ' + milliseconds);
            console.log('formattedDurration: ' + formattedDuration);
        
        return formattedDuration;
      } else {
        return 0;
      }
}

dated.getFormattedMinDuration = function(minutes = null) {
    if (minutes !== null) {
        let formattedDuration = '',
            hours = null;
        minutes = parseInt(minutes);  
        if (minutes >= 60) {
            hours = Math.floor(minutes / 60);
            minutes = minutes - (hours * 60);    
        }

        formattedDuration = ((hours !== null) ? (hours + ' hour' + (hours > 1 ? 's ' : ' ')): '') + ((minutes > 0) ? (minutes + ' minute' + (minutes > 1 ? 's ' : ' ')) : '');
        console.log('remainder minutes: ' + minutes);
            console.log('formattedDurration: ' + formattedDuration);
        
        return formattedDuration;
      } else {
        return 0;
      }
}

dated.milliSec2Minute = function(milliseconds = null) {
  if (milliseconds !== null) {
    return (milliseconds / dated.inMilliSeconds.minute).toFixed(0);
  } else {
    return 0;
  }
}


dated.daysToMilliseconds = function(numberOfDays = null) {
    if (numberOfDays !== null) {
        return dated.inMilliSeconds.day * numberOfDays;
    } else {
        return 0;
    }
}

/*
dated.getTwelveHourTime = function(displaySeconds = true) {
  //return dated.currentHour = dated.hour[dated.currentDT.getHour() % 24];
  let curHour = dated.hour[parseInt(dated.getCurrHour() % 24)];
  let curMin = dated.currentDT.getMinutes();
  let curSec = dated.currentDT.getSeconds();
  curMin = (curMin < 10) ? '0' + curMin : curMin;
  curSec = (curSec < 10) ? '0' + curSec : curSec;

  let meridiemString = curHour.substring(curHour.length - 2, curHour.length);
  let curHourNum = curHour.substring(0, curHour.length - 2);
  let twelveHourTimeString = `${curHourNum}:${curMin}:${ displaySeconds ? curSec : ''} ${meridiemString}`;

  return twelveHourTimeString;

};

*/

// From Notes Dashboard - Use to create function along the lines of 'time past since'
////////////////////////////////////////////////////////

// htmlRecentNotes += "<div style='background:rgba(" + tempRed + ", " + tempGreen + ", 0, " + tempOpacity + ");' id='" + curNoteId + "' class='idea-note' data-note-id='" + data[key]['note_id'] + "' data-note-date-time-stamp='" + data[key]['note_datetime_stamp'] + "' data-note-name='" + data[key]['note_name'] + "' data-note-subject='" + data[key]['note_subject'] + "' data-note-creator='" + data[key]['note_creator'] + "'><h3 style='display: inline;'>" + data[key]['note_name'] + "</h3><span style='font-size: 75%;'> --- " + data[key]['note_subject'] + "</span><br><span class='indented tab1'>Posted <strong style='font-size: 102.5%;'>" + ((daysTimeDiff>0) ?  daysTimeDiff + ' days, ' : ' ') + ((hoursInDay>0) ? ((hoursInDay>1) ? (hoursInDay + " hours &amp; ") : (hoursInDay + " hour &amp; ")) : ' ') + " " + ((minsInHour>0) ?  minsInHour + ' minutes'+ ((minsInHour>1) ? '\'s ': ' ') : ' ') + "ago</strong></span><br></div>";

// dated.set methods /////////////////////////////////////////////////////////////////////////////////////////////////
dated.setToDate = function(dateToSet) {
  if (dateToSet) {
    dated.toDate = new Date(dateToSet);
  } else {
    dated.toDate = new Date();
  }
};

dated.setFromDate = function(dateToSet) {
  if (dateToSet) {
    dated.fromDate = new Date(dateToSet);
  } else {
    dated.fromDate = new Date();
  }
};

// TODO: convert to an actual data.js method
// from: https://stackoverflow.com/questions/1050720/adding-hours-to-javascript-date-object

// export Date.prototype.addHours = function(h) {
//     this.setTime(this.getTime() + (h*60*60*100));
//     return this;}

// date.toDate methods /////////////////////////////////////////////////////////////////////////////////////////////////
dated.getToDate = function() {
  if (dated.fromDate) {
    return dated.toDate.toLocaleDateString();
  } else {
    return "fromDate not set!";
  }
};

dated.getToDateDay = function() {
  return dated.toDate.getMonth();
};
dated.getToDateUS = function() {
  return (dated.toDateUS = dated.toDate.getMonth() + 1 + "/" + dated.toDate.getDate() + "/" + dated.toDate.getFullYear());
};

dated.getURLencodedToDateUS = function() {
  return (dated.toDateUS =
    dated.toDate.getMonth() +
    1 +
    "%2F" +
    dated.toDate.getDate() +
    "%2F" +
    dated.toDate.getFullYear());
};

// dated.fromDate methods /////////////////////////////////////////////////////////////////////////////////////////////////
dated.getFromDate = function() {
  if (dated.fromDate) {
    return dated.fromDate.toLocaleDateString();
  } else {
    return "fromDate not set!";
  }
};

dated.getFromDateUS = function() {
  return (dated.fromDateUS =
    dated.fromDate.getMonth() +
    1 +
    "/" +
    dated.fromDate.getDate() +
    "/" +
    dated.fromDate.getFullYear());

    // return (dated.fromDateUS = dated.fromDate.getMonth() + 1 + "/" + dated.fromDate.getDate() + "/" + dated.fromDate.getFullYear());
};

dated.getURLencodedFromDateUS = function() {
  return (dated.fromDateUS =
    dated.fromDate.getMonth() +
    1 +
    "%2F" +
    dated.fromDate.getDate() +
    "%2F" +
    dated.fromDate.getFullYear());
};

// dated.DIFF methods /////////////////////////////////////////////////////////////////////////////////////////////////
dated.getFromToDiff = function() {
  if (dated.toDate && dated.fromDate) {
    return (dated.milsDiff = dated.fromDate.getTime() - dated.toDate.getTime());
  } else {
    return "To or From Dates Not Set!";
  }
};

dated.getToFromDiff = function() {
  if (dated.toDate && dated.fromDate) {
    return (dated.milsDiff = dated.toDate.getTime() - dated.fromDate.getTime());
  } else {
    return "To or From Dates Not Set!";
  }
};

dated.getToNowDiff = function(passedDate) {
  dated.fromDate = new Date();
  if ((dated.toDate || passedDate) && dated.fromDate) {
    return (dated.milsDiff = dated.toDate.getTime() - dated.fromDate.getTime());
  } else {
    return "To or From Dates Not Set!";
  }
};

dated.getDiffFromNow = function(dmyType, dmyNum) {
  //dated.setFromDate();
  let fromDay = dated.fromDate.getDate();
  let fromMonth = dated.fromDate.getMonth();
  let fromYear = dated.fromDate.getFullYear();
  let toDay = fromDay;
  let toMonth = fromMonth;
  let toYear = fromYear;

  switch (dmyType) {
    case "days":
      toDay = fromDay - dmyNum;
      break;
    case "months":
      toMonth = fromMonth - dmyNum;
      break;
    case "years":
      toYear = fromYear - dmyNum;
    default:
      return "No Date, Month or Year type to calculate";
  }
  return new Date(toYear, toMonth, toDay);
};

dated.getDiffFromNowToISO = function(isoDateString) {
  if (isoDateString) {
    this.refreshDate();
    dated.toDate = new Date(isoDateString);
    dated.milDiff = dated.currentDT - dated.toDate.getTime();
    dated.updateDiff();
  } else {
    return "No ISO Date given!";
  }
};

// dated.REFRESH/UPDATE methods

dated.updateDiff = function() {
  if (dated.milsDiff) {
    dated.secsDiff = Math.floor(dated.milDiff / 1000);
    dated.minsDiff = Math.floor(dated.secsDiff / 60);
    dated.hoursDiff = Math.floor(dated.hoursDiff / 60);
    dated.hMinDiff = dated.minsDiff % 60;
    dated.daysDiff = Math.floor(dated.hoursDiff / 24);
    dated.dHourDiff = dated.hoursDiff % 24;
  }
  /* I may want this to just not do anything if condition not met because this should only be called by the constructor or private class method
    ) else {
        return 'mils (milliseconds) difference not set!';
    }
    */
};


// dated GET TODAYS DATE LONG

dated.getTodayLong = function() {
  dated.refreshDate();
  let todaysDateStringLong = "";
  todaysDateStringLong =
    dated.getWeekDay() +
    ", " +
    dated.getTextualMonth() +
    " " +
    dated.getOrdinalDay() +
    " " +
    dated.currentDT.getFullYear();
  return todaysDateStringLong;
};

dated.getDateShort = function() {
  dated.refreshDate();
  let todaysDateStringShort = "";
  todaysDateStringShort =
    dated.getShortWeekDay() +
    ", " +
    dated.getShortTextualMonth() +
    ". " +
    dated.getOrdinalDay(); // +
    // " " +
    // dated.currentDT.getFullYear();
  return todaysDateStringShort;
};

// dated Special Methods /////////////////////////////////////////////////////////////////////////////////////////////////

// Attach Real-time clock to target element

dated.attachRealTimeClockTo = function(elementId = "", updateInterval = 1000) {
  if (elementId) {
    let elementToAttach = document.getElementById(elementId);
    if (typeof elementToAttach === "object") {
      dated.refreshDate();
      elementToAttach.innerHTML = "initializing...";
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      setInterval(() => {
        dated.refreshDate();
        //elementToAttach.innerHTML = dated.currentDT.toLocaleDateString('en-US', options);
        elementToAttach.innerHTML =
          "\t " + dated.currentDT.toLocaleTimeString("en-US");
        //document.getElementById('JS-calendar-today').innerHTML += rightNow.toLocaleDateString('en-US', options);
        //document.getElementById('JS-calendar-time').innerHTML += rightNow.toLocaleTimeString('en-US');
      }, updateInterval);
    }
  } else {
    console.log("elementId not set");
    return "elementId not set";
  }
}

  dated.realTimeClock = function(updateInterval = 1000) {
    // if (elementId) {
      // let elementToAttach = document.getElementById(elementId);
      // if (typeof elementToAttach === "object") {
        // dated.refreshDate();
        dated.refreshTime();
        // elementToAttach.innerHTML = "initializing...";
        let options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
        };
        setInterval(() => {
          // dated.refreshDate();
          dated.refreshTime();
          dated.refreshDate();
          //elementToAttach.innerHTML = dated.currentDT.toLocaleDateString('en-US', options);
          return dated.currentDT.toLocaleTimeString("en-US");
          //document.getElementById('JS-calendar-today').innerHTML += rightNow.toLocaleDateString('en-US', options);
          //document.getElementById('JS-calendar-time').innerHTML += rightNow.toLocaleTimeString('en-US');
        }, updateInterval);
  }
    // } else {
      // console.log("elementId not set");
      // return "elementId not set";
    // }

