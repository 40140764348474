<template>
<span :class="componentName">
    <v-dialog v-model="displayDialog" overlay-opacity="1" overlay-color="rgba(13, 13, 13, .75)" persistent max-width="90%"> 
        
        <v-card>
            <v-card-title>
                <span class="mdi mdi-alert orange-alert-icon" style="display: block; width: 100%;margin: auto; font-size: 1.85rem; line-height: 1.5;"><strong>It's {{ formattedTime }} </strong></span> <br class=""><span style="display: block; width: 100%;margin: auto; font-size: 1.85rem; line-height: 1.5;" v-html="getInfoDialogState.title"></span> <br class="">
                <!--
                <span style="display: block; width: 100%; margin: auto; "><strong style="font-size: 1.75rem; line-height: 1.5;"> {{ infoDialogObj.title }}</strong></span> -->
            </v-card-title>
            
            <v-card-text> <span style="display: block; width: 100%; margin: auto;" v-html="getInfoDialogState.message"></span></v-card-text>
            <br class="">
            
            <v-divider></v-divider>
            <v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="onConfirmInfo" style="margin: auto; font-size: 1.5rem; font-weight: 900; width: 95%;">OK</v-btn>
                  
                </v-card-actions>
            </v-card-text>
          
        </v-card>
    </v-dialog>
</span>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    name: "InformationDialog",
    data: () => ({
        componentName: "InformationDialog",
        shaped: false,
        outlined: false,
        rounded: false,
        // dense: false,
        autofocus: true
    }),
    computed: {
        ...mapGetters('time', {
            getCurrentTimestamp: 'getCurrentTimestamp',
            getCurrentTimeObj: 'getCurrentTimeObj',
        }),
        ...mapGetters( 'uiState', {
            getAppState: 'getAppState',
            getInfoDialogState: 'getInfoDialogState'  
        }),
        formattedTime() {
            return this.$datedJS.dated.getTwelveHourTime(this.getInfoDialogState.timestamp, false); 
        },
        displayTimeRemaining() {
            return (this.getInfoDialogState.timestamp + this.getInfoDialogState.displayTime) - this.getCurrentTimestamp;
        },
        displayDialog() {
            return ((this.displayTimeRemaining > 0) && (this.getInfoDialogState.show)); 
        }
    },
    created: function() {
        console.log("Display information dialog created function called");

    },
    updated() {
        console.log('updated called in InformationDialog component');
     
    },
    methods: {

        ...mapMutations( 'uiState', {
           SET_INFO_DIALOG_OBJ: 'SET_INFO_DIALOG_OBJ'
        }),
        
        onConfirmInfo() {
            let infoDialogObj = this.$arrayObjUtils.arrayObjsDeepCopy(this.getInfoDialogState);
            infoDialogObj.status = 'confirmed';
            infoDialogObj.show = false;
            this.SET_INFO_DIALOG_OBJ(infoDialogObj);
            console.log(`calling: confirmInfoMessage`);
            this.$emit(`confirmInfoMessage`);

        },
    }
};
</script>

<style>


</style>