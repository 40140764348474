<template>
    <v-dialog v-model="displayDialog" persistent max-width="500" class="save-changes-dialog"> 
        <v-card>
            <v-card-title><span class="mdi mdi-alert orange-alert-icon">Confirm Delete</span>
            </v-card-title>
            <v-card-text>
               You are about to delete the item:
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text v-html="selectedItem.name"></v-card-text>
            
            <v-divider></v-divider>
            <v-card-text>
                Deleting Can NOT be undone. Are you sure you want to proceed?
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="onCancelDelete">No</v-btn>
                <v-btn color="primary" text @click="onConfirmDelete">Yes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "ConfirmDeleteDialog",
    props: [
        "showConfirmDeleteDialog",
        "selectedItem",
        "selectedItemType", // seletedItemType used to distinguish between tasks, notes, etc so this component can easily be used with other components that modify various record types.
    ],
    data: () => ({
        shaped: false,
        outlined: false,
        rounded: false,
        // dense: false,
        autofocus: true
    }),
    created: function() {
    
        console.log("Item to delete: " + this.selectedItem.name);
    },
    computed: {
        displayDialog() {
            return this.showConfirmDeleteDialog;
            
        }
    },
    methods: {
        
        onCancelDelete() {
            this.$emit(`cancel${this.selectedItemType}Delete`);
        },
        onConfirmDelete() {
            console.log(`calling: confirm${this.selectedItemType}Delete('${this.selectedItem.id}')`);
            this.$emit(`confirm${this.selectedItemType}Delete`, this.selectedItem.id);
            // this.$emit(`confirm${this.selectedItemType}Delete('${this.selectedItem.id}')`);
        }
    }
};
</script>

<style>


</style>