<template>
    <div id="auto-complete-container-id" class="auto-complete-container">
            <!-- <input
                v-model="inputString"
                @input="onInput"
                @focus="openDropDown"
                type="text"
             /> -->
             <!-- 
                  v-model="inputString"
              -->
            <v-text-field
                v-model="inputString"
                @input="onInput"
                @click="openDropDown"
                @focus="[  openDropDown(), $event.target.select() ];"
                type="text"
                dense
                outlined
                suffix="minutes"
                :label="inputLabel"
            ></v-text-field> 
            <ul 
            v-show="showSuggestions"
            class="auto-complete-suggestions">
                <li 
                    v-for="(suggestion, i) in suggestions"
                    :key="i"
                    @click="selectSuggestion(suggestion, suggestion[suggestionsPoolPrefix + 'Index'])"
                    class="auto-complete-suggestion"
                >
                   <span v-if="typeof(suggestion) === 'string'"> {{ suggestion }} </span>
                   <span v-else> {{ suggestion[suggestionsPoolPrefix + 'Label'] }} </span>
                </li>
            </ul>
             
    </div>
</template>

<script>
    export default {
        name: 'AutoCompleteInput',
        props: [ 'modelValue', 'inputValue', 'suggestionsPool', 'suggestionsPoolPrefix', 'inputVariableName', 'inputLabel' ],
        // emits: [ 'update:modelValue' ],
        //  {
        //     SuggestionsPool: {
        //         type: Array,
        //         required: false,
        //         default: () => [],
        //     },
        //     inputVariableName: {
        //         type: String,
        //         required: true,
        //         default: ''  
        //     }
        // },
        data: () => ({
            autoCompleteContainerEl: null,
            inputString: '',
            showSuggestions: false,
            suggestions: [{}],
        }),
        computed: {
            inputFullString() {
                return this.inputString + ' minutes';
            }
        },
        created() {
            // this.suggestions = this.suggestionsPool;
           console.log('AutoCompleteInput.vue created method called');
           this.inputString = this.inputValue;
            
        },
        mounted() {
            this.filterSuggestions();
            // this.autoCompleteContainerEl = document.getElementById('auto-complete-container-id');
            document.addEventListener('click', this.handleClickOutside);
            // this.inputString = this.inputValue !== null ? this.inputValue : '';
         
            console.log('typeof(this.suggestionsPool[0]): ' + typeof(this.suggestionsPool[0]));
            this.inputString = this.modelValue;
            // this.fetchNotes();
        },
        updated() {
            console.log('AutoCompleteInput.vue updated method called' );
              console.log('this.modelValue: ' + this.modelValue + '\nthis.inputValue: ' + this.inputValue);
            if(this.modelValue !== this.inputValue) {
                console.log('this.modelValue: ' + this.modelValue + '\nthis.inputValue: ' + this.inputValue);
                this.inputString = this.inputValue;
            }
            // this.inputString = this.inputValue;
            this.$emit( 'updateComputed' );
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);

        },
        methods: {
            filterSuggestions() {
                console.log('typeof(this.suggestionsPool[0]): ' + typeof(this.suggestionsPool[0]));
                console.log('this.suggestionsPool[0]: ' + JSON.stringify(this.suggestionsPool[0], null, 1));
                
                if (typeof(this.suggestionsPool[0]) === 'string') {
                    this.suggestions = this.suggestionsPool.filter(suggestion => suggestion.toLowerCase().indexOf(this.inputString.toLowerCase()) > -1);
                } else {
                    // console.log('suggestion[this.suggestionsPoolLabel]: ' + suggestion[this.suggestionsPoolLabel]);
                    console.log('this.inputString: ' + this.inputString);
                    /*
                    this.suggestions = this.suggestionsPool.filter(suggestion => {
                        console.log('suggestion[this.suggestionsPoolLabel]: ' + suggestion[this.suggestionsPoolLabel]);
                        return suggestion[this.suggestionsPoolLabel].toLowerCase().indexOf(this.inputString.toLowerCase()) > -1; 
                    });
                    */
                    if(this.inputString.length > 0) {
                        this.suggestions = this.suggestionsPool.filter(suggestion => suggestion[this.suggestionsPoolPrefix + 'Label'].toLowerCase().indexOf(this.inputString.toLowerCase()) > -1); 
                    } else {
                        this.suggestions = this.suggestionsPool;
                    }

                    console.log('this.suggestions: ' + JSON.stringify(this.suggestions, null, 1));
                } 

            },
            onInput() {
                // this.inputString = textValue;
                this.filterSuggestions();
                this.showSuggestions = true;
                this.$emit( 'updateTextInput', { 'inputVariableName': this.inputVariableName, 'inputString': this.inputString } );
                this.$emit( 'updateComputed' );
                
            },
            openDropDown() {
                this.showSuggestions = true;
            },
            loseFocus() {
                console.log('loseFocus called');
                this.showSuggestions = false;
                this.$emit( 'updateTextInput', { 'inputVariableName': this.inputVariableName, 'inputString': this.inputString } );
                this.$emit( 'updateComputed' );
                this.$emit( 'loseFocus' );
            },
            selectSuggestion(suggestion, index) {

                console.log('typeof(suggestion): ' + typeof(suggestion));
                console.log('index: ' + suggestion + 'with index of: ' + index);
                console.log('suggestion: ' + JSON.stringify(suggestion, null, 2));
                if (typeof(suggestion) === 'string') {
                    console.log('selectSuggestion suggestion: ' + suggestion + 'with index of: ' + index);
                    this.inputString = suggestion;
                    
                } else {
                    console.log('selectSuggestion suggestion[this.suggestionsPoolPrefix + \'MinutesValue\']: ' + suggestion[this.suggestionsPoolPrefix + 'MinutesValue'] + 'with index of: ' + index);
                    this.inputString = suggestion[this.suggestionsPoolPrefix + 'MinutesValue'];
                    console.log('inputString(suggestion[this.this.suggestionsPoolPrefix + \'MinutesValue\']): ' + this.inputString);
                    this.inputString = this.suggestionsPool[index][this.suggestionsPoolPrefix + 'MinutesValue'];
                    console.log('inputString(this.suggestions[index][this.suggestionsPoolPrefix + \'MinutesValue\']): ' + this.inputString);
                }

                console.log('\n\tsuggestion[' + index + ']: ' + suggestion[index]);
                console.log('\n\inputString: ' +  this.inputString );

                this.showSuggestions = false;
                this.$emit( 'updateTextInput', { 'inputVariableName': this.inputVariableName, 'inputString': this.inputString } );
                this.$emit( 'updateComputed' );
               
                
            },
            handleClickOutside(event) {
                if (!this.$el.contains(event.target)) {
                    console.log('this.$el' + this.$el);
                    this.showSuggestions = false;
                }
            }
        }
    };
</script>

<style>
    /* #app {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        margin-top: 60px;
    } */
    :root {
        --left-offset: .5rem;
    }

    .auto-complete-container {
        position: relative;
        /* width: 5.75rem; */
        width: calc(100% - var(--left-offset));
    }

    .auto-complete-container input {
        width: 100%;
        /* width :6rem; */
    
    }

    .auto-complete-suggestions {
        /* top: -1rem; */
        padding: 0;
        position: fixed;

        padding-top: .125rem;
        margin: 0;
        margin-left: var(--left-offset);
        margin-top: -1.5rem;
        border: 1px solid #eeeeee;
        max-height: 33vh;
        height: auto;
        overflow: auto;
        z-index: 1;
        width: calc(15rem - calc(var(--left-offset) * 2));
        background: rgba(130, 130, 130, .5);
        border: .25rem inset rgba(13, 220, 250, .995);
        border-bottom-left-radius: .35rem;
        border-bottom-right-radius: .35rem;
        box-shadow: 1.125rem 0.125rem 0.5rem rgba(6, 6, 6, .95);
        border-top: none;
    }

    .auto-complete-suggestion {
        list-style: none;
        margin: 0;
        text-align: left;
        line-height: 1.1;
        padding: .25rem 0;
        margin-left: -1.5rem;
        padding-left: 1rem;
        font-size: 1.1rem;
        color: rgb(240, 245, 250);
        font-family: Tomorrow;
        /* font-family: Prompt; */
        font-weight: 400;
        letter-spacing: .035rem;
        background-color: rgba(26, 26, 26, .65);
    }

    .auto-complete-suggestion:nth-child(2n+1) {
        background-color: rgba(6, 6, 6, .65);
    }

    .auto-complete-suggestions > .auto-complete-suggestion.is-active,
    .auto-complete-suggestions > .auto-complete-suggestion:hover {
        background-color: rgba(25, 50, 175, .85);
        color: rgb(225, 225, 250);
    }

</style>

